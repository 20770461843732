import React, { Component } from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import reqwest from 'reqwest';

import settings from '../../../../settings';
import Loader from '../../../components/Loader';
import Dropdown from '../../../components/Inputs/Dropdown';
import DataContainer from '../../../logic/dataContainer';

class TimeTracking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: [],
      SelectedMonth: moment().format('MM'),
      SelectedYear: moment().format('YYYY'),
    };

    const userData = JSON.parse(localStorage.getItem('userdata'));
    this.UserGuid = userData.userGuid;
  }

  componentDidMount() {
    this._getTimeTracking();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.SelectedMonth !== this.state.SelectedMonth || prevState.SelectedYear !== this.state.SelectedYear) {
      this._getTimeTracking();
    }
  }

  _getTimeTracking = () => {
    reqwest({
      method: 'GET',
      url: settings.azn.timetracking.getList,
      data: {
        Token: localStorage.getItem('token'),
        UserGuid: this.UserGuid,
        Month: this.state.SelectedMonth,
        Year: this.state.SelectedYear,
      },
    })
      .then(res => {
        this.dataContainer = new DataContainer(res);
        this.setState({
          loading: false,
        });
      })
      .catch(() => {
        throw new Error('Something went very very wrong...');
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.dataContainer.get('GetAznMonth', 'Days');

    const sumData = [
      {
        label: 'Geleistete Stunden',
        totalHours: this.dataContainer.get('GetAznMonth', 'WTime'),
        sfnSunday: this.dataContainer.get('GetAznMonth', 'SFNSun'),
        sfnHoliday: this.dataContainer.get('GetAznMonth', 'SFNHol'),
      },
      {
        label: 'SFN-Stunden',
        totalHours: this.dataContainer.get('GetAznMonth', 'SFNSum'),
      },
      {
        label: 'Übertrag Vormonat',
        totalHours: this.dataContainer.get('GetAznMonth', 'OWTime'),
      },
      {
        label: 'Sollstunden',
        totalHours: this.dataContainer.get('GetAznMonth', 'PWTime'),
      },
      {
        label: 'Feiertage',
        totalHours: this.dataContainer.get('GetAznMonth', 'HWTime'),
      },
      {
        label: 'Gesamtsumme',
        totalHours: this.dataContainer.get('GetAznMonth', 'WTimeSum'),
      },
    ];

    return (
      <>
        <div className='Toolbar'>
          <Dropdown
            validator={[]}
            defaultValue={this.state.SelectedMonth}
            placeholder='Monat'
            options={[
              { label: 'Alle', value: '' },
              { label: 'Januar', value: '01' },
              { label: 'Februar', value: '02' },
              { label: 'März', value: '03' },
              { label: 'April', value: '04' },
              { label: 'Mai', value: '05' },
              { label: 'Juni', value: '06' },
              { label: 'Juli', value: '07' },
              { label: 'August', value: '08' },
              { label: 'September', value: '09' },
              { label: 'Oktober', value: '10' },
              { label: 'November', value: '11' },
              { label: 'Dezember', value: '12' },
            ]}
            getSelected={value => {
              this.setState({
                SelectedMonth: value,
              });
            }}
            name='Month'
          />
          <Dropdown
            validator={[]}
            defaultValue={this.state.SelectedYear}
            placeholder='Jahr'
            options={Array.from({ length: 10 }, (v, i) => new Date().getFullYear() - i).map(year => ({ label: `${year}`, value: `${year}` }))}
            getSelected={value => {
              this.setState({
                SelectedYear: value,
              });
            }}
            name='Year'
          />
          <div className='Toolbar-buttons' />
        </div>
        <ReactTable
          getTrProps={(_, { original }) => ({
            onClick: () =>
              this.props.history.replace(
                `${this.props.match.url}/CorrectionRequest/new/${moment(original.Params.Date, 'DD.MM.YYYY').format('YYYY-MM-DD')}`,
              ),
          })}
          data={data}
          pageSize={data.length}
          noDataText='Es liegen keine Zeitbuchungen für den ausgewählten Zeitraum vor.'
          columns={[
            {
              Header: 'Datum',
              accessor: 'Params.Date',
              width: 200,
            },
            {
              Header: 'Beginn',
              accessor: 'Params.From',
              width: 150,
            },
            {
              Header: 'Ende',
              accessor: 'Params.To',
              width: 150,
            },
            {
              Header: 'Pause',
              accessor: 'Params.Break',
              width: 150,
            },
            {
              Header: 'Gesamtstunden',
              accessor: 'Params.Hours',
            },
            {
              Header: 'SFN-Sonntag',
              accessor: 'Params.SFNSunday',
            },
            {
              Header: 'SFN-Feiertag',
              accessor: 'Params.SFNHoliday',
            },
            {
              Header: 'Typ',
              accessor: 'Params.Type',
            },
          ]}
        />
        <div style={{ borderTop: '1px solid #333333' }} />
        <ReactTable
          data={sumData}
          pageSize={sumData.length}
          columns={[
            {
              Header: '',
              headerStyle: {
                display: 'none',
              },
              style: {
                fontWeight: 700,
              },
              accessor: 'label',
              width: 650,
            },
            {
              Header: '',
              headerStyle: {
                display: 'none',
              },
              accessor: 'totalHours',
            },
            {
              Header: '',
              headerStyle: {
                display: 'none',
              },
              accessor: 'sfnSunday',
            },
            {
              Header: '',
              headerStyle: {
                display: 'none',
              },
              accessor: 'sfnHoliday',
            },
            {
              Header: '',
              headerStyle: {
                display: 'none',
              },
              accessor: 'type',
            },
          ]}
        />
      </>
    );
  }
}

export default TimeTracking;
