import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

class Searchbar extends Component {
  render() {
    const { onChange, ...props } = this.props;
    return (
      <div className='Searchbar-wrapper'>
        <div className='Searchbar'>
          <FontAwesomeIcon icon={faSearch} className='Searchbar-icon' />
          <input
            className='Searchbar-Field'
            type='text'
            {...props}
            onChange={evt => {
              const { value } = evt.target;
              onChange(value, evt);
            }}
          />
        </div>
      </div>
    );
  }
}

Searchbar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Searchbar.defaultProps = {
  placeholder: 'Suchen...',
};

export default Searchbar;
