import React from 'react';
import PropTypes from 'prop-types';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../../settings';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import ContentBox from '../../../../../components/FormArea/ContentBox';

class TravelExpensesRequestOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      travelExpensesRequestList: [],
      loading: true,
    };

    reqwest({
      method: 'get',
      url: settings.usermanagement.travelExpensesRequest.getList,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
        UserGuid: this.props.UserGuid,
      },
    }).then(travelExpensesRequestResponse => {
      const travelExpensesRequestList = JSON.parse(travelExpensesRequestResponse);
      this.setState({
        travelExpensesRequestList,
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.state.travelExpensesRequestList.filter(row => row.Guid);

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Reisekostenanträge'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => this.props.history.replace(`${this.props.match.url}/${original.Guid}`),
              })}
              data={data}
              pageSize={data.length}
              noDataText='Es liegen keine Reisekostenanträge für den ausgewählten Benutzer vor.'
              columns={[
                {
                  Header: 'Datum des Antrags',
                  accessor: 'Date',
                },
                {
                  Header: 'Anlass',
                  accessor: 'Occasion',
                },
                {
                  Header: 'Termin',
                  accessor: 'Appointment',
                },
              ]}
            />
          </ContentBox>
        </div>
        <RightSide></RightSide>
      </>
    );
  }
}

TravelExpensesRequestOverview.propTypes = {
  UserGuid: PropTypes.string.isRequired,
};

export default TravelExpensesRequestOverview;
