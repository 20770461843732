import React from 'react';
import PropTypes from 'prop-types';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../../settings';

import Loader from '../../../../../components/Loader';
import RightSide from '../../../../../components/FormArea/RightSide';
import ContentBox from '../../../../../components/FormArea/ContentBox';

class TrainingsOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      trainings: [],
      loading: true,
    };

    reqwest({
      method: 'get',
      url: settings.usermanagement.trainings.getList,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
        UserGuid: this.props.UserGuid,
      },
    }).then(trainingsResponse => {
      const trainings = JSON.parse(trainingsResponse);
      this.setState({
        trainings,
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.state.trainings.filter(row => row.Params.Guid);
    /** Test */
    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Schulungen / Inforeisen'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => this.props.history.replace(`/Workshops/${original.Params.Guid}`),
              })}
              data={data}
              pageSize={data.length}
              noDataText='Es liegen keine Inforeisen / Schulungen für den ausgewählten Benutzer vor.'
              columns={[
                {
                  Header: 'Titel',
                  accessor: 'Params.Title',
                },
                {
                  Header: 'Von',
                  accessor: 'Params.From',
                },
                {
                  Header: 'Bis',
                  accessor: 'Params.To',
                },
                {
                  Header: 'Status',
                  accessor: 'Params.Status',
                },
              ]}
            />
          </ContentBox>
        </div>
        <RightSide></RightSide>
      </>
    );
  }
}

TrainingsOverview.propTypes = {
  UserGuid: PropTypes.string.isRequired,
};

export default TrainingsOverview;
