import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import RightSide from '../../../../../components/FormArea/RightSide';
import Button from '../../../../../components/Button';
import Form from '../../../../../components/Form';
import Input from '../../../../../components/Inputs/Input';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Switch from '../../../../../components/Inputs/Switch';
import Datepicker from '../../../../../components/Inputs/Datepicker';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';

class ContractNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');
    this.dataContainer = new DataContainer();
    this.dataContainer.add('Basic', {
      Guid: '',
      Typ: 'FranContract',
      FranGuid: this.props.franchiseGuid,
    });
    this.dataContainer.add('Generals', {
      Amount: '',
      ContractDate: '',
      From: '',
      To: '',
      Guarantee: '',
      Form: '',
      TerritorialProtection: '',
      OptionalTerrProtection: '',
      FranchiseHolder: '',
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const newContract = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.franchise.contract.save,
      data: {
        Token: localStorage.getItem('token'),
        Contract: newContract,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Ihr Vertrag wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Vertrag'>
              <Input
                type='text'
                label='Franchisegebühr:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Generals', 'Amount')(value);
                }}
                name='Amount'
                defaultValue={this.dataContainer.get('Generals', 'Amount')}
              />
              <Datepicker
                label='Vertragsabschluss:'
                onChange={date => {
                  this.dataContainer.set('Generals', 'ContractDate')(date);
                }}
                validator={[]}
                defaultValue={this.dataContainer.get('Generals', 'ContractDate')}
                name='ContractDate'
              />
              <Datepicker
                label='Gültig ab:'
                onChange={date => {
                  this.dataContainer.set('Generals', 'From')(date);
                }}
                validator={[]}
                defaultValue={this.dataContainer.get('Generals', 'From')}
                name='From'
              />
              <Datepicker
                label='Laufzeit bis:'
                onChange={date => {
                  this.dataContainer.set('Generals', 'To')(date);
                }}
                validator={[]}
                defaultValue={this.dataContainer.get('Generals', 'To')}
                name='To'
              />
              <Switch
                label='Bürgschaft:'
                name='Guarantee'
                onChange={value => {
                  this.dataContainer.set('Generals', 'Guarantee')(value);
                }}
                validator={[]}
                defaultChecked={this.dataContainer.get('Generals', 'Guarantee')}
              />
              <Input
                type='text'
                label='Bürgschaftsform:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Generals', 'Form')(value);
                }}
                name='Form'
                defaultValue={this.dataContainer.get('Generals', 'Form')}
              />
              <Switch
                label='Aktivpaket:'
                name='OptionalTerrProtection'
                onChange={value => {
                  this.dataContainer.set('Generals', 'OptionalTerrProtection')(value);
                }}
                validator={[]}
                defaultChecked={this.dataContainer.get('Generals', 'OptionalTerrProtection')}
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default ContractNew;
