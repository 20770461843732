import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Detail from './Detail';

const OfficeBasedata = props => {
  return (
    <Switch>
      <Route render={routerProps => <Detail {...routerProps} {...props} />} />
    </Switch>
  );
};

export default withRouter(OfficeBasedata);
