import React from 'react';
import PropTypes from 'prop-types';

import reqwest from 'reqwest';
import swal from 'sweetalert';
import moment from 'moment';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import RightSide from '../../../../../components/FormArea/RightSide';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Inputs/Input';
import Datepicker from '../../../../../components/Inputs/Datepicker';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';

class ContractDataNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      contractList: [],
    };

    this.parentRoute = this.props.match.url.replace(new RegExp('/new$'), '');

    this.dataContainer = new DataContainer();
    this.dataContainer.add('Basic', {
      Guid: '',
      Typ: 'Contract',
      UserGuid: this.props.UserGuid,
    });
    this.dataContainer.add('Generals', {});

    const promiseContractsList = reqwest({
      method: 'get',
      url: settings.usermanagement.contracts.getList,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
        UserGuid: this.props.UserGuid,
      },
    }).then(contractsResponse => {
      const contractList = JSON.parse(contractsResponse);
      this.setState({
        contractList,
      });
    });

    Promise.all([promiseContractsList]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const contractList = this.state.contractList.map(contract => {
      return {
        from: contract.Params.From,
        to: contract.Params.To,
      };
    });
    const enteredRange = moment.range(
      moment(this.dataContainer.get('Generals', 'From'), 'DD.MM.YYYY'),
      moment(this.dataContainer.get('Generals', 'To'), 'DD.MM.YYYY'),
    );
    const rangeValid = contractList.every(contract => {
      const range = moment.range(moment(contract.from, 'DD.MM.YYYY'), moment(contract.to, 'DD.MM.YYYY'));
      return !enteredRange.overlaps(range);
    });

    if (!rangeValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Die eingegebenen Daten überschneiden sich mit anderen Verträgen',
      });
      return;
    }

    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    // TODO: Switch to List of Datacontainers
    const newContract = {
      ParamName: 'Entry',
      Params: {
        ...this.dataContainer.get('Basic'),
        ...this.dataContainer.get('Generals'),
      },
    };

    reqwest({
      method: 'POST',
      url: settings.usermanagement.contracts.save,
      data: {
        Token: localStorage.getItem('token'),
        Contract: JSON.stringify(newContract),
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Ihre Vertrag wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Neuer Vertrag'>
              <Input
                type='text'
                validator={[]}
                label='Grundgehalt mtl.:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'BaseSalary')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'BaseSalary')}
                name='Grundgehalt'
              />
              <Input
                type='text'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                  {
                    type: 'number',
                    error: 'Bitte geben Sie nur Zahlen ein',
                  },
                ]}
                label='Sollarbeitsstunden mtl.:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'WorkingHours')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'WorkingHours')}
                name='Stunden'
              />
              <Datepicker
                label='Sollarbeitsstunden Start:'
                onChange={day => {
                  this.dataContainer.set('Generals', 'WorkingHoursStart')(day);
                }}
                defaultValue={this.dataContainer.get('Generals', 'WorkingHoursStart')}
                validator={[]}
                name='WorkingHoursStart'
              />
              <Input
                type='text'
                validator={[]}
                label='Provision in Prozent:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'Commission')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Commission')}
                name='Provision in Prozent'
              />
              <Input
                type='text'
                validator={[]}
                label='Provision in Euro:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'CommissionEuro')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'CommissionEuro')}
                name='Provision in Euro'
              />
              <Input
                type='text'
                validator={[]}
                label='Geplanter Umsatz:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'PlanTurnover')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'PlanTurnover')}
                name='Geplanten Umsatz'
              />
              <Input
                type='text'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                  {
                    type: 'number',
                    error: 'Bitte geben Sie nur Zahlen ein',
                  },
                ]}
                label='Urlaubstage:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'Holidays')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Holidays')}
                name='Urlaubstage'
              />
              <Datepicker
                label='Gültig von:'
                onChange={day => {
                  this.dataContainer.set('Generals', 'From')(day);
                }}
                defaultValue={this.dataContainer.get('Generals', 'From')}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                  {
                    custom: value => {
                      if (!this.dataContainer.get('Generals', 'To') || !value) {
                        return true;
                      }
                      const validFrom = moment(value, 'DD.MM.YYYY');
                      const validTo = moment(this.dataContainer.get('Generals', 'To'), 'DD.MM.YYYY');
                      return validFrom.isBefore(validTo);
                    },
                    error: 'Von darf nicht nach Bis liegen.',
                  },
                ]}
                name='From'
              />
              <Datepicker
                label='Gültig bis:'
                onChange={day => {
                  this.dataContainer.set('Generals', 'To')(day);
                }}
                defaultValue={this.dataContainer.get('Generals', 'To')}
                validator={[
                  {
                    custom: value => {
                      if (!this.dataContainer.get('Generals', 'From') || !value) {
                        return true;
                      }
                      const validFrom = moment(this.dataContainer.get('Generals', 'From'), 'DD.MM.YYYY');
                      const validTo = moment(value, 'DD.MM.YYYY');
                      return validTo.isAfter(validFrom);
                    },
                    error: 'Bis darf nicht vor Von liegen.',
                  },
                ]}
                name='To'
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

ContractDataNew.propTypes = {
  UserGuid: PropTypes.string.isRequired,
};

export default ContractDataNew;
