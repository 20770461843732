import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { NavLink, Switch, Route } from 'react-router-dom';
import reqwest from 'reqwest';

import Loader from '../../../components/Loader';

import Basedata from './tabs/Basedata';
import Bankdata from './tabs/Bankdata/index';
import Owner from './tabs/Owner/index';
import Contracts from './tabs/Contracts/index';
import Technic from './tabs/Technic/index';
import InventoryModule from './tabs/InventoryModule/index';
import Appearance from './tabs/Appearance';
import CentralMarketing from './tabs/CentralMarketing/index';
import Employees from './tabs/Employees/index';
import OpeningHours from './tabs/OpeningHours/index';
import Files from './tabs/Files';
import PWManager from './tabs/PWManager';

import DataContainer from '../../../logic/dataContainer';
import settings from '../../../../settings';

class FranchiseOfficeManagement extends Component {
  constructor(props) {
    super(props);
    this.guid = props.match.params.guid;
    this.state = {
      loading: true,
      groups: [],
    };

    const fPromise = reqwest({
      method: 'GET',
      url: settings.franchise.general.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: this.guid,
      },
    }).then(res => {
      this.dataContainer = new DataContainer(res);
    });

    const gPromise = reqwest({
      method: 'get',
      url: settings.inventory.GetGroups,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(groupsResponse => {
      const groups = JSON.parse(groupsResponse);
      this.setState({
        groups: groups
          .filter(
            group =>
              !!group[0].Params.Guid &&
              group[1].Params.Active === 'true' &&
              (group[1].Params.ValidFor === 'Franchise' || group[1].Params.ValidFor === 'beide'),
          )
          .sort((a, b) => {
            if (a[1].Params.Weight > b[1].Params.Weight) return 1;
            if (a[1].Params.Weight < b[1].Params.Weight) return -1;
            return 0;
          }),
      });
    });

    Promise.all([fPromise, gPromise]).then(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>
              {this.dataContainer.get('FGenerals', 'Agencynumber')} {this.dataContainer.get('FGenerals', 'Shortname')}
            </p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Tabs'>
              <NavLink exact to={`${this.props.match.url}`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
                Stammdaten
              </NavLink>
              <NavLink to={`${this.props.match.url}/OpeningHours`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
                Öffnungszeiten
              </NavLink>
              <NavLink to={`${this.props.match.url}/Bankdata`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
                Bankdaten
              </NavLink>
              <NavLink to={`${this.props.match.url}/Owner`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
                Privatadresse
              </NavLink>
              <NavLink to={`${this.props.match.url}/Contracts`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
                Verträge
              </NavLink>
              <NavLink to={`${this.props.match.url}/Technic`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
                Technik
              </NavLink>
              <NavLink to={`${this.props.match.url}/Appearance`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
                Erscheinungsbild
              </NavLink>
              <NavLink to={`${this.props.match.url}/CentralMarketing`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
                Zentrales Marketing
              </NavLink>
              <NavLink to={`${this.props.match.url}/Employees`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
                Mitarbeiter
              </NavLink>
              <NavLink to={`${this.props.match.url}/Files`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
                Dateien
              </NavLink>
              <NavLink to={`${this.props.match.url}/PasswordManager`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
                Passwortmanager
              </NavLink>
              {this.state.groups.map(group => {
                return (
                  <NavLink
                    key={group[0].Params.Guid}
                    to={`${this.props.match.url}/Inventory/${group[0].Params.Guid}`}
                    className='FormArea-Body-Tab'
                    activeClassName='is-Active'
                    replace
                  >
                    {group[1].Params.Name}
                  </NavLink>
                );
              })}
            </div>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <Switch>
                    <Route path={`${this.props.match.path}/Bankdata`} render={() => <Bankdata franchiseGuid={this.guid} />} />
                    <Route path={`${this.props.match.path}/Owner`} render={() => <Owner franchiseGuid={this.guid} />} />
                    <Route path={`${this.props.match.path}/Contracts`} render={() => <Contracts franchiseGuid={this.guid} />} />
                    <Route path={`${this.props.match.path}/Technic`} render={() => <Technic franchiseGuid={this.guid} />} />
                    <Route path={`${this.props.match.path}/Appearance`} render={() => <Appearance franchiseGuid={this.guid} />} />
                    <Route path={`${this.props.match.path}/CentralMarketing`} render={() => <CentralMarketing franchiseGuid={this.guid} />} />
                    <Route path={`${this.props.match.path}/Employees`} render={() => <Employees franchiseGuid={this.guid} />} />
                    <Route path={`${this.props.match.path}/OpeningHours`} render={() => <OpeningHours franchiseGuid={this.guid} />} />
                    <Route path={`${this.props.match.path}/Files`} render={() => <Files franchiseGuid={this.guid} />} />
                    <Route path={`${this.props.match.path}/PasswordManager`} render={() => <PWManager franchiseGuid={this.guid} />} />
                    <Route path={`${this.props.match.path}/Inventory/:GroupGuid`} render={() => <InventoryModule OfficeGuid={this.guid} />} />
                    <Route render={() => <Basedata franchiseGuid={this.guid} />} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

FranchiseOfficeManagement.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      guid: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default FranchiseOfficeManagement;
