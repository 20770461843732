import reqwest from 'reqwest';
import settings from '../../settings';

const avaliableToCheck = ['email', 'username', 'expnr', 'ccr', 'agencynumber'];
const checkAvailable = async (field = 'username', value = '') => {
  if (!avaliableToCheck.includes(field)) {
    throw new Error(
      `Value "${field}" is not avaliable to check if already taken. Perhaps it's not unqiue? Please use one of these: ${avaliableToCheck.join(', ')}`,
    );
  }

  const data = await reqwest({
    method: 'GET',
    url: settings.validators[field],
    data: {
      Token: localStorage.getItem('token'),
      [field]: value,
    },
  });

  if (data === 'No Data') {
    return true;
  }

  const container = JSON.parse(data);
  const results = container.Params;
  const found = Object.keys(results).some(key => {
    return results[key] === 'value found';
  });
  return !found;
};

export default checkAvailable;
