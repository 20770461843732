import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Details from './Detail';
import Overview from './Overview';
import New from './New';
import Modal from '../../components/Modal';

const OfficeManagement = props => {
  const paths = {
    new: `${props.match.path}/new`,
    details: `${props.match.path}/:ccr`,
    search: `${props.match.path}/search/:search?`,
  };

  return (
    <Modal title='Stammdaten Büro'>
      <Switch>
        <Route path={paths.new} component={New} />
        <Route path={paths.search} component={Overview} />
        <Route path={paths.details} component={Details} />
        <Route component={Overview} />
      </Switch>
    </Modal>
  );
};

export default withRouter(OfficeManagement);
