import React from 'react';
import PropTypes from 'prop-types';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../../settings';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Button from '../../../../../components/Button';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

class PersonalAccessOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      personalAccessList: [],
      loading: true,
    };

    reqwest({
      method: 'get',
      url: settings.usermanagement.personalAccess.getList,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
        UserGuid: this.props.UserGuid,
      },
    }).then(personalAccessResponse => {
      const personalAccessList = JSON.parse(personalAccessResponse);
      this.setState({
        personalAccessList,
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.state.personalAccessList.filter(row => row.Guid);

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Persönliche Zugänge'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => this.props.history.replace(`${this.props.match.url}/${original.Guid}`),
              })}
              data={data}
              pageSize={data.length}
              noDataText='Es liegen keine Zugänge für den ausgewählten Benutzer vor.'
              columns={[
                {
                  Header: 'Kostenstelle',
                  accessor: 'CCR',
                },
                {
                  Header: 'Portal',
                  accessor: 'Portal',
                },
                {
                  Header: 'Benutzername',
                  accessor: 'Username',
                },
                {
                  Header: 'URL',
                  accessor: 'URL',
                },
              ]}
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faPlus} to={`${this.props.match.url}/new`} replace>
            Neuer Zugang
          </Button>
        </RightSide>
      </>
    );
  }
}

PersonalAccessOverview.propTypes = {
  UserGuid: PropTypes.string.isRequired,
};

export default PersonalAccessOverview;
