import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import VacationRequest from './VacationRequest';
import SickDays from './SickDays';
import TimeTracking from './TimeTracking';

const AZN = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/SickDays`} render={() => <SickDays />} />
      <Route path={`${props.match.path}/VacationRequest`} render={() => <VacationRequest />} />
      <Route path={`${props.match.path}/TimeTracking`} render={() => <TimeTracking />} />
    </Switch>
  );
};

export default withRouter(AZN);
