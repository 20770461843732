import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faEye, faPen, faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Link, generatePath } from 'react-router-dom';
import swal from 'sweetalert';
import reqwest from 'reqwest';

import Button from '../../../components/Button';
import settings from '../../../../settings';

class Month extends React.Component {
  prev = () => {
    const prevMonth = this.currentMonth.subtract(1, 'month').format('M');
    const prevMonthYear = this.currentMonth.subtract(1, 'month').format('YYYY');
    const path = generatePath(this.props.match.path, {
      ...this.props.match.params,
      month: prevMonth,
      year: prevMonthYear,
    });
    this.props.history.replace(path);
  };

  next = () => {
    const nextMonth = this.currentMonth.add(1, 'month').format('M');
    const nextMonthYear = this.currentMonth.add(1, 'month').format('YYYY');
    const path = generatePath(this.props.match.path, {
      ...this.props.match.params,
      month: nextMonth,
      year: nextMonthYear,
    });
    this.props.history.replace(path);
  };

  sendToReview = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text:
        'Möchten Sie diesen Dienstplan wirklich zur Prüfung freigeben? Sie können während der Prüfungszeit keine Änderungen mehr am Dienstplan vornehmen.',
      icon: 'warning',
      buttons: ['Abbrechen', 'Ja, zur Prüfung freigeben'],
    }).then(data => {
      if (data) {
        reqwest({
          method: 'GET',
          url: settings.dutySchedule.review,
          data: {
            Token: localStorage.getItem('token'),
            OfficeGuid: this.props.officeData.Guid,
            Month: this.currentMonth.format('MM'),
            Year: this.currentMonth.format('YYYY'),
            OfficeState: 'review',
          },
        })
          .then(() => {
            swal({
              title: 'Erfolgreich!',
              text: 'Der Dienstplan wurde erfolgreich zur Prüfung eingereicht.',
              icon: 'success',
            });
            this.props.getRosterOverview();
          })
          .catch(() => {
            swal({
              title: 'Fehler!',
              text: 'Beim einreichen des Dienstplans ist ein Fehler aufgetreten.',
              icon: 'error',
            });
          });
      }
    });
  };

  decline = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diesen Dienstplan wirklich ablehnen?',
      icon: 'warning',
      buttons: ['Nein, abbrechen', 'Ja, ablehnen'],
      dangerMode: true,
    }).then(data => {
      if (data) {
        reqwest({
          method: 'GET',
          url: settings.dutySchedule.decline,
          data: {
            Token: localStorage.getItem('token'),
            OfficeGuid: this.props.officeData.Guid,
            Month: this.currentMonth.format('MM'),
            Year: this.currentMonth.format('YYYY'),
            OfficeState: 'declined',
          },
        })
          .then(() => {
            swal({
              title: 'Erfolgreich!',
              text: 'Der Dienstplan wurde erfolgreich abgelehnt.',
              icon: 'success',
            });
            this.props.getRosterOverview();
          })
          .catch(() => {
            swal({
              title: 'Fehler!',
              text: 'Beim Ablehnen des Dienstplans ist ein Fehler aufgetreten.',
              icon: 'error',
            });
          });
      }
    });
  };

  accept = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diesen Dienstplan wirklich akzeptieren?',
      icon: 'warning',
      buttons: ['Nein, abbrechen', 'Ja, akzeptieren'],
    }).then(data => {
      if (data) {
        reqwest({
          method: 'GET',
          url: settings.dutySchedule.accept,
          data: {
            Token: localStorage.getItem('token'),
            OfficeGuid: this.props.officeData.Guid,
            Month: this.currentMonth.format('MM'),
            Year: this.currentMonth.format('YYYY'),
            OfficeState: 'accepted',
          },
        })
          .then(() => {
            swal({
              title: 'Erfolgreich!',
              text: 'Der Dienstplan wurde erfolgreich akzeptiert.',
              icon: 'success',
            });
            this.props.getRosterOverview();
          })
          .catch(() => {
            swal({
              title: 'Fehler!',
              text: 'Beim Akzeptieren des Dienstplans ist ein Fehler aufgetreten.',
              icon: 'error',
            });
          });
      }
    });
  };

  render() {
    this.currentMonth = moment(`${this.props.match.params.month}-${this.props.match.params.year}`, 'M-YYYY');

    // Shamelessly stolen from https://stackoverflow.com/a/48143904
    let calendar = [];
    const startDay = this.currentMonth
      .clone()
      .startOf('month')
      .startOf('week');
    const endDay = this.currentMonth
      .clone()
      .endOf('month')
      .endOf('week');

    let date = startDay.clone().subtract(1, 'day');

    while (date.isBefore(endDay, 'day')) {
      calendar.push({
        id: date
          .clone()
          .add(1, 'day')
          .week(),
        days: Array(7)
          .fill(0)
          .map(() => date.add(1, 'day').clone()),
      });
    }

    const numVacations = this.props.vacations.reduce((accumulator, vacations) => {
      return accumulator + parseInt(vacations.Vacation, 10) + parseInt(vacations.VacationRequests, 10);
    }, 0);

    return (
      <>
        <div className='DutyRoster'>
          <div className='DutyRoster-calendar'>
            <h2>Monatsübersicht für {this.currentMonth.format('MMMM YYYY')}</h2>
            <div className='DutyRoster-header'>
              <button className='DutyRoster-prevMonth' onClick={this.prev}>
                <FontAwesomeIcon icon={faCaretLeft} size='2x' />
              </button>
              <div>{this.currentMonth.format('MMMM YYYY')}</div>
              <button className='DutyRoster-nextMonth' onClick={this.next}>
                <FontAwesomeIcon icon={faCaretRight} size='2x' />
              </button>
            </div>
            <div className='DutyRoster-week DutyRoster-week--heading'>
              {moment.weekdaysMin(true).map(day => (
                <div className='DutyRoster-day' key={day}>
                  {day}
                </div>
              ))}
            </div>
            {calendar.map(week => {
              return (
                <div className='DutyRoster-week DutyRoster-week--body' key={week.id}>
                  {!this.props.isLocked && (
                    <Link
                      to={`${this.props.match.url}${this.props.match.url.endsWith('/') ? '' : '/'}week/${week.id}`}
                      className='DutyRoster-editWeek'
                    >
                      <FontAwesomeIcon className='' icon={faPen} />
                    </Link>
                  )}
                  {week.days.map(day => {
                    const classNames = [];

                    if (moment(day).format('MM') !== this.currentMonth.format('MM')) {
                      return <div className='DutyRoster-day' key={day} />;
                    }

                    const status = this.props.officeData.OfficeCrew[parseInt(day.format('D'), 10) - 1];

                    const isWorkingDay =
                      this.props.openingHours &&
                      this.props.openingHours.some(item => {
                        return moment(day).format('dddd') === item.Day;
                      });

                    if (day.isSame(moment(), 'day')) {
                      classNames.push('DutyRoster-day--today');
                    }
                    if (status === 'O' && isWorkingDay) {
                      classNames.push('DutyRoster-day--red');
                    }
                    if (status === 'B' && isWorkingDay) {
                      classNames.push('DutyRoster-day--yellow');
                    }
                    if (status === 'E') {
                      classNames.push('DutyRoster-day--green');
                    }

                    return (
                      <Link
                        to={`${this.props.match.url}${this.props.match.url.endsWith('/') ? '' : '/'}week/${week.id}`}
                        className={`DutyRoster-day ${classNames.join(' ')}`}
                        key={day}
                      >
                        <span className='DutyRoster-dayNumber'>{day.format('D')}</span>
                        <FontAwesomeIcon className='DutyRoster-dayOverviewLink' icon={faEye} />
                      </Link>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {numVacations > 0 && (
            <div className='DutyRoster-vacation'>
              <h2>
                {numVacations} Urlaubsanträge für den Zeitraum {this.currentMonth.format('MMMM')}
              </h2>
              <table className='VacationTable' border='0'>
                <thead>
                  <tr className='VacationTable-head'>
                    <th>Zeitraum</th>
                    <th>Mitarbeiter</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='VacationTable-sectionHead VacationTable-sectionHead--grey'>
                    <td colSpan='3'>Beantragt</td>
                  </tr>
                  {this.props.vacations.map(employee => {
                    return employee.VacReq.map(request => {
                      return (
                        <tr key={request}>
                          <td>
                            {request.Params.From} - {request.Params.To}
                          </td>
                          <td>
                            {employee.Firstname} {employee.Lastname}
                          </td>
                          <td>
                            <Link to={`/AZN/VacationRequest/${request.Params.Guid}`} className='VacationTable-editVacation'>
                              <FontAwesomeIcon icon={faPen} />
                            </Link>
                          </td>
                        </tr>
                      );
                    });
                  })}
                  <tr className='VacationTable-sectionHead VacationTable-sectionHead--green'>
                    <td colSpan='3'>Genehmigt</td>
                  </tr>
                  {this.props.vacations.map(employee => {
                    return employee.Vac.map(request => {
                      return (
                        <tr key={request}>
                          <td>
                            {request.Params.From} - {request.Params.To}
                          </td>
                          <td>
                            {employee.Firstname} {employee.Lastname}
                          </td>
                          <td>
                            <Link to={`/AZN/VacationRequest/${request.Params.Guid}`} className='VacationTable-editVacation'>
                              <FontAwesomeIcon icon={faPen} />
                            </Link>
                          </td>
                        </tr>
                      );
                    });
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {this.props.officeData.OfficeState !== 'accepted' && (
          <>
            <hr />
            <div className='Toolbar'>
              <div className='Toolbar-buttons'>
                {(!this.props.officeData.OfficeState ||
                  this.props.officeData.OfficeState === 'editable' ||
                  this.props.officeData.OfficeState === 'declined') && (
                  <Button type='primary' icon={faCheck} onClick={this.sendToReview}>
                    Zur Prüfung freigeben
                  </Button>
                )}
                {this.props.officeData.OfficeState === 'review' && (
                  <>
                    <Button type='danger' icon={faTimes} onClick={this.decline}>
                      Dienstplan ablehnen
                    </Button>
                    <Button type='primary' icon={faCheck} onClick={this.accept}>
                      Dienstplan akzeptieren
                    </Button>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

Month.propTypes = {
  loading: PropTypes.bool,
  officeData: PropTypes.shape({
    CCR: PropTypes.string,
    Shortname: PropTypes.string,
    Street: PropTypes.string,
    Zip: PropTypes.string,
    City: PropTypes.string,
    State: PropTypes.string,
    Officetype: PropTypes.string,
    Tel: PropTypes.string,
    Fax: PropTypes.string,
    EMail: PropTypes.string,
    Opening: PropTypes.string,
    Closure: PropTypes.string,
    Minemployees: PropTypes.string,
    Supervisor: PropTypes.string,
    Showcase: PropTypes.string,
    Cooperationoffices: PropTypes.arrayOf(PropTypes.string),
    Planscales: PropTypes.string,
    Comment1: PropTypes.string,
    Comment2: PropTypes.string,
    CreateDate: PropTypes.string,
    CreateUserGuid: PropTypes.string,
    CreateUserName: PropTypes.string,
    UpdateDate: PropTypes.string,
    UpdateUserGuid: PropTypes.string,
    UpdateUserName: PropTypes.string,
    Typ: PropTypes.string,
    Guid: PropTypes.string,
    DateTime: PropTypes.string,
    OfficeCrew: PropTypes.string,
    OfficeState: PropTypes.string,
  }),
  weekdays: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  vacations: PropTypes.arrayOf(
    PropTypes.shape({
      Guid: PropTypes.string,
      Lastname: PropTypes.string,
      Firstname: PropTypes.string,
      To: PropTypes.string,
      From: PropTypes.string,
      Holidays: PropTypes.string,
      WorkingHours: PropTypes.string,
      TrainingRequests: PropTypes.string,
      Training: PropTypes.string,
      TraReq: PropTypes.arrayOf(PropTypes.shape({})),
      Tra: PropTypes.arrayOf(PropTypes.shape({})),
      VacationRequests: PropTypes.string,
      Vacation: PropTypes.string,
      VacReq: PropTypes.arrayOf(PropTypes.shape({})),
      Vac: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
  getRosterOverview: PropTypes.func,
  getDayBookings: PropTypes.func,
  openingHours: PropTypes.arrayOf(
    PropTypes.shape({
      Day: PropTypes.string,
      From: PropTypes.string,
      To: PropTypes.string,
    }),
  ),
  isLocked: PropTypes.bool,
};

export default Month;
