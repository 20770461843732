import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Overview from './tabs/index';
import Modal from '../../components/Modal';

const Systemmangement = () => {
  return (
    <Modal title='Systemverwaltung'>
      <Switch>
        <Route component={Overview} />
      </Switch>
    </Modal>
  );
};

export default withRouter(Systemmangement);
