import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Details from './Detail';
import Overview from './Overview';
import New from './New';
import Modal from '../../../components/Modal';

const VacationRequest = props => {
  const paths = {
    new: `${props.match.path}/new`,
    details: `${props.match.path}/:id`,
  };

  return (
    <Modal title='Urlaubsanträge'>
      <Switch>
        <Route exact path={paths.new} component={New} />
        <Route exact path={paths.details} component={Details} />
        <Route component={Overview} />
      </Switch>
    </Modal>
  );
};

export default withRouter(VacationRequest);
