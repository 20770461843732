import React, { Component } from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../../settings';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Button from '../../../../../components/Button';
import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import getConstants from '../../../../../logic/constants';

class EmployeesOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employees: [],
      positions: [],
      loading: true,
    };

    const UserPromise = reqwest({
      method: 'get',
      url: settings.franchise.user.getList,
      data: {
        Token: localStorage.getItem('token'),
        FranGuid: this.props.franchiseGuid,
      },
    }).then(data => {
      data = JSON.parse(data);
      this.setState({
        employees: data,
      });
    });

    const positonsPromise = getConstants('FranPositiontypes').then(positions => {
      this.setState({
        positions,
      });
    });

    Promise.all([UserPromise, positonsPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.state.employees.filter(row => row.Guid);

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Mitarbeiter'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => this.props.history.replace(`${this.props.match.url}/${original.Guid}`),
              })}
              data={data}
              pageSize={data.length}
              noDataText='Es liegt kein Mitarbeiter für das ausgewählte Büro vor.'
              defaultSorted={[
                {
                  id: 'Firstname',
                  desc: false,
                },
                {
                  id: 'Lastname',
                  desc: false,
                },
              ]}
              columns={[
                {
                  Header: 'Vorname',
                  accessor: 'Firstname',
                },
                {
                  Header: 'Nachname',
                  accessor: 'Lastname',
                },
                {
                  Header: 'Position',
                  accessor: 'Position',
                  Cell: ({ value }) => this.state.positions.map(position => (position.value === value ? position.label : null)),
                },
              ]}
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faPlus} to={`${this.props.match.url}/new`} replace>
            Neuer Mitarbeiter
          </Button>
        </RightSide>
      </>
    );
  }
}

export default EmployeesOverview;
