import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../settings';
import DataContainer from '../../logic/dataContainer';

import RightSide from '../../components/FormArea/RightSide';
import Loader from '../../components/Loader';
import Form from '../../components/Form';
import Button from '../../components/Button';
import Input from '../../components/Inputs/Input';
import ContentBox from '../../components/FormArea/ContentBox';
import Datepicker from '../../components/Inputs/Datepicker';
import Dropdown from '../../components/Inputs/Dropdown';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import Switch from '../../components/Inputs/Switch';
import getConstants from '../../logic/constants';

class FranchiseNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');
    this.dataContainer = new DataContainer();
    this.dataContainer.add('FBasic', {
      Guid: '',
      Typ: 'Franchise',
    });
    this.dataContainer.add('FGenerals', {
      Agencynumber: '',
      Shortname: '',
      Street: '',
      Zip: '',
      City: '',
      State: '',
      Map: '',
      Owner: '',
      Officetype: '',
      Phone: '',
      Fax: '',
      Emailoffice: '',
      EmailOwner: '',
      DomainTravelcenter: '',
      DomainOwner: '',
      Smallchain: false,
      ConclusionContract: '',
      Opening: '',
      StartOfContract: '',
      Closure: '',
      CompanyName: '',
      TradeRegisterNr: '',
      TradeRegisterPlace: '',
      Taxnumber: '',
      Salestaxnumber: '',
      Comment1: '',
      PictureRights: '',
    });
    this.dataContainer.add('FIntern', {
      Comment2: '',
    });
    this.dataContainer.add('FPriv', {
      NoticePeriod: '',
      AutoContractExtension: '',
    });

    this.state = {
      loading: true,
    };

    const statesPromise = getConstants('Bcountry').then(states => {
      this.setState({
        states,
      });
    });

    const typesPromise = getConstants('Officetype').then(types => {
      this.setState({
        types,
      });
    });

    Promise.all([statesPromise, typesPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const newFranchise = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.franchise.general.save,
      data: {
        Token: localStorage.getItem('token'),
        FranchiseItem: newFranchise,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Das Franchise wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>Neues Franchisebüro erstellen</p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <div className='FormArea-Form-Left'>
                    <Form ref={node => (this.form = node)}>
                      <ContentBox title='Stammdaten'>
                        <Input
                          type='text'
                          label='Agenturnummer:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'Agencynumber')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'Agencynumber')}
                          name='Agencynumber'
                        />
                        <Input
                          type='text'
                          label='Kurzbezeichnung:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'Shortname')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'Shortname')}
                          name='Kurzbezeichnung'
                        />
                        <Input
                          type='text'
                          label='Strasse:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'Street')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'Street')}
                          name='Strasse'
                        />
                        <Input
                          type='text'
                          label='PLZ:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'Zip')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'Zip')}
                          name='PLZ'
                        />
                        <Input
                          type='text'
                          label='Ort:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'City')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'City')}
                          name='Ort'
                        />
                        <Dropdown
                          label='Bundesland:'
                          isSearchable
                          getSelected={value => {
                            this.dataContainer.set('FGenerals', 'State')(value);
                          }}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          defaultValue={this.dataContainer.get('FGenerals', 'State')}
                          options={this.state.states}
                          name='State'
                        />
                        <Dropdown
                          label='Bürotyp:'
                          isSearchable
                          getSelected={value => {
                            this.dataContainer.set('FGenerals', 'Officetype')(value);
                          }}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          defaultValue={this.dataContainer.get('FGenerals', 'Officetype')}
                          options={this.state.types}
                          name='Officetype'
                        />
                        <Input
                          type='text'
                          label='Telefon:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'Phone')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'Phone')}
                          name='Telefon'
                        />
                        <Input
                          type='text'
                          label='Fax:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'Fax')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'Fax')}
                          name='Fax'
                        />
                        <Input
                          type='text'
                          label='E-Mail Büro:'
                          validator={[
                            {
                              type: 'email',
                              error: 'Die E-Mail Adresse scheint nicht gültig zu sein',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'Emailoffice')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'Emailoffice')}
                          name='Emailoffice'
                        />
                        <Input
                          type='text'
                          label='E-Mail Inhaber:'
                          validator={[
                            {
                              type: 'email',
                              error: 'Die E-Mail Adresse scheint nicht gültig zu sein',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'EmailOwner')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'EmailOwner')}
                          name='EmailOwner'
                        />
                        <Input
                          type='text'
                          label='Domain Reisecenter:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'DomainTravelcenter')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'DomainTravelcenter')}
                          name='Domain'
                        />
                        <Input
                          type='text'
                          label='Domain eigene:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'DomainOwner')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'DomainOwner')}
                          name='DomainOwn'
                        />
                        <Switch
                          label='Kleinkette:'
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'Smallchain')(value);
                          }}
                          validator={[]}
                          name='Smallchain'
                          defaultChecked={this.dataContainer.get('FGenerals', 'Smallchain')}
                        />
                        <Datepicker
                          label='Vertragsabschluss:'
                          onChange={day => {
                            this.dataContainer.set('FGenerals', 'ConclusionContract')(day);
                          }}
                          validator={[]}
                          defaultValue={this.dataContainer.get('FGenerals', 'ConclusionContract')}
                          name='ConclusionContract'
                        />
                        <Datepicker
                          label='Eröffnung:'
                          onChange={day => {
                            this.dataContainer.set('FGenerals', 'Opening')(day);
                          }}
                          validator={[]}
                          defaultValue={this.dataContainer.get('FGenerals', 'Opening')}
                          name='Opening'
                        />
                        <Datepicker
                          label='Vertragsbeginn:'
                          onChange={day => {
                            this.dataContainer.set('FGenerals', 'StartOfContract')(day);
                          }}
                          validator={[]}
                          defaultValue={this.dataContainer.get('FGenerals', 'StartOfContract')}
                          name='StartOfContract'
                        />
                        <Input
                          type='text'
                          label='Kündigungsfrist:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('FPriv', 'NoticePeriod')(value);
                          }}
                          defaultValue={this.dataContainer.get('FPriv', 'NoticePeriod')}
                          name='Kündigungsfrist'
                        />
                        <Input
                          type='text'
                          label='Automatische Vertragsverlängerung:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('FPriv', 'AutoContractExtension')(value);
                          }}
                          defaultValue={this.dataContainer.get('FPriv', 'AutoContractExtension')}
                          name='Vertragsverlängerung'
                        />
                        <Datepicker
                          label='Schliessung:'
                          onChange={day => {
                            this.dataContainer.set('FGenerals', 'Closure')(day);
                          }}
                          validator={[]}
                          defaultValue={this.dataContainer.get('FGenerals', 'Closure')}
                          name='Closure'
                        />
                        <Input
                          type='textarea'
                          label='Bemerkung:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'Comment1')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'Comment1')}
                          name='Bemerkung'
                        />
                        <Input
                          type='textarea'
                          label='Bemerkung intern:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('FIntern', 'Comment2')(value);
                          }}
                          defaultValue={this.dataContainer.get('FIntern', 'Comment2')}
                          name='BemerkungInternal'
                        />
                      </ContentBox>
                      <ContentBox title='Firmierung'>
                        <Input
                          type='text'
                          label='Firmenname:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'CompanyName')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'CompanyName')}
                          name='Namen'
                        />
                        <Input
                          type='text'
                          label='Handelsregister Nummer:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'TradeRegisterNr')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'TradeRegisterNr')}
                          name='Nummer'
                        />
                        <Input
                          type='text'
                          label='Handelsregister Ort:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'TradeRegisterPlace')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'TradeRegisterPlace')}
                          name='TradeRegisterPlace'
                        />
                        <Input
                          type='text'
                          label='Steuernummer:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'Taxnumber')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'Taxnumber')}
                          name='Steuernummer'
                        />
                        <Input
                          type='text'
                          label='Umsatzsteuernummer:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('FGenerals', 'Salestaxnumber')(value);
                          }}
                          defaultValue={this.dataContainer.get('FGenerals', 'Salestaxnumber')}
                          name='Umsatzsteuernummer'
                        />
                      </ContentBox>
                    </Form>
                  </div>
                  <RightSide>
                    <Button big icon={faTimes} to={this.parentRoute}>
                      Abbrechen
                    </Button>
                    <Button big type='primary' icon={faSave} onClick={this.save}>
                      Speichern
                    </Button>
                  </RightSide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FranchiseNew;
