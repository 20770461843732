import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Button extends React.Component {
  handleClick = () => {
    if (typeof this.props.onClick === 'function' && !this.props.disabled) {
      this.props.onClick();
    }
  };

  render() {
    const classNames = `
      Button
      Button--${this.props.type}
      ${this.props.big ? 'Button--big' : ''}
      ${this.props.small ? 'Button--small' : ''}
      ${this.props.disabled ? 'Button--disabled' : ''}
      ${this.props.className}
    `;

    // Render outside Link
    if (this.props.href) {
      return (
        <a className={classNames} disabled={this.props.disabled} target='_blank' rel='noopener noreferrer' href={this.props.href}>
          {this.props.icon && <FontAwesomeIcon icon={this.props.icon} className='Button-icon' />}
          {this.props.children}
        </a>
      );
    }

    // Render internal Link
    if (this.props.to) {
      return (
        <Link className={classNames} to={this.props.to}>
          {this.props.icon && <FontAwesomeIcon icon={this.props.icon} className='Button-icon' />}
          {this.props.children}
        </Link>
      );
    }

    // Render functional Button (default)
    return (
      <button type='button' className={classNames} onClick={this.handleClick}>
        {this.props.icon && <FontAwesomeIcon icon={this.props.icon} className='Button-icon' />}
        {this.props.children}
      </button>
    );
  }
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.shape({
    prefix: PropTypes.string,
    iconName: PropTypes.string,
    icon: PropTypes.arrayOf(PropTypes.any),
  }),
  className: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  big: PropTypes.bool,
  type: PropTypes.oneOf(['default', 'primary', 'danger', 'warning']),
};

Button.defaultProps = {
  className: '',
  type: 'default',
  disabled: false,
  big: false,
};

export default Button;
