import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../settings';

import RightSide from '../../../../components/FormArea/RightSide';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import ContentBox from '../../../../components/FormArea/ContentBox';
import Input from '../../../../components/Inputs/Input';
import Switch from '../../../../components/Inputs/Switch';
import Dropdown from '../../../../components/Inputs/Dropdown';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../logic/dataContainer';

class RoleDetail extends React.Component {
  constructor(props) {
    super(props);

    this.roleGuid = props.match.params.id;
    this.parentRoute = this.props.match.url.replace(new RegExp(`/${this.roleGuid}$`), '');

    this.state = {
      loadedRights: [],

      toRemoveRelations: {},
      toAddRelations: {},

      loading: true,
    };

    const GetRole = reqwest({
      method: 'GET',
      url: settings.permissions.GetPermission,
      data: {
        Token: localStorage.getItem('token'),
        Guid: this.roleGuid,
      },
    }).then(roleResult => {
      this.dataContainer = new DataContainer(roleResult);
    });

    const GetRelations = reqwest({
      method: 'GET',
      url: settings.permissions.GetRightsForRole,
      data: {
        Token: localStorage.getItem('token'),
        RoleGuid: this.roleGuid,
      },
    }).then(roleResult => {
      roleResult = JSON.parse(roleResult);

      this.setState({
        roleRelations: [
          ...roleResult.map(role => ({
            parent: role.Params.Parent,
            child: role.Params.Child,
            guid: role.Params.Guid,
          })),
        ],
      });
    });

    const getApplicationSectionsPromise = reqwest({
      method: 'GET',
      url: settings.permissions.GetApplicationSections,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(applicationSectionsResult => {
      this.sectionContainer = new DataContainer(applicationSectionsResult);
      applicationSectionsResult = JSON.parse(applicationSectionsResult);

      this.setState({
        sections: applicationSectionsResult.map(section => ({
          value: section.Params.Guid,
          label: section.Params.Name,
        })),
      });
    });

    Promise.all([GetRole, getApplicationSectionsPromise, GetRelations]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  _changeSection = newSection => {
    reqwest({
      method: 'GET',
      url: settings.permissions.GetRightsForSection,
      data: {
        Token: localStorage.getItem('token'),
        Section: newSection,
      },
    }).then(sectionResult => {
      sectionResult = JSON.parse(sectionResult);
      const notFound = sectionResult[0].Params?.SearchAllResult === 'Entry not found';

      if (!this.state.loadedRights[newSection]) {
        this.setState(prevState => ({
          loadedRights: {
            ...prevState.loadedRights,
            [newSection]: !notFound
              ? sectionResult.map(section => ({
                  name: section.Params.Name,
                  guid: section.Params.Guid,
                }))
              : [],
          },
          toAddRelations: {
            ...prevState.toAddRelations,
            [newSection]: [],
          },
          toRemoveRelations: {
            ...prevState.toRemoveRelations,
            [newSection]: [],
          },
        }));
      }
      this.setState({
        selectedSection: newSection,
      });
    });
  };

  _changeRight = (value, right) => {
    const { selectedSection } = this.state;
    const initalValue = this.state.roleRelations.some(r => r.child === right.guid);
    const relation = this.state.roleRelations.filter(rel => rel.child === right.guid);
    let relationGuid = 0;
    if (relation.length) {
      relationGuid = relation[0].guid;
    }

    if (initalValue && !value) {
      // Delete because it was set and now isn't.
      this.setState(prevState => ({
        toRemoveRelations: {
          ...prevState.toRemoveRelations,
          [selectedSection]: [...prevState.toRemoveRelations[selectedSection], relationGuid],
        },
      }));
    }

    if (initalValue && value) {
      // Remove from toRemoveRelations because it's now in it's initalValue
      this.setState(prevState => ({
        toRemoveRelations: {
          ...prevState.toRemoveRelations,
          [selectedSection]: prevState.toAddRelations[selectedSection].filter(relation => relation !== relationGuid),
        },
      }));
    }

    if (!initalValue && value) {
      // Add because it wasn't set and now is.
      this.setState(prevState => ({
        toAddRelations: {
          ...prevState.toAddRelations,
          [selectedSection]: [...prevState.toAddRelations[selectedSection], right.guid],
        },
      }));
    }

    if (!initalValue && !value) {
      // Remove from toAddRelations because it's now in it's initalValue
      this.setState(prevState => ({
        toAddRelations: {
          ...prevState.toAddRelations,
          [selectedSection]: prevState.toAddRelations[selectedSection].filter(relation => relation !== right.guid),
        },
      }));
    }
  };

  save = () => {
    const { toAddRelations, toRemoveRelations } = this.state;

    swal({
      title: 'Sind Sie sicher?',
      text:
        'Das ändern dieser Einstellungen ändert die Rechte für die Komplette Anwendung. Eventuell haben Sie danach keinen Zugriff auf gewisse Bereiche mehr.',
      icon: 'warning',
      buttons: ['Abbrechen', 'Speichern'],
      dangerMode: true,
    }).then(willSave => {
      const promises = [];

      if (willSave) {
        promises.push(
          reqwest({
            method: 'POST',
            url: settings.permissions.AddRole,
            data: {
              Token: localStorage.getItem('token'),
              Role: this.dataContainer.getStringified(),
            },
          }).then(roleSaveResult => {
            roleSaveResult = JSON.parse(roleSaveResult);

            return roleSaveResult[0].Params.SaveResult === 'Updated';
          }),
        );

        Object.keys(toAddRelations).forEach(key => {
          const value = toAddRelations[key];
          if (!value.length) return;

          let toSendContainer = new DataContainer();
          value.forEach(rightGuid => {
            toSendContainer.add('Relation', {
              PropertyName: 'Relation',
              Parent: this.roleGuid,
              Child: rightGuid,
              ChildType: 'Right',
            });
          });

          promises.push(
            reqwest({
              method: 'POST',
              url: settings.permissions.AddRightsToRole,
              data: {
                Token: localStorage.getItem('token'),
                Relations: toSendContainer.getStringified(),
              },
            }).then(addResult => {
              addResult = JSON.parse(addResult);

              return addResult.every(r => r.Params.SaveResult === 'Saved');
            }),
          );
        });

        Object.keys(toRemoveRelations).forEach(key => {
          const value = toRemoveRelations[key];
          if (!value.length) return;

          let toSendContainer = new DataContainer();
          value.forEach(relationGuid => {
            if (relationGuid === 0) return;
            toSendContainer.add('Relation', {
              Guid: relationGuid,
            });
          });

          promises.push(
            reqwest({
              method: 'POST',
              url: settings.permissions.RemoveRightFromRole,
              data: {
                Token: localStorage.getItem('token'),
                Rights: toSendContainer.getStringified(),
              },
            }).then(removeResult => {
              removeResult = JSON.parse(removeResult);

              return removeResult.every(r => r.Params.DeleteResult === 'Deleted');
            }),
          );
        });

        Promise.all(promises).then(results => {
          const result = results.every(r => r === true);

          if (result) {
            swal({
              title: 'Erfolgreich!',
              text: 'Die Rechte wurden erfolgreich gespeichert!',
              icon: 'success',
            });
          }
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Rolle'>
            <Input
              type='text'
              label='Name:'
              validator={[
                {
                  required: true,
                  error: 'Bitte füllen Sie dieses Feld aus',
                },
              ]}
              onChange={value => {
                this.dataContainer.set('Entry', 'Name')(value);
              }}
              defaultValue={this.dataContainer.get('Entry', 'Name')}
              name='Name'
            />
            <Input
              type='text'
              label='Bemerkung:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('Entry', 'Bemerkung')(value);
              }}
              defaultValue={this.dataContainer.get('Entry', 'Bemerkung')}
              name='Bemerkung'
            />
          </ContentBox>
          <ContentBox title='Rechte für Rolle ändern'>
            <Dropdown
              label='Bereich:'
              isSearchable
              fullWidth
              getSelected={value => {
                this._changeSection(value);
              }}
              options={this.state.sections}
              validator={[]}
              name='Bereich'
            />
            {this.state.sections.map(section => {
              if (this.state.selectedSection === section.value) {
                return (
                  <div key={section.value} className='Rights-Wrapper'>
                    {this.state?.loadedRights[section.value].length ? (
                      this.state.loadedRights[section.value].map(right => {
                        let isChosen = 0;
                        const roleRelations = this.state.roleRelations;
                        isChosen = roleRelations.filter(r => r.child === right.guid).length;

                        return (
                          <Switch
                            inline
                            key={right.guid}
                            name={right.guid}
                            label={right.name}
                            onChange={value => {
                              this._changeRight(value, right);
                            }}
                            defaultChecked={isChosen ? true : false}
                            validator={[]}
                          />
                        );
                      })
                    ) : (
                      <div className='NoData-Error'>Für diese Sektion sind keine Rechte hinterlegt.</div>
                    )}
                  </div>
                );
              }
              return <span key={section.value}></span>;
            })}
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default RoleDetail;
