import React from 'react';
import PropTypes from 'prop-types';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../settings';
import Loader from '../LoaderSmall';
import base64Downloader from '../../logic/base64Downloader';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import '../../../css/filepond.css';
import FilePreview from '../FilePreview';
import uuid from 'uuid';

class File extends React.Component {
  constructor(props) {
    super(props);
    registerPlugin(FilePondPluginFileEncode);

    let loading = true;

    let guids = props.defaultFiles || '';
    if (Array.isArray(guids)) {
      guids = guids.join(';');
    }

    if (guids) {
      reqwest({
        method: 'GET',
        url: props.editable ? settings.files.getListForGuids : settings.files.getFileHeadList,
        data: {
          Token: localStorage.getItem('token'),
          Guid: guids,
        },
      }).then(data => {
        const filesResult = JSON.parse(data);
        const files = [];
        filesResult.forEach(file => {
          if (!file || !file.Params || file.Params?.Data === 'stream not found') return;
          files.push(file.Params);
        });

        this.setState({
          loading: false,
          files,
        });
      });
    } else {
      loading = false;
    }

    this.state = {
      loading,
      files: [],
      filesToUpload: [],
      filesToDelete: [],
    };
  }

  _uiRemoveError = () => {
    swal({
      title: 'Fehler!',
      text: 'Bei dem löschen der Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es erneuet.',
      error: 'error',
    });
  };

  _onFilepondFileAdd = (error, file) => {
    if (error || !file) {
      swal({
        icon: 'error',
        title: 'Fehler!',
        text: 'Beim hinzufügen der Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
      });
      return;
    }

    if (this.props.maxFiles === this.state.files.length) {
      swal({
        icon: 'error',
        title: 'Fehler!',
        text: 'Die maximale Anzahl an Dateien ist erreicht, bitte löschen Sie zuerst eine bevor Sie eine neue Datei hinzufügen.',
      });
      return;
    }

    const newFile = {
      Guid: `new:${uuid()}`,
      Filesize: file.fileSize,
      Filename: file.filename,
      DataType: file.fileType,
      Data: file.getFileEncodeDataURL(),
    };

    this.setState(prevState => ({
      filesToUpload: [...prevState.filesToUpload, newFile],
      files: [...prevState.files, newFile],
    }));
  };

  removeFile = guid => {
    this.setState(prevState => ({
      filesToDelete: [...prevState.filesToDelete, ...prevState.files.filter(file => file.Guid === guid)],
    }));
  };

  onReAdd = guid => {
    this.setState(prevState => ({
      filesToDelete: [...prevState.filesToDelete.filter(f => f.Guid !== guid)],
    }));
  };

  saveFiles() {
    return new Promise((resolve, reject) => {
      const uploadPromises = [];
      const deletePromises = [];

      const { filesToUpload, filesToDelete } = this.state;

      if (filesToUpload.length) {
        uploadPromises.push(
          ...filesToUpload.map(
            file =>
              new Promise((resolve, reject) => {
                reqwest({
                  method: 'post',
                  url: settings.files.save,
                  data: {
                    Token: localStorage.getItem('token'),
                    Files: JSON.stringify({
                      ParamName: 'File',
                      Params: {
                        ...file,
                        Guid: '',
                      },
                    }),
                  },
                })
                  .then(fileResult => {
                    fileResult = JSON.parse(fileResult).Params;
                    this.setState(prevState => ({
                      files: prevState.files.map(f => {
                        if (f.Guid === file.Guid) {
                          return {
                            ...f,
                            Guid: fileResult.Guid,
                          };
                        }
                        return f;
                      }),
                    }));
                    return resolve();
                  })
                  .fail(() => {
                    reject();
                  });
              }),
          ),
        );
      }

      if (filesToDelete.length) {
        deletePromises.push(
          ...filesToDelete.map(file => {
            if (file.Guid && !file.Guid.startsWith('new:')) {
              return new Promise((resolve, reject) => {
                reqwest({
                  method: 'GET',
                  url: settings.files.delete,
                  data: {
                    Token: localStorage.getItem('token'),
                    Guid: file.Guid,
                  },
                }).then(deleteResult => {
                  if (deleteResult === 'Deleted') {
                    this.setState(
                      prevState => ({
                        files: prevState.files.filter(f => f.Guid !== file.Guid),
                      }),
                      () => {
                        resolve();
                      },
                    );
                    return;
                  }
                  return reject(deleteResult);
                });
              });
            }
            return null;
          }),
        );
      }

      Promise.all([...uploadPromises, ...deletePromises])
        .then(() => {
          const uploadedGuids = [...this.state.files.map(f => f.Guid)].filter(guid => guid !== '' && !guid.startsWith('new:')).join(';');
          resolve(uploadedGuids);
        })
        .catch(data => {
          console.error(data);
          reject(data);
        });
    });
  }

  downloadFile = fileGuid => {
    document.documentElement.style.cursor = 'wait';
    reqwest({
      method: 'GET',
      url: settings.files.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: fileGuid,
      },
    })
      .then(file => {
        file = JSON.parse(file).Params;

        document.documentElement.style.cursor = 'auto';
        base64Downloader(file.Data, file.Filename);
      })
      .fail(() => {
        document.documentElement.style.cursor = 'auto';
      });
  };

  render() {
    return (
      <div className='Input Input--file Input--fullWidth'>
        {this.props.label && (
          <label className='Input-label' htmlFor={this.props.name}>
            {this.props.label}
          </label>
        )}
        {this.props.editable && (
          <FilePond
            files={[]}
            ref={node => (this.pond = node)}
            maxFiles={this.props.maxFiles}
            onaddfile={(...args) => {
              this.pond.removeFiles();
              this._onFilepondFileAdd(...args);
            }}
            allowMultiple={this.props.maxFiles > 1 ? true : false}
            allowReplace={this.props.maxFiles > 1 ? false : true}
            allowFileEncode={true}
            {...settings.FILEPOND_LABELS}
          />
        )}
        {this.state.loading ? (
          <Loader />
        ) : (
          this.state.files.map(file => (
            <FilePreview
              marked={this.state.filesToDelete.some(ftd => ftd.Guid === file.Guid)}
              deleteable={this.props.editable}
              key={file.Guid}
              file={file}
              onDownload={({ Guid }) => this.downloadFile(Guid)}
              onRemove={({ Guid }) => this.removeFile(Guid)}
              onReAdd={({ Guid }) => this.onReAdd(Guid)}
            />
          ))
        )}
      </div>
    );
  }
}

File.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  editable: PropTypes.bool,
  maxFiles: PropTypes.number,
  defaultFiles: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),

  // Filepond PropsTypes
  onRemoveFile: PropTypes.func,
  beforeAddFile: PropTypes.func,
  onAddFile: PropTypes.func,
  onUpdateFiles: PropTypes.func,
};

File.defaultProps = {
  label: '',
  maxFiles: 1,
  name: uuid(),

  onRemoveFile: () => null,
  beforeAddFile: () => null,
  onAddFile: () => null,
  onUpdateFiles: () => null,
};

export default File;
