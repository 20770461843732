import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../settings';

import RightSide from '../../../../components/FormArea/RightSide';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import Editor from '../../../../components/Editor';
import Input from '../../../../components/Inputs/Input';
import ContentBox from '../../../../components/FormArea/ContentBox';
import { faTimes, faSave, faTrash } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../logic/dataContainer';

class EMailTemplatesDetails extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');

    this.state = {
      loading: true,
      templatePlaceholder: [
        {
          title: 'Benutzer',
          icon: 'user',
          value: [
            { key: 'UserSalutation', value: 'Benutzer Anrede' },
            { key: 'UserFirstName', value: 'Benutzer Vorname' },
            { key: 'UserLastName', value: 'Benutzer Nachname' },
            { key: 'UserZip', value: 'Benutzer PLZ' },
            { key: 'UserPlace', value: 'Benutzer Ort' },
            { key: 'UserStreet', value: 'Benutzer Straße' },
          ],
        },
        {
          title: 'Büro',
          icon: 'building',
          value: [
            { key: 'OfficeName', value: 'Büro Name' },
            { key: 'OfficePlace', value: 'Büro Ort' },
            { key: 'OfficeZip', value: 'Büro PLZ' },
            { key: 'OfficePlace', value: 'Büro Ort' },
            { key: 'OfficeStreet', value: 'Büro Straße' },
          ],
        },
        {
          title: 'Test',
          icon: 'cogs',
          value: [
            { key: 'TestName', value: 'Test Name' },
            { key: 'TestPlace', value: 'Test Ort' },
            { key: 'TestZip', value: 'Test PLZ' },
            { key: 'TestPlace', value: 'Test Ort' },
            { key: 'TestStreet', value: 'Test Straße' },
          ],
        },
      ],
    };

    const EMailTemplatePromise = reqwest({
      method: 'GET',
      url: settings.systemmanagement.emailtemplate.get,
      contentType: 'JSON',
      data: {
        Token: localStorage.getItem('token'),
        Guid: props.match.params.id,
      },
    }).then(eMailTemplateResponse => {
      this.dataContainer = new DataContainer(eMailTemplateResponse);
    });

    Promise.all([EMailTemplatePromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  save = () => {
    const EMailTemplate = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.systemmanagement.emailtemplate.save,
      data: {
        Token: localStorage.getItem('token'),
        EMailTemplate,
      },
    }).then(() => {
      swal({
        title: 'Erfolg!',
        text: 'Das E-Mail Template wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie dieses E-Mail Template wirklich löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'POST',
          url: settings.systemmanagement.emailtemplate.delete,
          data: {
            Guid: this.dataContainer.get('Basic', 'Guid'),
          },
        }).then(() => {
          swal({
            title: 'Erfolg!',
            text: 'Das E-Mail Template wurde erfolgreich gelöscht.',
            icon: 'success',
          });
          this.props.history.replace(this.parentRoute);
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='E-Mail Template'>
            <Input
              type='text'
              label='Template Titel:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('Generals', 'Title')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'Title')}
              name='Title'
            />
            <Input
              type='text'
              label='Betreff:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('Generals', 'Subject')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'Subject')}
              name='Subject'
            />
            <Editor
              onChange={value => {
                this.dataContainer.set('Generals', 'Body')(value);
              }}
              editable
              templatePlaceholder={this.state.templatePlaceholder}
              updateConfig={options => {
                return {
                  ...options,
                  toolbarButtons: [
                    ...options.toolbarButtons,
                    ...['|'],
                    ...this.state.templatePlaceholder.map(placeholder => {
                      return placeholder.title;
                    }),
                  ],
                };
              }}
            >
              {this.dataContainer.get('Generals', 'Body')}
            </Editor>
          </ContentBox>
        </div>
        <RightSide>
          <Button big type='danger' icon={faTrash} onClick={this.delete}>
            Löschen
          </Button>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default EMailTemplatesDetails;
