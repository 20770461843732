import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';
import moment from 'moment';

import settings from '../../../../../../settings';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import File from '../../../../../components/Inputs/File';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Datepicker from '../../../../../components/Inputs/Datepicker';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import Input from '../../../../../components/Inputs/Input';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../../logic/dataContainer';
import getConstants from '../../../../../logic/constants';

class MarketingNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');

    this.state = {
      loading: true,
      actionTypes: [],
    };

    this.dataContainer = new DataContainer();
    this.dataContainer.add('Basic', {
      Guid: '',
      Typ: 'Marketing',
      OfficeGuid: this.props.OfficeGuid,
    });
    this.dataContainer.add('Generals', {
      From: '',
      To: '',
      Action: '',
      Amount: '',
      File: '',
    });

    getConstants('CentralMarketingActionTypes').then(actionTypes => {
      this.setState({
        loading: false,
        actionTypes,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    this.fileInput.saveFiles().then(guids => {
      this.dataContainer.set('Generals', 'File')(guids);
      const newMarketing = this.dataContainer.getStringified();

      reqwest({
        method: 'POST',
        url: settings.office.marketing.save,
        data: {
          Token: localStorage.getItem('token'),
          Marketing: newMarketing,
        },
      }).then(() => {
        swal({
          title: 'Erfolgreich!',
          text: 'Ihr Marketing wurde erfolgreich gespeichert.',
          icon: 'success',
        });
        this.props.history.replace(this.parentRoute);
      });
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Marketingaktion'>
              <Datepicker
                label='Von:'
                defaultValue={this.dataContainer.get('Generals', 'From')}
                onChange={day => {
                  this.dataContainer.set('Generals', 'From')(day);
                }}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                  {
                    custom: value => {
                      if (!this.dataContainer.get('Generals', 'To')) {
                        return true;
                      }
                      const From = moment(value, 'DD.MM.YYYY');
                      const To = moment(this.dataContainer.get('Generals', 'To'), 'DD.MM.YYYY');
                      return From.isBefore(To);
                    },
                    error: 'Von darf nicht nach Bis liegen.',
                  },
                ]}
                name='From'
              />
              <Datepicker
                label='Bis:'
                defaultValue={this.dataContainer.get('Generals', 'To')}
                onChange={day => {
                  this.dataContainer.set('Generals', 'To')(day);
                }}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                  {
                    custom: value => {
                      if (!this.dataContainer.get('Generals', 'From')) {
                        return true;
                      }
                      const From = moment(this.dataContainer.get('Generals', 'From'), 'DD.MM.YYYY');
                      const To = moment(value, 'DD.MM.YYYY');
                      return To.isAfter(From);
                    },
                    error: 'Bis darf nicht vor Von liegen.',
                  },
                ]}
                name='To'
              />
              <Dropdown
                label='Aktion:'
                isSearchable
                defaultValue={this.dataContainer.get('Generals', 'Action')}
                options={this.state.actionTypes}
                getSelected={value => {
                  this.dataContainer.set('Generals', 'Action')(value);
                }}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                name='Action'
              />
              <Input
                type='text'
                label='Betrag:'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                onChange={value => {
                  this.dataContainer.set('Generals', 'Amount')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Amount')}
                name='Betrag'
              />
              <File
                editable
                ref={node => (this.fileInput = node)}
                label='Datei:'
                maxFiles={1}
                defaultFiles={this.dataContainer.get('Generals', 'File')}
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default MarketingNew;
