import React, { Component } from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import Loader from '../../../../../components/Loader';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Form from '../../../../../components/Form';
import Input from '../../../../../components/Inputs/Input';
import RightSide from '../../../../../components/FormArea/RightSide';
import Button from '../../../../../components/Button';
import Datepicker from '../../../../../components/Inputs/Datepicker';
import { faSave } from '@fortawesome/pro-solid-svg-icons';

class OwnerDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    reqwest({
      method: 'GET',
      url: settings.franchise.general.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: this.props.franchiseGuid,
        ConTyp: 'Owner',
      },
    }).then(res => {
      this.dataContainer = new DataContainer(res);
      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const data = this.dataContainer.getStringified();

    reqwest({
      method: 'post',
      url: settings.franchise.general.save,
      data: {
        FranchiseItem: data,
      },
    }).then(result => {
      result = JSON.parse(result);
      if (result.Params.SaveResult === 'Saved' || result.Params.SaveResult === 'Updated') {
        swal({
          title: 'Erfolgreich!',
          text: 'Das Franchise wurde erfolgreich gespeichert.',
          icon: 'success',
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <Form ref={node => (this.form = node)}>
          <ContentBox title='Privatadresse'>
            <Input
              type='text'
              label='Vorname:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FOwner', 'Firstname')(value);
              }}
              defaultValue={this.dataContainer.get('FOwner', 'Firstname')}
              name='Firstname'
            />
            <Input
              type='text'
              label='Nachname:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FOwner', 'Lastname')(value);
              }}
              defaultValue={this.dataContainer.get('FOwner', 'Lastname')}
              name='Lastname'
            />
            <Input
              type='text'
              label='Straße:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FOwner', 'StreetPriv')(value);
              }}
              defaultValue={this.dataContainer.get('FOwner', 'StreetPriv')}
              name='Straße'
            />
            <Input
              type='text'
              label='Postleitzahl:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FOwner', 'ZipPriv')(value);
              }}
              defaultValue={this.dataContainer.get('FOwner', 'ZipPriv')}
              name='Postleitzahl'
            />
            <Input
              type='text'
              label='Stadt:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FOwner', 'CityPriv')(value);
              }}
              defaultValue={this.dataContainer.get('FOwner', 'CityPriv')}
              name='Stadt'
            />
            <Input
              type='text'
              label='Telefonnummer:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FOwner', 'TelPriv')(value);
              }}
              defaultValue={this.dataContainer.get('FOwner', 'TelPriv')}
              name='Telefonnummer'
            />
            <Input
              type='text'
              label='Handynummer:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FOwner', 'MobilePriv')(value);
              }}
              defaultValue={this.dataContainer.get('FOwner', 'MobilePriv')}
              name='Handynummer'
            />
            <Input
              type='text'
              label='Email Adresse:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FOwner', 'EmailPriv')(value);
              }}
              defaultValue={this.dataContainer.get('FOwner', 'EmailPriv')}
              name='Email'
            />
            <Datepicker
              label='Geburtsdatum:'
              onChange={day => {
                this.dataContainer.set('FOwner', 'DateOfBirth')(day);
              }}
              validator={[]}
              defaultValue={this.dataContainer.get('FOwner', 'DateOfBirth')}
              name='DateOfBirth'
            />
          </ContentBox>
          <ContentBox title='zweite Privatadresse'>
            <Input
              type='text'
              label='Vorname:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FOwner', 'Firstname2')(value);
              }}
              defaultValue={this.dataContainer.get('FOwner', 'Firstname2')}
              name='Firstname2'
            />
            <Input
              type='text'
              label='Nachname:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FOwner', 'Lastname2')(value);
              }}
              defaultValue={this.dataContainer.get('FOwner', 'Lastname2')}
              name='Lastname2'
            />
            <Input
              type='text'
              label='Straße:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FOwner', 'StreetPriv2')(value);
              }}
              defaultValue={this.dataContainer.get('FOwner', 'StreetPriv2')}
              name='Straße2'
            />
            <Input
              type='text'
              label='Postleitzahl:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FOwner', 'ZipPriv2')(value);
              }}
              defaultValue={this.dataContainer.get('FOwner', 'ZipPriv2')}
              name='Postleitzahl2'
            />
            <Input
              type='text'
              label='Stadt:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FOwner', 'CityPriv2')(value);
              }}
              defaultValue={this.dataContainer.get('FOwner', 'CityPriv2')}
              name='Stadt2'
            />
            <Input
              type='text'
              label='Telefonnummer:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FOwner', 'TelPriv2')(value);
              }}
              defaultValue={this.dataContainer.get('FOwner', 'TelPriv2')}
              name='Telefonnummer2'
            />
            <Input
              type='text'
              label='Handynummer:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FOwner', 'MobilePriv2')(value);
              }}
              defaultValue={this.dataContainer.get('FOwner', 'MobilePriv2')}
              name='Handynummer2'
            />
            <Input
              type='text'
              label='Email Adresse:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FOwner', 'EmailPriv2')(value);
              }}
              defaultValue={this.dataContainer.get('FOwner', 'EmailPriv2')}
              name='Email2'
            />
            <Datepicker
              label='Geburtsdatum:'
              onChange={day => {
                this.dataContainer.set('FOwner', 'DateOfBirth2')(day);
              }}
              validator={[]}
              defaultValue={this.dataContainer.get('FOwner', 'DateOfBirth2')}
              name='DateOfBirth2'
            />
          </ContentBox>
        </Form>
        <RightSide>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default OwnerDetail;
