import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import swal from '@sweetalert/with-react';

import reqwest from 'reqwest';
import settings from '../../../../settings';

import Loader from '../../../components/Loader';
import ContentBox from '../../../components/FormArea/ContentBox';
import RightSide from '../../../components/FormArea/RightSide';
import Button from '../../../components/Button';
import Comment from '../../../components/Comments/Comment';

import downloadB64 from '../../../logic/base64Downloader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faTrash, faPen, faSave, faAngleLeft, faAngleRight, faFileAlt, faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';
import ComposeComment from '../../../components/Comments/Compose';
import Editor from '../../../components/Editor';

class NewsDetail extends Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');
    this.userRoles = JSON.parse(localStorage.getItem('userdata')).roles;
    this.userHasAdministrativeActions = this.userRoles.some(role =>
      ['Superuser', 'Geschäftsführer', 'GF', 'Regionalleitung', 'Marketing', 'Schulung / Personal'].includes(role),
    );
    this.commentsPerPage = 4;

    this.state = {
      loading: true,
      commentsLoading: true,
      page: 1,
      pages: 0,
      newComment: {
        textareaText: '',
      },
      commentGuids: [],
      comments: [],
      FileGuids: '',
      Files: [],
      Basic: {},
      General: {},
      RightSide: {},
    };

    reqwest({
      method: 'GET',
      url: settings.news.default.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: props.match.params.id,
      },
    }).then(res => {
      res = JSON.parse(res);

      const files = [];
      const filesQueue = [];
      const fileGuids = res[1].Params?.Files.split(';');
      fileGuids.forEach(fileGuid => {
        if (!fileGuid) return;
        filesQueue.push(
          reqwest({
            method: 'get',
            url: settings.files.getHead,
            data: {
              Token: localStorage.getItem('token'),
              Guid: fileGuid,
            },
          }).then(fileResult => {
            fileResult = JSON.parse(fileResult).Params;

            files.push(fileResult);
          }),
        );
      });

      Promise.all(filesQueue).then(() => {
        this.setState(
          {
            loading: false,
            Files: files,
            FileGuids: fileGuids,
            Basic: {
              ...res[0].Params,
            },
            General: {
              Categories: JSON.parse(res[1].Params.Categories),
              ...res[1].Params,
            },
            RightSide: {
              ...res[2].Params,
            },
          },
          () => {
            const allCommentGuids = this.state.General.Comments.split(';').filter(c => c !== '');
            const firstCommentGuidsToLoad = this.paginateArray(allCommentGuids, this.commentsPerPage, 1);
            const commentGuids = firstCommentGuidsToLoad.join(';');

            reqwest({
              method: 'GET',
              url: settings.news.comments.getList,
              data: {
                Token: localStorage.getItem('token'),
                CommentGuids: commentGuids,
              },
            }).then(comments => {
              if (comments !== 'No Data') {
                comments = JSON.parse(comments);
                comments = comments.filter(c => c.Params.GetEntryResult !== 'Entry not found');

                this.setState({
                  pages: Math.ceil(allCommentGuids.length / this.commentsPerPage),
                  commentsLoading: false,
                  commentGuids: allCommentGuids,
                  comments: comments,
                });
              }
              this.setState({
                commentsLoading: false,
              });
            });
          },
        );
      });
    });
  }

  // Stolen from https://stackoverflow.com/questions/42761068/paginate-javascript-array Thanks!
  paginateArray = (array, page_size, page_number) => {
    --page_number; // because pages logically start with 1, but technically with 0
    return array.slice(page_number * page_size, (page_number + 1) * page_size);
  };

  handlePageClick = data => {
    let selected = data.selected + 1;
    // let offset = Math.ceil(selected * this.commentsPerPage);

    this.setState({
      commentsLoading: true,
    });

    let promises = [];
    let comments = this.state.comments;
    const commentGuids = this.paginateArray(this.state.commentGuids, this.commentsPerPage, selected);
    const connectedCommentGuids = commentGuids.join(';');
    if (!comments.some(cg => commentGuids.includes(cg.Params.Guid))) {
      promises.push(
        reqwest({
          method: 'GET',
          url: settings.news.comments.getList,
          data: {
            Token: localStorage.getItem('token'),
            CommentGuids: connectedCommentGuids,
          },
        }).then(commentResult => {
          commentResult = JSON.parse(commentResult);
          commentResult.forEach((comment, index) => {
            const commentList = this.state.comments;
            commentList[(selected - 1) * this.commentsPerPage + index] = comment;
            comments = commentList;
          });
        }),
      );
    }

    Promise.all(promises).then(() => {
      this.setState({
        commentsLoading: false,
        page: selected++,
        comments,
      });
    });
  };

  newComment = () => {
    const text = this.state.newComment.textareaText;

    reqwest({
      method: 'POST',
      url: settings.news.comments.save,
      data: {
        Token: localStorage.getItem('token'),
        Comment: JSON.stringify({
          ParamName: 'Comment',
          Params: {
            NewsGuid: this.props.match.params.id,
            Text: text,
          },
        }),
      },
    }).then(result => {
      result = JSON.parse(result).Params;

      if (result.SaveResult === 'Saved') {
        swal({
          title: 'Erfolgreich!',
          text: 'Ihr Kommentar wurde erfolgreich gespeichert.',
          icon: 'success',
        });

        const comment = {
          ParamName: 'Entry',
          Params: {
            Guid: result.Guid,
            NewsGuid: this.props.match.params.id,
            Text: result.Text,
            UserGuid: result.UserGuid,
            UserName: result.UserName,
            CreateDate: result.CreateDate,
            DateTime: result.CreateDate,
          },
        };

        const commentGuids = [...this.state.commentGuids, result.Guid];

        this.setState(prevState => ({
          commentGuids,
          comments: [...prevState.comments, comment],
          pages: Math.ceil(commentGuids.length / this.commentsPerPage),
        }));
      }
    });
  };

  confirm = () => {
    reqwest({
      method: 'GET',
      url: settings.news.default.confirmRead,
      data: {
        Token: localStorage.getItem('token'),
        NewsGuid: this.props.match.params.id,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Sie haben die News erfolgreich als gelesen makiert.',
        icon: 'success',
      });
    });
  };

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diese News wirklich löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'POST',
          url: settings.news.default.delete,
          data: {
            Token: localStorage.getItem('token'),
            Guid: this.props.match.params.id,
          },
        })
          .then(() => {
            swal({
              title: 'Erfolgreich!',
              text: 'News wurde erfolgreich gelöscht.',
              icon: 'success',
            });
            this.props.history.replace(this.parentRoute);
          })
          .catch(() => {
            throw new Error('Something went very very wrong...');
          });
      }
    });
  };

  editComment = commentGuid => {
    this.setState({
      editMode: this.state.editMode === commentGuid ? '' : commentGuid,
    });
  };

  deleteComment = commentGuid => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diesen Kommentar wirklich löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'POST',
          url: settings.news.comments.delete,
          data: {
            Token: localStorage.getItem('token'),
            CommentGuid: commentGuid,
            NewsGuid: this.props.match.params.id,
          },
        })
          .then(commentDeleteResult => {
            commentDeleteResult = JSON.parse(commentDeleteResult);

            if (commentDeleteResult.DeleteResult === 'Deleted') {
              swal({
                title: 'Erfolgreich!',
                text: 'Der Kommentar wurde erfolgreich gelöscht.',
                icon: 'success',
              });
              this.setState(prevState => {
                const commentGuids = prevState.commentGuids.filter(g => g !== commentGuid);
                return {
                  commentGuids,
                  comments: prevState.comments.filter(c => c.Params.Guid !== commentGuid),
                  pages: Math.ceil(commentGuids.length / this.commentsPerPage),
                };
              });
            }
          })
          .catch(() => {
            throw new Error('Something went very very wrong...');
          });
      }
    });
  };

  saveComment = (comment, text) => {
    reqwest({
      method: 'POST',
      url: settings.news.comments.save,
      data: {
        Token: localStorage.getItem('token'),
        Comment: JSON.stringify({
          Paramname: 'Comment',
          Params: {
            ...comment,
            Text: text,
          },
        }),
      },
    }).then(updateResult => {
      updateResult = JSON.parse(updateResult).Params;

      if (updateResult.SaveResult === 'Updated') {
        swal({
          title: 'Erfolgreich!',
          text: 'Der Kommentar wurde erfolgreich gespeichert.',
          icon: 'success',
        });
        this.setState(prevState => ({
          comments: prevState.comments.map(comment => {
            if (comment.Params.Guid === updateResult.Guid) {
              return {
                ...comment,
                Params: {
                  ...comment.Params,
                  Text: text,
                },
              };
            }
            return comment;
          }),
        }));
        this.editComment(updateResult.Guid);
      }
    });
  };

  publish = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      content: (
        <p>
          Möchten Sie diese News wirklich veröffentlichen? Danach wird Sie an ausgewählte Mitarbeiter verschickt und Sie können den Titel nicht mehr
          ändern!
        </p>
      ),
      icon: 'warning',
      buttons: ['Abbrechen', 'Veröffentlichen'],
      dangerMode: true,
    }).then(willPublish => {
      if (willPublish) {
        let toPublish = [{}, {}, {}];

        toPublish[0].ParamName = 'Basic';
        toPublish[0].Params = this.state.Basic;

        toPublish[1].ParamName = 'General';
        toPublish[1].Params = this.state.General;
        toPublish[1].Params.Released = 'true';

        toPublish[2].ParamName = 'RightSide';
        toPublish[2].Params = this.state.RightSide;

        reqwest({
          method: 'post',
          url: settings.news.default.publishNews,
          data: {
            Token: localStorage.getItem('token'),
            News: JSON.stringify(toPublish),
          },
        })
          .then(() => {
            swal({
              title: 'Erfolgreich!',
              text: 'News wurden erfolreich veröffentlicht!',
              icon: 'success',
            });
            this.props.history.replace(this.parentRoute);
          })
          .catch(() => {
            throw new Error('Something went very very wrong...');
          });
      }
    });
  };

  download = guid => {
    reqwest({
      method: 'GET',
      url: settings.files.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: guid,
      },
    }).then(fileResult => {
      fileResult = JSON.parse(fileResult).Params;

      downloadB64(fileResult.Data, fileResult.Filename);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const comments = [...this.state.comments].splice((this.state.page - 1) * this.commentsPerPage, this.commentsPerPage);

    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>{this.state.General.Title}</p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <div className='FormArea-Form-Left'>
                    <ContentBox>
                      <div className='NewsContent'>
                        <Editor>{this.state.General.Text}</Editor>
                      </div>
                    </ContentBox>
                    {this.state.Files.length !== 0 && (
                      <div className='Attachements'>
                        <h2 className='Attachements-Title'>Anhänge</h2>
                        <div className='Attachements-Files'>
                          {this.state.Files.map(file => (
                            <button
                              key={file.Guid}
                              className='Attachements-Button'
                              onClick={() => {
                                this.download(file.Guid);
                              }}
                            >
                              <FontAwesomeIcon className='Attachements-Button-Icon' icon={faFileAlt} />
                              <span className='Attachements-Button-Name'>{file.Filename}</span>{' '}
                              <small>({(file.Filesize / 1000000).toFixed(2)}mb)</small>
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                    {this.state.General.AllowComments === 'true' && (
                      <>
                        <div className='ComposeCommentBox'>
                          <h2 className='ComposeCommentBox-Title'>Kommentar schreiben</h2>
                          <ComposeComment
                            textareaOnChange={value => {
                              this.setState({
                                newComment: {
                                  textareaText: value,
                                },
                              });
                            }}
                            saveOnClick={this.newComment}
                          />
                        </div>

                        <div className='CommentBox'>
                          <h2 className='CommentBox-Title'>Kommentare ({this.state.commentGuids.length})</h2>
                          {this.state.commentsLoading ? (
                            <Loader text='Die Kommentare werden geladen...' />
                          ) : (
                            comments.map(comment => {
                              comment = comment.Params;
                              const actions = [];

                              const userGuid = JSON.parse(localStorage.getItem('userdata')).userGuid;
                              const userIsCommentCreator = comment.UserGuid === userGuid;
                              const userIsNewsCreator = userGuid === this.state.RightSide.CreateUserGuid;
                              const userIsAdmin =
                                this.userRoles.includes('Superuser') || this.userRoles.includes('GF') || this.userRoles.includes('RL');
                              const canEdit = userIsCommentCreator || userIsAdmin || userIsNewsCreator;
                              if (canEdit) {
                                actions.push({
                                  name: this.state.editMode === comment.Guid ? 'Editieren beenden' : 'Editieren',
                                  icon: faPen,
                                  onClick: guid => {
                                    this.editComment(guid);
                                  },
                                });
                              }

                              const canDelete = canEdit || this.userHasAdministrativeActions || userIsNewsCreator;
                              if (canDelete) {
                                actions.push({
                                  name: 'Löschen',
                                  icon: faTrash,
                                  onClick: guid => {
                                    this.deleteComment(guid);
                                  },
                                });
                              }

                              return (
                                <Comment
                                  key={comment.Guid}
                                  guid={comment.Guid}
                                  userGuid={comment.UserGuid}
                                  name={comment.UserName}
                                  content={comment.Text}
                                  date={comment.DateTime}
                                  textRef={node => (this.text = node)}
                                  editMode={this.state.editMode === comment.Guid ? true : false}
                                  editModeButtons={[
                                    {
                                      text: 'Leeren',
                                      icon: faTrash,
                                      type: 'danger',
                                      onClick: () => {
                                        this.text.value = '';
                                      },
                                    },
                                    {
                                      text: 'Speichern',
                                      icon: faSave,
                                      type: 'primary',
                                      onClick: () => {
                                        this.saveComment(comment, this.text.value.replace(/\n/g, '<br />'));
                                      },
                                    },
                                  ]}
                                  avaliableActions={actions}
                                />
                              );
                            })
                          )}
                          {this.state.pages !== 0 && (
                            <ReactPaginate
                              previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                              nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                              containerClassName={'Comments-Pagination'}
                              onPageChange={this.handlePageClick}
                              pageCount={this.state.pages}
                              pageRangeDisplayed={5}
                              marginPagesDisplayed={2}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <RightSide
                    createDate={this.state.RightSide.CreateDate}
                    createUserName={this.state.RightSide.CreateUserName}
                    updateDate={this.state.RightSide.UpdateDate}
                    updateUserName={this.state.RightSide.UpdateUserName}
                  >
                    <Button big icon={faTimes} to={this.parentRoute}>
                      Abbrechen
                    </Button>
                    {(this.state.General.Confirm || this.state.General.Status === 'confirmed') && (
                      <Button big icon={faCheck} type='primary' onClick={this.confirm}>
                        Bestätigen
                      </Button>
                    )}
                    {this.userHasAdministrativeActions && (
                      <>
                        <label className='LabelInRightside'>Administrative Aktionen: </label>
                        {(this.state.General.Released === 'true' || this.state.General.Confirm === 'true') &&
                          this.state.General.Status !== 'confirmed' && (
                            <Button big icon={faBadgeCheck} type='warning' onClick={this.publish}>
                              Veröffentlichen
                            </Button>
                          )}
                        <Button big icon={faTrash} type='danger' onClick={this.delete}>
                          Löschen
                        </Button>
                        <Button big icon={faPen} type='default' to={`${this.parentRoute}/edit/${this.props.match.params.id}`}>
                          Bearbeiten
                        </Button>
                      </>
                    )}
                  </RightSide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NewsDetail;
