import React, { Component } from 'react';
import ReactTable from 'react-table';
import reqwest from 'reqwest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText, faMapMarkerAlt, faPlus } from '@fortawesome/pro-solid-svg-icons';

import history from '../../history';
import settings from '../../../settings';
import Loader from '../../components/Loader';
import Searchbar from '../../components/Searchbar';
import Button from '../../components/Button';

class FranchiseOfficeManagement extends Component {
  constructor(props) {
    super(props);

    let searchString = '';
    if (props.match.params.search) {
      searchString = props.match.params.search;
    }

    this.state = {
      loading: true,
      data: [],
      searchString,
    };
  }

  componentDidMount() {
    const promise = reqwest({
      method: 'get',
      url: settings.franchise.general.getList,
      data: {
        Token: localStorage.getItem('token'),
        Typ: 'Franchise',
      },
    }).then(res => {
      this.setState({
        data: JSON.parse(res),
      });
    });

    Promise.all([promise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  _search = value => {
    const val = value.toLowerCase();

    history.replace(val ? `/franchisemanagement/search/${val}` : '/franchisemanagement');

    this.setState({
      searchString: val,
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.state.data.filter(rowData =>
      [rowData.Shortname, rowData.Agencynumber, rowData.Zip, rowData.Street, rowData.City, rowData.Phone]
        .map(e => e.toLowerCase())
        .some(v => {
          return v.indexOf(this.state.searchString) >= 0;
        }),
    );

    return (
      <>
        <div className='Toolbar'>
          <Searchbar placeholder='Franchisebüro suchen...' defaultValue={this.state.searchString} onChange={this._search} />
          <div className='Toolbar-buttons'>
            <Button icon={faPlus} to='/franchisemanagement/new'>
              Franchisebüro erstellen
            </Button>
          </div>
        </div>
        <ReactTable
          getTrProps={(_, { original }) => ({
            onClick: () => this.props.history.push(`/franchisemanagement/${original.Guid}`),
          })}
          data={data}
          pageSize={data.length}
          noDataText='Es wurden keine Franchisebüros gefunden.'
          defaultSorted={[
            {
              id: 'Shortname',
              desc: false,
            },
          ]}
          columns={[
            {
              Header: 'Kurzbezeichnung',
              accessor: 'Shortname',
              maxWidth: 250,
            },
            {
              Header: 'Agenturnummer',
              accessor: 'Agencynumber',
              maxWidth: 250,
            },
            {
              id: 'address',
              Header: 'Adresse und Telefonnummer',
              Cell: ({ original }) => (
                <>
                  <FontAwesomeIcon fixedWidth icon={faEnvelopeOpenText} /> {original.Phone}
                  <br />
                  <FontAwesomeIcon fixedWidth icon={faMapMarkerAlt} /> {original.Street}, {original.Zip} {original.City}
                </>
              ),
            },
          ]}
        />
      </>
    );
  }
}

export default FranchiseOfficeManagement;
