import React from 'react';
import PropTypes from 'prop-types';

import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Inputs/Input';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import { faTrash, faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';

class TravelExpensesRequestDetail extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');

    this.state = {
      loading: true,
    };

    reqwest({
      method: 'GET',
      url: settings.usermanagement.TravelExpensesRequest.get,
      contentType: 'JSON',
      data: {
        Token: localStorage.getItem('token'),
        Guid: props.match.params.id,
      },
    }).then(travelExpensesRequestResponse => {
      this.dataContainer = new DataContainer(travelExpensesRequestResponse);

      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    reqwest({
      method: 'post',
      url: settings.usermanagement.TravelExpensesRequest.save,
      data: {
        Token: localStorage.getItem('token'),
        TravelExpensesRequest: this.dataContainer.getStringified(),
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Ihr Antrag wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Reisekostenantrag'>
              <Input
                label='Von:'
                placeholder='08:00'
                onChange={value => {
                  this.dataContainer.set('RightSide', 'CreateDate');
                }}
                defaultValue={this.dataContainer.get('RightSide', 'CreateDate')}
                name=''
                validator={[
                  {
                    type: 'time',
                    error: 'Bitte geben Sie eine valide Uhrzeit an',
                  },
                ]}
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide
          createDate={this.dataContainer.get('RightSide', 'CreateDate')}
          createUserName={this.dataContainer.get('RightSide', 'CreateUserName')}
          updateDate={this.dataContainer.get('RightSide', 'UpdateDate')}
          updateUserName={this.dataContainer.get('RightSide', 'UpdateUserName')}
        >
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

TravelExpensesRequestDetail.propTypes = {
  UserGuid: PropTypes.string.isRequired,
};

export default TravelExpensesRequestDetail;
