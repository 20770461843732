import React from 'react';
import PropTypes from 'prop-types';

import reqwest from 'reqwest';
import swal from 'sweetalert';
import moment from 'moment';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Datepicker from '../../../../../components/Inputs/Datepicker';
import Input from '../../../../../components/Inputs/Input';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import { faTimes, faTrash, faSave } from '@fortawesome/pro-solid-svg-icons';

class ContractDataDetails extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');

    this.state = {
      loading: true,
      contractList: [],
      Basic: {
        Guid: '',
        Typ: 'Contract',
        UserGuid: this.props.UserGuid,
      },
      Generals: {},
      RightSide: {},
    };

    const promiseContract = reqwest({
      method: 'GET',
      url: settings.usermanagement.contracts.get,
      contentType: 'JSON',
      data: {
        Token: localStorage.getItem('token'),
        Guid: props.match.params.id,
      },
    }).then(contractResponse => {
      this.dataContainer = new DataContainer(contractResponse);

      this.setState({
        loading: false,
      });
    });

    const promiseContractList = reqwest({
      method: 'get',
      url: settings.usermanagement.contracts.getList,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
        UserGuid: this.props.UserGuid,
      },
    }).then(contractResponse => {
      const contractList = JSON.parse(contractResponse);
      this.setState({
        contractList,
      });
    });

    Promise.all([promiseContract, promiseContractList]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie den Vertrag wirklich löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'POST',
          url: settings.usermanagement.contracts.delete,
          data: {
            Token: localStorage.getItem('token'),
            Guid: this.props.match.params.id,
          },
        }).then(() => {
          swal({
            title: 'Erfolgreich!',
            text: 'Ihr Vertrag wurde erfolgreich gelöscht.',
            icon: 'success',
          });
          this.props.history.replace(this.parentRoute);
        });
      } else {
        swal({ title: 'Abgebrochen!', text: 'Der Vertrag wurde nicht gelöscht.', icon: 'info' });
      }
    });
  };

  save = async () => {
    const contractList = this.state.contractList
      .filter(entry => {
        return entry.Params.Guid !== this.props.match.params.id;
      })
      .map(entry => {
        return {
          from: entry.Params.From,
          to: entry.Params.To,
        };
      });
    const enteredRange = moment.range(
      moment(this.dataContainer.get('Generals', 'From'), 'DD.MM.YYYY'),
      moment(this.dataContainer.get('Generals', 'To'), 'DD.MM.YYYY'),
    );
    const rangeValid = contractList.every(contract => {
      const range = moment.range(moment(contract.from, 'DD.MM.YYYY'), moment(contract.to, 'DD.MM.YYYY'));
      return !enteredRange.overlaps(range);
    });

    if (!rangeValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Die eingegebenen Daten überschneiden sich mit anderen Verträgen',
      });
      return;
    }

    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    // TODO: Switch to List of Datacontainers
    const newContract = {
      ParamName: 'Entry',
      Params: {
        ...this.dataContainer.get('Basic'),
        ...this.dataContainer.get('Generals'),
      },
    };

    reqwest({
      method: 'POST',
      url: settings.usermanagement.contracts.save,
      data: {
        Token: localStorage.getItem('token'),
        Contract: JSON.stringify(newContract),
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Ihre Vertrag wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title={`${this.dataContainer.get('Generals', 'From')} - ${this.dataContainer.get('Generals', 'To')}`}>
              <Input
                type='text'
                validator={[]}
                label='Grundgehalt mtl.:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'BaseSalary')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'BaseSalary')}
                name='Grundgehalt'
              />
              <Input
                type='text'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                  {
                    type: 'number',
                    error: 'Bitte geben Sie nur Zahlen ein',
                  },
                ]}
                label='Sollarbeitsstunden mtl.:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'WorkingHours')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'WorkingHours')}
                name='Stunden'
              />
              <Datepicker
                label='Sollarbeitsstunden Start:'
                onChange={day => {
                  this.dataContainer.set('Generals', 'WorkingHoursStart')(day);
                }}
                defaultValue={this.dataContainer.get('Generals', 'WorkingHoursStart')}
                validator={[]}
                name='WorkingHoursStart'
              />
              <Input
                type='text'
                validator={[]}
                label='Provision in Prozent:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'Commission')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Commission')}
                name='Provision in Prozent'
              />
              <Input
                type='text'
                validator={[]}
                label='Provision in Euro:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'CommissionEuro')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'CommissionEuro')}
                name='Provision in Euro'
              />
              <Input
                type='text'
                validator={[]}
                label='Geplanter Umsatz:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'PlanTurnover')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'PlanTurnover')}
                name='Geplanten Umsatz'
              />
              <Input
                type='text'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                  {
                    type: 'number',
                    error: 'Bitte geben Sie nur Zahlen ein',
                  },
                ]}
                label='Urlaubstage:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'Holidays')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Holidays')}
                name='Urlaubstage'
              />
              <Datepicker
                label='Gültig von:'
                onChange={day => {
                  this.dataContainer.set('Generals', 'From')(day);
                }}
                defaultValue={this.dataContainer.get('Generals', 'From')}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                  {
                    custom: value => {
                      if (!this.dataContainer.get('Generals', 'To') || !value) {
                        return true;
                      }
                      const validFrom = moment(value, 'DD.MM.YYYY');
                      const validTo = moment(this.dataContainer.get('Generals', 'To'), 'DD.MM.YYYY');
                      return validFrom.isBefore(validTo);
                    },
                    error: 'Von darf nicht nach Bis liegen.',
                  },
                ]}
                name='From'
              />
              <Datepicker
                label='Gültig bis:'
                onChange={day => {
                  this.dataContainer.set('Generals', 'To')(day);
                }}
                defaultValue={this.dataContainer.get('Generals', 'To')}
                validator={[
                  {
                    custom: value => {
                      if (!this.dataContainer.get('Generals', 'From') || !value) {
                        return true;
                      }
                      const validFrom = moment(this.dataContainer.get('Generals', 'From'), 'DD.MM.YYYY');
                      const validTo = moment(value, 'DD.MM.YYYY');
                      return validTo.isAfter(validFrom);
                    },
                    error: 'Bis darf nicht vor Von liegen.',
                  },
                ]}
                name='To'
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide
          createDate={this.dataContainer.get('RightSide', 'CreateDate')}
          createUserName={this.dataContainer.get('RightSide', 'CreateUserName')}
          updateDate={this.dataContainer.get('RightSide', 'UpdateDate')}
          updateUserName={this.dataContainer.get('RightSide', 'UpdateUserName')}
        >
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='danger' icon={faTrash} onClick={this.delete}>
            Löschen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

ContractDataDetails.propTypes = {
  UserGuid: PropTypes.string.isRequired,
};

export default ContractDataDetails;
