import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import reqwest from 'reqwest';
import arraySort from 'array-sort';
import swal from '@sweetalert/with-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generatePath } from 'react-router-dom';
import { faCaretLeft, faCaretRight, faArrows, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import settings from '../../../../settings';
import Input from '../../../components/Inputs/Input';
import DataContainer from '../../../logic/dataContainer';
import validate from '../../../logic/validator';
import LoaderSmall from '../../../components/LoaderSmall';

class Week extends React.Component {
  prev = () => {
    const prevWeek = this.currentWeek.subtract(1, 'week').format('w');
    const path = generatePath(this.props.match.path, {
      ...this.props.match.params,
      week: prevWeek,
    });
    this.props.history.replace(path);
  };

  next = () => {
    const prevWeek = this.currentWeek.add(1, 'week').format('w');
    const path = generatePath(this.props.match.path, {
      ...this.props.match.params,
      week: prevWeek,
    });
    this.props.history.replace(path);
  };

  removeBooking = data => {
    reqwest({
      method: 'POST',
      url: settings.dutySchedule.bookings.remove,
      data: {
        Token: localStorage.getItem('token'),
        ...data,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        icon: 'success',
        text: 'Der Mitarbeiter wurde erfolgreich entfent.',
      });
      this.props.getDayBookings();
      this.props.getRosterOverview();
    });
  };

  checkUserAvailability = (userGuid, date, from = null, to = null) => {
    const vacation = this.props.vacations.find(vacation => {
      return vacation.UserGuid === userGuid;
    });

    const bookings = this.props.bookings.filter(booking => booking.Date === date && booking.UserGuid === userGuid);

    const isBooked = bookings.some(booking => {
      const fullDayActivity = booking.Typ === 'Sick' || booking.Typ === 'Vacation';
      let partialActivity = false;
      if (from && to && booking.From && booking.To) {
        const bookingFrom = moment(booking.From, 'HH:mm');
        const bookingTo = moment(booking.To, 'HH:mm');
        const newFrom = moment(from, 'HH:mm');
        const newTo = moment(to, 'HH:mm');
        partialActivity =
          (bookingFrom.format('HH:mm') === newFrom.format('HH:mm') && bookingTo.format('HH:mm') === newTo.format('HH:mm')) ||
          newFrom.isBetween(bookingFrom, bookingTo, 'minutes') ||
          newTo.isBetween(bookingFrom, bookingTo, 'minutes');
      }
      return fullDayActivity || partialActivity;
    });

    const isInOffice = vacation.Anwesenheit[parseInt(moment(date, 'DD.MM.YYYY').format('DD'), 10) - 1] === '1';
    return isInOffice && !isBooked;
  };

  onDragEnd = async result => {
    const draggedItem = result.draggableId;
    const sourceList = result.source.droppableId;
    const droppedList = result.destination.droppableId;

    if (sourceList === droppedList) {
      return;
    }

    if (!this.checkUserAvailability(draggedItem, droppedList)) {
      swal({
        title: 'Achtung!',
        icon: 'warning',
        text: 'Der Mitarbeiter ist an diesem Tag nicht verfügbar...',
      });
      return;
    }

    const todaysOpeningHours =
      this.props.openingHours &&
      this.props.openingHours.filter(item => {
        return moment(droppedList, 'DD.MM.YYYY').format('dddd') === item.Day;
      });

    arraySort(todaysOpeningHours, 'From');

    let startTime;
    let endTime;

    const timesSet = await new Promise(resolve => {
      const timeValidators = [
        {
          required: true,
          error: 'Bitte füllen Sie dieses Feld aus',
        },
        {
          custom: value => {
            return moment(value, 'HH:mm').isValid();
          },
          error: 'Die eingegebene Zeit ist nicht valide.',
        },
        {
          custom: value => {
            const enteredTime = moment(value, 'HH:mm');
            return todaysOpeningHours.some(item => enteredTime.isBetween(moment(item.From, 'HH:mm'), moment(item.To, 'HH:mm'), 'minutes', '[]'));
          },
          error: 'Die eingegebene Zeit liegt außerhalb der Öffnungzeiten.',
        },
      ];

      swal({
        title: 'Arbeitszeiten',
        icon: 'info',
        text: 'Bitte geben Sie die Arbeitszeiten dieses Mitarbeiters an.',
        content: (
          <div>
            <div style={{ textAlign: 'left' }}>
              <b>Öffnungszeiten:</b> <br />
              {todaysOpeningHours.map(item => (
                <div key={`${item.From} - ${item.To}`}>
                  {item.From} - {item.To}
                </div>
              ))}
            </div>
            <br />
            <div style={{ textAlign: 'left' }}>
              <b>Arbeitsstart:</b>
              <Input
                type='text'
                onChange={value => {
                  startTime = moment(value, 'HH:mm').format('HH:mm');
                }}
                placeholder='08:00'
                validator={timeValidators}
                name='Arbeitsstart'
              />
            </div>
            <div style={{ textAlign: 'left' }}>
              <b>Arbeitsende:</b>
              <Input
                type='text'
                onChange={value => {
                  endTime = moment(value, 'HH:mm').format('HH:mm');
                }}
                placeholder='13:00'
                validator={timeValidators}
                name='Arbeitsende'
              />
            </div>
          </div>
        ),
      }).then(async confirm => {
        const validateStart = await validate(startTime, timeValidators);
        const validateEnd = await validate(endTime, timeValidators);
        if (confirm && validateStart.isValid && validateEnd.isValid) {
          return resolve(true);
        }
        return resolve(false);
      });
    });

    if (!timesSet) {
      return;
    }

    if (!startTime || !endTime) {
      swal({
        title: 'Fehler!',
        icon: 'error',
        text: 'Bitte geben Sie die Arbeitszeiten des Mitarbeiters an.',
      });
      return;
    }

    if (!this.checkUserAvailability(draggedItem, droppedList, startTime, endTime)) {
      swal({
        title: 'Achtung!',
        icon: 'warning',
        text: 'Der Mitarbeiter ist in diesem Zeitraum nicht verfügbar...',
      });
      return;
    }

    // NOTE:
    // We are currently splitting the Work times based on the Opening Hours.
    // If this isn't needed anymore, or it leads to bugs, feel free to delete it.
    const matchingOpeningHours = todaysOpeningHours.filter(oh => {
      return (
        moment(startTime, 'HH:mm').isBefore(moment(oh.To, 'HH:mm'), 'minutes') ||
        moment(endTime, 'HH:mm').isAfter(moment(oh.From, 'HH:mm'), 'minutes')
      );
    });

    const data = new DataContainer();
    matchingOpeningHours.forEach((oh, index) => {
      const From = index === 0 ? startTime : oh.From;
      const To = index === matchingOpeningHours.length - 1 ? endTime : oh.To;
      if (From !== To) {
        data.add('Booking', {
          UserGuid: draggedItem,
          Date: moment(droppedList, 'DD.MM.YYYY').format('DD.MM.YYYY'),
          From,
          To,
          Hours: (moment(To, 'HH:mm').diff(moment(From, 'HH:mm'), 'minutes') / 60).toFixed(2),
          Typ: 'Arbeitszeit', // Pause || Arbeitszeit
          OfficeGuid: this.props.match.params.officeGuid,
        });
      }
    });

    await reqwest({
      method: 'POST',
      url: settings.dutySchedule.bookings.save,
      data: {
        Token: localStorage.getItem('token'),
        Booking: data.getStringified(),
        Month: moment(droppedList, 'DD.MM.YYYY').format('MM'),
        From: this.startDay.format('DD.MM.YYYY'),
        To: this.endDay.format('DD.MM.YYYY'),
        OpeningHours: JSON.stringify(
          todaysOpeningHours.map(item => ({
            From: item.From,
            To: item.To,
          })),
        ),
      },
    })
      .then(() => {
        swal({
          title: 'Erfolgreich!',
          icon: 'success',
          text: 'Der Mitarbeiter wurde erfolgreich hinzugefügt.',
        });
        this.props.getDayBookings();
        this.props.getRosterOverview();
      })
      .fail(() => {
        swal({
          title: 'Fehler!',
          icon: 'error',
          text: 'Ein unerwarteter Fehler ist aufgetreten. Der Mitarbeiter wurde nicht hinzugefügt.',
        });
        this.props.getDayBookings();
        this.props.getRosterOverview();
      });
  };

  render() {
    this.currentWeek = moment(`${this.props.match.params.week}-${this.props.match.params.year}`, 'w-YYYY');
    this.currentMonth = moment(`${this.props.match.params.month}-${this.props.match.params.year}`, 'M-YYYY');

    // Shamelessly stolen from https://stackoverflow.com/a/48143904
    this.calendar = [];
    this.startDay = this.currentWeek.clone().startOf('week');
    this.endDay = this.currentWeek.clone().endOf('week');

    let date = this.startDay.clone().subtract(1, 'day');

    while (date.isBefore(this.endDay, 'day')) {
      this.calendar.push({
        id: date
          .clone()
          .add(1, 'day')
          .week(),
        days: Array(7)
          .fill(0)
          .map(() => date.add(1, 'day').clone()),
      });
    }

    return (
      <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
        <h2>KW {this.currentWeek.format('ww')}</h2>
        <div className='DutyRoster'>
          {!this.props.isLocked && (
            <Droppable droppableId='pool'>
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef} className='DutyRoster-employeeList'>
                  <div className='DutyRoster-employeeListHeader'>Mitarbeiter</div>

                  {this.props.employees.map((employee, index) => {
                    const soll = employee.Wochensoll ? parseFloat(employee.Wochensoll.replace(/,/g, '.')).toFixed(2) : 0;
                    const ist = employee.Wochenist ? parseFloat(employee.Wochenist.replace(/,/g, '.')).toFixed(2) : 0;
                    const rest = (soll - ist).toFixed(2);
                    return (
                      <Draggable key={employee.UserGuid} draggableId={employee.UserGuid} index={index} isDragDisabled={false}>
                        {provided => (
                          <div ref={provided.innerRef} {...provided.draggableProps} className='DutyRoster-employee'>
                            <span className='DutyRoster-employeeName'>
                              {employee.Firstname} {employee.Lastname}{' '}
                              <span className={`DutyRoster-employeeNotice ${rest > 0 ? 'DutyRoster-employeeNotice--red' : ''}`}>
                                ({rest * -1} Std)
                              </span>
                            </span>
                            <span className='DutyRoster-employeeMoveHandle' {...provided.dragHandleProps}>
                              <FontAwesomeIcon icon={faArrows} />
                            </span>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          )}
          <div className='DutyRoster-calendar DutyRoster-calendar--week'>
            <div className='DutyRoster-header'>
              {this.currentWeek.startOf('week').diff(this.currentMonth.startOf('month'), 'days') > 0 ? (
                <button className='DutyRoster-prevMonth' onClick={this.prev}>
                  <FontAwesomeIcon icon={faCaretLeft} size='2x' />
                </button>
              ) : (
                <span className='DutyRoster-prevMonth' />
              )}
              <div>
                KW {this.currentWeek.format('ww')} | {this.currentWeek.startOf('week').format('DD.MM.YYYY')} -{' '}
                {this.currentWeek.endOf('week').format('DD.MM.YYYY')}
              </div>
              {this.currentWeek.endOf('week').diff(this.currentMonth.endOf('month'), 'days') < 0 ? (
                <button className='DutyRoster-nextMonth' onClick={this.next}>
                  <FontAwesomeIcon icon={faCaretRight} size='2x' />
                </button>
              ) : (
                <span className='DutyRoster-nextMonth' />
              )}
            </div>
            <div className='DutyRoster-week DutyRoster-week--heading'>
              {this.calendar.map(week => {
                return week.days.map(day => {
                  const classNames = [];

                  if (moment(day).format('MM') !== this.currentMonth.format('MM')) {
                    return <div className='DutyRoster-day' key={day} />;
                  }

                  const status = this.props.officeData.OfficeCrew[parseInt(day.format('D'), 10) - 1];

                  const isWorkingDay =
                    this.props.openingHours &&
                    this.props.openingHours.some(item => {
                      return moment(day).format('dddd') === item.Day;
                    });

                  if (day.isSame(moment(), 'day')) {
                    classNames.push('DutyRoster-day--today');
                  }
                  if (status === 'O' && isWorkingDay) {
                    classNames.push('DutyRoster-day--red');
                  }
                  if (status === 'B' && isWorkingDay) {
                    classNames.push('DutyRoster-day--yellow');
                  }
                  if (status === 'E') {
                    classNames.push('DutyRoster-day--green');
                  }
                  if (!isWorkingDay) {
                    classNames.push('DutyRoster-day--disabled');
                  }

                  return (
                    <div className={`DutyRoster-day ${classNames.join(' ')}`} key={day}>
                      {day.format('dddd')} <br /> ({day.format('DD.MM.YYYY')})
                    </div>
                  );
                });
              })}
            </div>
            {this.calendar.map(week => {
              return (
                <div className='DutyRoster-week DutyRoster-week--body' key={week.id}>
                  {week.days.map(day => {
                    const isWorkingDay =
                      this.props.openingHours &&
                      this.props.openingHours.some(item => {
                        return moment(day).format('dddd') === item.Day;
                      });

                    if (moment(day).format('MM') !== this.currentMonth.format('MM') || !isWorkingDay) {
                      return (
                        <div className='DutyRoster-day DutyRoster-day--disabled' key={day.format('DD.MM.YYYY')}>
                          Ablegen nicht möglich!
                        </div>
                      );
                    }

                    const bookings = this.props.bookings && this.props.bookings.filter(booking => booking.Date === day.format('DD.MM.YYYY'));

                    if (this.props.bookingsLoading) {
                      return (
                        <div className='DutyRoster-day' key={day.format('DD.MM.YYYY')}>
                          <center>
                            <LoaderSmall />
                          </center>
                        </div>
                      );
                    }

                    arraySort(bookings, 'From');

                    return (
                      <Droppable droppableId={day.format('DD.MM.YYYY')} key={day.format('DD.MM.YYYY')} isDropDisabled={this.props.isLocked}>
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={
                              snapshot.isDraggingOver
                                ? {
                                    background: 'lightblue',
                                    boxShadow: 'inset 0 0 0 5px #4768A8',
                                  }
                                : {}
                            }
                            className='DutyRoster-day'
                          >
                            {bookings &&
                              bookings
                                .filter(booking => booking.Typ !== 'Arbeitszeit')
                                .map(booking => {
                                  return (
                                    <div key={booking.Guid} className='DutyRoster-employee'>
                                      <span className='DutyRoster-employeeName'>
                                        <div className='DutyRoster-employeeNotice DutyRoster-employeeNotice--red'>
                                          {(type => {
                                            switch (type) {
                                              case 'Sick':
                                                return 'Krank';
                                              case 'Vacation':
                                                return 'Urlaub';
                                              default:
                                                return type;
                                            }
                                          })(booking.Typ)}
                                        </div>
                                        {booking.Firstname} {booking.Lastname}
                                      </span>
                                    </div>
                                  );
                                })}
                            {bookings &&
                              bookings
                                .filter(booking => booking.Typ === 'Arbeitszeit')
                                .map(booking => {
                                  return (
                                    <div key={booking.Guid} className='DutyRoster-employee'>
                                      <span className='DutyRoster-employeeTime'>
                                        {booking.From} - {booking.To}
                                      </span>
                                      <span className='DutyRoster-employeeName'>
                                        {booking.Firstname} {booking.Lastname}
                                      </span>
                                      {!this.props.isLocked && (
                                        <button
                                          className='DutyRoster-employeeRemove'
                                          onClick={() => {
                                            const todaysOpeningHours =
                                              this.props.openingHours &&
                                              this.props.openingHours.filter(item => {
                                                return day.format('dddd') === item.Day;
                                              });

                                            arraySort(todaysOpeningHours, 'From');
                                            this.removeBooking({
                                              Guid: booking.Guid,
                                              Day: day.format('DD'),
                                              Month: day.format('MM'),
                                              Year: day.format('YYYY'),
                                              UserGuid: booking.UserGuid,
                                              OfficeGuid: this.props.officeData.Guid,
                                              From: this.startDay.format('DD.MM.YYYY'),
                                              To: this.endDay.format('DD.MM.YYYY'),
                                              OpeningHours: JSON.stringify(
                                                todaysOpeningHours.map(item => ({
                                                  From: item.From,
                                                  To: item.To,
                                                })),
                                              ),
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                      )}
                                    </div>
                                  );
                                })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </DragDropContext>
    );
  }
}

Week.propTypes = {
  loading: PropTypes.bool,
  officeData: PropTypes.shape({
    CCR: PropTypes.string,
    Shortname: PropTypes.string,
    Street: PropTypes.string,
    Zip: PropTypes.string,
    City: PropTypes.string,
    State: PropTypes.string,
    Officetype: PropTypes.string,
    Tel: PropTypes.string,
    Fax: PropTypes.string,
    EMail: PropTypes.string,
    Opening: PropTypes.string,
    Closure: PropTypes.string,
    Minemployees: PropTypes.string,
    Supervisor: PropTypes.string,
    Showcase: PropTypes.string,
    Cooperationoffices: PropTypes.arrayOf(PropTypes.string),
    Planscales: PropTypes.string,
    Comment1: PropTypes.string,
    Comment2: PropTypes.string,
    CreateDate: PropTypes.string,
    CreateUserGuid: PropTypes.string,
    CreateUserName: PropTypes.string,
    UpdateDate: PropTypes.string,
    UpdateUserGuid: PropTypes.string,
    UpdateUserName: PropTypes.string,
    Typ: PropTypes.string,
    Guid: PropTypes.string,
    DateTime: PropTypes.string,
    OfficeCrew: PropTypes.string,
  }),
  weekdays: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  vacations: PropTypes.arrayOf(
    PropTypes.shape({
      Guid: PropTypes.string,
      Lastname: PropTypes.string,
      Firstname: PropTypes.string,
      To: PropTypes.string,
      From: PropTypes.string,
      Holidays: PropTypes.string,
      WorkingHours: PropTypes.string,
      TrainingRequests: PropTypes.string,
      Training: PropTypes.string,
      TraReq: PropTypes.arrayOf(PropTypes.shape({})),
      Tra: PropTypes.arrayOf(PropTypes.shape({})),
      VacationRequests: PropTypes.string,
      Vacation: PropTypes.string,
      VacReq: PropTypes.arrayOf(PropTypes.shape({})),
      Vac: PropTypes.arrayOf(PropTypes.shape({})),
      Anwesenheit: PropTypes.string,
    }),
  ),
  openingHours: PropTypes.arrayOf(
    PropTypes.shape({
      Day: PropTypes.string,
      From: PropTypes.string,
      To: PropTypes.string,
    }),
  ),
  bookings: PropTypes.arrayOf(
    PropTypes.shape({
      Guid: PropTypes.string,
      From: PropTypes.string,
      To: PropTypes.string,
      OfficeGuid: PropTypes.string,
      Hours: PropTypes.string,
      Typ: PropTypes.string,
      UserGuid: PropTypes.string,
      Firstname: PropTypes.string,
      Lastname: PropTypes.string,
      Date: PropTypes.string,
    }),
  ),
  bookingsLoading: PropTypes.bool,
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      UserGuid: PropTypes.string,
      Firstname: PropTypes.string,
      Lastname: PropTypes.string,
      Wochensoll: PropTypes.string,
      Wochenist: PropTypes.string,
      WeekFrom: PropTypes.string,
      WeekTo: PropTypes.string,
    }),
  ),
  getRosterOverview: PropTypes.func,
  getDayBookings: PropTypes.func,
  isLocked: PropTypes.bool,
};

export default Week;
