import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import { faArrowCircleLeft, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';

class Modal extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
      document.body.classList.add('u-noScroll');
    }
  }

  componentDidMount() {
    document.body.classList.add('u-noScroll');
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    document.body.classList.remove('u-noScroll');
  }

  render() {
    const { title, children } = this.props;
    return (
      <div className='ContentArea-Box'>
        <div className='Box-Header'>
          <button className='Box-Header-Back' onClick={this.props.history.goBack}>
            <FontAwesomeIcon size='2x' icon={faArrowCircleLeft} />
            <span className='Box-Header-BackText'>Zurück zur Übersicht</span>
          </button>
          <div className='Box-Header-Title'>{title}</div>
          <Link to='/' className='Box-Header-Close'>
            <FontAwesomeIcon size='2x' icon={faTimesCircle} />
          </Link>
        </div>
        <div className='Box-Body'>{children}</div>
      </div>
    );
  }
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default withRouter(Modal);
