import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import RightSide from '../../../../../components/FormArea/RightSide';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Inputs/Input';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Switch from '../../../../../components/Inputs/Switch';

class AppearanceNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');
    this.dataContainer = new DataContainer();
    this.dataContainer.add('Basic', {
      Guid: '',
      Typ: 'Appearance',
      FranGuid: this.props.franchiseGuid,
    });
    this.dataContainer.add('General', {
      Other: '',
      Promotion: '',
      CountShowcase: '0',
      AssortmentDisplay: '',
      TerminalStrip: false,
      FirstShowcase: false,
      NewsletterWall: false,
      SecondShowcase: false,
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const newAppearance = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.franchise.appearance.save,
      data: {
        Appearance: newAppearance,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Ihr Erscheinungsbild wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Erscheinungsbild'>
              <Input
                type='text'
                label='Außenwerbung:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('General', 'Promotion')(value);
                }}
                name='Promotion'
                defaultValue={this.dataContainer.get('General', 'Promotion')}
              />
              <Input
                type='text'
                label='Anzahl Schaufenster:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('General', 'CountShowcase')(value);
                }}
                name='CountShowcase'
                defaultValue={this.dataContainer.get('General', 'CountShowcase')}
              />
              <Switch
                label='Erstschaufenster:'
                onChange={value => {
                  this.dataContainer.set('General', 'FirstShowcase')(value);
                }}
                validator={[]}
                name='FirstShowcase'
                defaultChecked={this.dataContainer.get('General', 'FirstShowcase')}
              />
              <Switch
                label='Zweitschaufenster:'
                onChange={value => {
                  this.dataContainer.set('General', 'SecondShowcase')(value);
                }}
                validator={[]}
                name='SecondShowcase'
                defaultChecked={this.dataContainer.get('General', 'SecondShowcase')}
              />
              <Switch
                label='Katalogwand:'
                onChange={value => {
                  this.dataContainer.set('General', 'NewsletterWall')(value);
                }}
                validator={[]}
                name='NewsletterWall'
                defaultChecked={this.dataContainer.get('General', 'NewsletterWall')}
              />
              <Switch
                label='Klemmschiene 80 cm:'
                onChange={value => {
                  this.dataContainer.set('General', 'TerminalStrip')(value);
                }}
                validator={[]}
                name='TerminalStrip'
                defaultChecked={this.dataContainer.get('General', 'TerminalStrip')}
              />
              <Switch
                label='Sortimentsdisplay:'
                onChange={value => {
                  this.dataContainer.set('General', 'AssortmentDisplay')(value);
                }}
                validator={[]}
                name='AssortmentDisplay'
                defaultChecked={this.dataContainer.get('General', 'AssortmentDisplay')}
              />
              <Input
                type='text'
                label='Sontiges:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('General', 'Other')(value);
                }}
                name='Other'
                defaultValue={this.dataContainer.get('General', 'Other')}
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default AppearanceNew;
