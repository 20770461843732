import React from 'react';
import PropTypes from 'prop-types';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import '../../css/filepond.css';

import downloadB64 from '../logic/base64Downloader';
import settings from '../../settings';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import Input from './Inputs/Input';

const FileDetail = props => {
  registerPlugin(FilePondPluginFileEncode);

  const filename = props.filename;
  const type = props.file?.options?.file?.type ? props.file?.options?.file?.type.split('/')[1] : filename.split('.').pop();
  const isImage = type === 'jpg' || type === 'jpeg' || type === 'png' || type === 'svg';

  let file = props.file;
  if (!Array.isArray(file)) file = [file];

  let files = {};
  if (file[0].file !== '') files = { files: file };

  return (
    <div className='File-detail'>
      <div className='File-header'>
        <div
          className={`File-preview ${props.notDownloadable ? 'File-preview-noDownload' : ''}`}
          onClick={() => {
            if (!props.notDownloadable) {
              downloadB64(file[0].file, filename);
            }
          }}
        >
          {isImage ? (
            <div className='File-image'>
              <img className='File-image-picture' src={props.file.file} alt={props.title} />
            </div>
          ) : (
            <div className='File-icon'>
              <FontAwesomeIcon icon={faCog} />
            </div>
          )}
        </div>
      </div>
      <div className='File-body'>
        <FilePond
          {...files}
          ref={props.pondRef}
          maxFiles={props.maxFiles}
          onaddfile={props.onAddFile}
          beforeAddFile={props.beforeAddFile}
          onremovefile={props.onRemoveFile}
          beforeRemoveFile={props.beforeRemoveFile}
          onupdatefiles={props.onUpdateFiles}
          allowMultiple={props.maxFiles > 1 ? true : false}
          allowReplace={props.maxFiles > 1 ? false : true}
          allowFileEncode={true}
          {...settings.FILEPOND_LABELS}
          {...props.pondProps}
        />
        <Input
          fullWidth
          type='text'
          label='Titel:'
          validator={[
            {
              required: true,
              error: 'Bitte füllen Sie dieses Feld aus',
            },
          ]}
          name='Title'
          innerRef={props.titleRef}
          defaultValue={props.title}
          placeholder='Titel eingeben...'
        />
      </div>
    </div>
  );
};

FileDetail.propTypes = {
  notDownloadable: PropTypes.bool,
  filename: PropTypes.string.isRequired,
  title: PropTypes.string,
  file: PropTypes.oneOfType([
    PropTypes.shape({
      filename: PropTypes.string,
      title: PropTypes.string,
      file: PropTypes.string,

      // FilePond needs this if the File is already on the Server
      // source is the Filename FilePond will ise
      // type is "local" if saved on Server
      source: PropTypes.string,
      options: PropTypes.shape({
        type: PropTypes.string,
        // file is used to only mock the File
        // if we don't mock file, FilePond will request the File!
        file: PropTypes.shape({
          name: PropTypes.string.isRequired,
          size: PropTypes.number,
          type: PropTypes.string,
        }),
      }),
    }),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  maxFiles: PropTypes.number,

  pondProps: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func, PropTypes.shape({ current: PropTypes.elementType })]),
  pondRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.elementType })]),
  titleRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.elementType })]),

  // Means User clicked on "X" but also means Filepond removes File on upload, don't use if you want to use it as delete
  // for delete use an specified Button
  onRemoveFile: PropTypes.func,
  beforeRemoveFile: PropTypes.func,

  onAddFile: PropTypes.func,
  beforeAddFile: PropTypes.func,

  onUpdateFiles: PropTypes.func,
};

export default FileDetail;
