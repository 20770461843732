import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../settings';

import RightSide from '../../../../components/FormArea/RightSide';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import ContentBox from '../../../../components/FormArea/ContentBox';
import Input from '../../../../components/Inputs/Input';
import Dropdown from '../../../../components/Inputs/Dropdown';
import Switch from '../../../../components/Inputs/Switch';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../logic/dataContainer';

class InventoryNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');

    this.state = {
      loading: false,
      protocols: [],
    };

    this.dataContainer = new DataContainer();
    this.dataContainer.add('Basic', {
      Guid: '',
      Typ: 'Group',
    });
    this.dataContainer.add('Generals', {
      Name: '',
      ValidFor: 'beide',
      Weight: '1',
      Active: 'true',
    });
  }

  save = () => {
    const Group = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.inventory.SaveGroup,
      data: {
        Token: localStorage.getItem('token'),
        Group,
      },
    }).then(saveResult => {
      saveResult = JSON.parse(saveResult);

      if (saveResult.Params.SaveResult === 'Saved') {
        swal({
          title: 'Erfolg!',
          text: 'Die Gruppe wurde erfolgreich angelegt.',
          icon: 'success',
        });
        this.props.history.replace(this.parentRoute);
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Neue Gruppe'>
            <Input
              type='text'
              label='Name:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('Generals', 'Name')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'Name')}
              name='Name'
            />
            <Dropdown
              label='Gültig für:'
              validator={[]}
              options={[
                { label: 'Eigentum', value: 'Eigentum' },
                { label: 'Franchise', value: 'Franchise' },
                { label: 'beide', value: 'beide' },
              ]}
              getSelected={value => {
                this.dataContainer.set('Generals', 'ValidFor')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'ValidFor')}
              name='ValidFor'
            />
            <Input
              type='text'
              label='Gewichtung:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('Generals', 'Weight')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'Weight')}
              name='Weight'
            />
            <Switch
              label='Aktiv?'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('Generals', 'Active')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'Active')}
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default InventoryNew;
