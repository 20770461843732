import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import reqwest from 'reqwest';

import settings from '../../settings';
import Logo from '../../Images/Signet.svg';
import Burgernav from '../../Images/icon-burger.svg';
import BurgernavClose from '../../Images/icon-close.svg';
import navJson from './Navigation.json';
import { faColumns, faSearch, faCaretDown, faSignOut } from '@fortawesome/pro-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { faIslandTropical } from '@fortawesome/pro-light-svg-icons';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openMenu: false,
      openUserMenu: false,
      userdata: JSON.parse(localStorage.getItem('userdata')),
      menu: navJson,
    };

    // Get Time of Day
    this.salutation = '';
    const date = new Date();
    const hour = date.getHours();
    if (hour >= 5 && hour < 11) {
      this.salutation = 'Guten Morgen';
    } else if (hour >= 11 && hour < 17) {
      this.salutation = 'Guten Tag';
    } else if (hour >= 17 || (hour >= 0 && hour < 5)) {
      this.salutation = 'Guten Abend';
    }

    // reqwest({
    //   url: settings.general.getMenu,
    //   method: 'get',
    //   data: {
    //     Token: localStorage.getItem('token'),
    //   },
    // }).then(menu => {
    //   const menuJson = JSON.parse(menu);
    //   this.setState({
    //     menu: menuJson
    //   });
    // });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        openMenu: false,
      });
    }
  }

  componentDidMount() {
    document.addEventListener('click', this._checkForMenuClickEventListener);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this._checkForMenuClickEventListener);
  }

  logout = () => {
    reqwest({
      method: 'get',
      url: settings.general.logout,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(() => {
      localStorage.clear();
      window.location.reload();
    });
  };

  toggleUserMenu = () => {
    this.setState({
      openUserMenu: !this.state.openUserMenu,
    });
  };

  toggleMenu = () => {
    this.setState(prevState => ({
      openMenu: !prevState.openMenu,
    }));
  };

  closeMenu = () => {
    this.setState({
      openMenu: false,
    });
  };

  _checkForMenuClickEventListener = event => {
    const { target } = event;
    const clickIsInNavigation = this._hasSomeParentClass(target, 'Navigation');
    if (!clickIsInNavigation) {
      this.closeMenu();
    }
  };

  _hasSomeParentClass = (element, classname) => {
    if (element.parentNode === document.documentElement) return false;
    if (element.classList.contains(classname)) return true;
    return element.parentNode && this._hasSomeParentClass(element.parentNode, classname);
  };

  render() {
    return (
      <div className='Navigation'>
        <div className={`Navigation-Left ${this.state.openMenu ? 'is-Active' : ''}`}>
          {process.env.NODE_ENV === 'development' && (
            <div className='DevPatch'>
              <div className='DevPatch-Inner'>Dev</div>
            </div>
          )}
          <div className='Navigation-Left-Top'>
            <Link to='/'>
              <img src={Logo} alt='Alltours' />
            </Link>
          </div>
          <nav className='Navigation-Sidenav'>
            <Link to='/' className='Navigation-FirstLevelMenu Navigation-FirstLevelMenu--Highlight'>
              <FontAwesomeIcon className='Navigation-FirstLevelMenu-Icon' icon={faColumns} />
              <p className='Navigation-FirstLevelMenu-Title'>Dashboard</p>
            </Link>
            <button className={`Navigation-FirstLevelMenu ${this.state.openMenu ? 'is-Active' : ''}`} onClick={this.toggleMenu}>
              <img src={this.state.openMenu ? BurgernavClose : Burgernav} alt='Burger' className='Navigation-FirstLevelMenu-Icon' />
              <p className='Navigation-FirstLevelMenu-Title'>Menü</p>
            </button>
            <div className={`Navigation-SecondLevelmenu ${this.state.openMenu ? 'is-Active' : ''}`}>
              {this.state.menu.map(menuGroup => {
                return (
                  <div key={menuGroup.title} className='Navigation-SecondLevelmenu-Group' style={{ borderLeft: `11px solid ${menuGroup.color}` }}>
                    <div className='Navigation-SecondLevelmenu-Group-Title'>{menuGroup.title}</div>
                    <div className='Navigation-SecondLevelmenu-Group-Links'>
                      {menuGroup.links &&
                        menuGroup.links.map(link => {
                          return (
                            <Link key={link.title} className='Navigation-SecondLevelmenu-Group-Link' to={link.url}>
                              {link.title}
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            </div>
          </nav>
        </div>
        <div className='Navigation-Top'>
          <div className='Navigation-Search'>
            <FontAwesomeIcon className='Navigation-Search-Icon' icon={faSearch} />
            <input type='text' className='Navigation-Search-Field' placeholder='Suchen...' />
          </div>
          <div className='Navigation-Top-Right'>
            <div className='Navigation-User'>
              <div className='Navigation-User-Image-Box'>
                <div className='Navigation-User-Image-Wrapper'>
                  <img src={'data:image/gif;base64,' + this.state.userdata.photo} alt='' className='Navigation-User-Image' />
                </div>
                <button className='Navigation-User-Menu-Button' onClick={this.toggleUserMenu}>
                  <FontAwesomeIcon className='Navigation-User-Menu-Button-Icon' icon={faCaretDown} />
                </button>
                <div className={`Navigation-User-Menu ${this.state.openUserMenu ? 'is-Active' : ''}`}>
                  <button className='Navigation-User-Menu-Close' onClick={this.toggleUserMenu}>
                    <FontAwesomeIcon className='Navigation-User-Menu-Close-Icon' icon={faTimesCircle} />
                  </button>
                  <Link className='Navigation-User-Menu-Link' to={`/usermanagement/${JSON.parse(localStorage.getItem('userdata')).expnr}`}>
                    Einstellungen
                  </Link>
                </div>
              </div>
              <div className='Navigation-User-Text'>
                {this.salutation}, <br />
                {this.state.userdata.firstname} {this.state.userdata.lastname}
              </div>
            </div>
            <div className='Navigation-Info'>
              <FontAwesomeIcon className='Navigation-Info-Icon' size='2x' icon={faIslandTropical} />
              <div className='Navigation-Info-Text'>
                <strong>Noch 4 Tage</strong>
                <br />
                bis zum nächsten Urlaub
              </div>
            </div>
            <button className='Logout-button' onClick={this.logout}>
              Logout <FontAwesomeIcon icon={faSignOut} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Navigation);
