import React from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../settings';

import Loader from '../../../../components/Loader';
import RightSide from '../../../../components/FormArea/RightSide';
import ContentBox from '../../../../components/FormArea/ContentBox';

class ConstantsOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      types: [],
      loading: true,
    };

    reqwest({
      method: 'get',
      url: settings.constants.getTypes,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(typesResponse => {
      const types = JSON.parse(typesResponse);
      this.setState({
        types: types,
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.state.types;

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Konstanten'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => this.props.history.replace(`${this.props.match.url}/${original.key}`),
              })}
              data={data}
              pageSize={data.length}
              noDataText='Es liegen keine Typen vor.'
              columns={[
                {
                  Header: 'Name',
                  accessor: 'value',
                },
              ]}
            />
          </ContentBox>
        </div>
        <RightSide />
      </>
    );
  }
}

// ConstantsOverview.propTypes = {
//   UserGuid: PropTypes.string.isRequired,
// };

export default ConstantsOverview;
