import PropTypes from 'prop-types';
import React from 'react';
import validate from '../../logic/validator';

export default class Switch extends React.Component {
  constructor(props) {
    super(props);
    let defaultChecked = false;
    if (props.defaultChecked === 'true' || props.defaultChecked === true) {
      defaultChecked = true;
    }
    this.value = defaultChecked;
  }

  /**
   * This function exists for "Form"
   * Do not delete unless you delete "Form"!
   */
  validate = async () => {
    const validator = await validate(this.value, this.props.validator);
    this.setState({
      error: validator.error,
    });
    return validator.isValid;
  };

  handleChange = () => {
    if (this.props.isDisabled) {
      return;
    }

    const { checked } = this.input;
    this.value = checked;

    this.validate();

    if (this.props.onChange) {
      this.props.onChange(checked);
    }
  };

  render() {
    return (
      <div className={`Switch ${this.props.inline ? 'Switch--inline' : ''} ${this.props.isDisabled ? 'Switch--disabled' : ''}`}>
        <label htmlFor={this.props.name} className='Switch-title'>
          {this.props.label}
          <div className='Switch-description'>{this.props.description}</div>
        </label>
        <label className='Switch-label' htmlFor={this.props.name} style={{ marginTop: this.props.description && '10px' }}>
          <input
            ref={node => {
              this.input = node;
            }}
            type='checkbox'
            id={this.props.name}
            name={this.props.name}
            disabled={this.props.isDisabled}
            defaultChecked={this.props.defaultChecked}
            onChange={this.handleChange}
            className='Switch-input'
          />
          <span className='Switch-switch' />
        </label>
      </div>
    );
  }
}

Switch.propTypes = {
  defaultChecked: PropTypes.bool,
  inline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  validator: PropTypes.arrayOf(
    PropTypes.shape({
      required: PropTypes.bool,
      error: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

Switch.defaultProps = {
  isDisabled: false,
  defaultChecked: false,
  description: '',
  onChange: () => null,
};
