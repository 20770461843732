import React from 'react';
import PropTypes from 'prop-types';

import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';

import history from '../../../../../history';
import settings from '../../../../../../settings';
import FileDetail from '../../../../../components/FileDetail';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import { faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';

class FileNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp('new$'), '');

    this.state = {
      loading: false,
      fileGuid: '',
      base64File: '',
      file: {
        filename: '',
        title: '',
        file: '',
      },
    };
  }

  _addFile = (error, file) => {
    if (error) {
      swal({
        title: 'Fehler',
        content: <p>Beim hinzufügen der Datei ist ein Fehler aufgetreten!</p>,
        icon: 'error',
      });
      return;
    }

    if (file.origin === 1) {
      // User has uploaded File and it's not set by Server or else
      reqwest({
        method: 'post',
        url: settings.files.save,
        data: {
          Token: localStorage.getItem('token'),
          Files: JSON.stringify({
            ParamName: 'File',
            Params: {
              Filesize: file.fileSize,
              Filename: file.filename,
              DataType: file.fileType,
              Data: file.getFileEncodeDataURL(),
            },
          }),
        },
      }).then(res => {
        res = JSON.parse(res);
        this.setState({
          fileGuid: res.Params.Guid,
          base64File: file.getFileEncodeDataURL(),
          file: {
            ...this.state.file,
            file: file.getFileEncodeDataURL(),
            filename: file.filename,
            source: file.filename,
            options: {
              type: 'local',
              file: {
                name: file.filename,
                size: file.fileSize,
                type: file.fileType,
              },
            },
          },
        });
      });
    }
  };

  _save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    if (!this.pond.getFiles().length) {
      swal({
        title: 'Fehler beim speichern!',
        text: 'Sie müssen eine Datei angeben um die Datei speichern zu können.',
        icon: 'error',
      });
      return;
    }

    let dataContainer = {
      ParamName: 'Entry',
      Params: {
        UserGuid: this.props.UserGuid,
        Typ: 'UserFile',
        Title: this.title.value,
        File: this.state.fileGuid,
      },
    };

    reqwest({
      method: 'post',
      url: settings.usermanagement.files.save,
      data: {
        Token: localStorage.getItem('token'),
        FilesContainer: JSON.stringify(dataContainer),
      },
    })
      .then(res => {
        res = JSON.parse(res);

        if (res.Params.SaveResult === 'Saved') {
          swal({
            title: 'Erfolgreich!',
            text: 'Die Datei wurde erfolgreich gespeichert.',
            icon: 'success',
          });
          history.push(this.props.match.url.replace(new RegExp('new$'), '') + res.Params.Guid);
        }
      })
      .fail(() => {
        swal({
          title: 'Fehler beim speichern!',
          text: 'Beim speichern der Datei ist ein Fehler aufgetreten.',
          icon: 'error',
        });
      });
  };

  _removeFileFromQueue = () => {
    if (!this.pond.getFiles().length) {
      this.setState({
        loading: false,
        base64File: '',
        file: {
          filename: '',
          title: '',
          file: '',
        },
      });
    }
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title={this.state.file.filename}>
              <FileDetail
                filename={this.state.file.filename}
                title={this.state.file.title}
                file={this.state.file}
                maxFiles={1}
                onAddFile={this._addFile}
                onRemoveFile={this._removeFileFromQueue}
                pondRef={node => (this.pond = node)}
                titleRef={node => (this.title = node)}
                notDownloadable={true}
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide>
          <Button big type={'danger'} icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this._save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

FileNew.propTypes = {
  UserGuid: PropTypes.string.isRequired,
};

export default FileNew;
