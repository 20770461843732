import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';
import sha512 from 'sha512';

import settings from '../../../settings';

import RightSide from '../../components/FormArea/RightSide';
import Loader from '../../components/Loader';
import Form from '../../components/Form';
import Button from '../../components/Button';
import Input from '../../components/Inputs/Input';
import ContentBox from '../../components/FormArea/ContentBox';
import Datepicker from '../../components/Inputs/Datepicker';
import Dropdown from '../../components/Inputs/Dropdown';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import getConstants from '../../logic/constants';
import DataContainer from '../../logic/dataContainer';
import checkAvailable from '../../logic/checkAvailable';

class UsersNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');
    this.dataContainer = new DataContainer();

    this.dataContainer.add('Basic', {
      Guid: '',
      Typ: 'Staff',
    });
    this.dataContainer.add('Login', {
      Username: '',
      Password: '',
    });
    this.dataContainer.add('Workdata', {
      Expnr: '',
      RegionalAdvisor: '',
      PersonalNr: '',
      Position: '',
      Salutation: '',
      Firstname: '',
      Lastname: '',
      BirthName: '',
      Dateofbirth: '',
      EMail: '',
      Tel: '',
      Fax: '',
      Nationality: '',
      BirthPlace: '',
    });
    this.dataContainer.add('Privatedata', {
      FamilyStatus: '',
      Telpriv: '',
      HandyP: '',
      EMailpriv: '',
      Zip: '',
      Street: '',
      City: '',
    });
    this.dataContainer.add('Additionalinfos', {
      DateOfLeaving: '',
      ExaminationDate: '',
      TrainingEnd: '',
      Ada: '',
      ImageRights: '',
      Notes: '',
    });

    this.state = {
      loading: true,
    };

    const positionPromise = getConstants('Positiontype').then(positions => {
      this.setState({
        positions,
      });
    });

    const maritalStatusPromise = getConstants('MaritalStatus').then(maritalStatus => {
      this.setState({
        maritalStatus,
      });
    });

    const rlPromise = reqwest({
      method: 'get',
      url: settings.usermanagement.general.getUserForPosition,
      data: {
        Token: localStorage.getItem('token'),
        Position: 'RL',
      },
    }).then(users => {
      users = JSON.parse(users);

      this.setState({
        users: users.map(element => {
          return {
            value: element.Params.Guid,
            label: `${element.Params.Firstname} ${element.Params.Lastname} (${element.Params.Expnr})`,
          };
        }),
      });
    });

    Promise.all([positionPromise, maritalStatusPromise, rlPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const newUser = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.usermanagement.general.save,
      data: {
        Token: localStorage.getItem('token'),
        User: newUser,
      },
    }).then(result => {
      result = JSON.parse(result);

      if (result.Params.SaveResult === 'Saved') {
        swal({
          title: 'Erfolgreich!',
          text: 'Der Nutzer wurde erfolgreich gespeichert.',
          icon: 'success',
        });
        this.props.history.replace(this.parentRoute);
      } else {
        swal({
          title: 'Fehler!',
          text: 'Es ist ein Fehler beim speichern aufgetreten.',
          icon: 'error',
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>Neuen Mitarbeiter erstellen</p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <div className='FormArea-Form-Left'>
                    <Form ref={node => (this.form = node)}>
                      <ContentBox title='Login'>
                        <Input
                          type='text'
                          label='Nutzername:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                            {
                              custom: async value => {
                                if (this.dataContainer.getInitial('Login', 'Username') !== value) {
                                  return await checkAvailable('username', value);
                                }
                                return true;
                              },
                              error: 'Dieser Nutzername wird bereits verwendet',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('Login', 'Username')(value);
                          }}
                          defaultValue={this.dataContainer.get('Login', 'Username')}
                          name='Nutzername'
                        />

                        <Input
                          passwordValidator
                          type='password'
                          label='Passwort:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('Login', 'Password')(sha512(value).toString('hex'));
                          }}
                          defaultValue={this.dataContainer.get('Login', 'Password')}
                          name='Passwort'
                        />
                      </ContentBox>
                      <ContentBox title='Stammdaten'>
                        <Input
                          type='text'
                          label='Exp Nr.:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                            {
                              custom: async value => {
                                if (this.dataContainer.getInitial('Workdata', 'Expnr') !== value) {
                                  return await checkAvailable('expnr', value);
                                }
                                return true;
                              },
                              error: 'Diese Exp Nr. wird bereits verwendet',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('Workdata', 'Expnr')(value);
                          }}
                          defaultValue={this.dataContainer.get('Workdata', 'Expnr')}
                          name='Expnr'
                        />

                        <Input
                          type='text'
                          label='Personal Nr.:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('Workdata', 'PersonalNr')(value);
                          }}
                          defaultValue={this.dataContainer.get('Workdata', 'PersonalNr')}
                          name='Personalnr'
                        />
                        <Dropdown
                          label='Regionalbetreuer:'
                          isSearchable
                          getSelected={value => {
                            this.dataContainer.set('Workdata', 'RegionalAdvisor')(value);
                          }}
                          defaultValue={this.dataContainer.get('Workdata', 'RegionalAdvisor')}
                          options={this.state.users}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='Regionalbetreuer'
                        />
                        <Dropdown
                          label='Position:'
                          isSearchable
                          getSelected={value => {
                            this.dataContainer.set('Workdata', 'Position')(value);
                          }}
                          defaultValue={this.dataContainer.get('Workdata', 'Position')}
                          options={this.state.positions}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='Position'
                        />
                        <Dropdown
                          label='Anrede:'
                          name='Salutation'
                          isSearchable
                          getSelected={value => {
                            this.dataContainer.set('Workdata', 'Salutation')(value);
                          }}
                          defaultValue={this.dataContainer.get('Workdata', 'Salutation')}
                          options={[
                            {
                              value: 'Herr',
                              label: 'Herr',
                            },
                            {
                              value: 'Frau',
                              label: 'Frau',
                            },
                            {
                              value: 'Divers',
                              label: 'Divers',
                            },
                          ]}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                        />

                        <Input
                          type='text'
                          label='Vorname:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('Workdata', 'Firstname')(value);
                          }}
                          defaultValue={this.dataContainer.get('Workdata', 'Firstname')}
                          name='Vorname'
                        />

                        <Input
                          type='text'
                          label='Nachname:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('Workdata', 'Lastname')(value);
                          }}
                          defaultValue={this.dataContainer.get('Workdata', 'Lastname')}
                          name='Nachname'
                        />

                        <Input
                          type='text'
                          label='Geburtsname:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('Workdata', 'BirthName')(value);
                          }}
                          defaultValue={this.dataContainer.get('Workdata', 'BirthName')}
                          name='Geburtsname'
                        />
                        <Datepicker
                          label='Geburtsdatum:'
                          validator={[]}
                          onChange={day => {
                            this.dataContainer.set('Workdata', 'Dateofbirth')(day);
                          }}
                          defaultValue={this.dataContainer.get('Workdata', 'Dateofbirth')}
                          name='Geburtsdatum'
                        />

                        <Input
                          type='text'
                          label='Geburtsort:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('Workdata', 'BirthPlace')(value);
                          }}
                          defaultValue={this.dataContainer.get('Workdata', 'BirthPlace')}
                          name='Geburtsort'
                        />

                        <Input
                          type='text'
                          label='E-Mail:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                            {
                              type: 'email',
                              error: 'Bitte geben Sie eine valide E-Mail Adresse ein',
                            },
                            {
                              custom: async value => {
                                if (this.dataContainer.getInitial('Workdata', 'EMail') !== value) {
                                  return await checkAvailable('email', value);
                                }
                                return true;
                              },
                              error: 'Diese E-Mail Adresse wird bereits verwendet',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('Workdata', 'EMail')(value);
                          }}
                          defaultValue={this.dataContainer.get('Workdata', 'EMail')}
                          name='E-Mail'
                        />

                        <Input
                          type='text'
                          label='Telefon Stammbüro:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('Workdata', 'Phone')(value);
                          }}
                          defaultValue={this.dataContainer.get('Workdata', 'Phone')}
                          name='Telefon'
                        />
                        <Input
                          type='text'
                          label='Staatsangehörigkeit:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('Workdata', 'Nationality')(value);
                          }}
                          defaultValue={this.dataContainer.get('Workdata', 'Nationality')}
                          name='Staatsangehörigkeit'
                        />
                      </ContentBox>
                      <ContentBox title='Privatdaten'>
                        <Input
                          type='text'
                          label='Straße:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('Privatedata', 'Street')(value);
                          }}
                          defaultValue={this.dataContainer.get('Privatedata', 'Street')}
                          name='Straße'
                        />

                        <Input
                          type='text'
                          label='PLZ:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('Privatedata', 'Zip')(value);
                          }}
                          defaultValue={this.dataContainer.get('Privatedata', 'Zip')}
                          name='Postleitzahl'
                        />

                        <Input
                          type='text'
                          label='Ort:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('Privatedata', 'City')(value);
                          }}
                          defaultValue={this.dataContainer.get('Privatedata', 'City')}
                          name='Ort'
                        />

                        <Input
                          type='text'
                          label='Telefon privat:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('Privatedata', 'Telpriv')(value);
                          }}
                          defaultValue={this.dataContainer.get('Privatedata', 'Telpriv')}
                          name='Telefonnummer'
                        />

                        <Input
                          type='text'
                          label='Handy privat:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('Privatedata', 'HandyP')(value);
                          }}
                          defaultValue={this.dataContainer.get('Privatedata', 'HandyP')}
                          name='Handy'
                        />

                        <Input
                          type='text'
                          label='E-Mail privat:'
                          validator={[
                            {
                              type: 'email',
                              error: 'Bitte geben Sie eine valide E-Mail Adresse ein',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('Privatedata', 'EMailpriv')(value);
                          }}
                          defaultValue={this.dataContainer.get('Privatedata', 'EMailpriv')}
                          name='E-Mailpriv'
                        />

                        <Input
                          type='text'
                          label='Kinder:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('Privatedata', 'Kids')(value);
                          }}
                          defaultValue={this.dataContainer.get('Privatedata', 'Kids')}
                          name='Anzahl Kinder'
                        />

                        <Input
                          type='text'
                          label='Geb-Kinder:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('Privatedata', 'DateOfBirthKids')(value);
                          }}
                          defaultValue={this.dataContainer.get('Privatedata', 'DateOfBirthKids')}
                          name='Geb-Kinder'
                        />

                        <Dropdown
                          label='Familienstand:'
                          isSearchable
                          getSelected={value => {
                            this.dataContainer.set('Privatedata', 'FamilyStatus')(value);
                          }}
                          defaultValue={this.dataContainer.get('Privatedata', 'FamilyStatus')}
                          options={this.state.maritalStatus}
                          validator={[]}
                          name='Familienstand'
                        />
                      </ContentBox>
                      <ContentBox title='Weitere Informationen'>
                        <Datepicker
                          label='Austrittsdatum:'
                          name='dateofleaving'
                          validator={[]}
                          onChange={day => {
                            this.dataContainer.set('Additionalinfos', 'DateOfLeaving')(day);
                          }}
                          defaultValue={this.dataContainer.get('Additionalinfos', 'DateOfLeaving')}
                        />
                        <Datepicker
                          label='Prüfungstermin:'
                          name='Prüfungstermin'
                          onChange={day => {
                            this.dataContainer.set('Additionalinfos', 'ExaminationDate')(day);
                          }}
                          defaultValue={this.dataContainer.get('Additionalinfos', 'ExaminationDate')}
                          validator={[]}
                        />
                        <Datepicker
                          label='Ausbildung beendet am:'
                          name='trainingend'
                          onChange={day => {
                            this.dataContainer.set('Additionalinfos', 'TrainingEnd')(day);
                          }}
                          defaultValue={this.dataContainer.get('Additionalinfos', 'TrainingEnd')}
                          validator={[]}
                        />

                        <Input
                          type='text'
                          label='ADA:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('Additionalinfos', 'Ada')(value);
                          }}
                          defaultValue={this.dataContainer.get('Additionalinfos', 'Ada')}
                          name='ADA'
                        />

                        <Input
                          type='text'
                          label='Bildrechte:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('Additionalinfos', 'ImageRights')(value);
                          }}
                          defaultValue={this.dataContainer.get('Additionalinfos', 'ImageRights')}
                          name='Bildrechte'
                        />

                        <Input
                          type='textarea'
                          label='Bemerkungen:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('Additionalinfos', 'Notes')(value);
                          }}
                          defaultValue={this.dataContainer.get('Additionalinfos', 'Notes')}
                          name='Bemerkungen'
                        />
                      </ContentBox>
                    </Form>
                  </div>
                  <RightSide>
                    <Button big icon={faTimes} to={this.parentRoute}>
                      Abbrechen
                    </Button>
                    <Button big type='primary' icon={faSave} onClick={this.save}>
                      Speichern
                    </Button>
                  </RightSide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UsersNew;
