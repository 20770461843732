/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

const RenderHTML = props => <span dangerouslySetInnerHTML={{ __html: props.children }} />;

RenderHTML.defaultProps = {
  children: '',
};

RenderHTML.propTypes = {
  children: PropTypes.string,
};

export default RenderHTML;
