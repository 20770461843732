import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Switch, NavLink, Route } from 'react-router-dom';

import reqwest from 'reqwest';
import settings from '../../../../settings';

import Loader from '../../../components/Loader';

import Basedata from './tabs/Basedata/index';
import OriginatingOffice from './tabs/OriginatingOffice/index';
import ContractData from './tabs/ContractData/index';
import PersonalAccess from './tabs/PersonalAccess/index';
import Trainings from './tabs/Trainings/index';
import Images from './tabs/Images/index';
import TravelExpensesRequest from './tabs/TravelExpensesRequest/index';

class Benutzerverwaltung extends Component {
  constructor(props) {
    super(props);

    this.userid = props.match.params.userId;

    this.state = {
      loading: true,
      formData: {},
    };
  }

  componentDidMount() {
    if (this.userid) {
      reqwest({
        method: 'get',
        url: settings.usermanagement.general.get,
        data: {
          Token: localStorage.getItem('token'),
          Usernr: this.userid,
        },
      }).then(res => {
        res = JSON.parse(res);
        this.setState({
          formData: res,
          guid: res[0].Params.Guid,
          loading: false,
        });
      });
    }
  }

  render() {
    if (this.state.loading) return <Loader />;
    return (
      <div className='FormArea'>
        <div className='FormArea-Top'>
          <p className='FormArea-Title'>
            #{this.state.formData[0]?.Params.Expnr} {this.state.formData[0]?.Params.Firstname} {this.state.formData[0]?.Params.Lastname}
          </p>
        </div>
        <div className='FormArea-Body'>
          <div className='FormArea-Body-Tabs'>
            <NavLink exact to={`${this.props.match.url}`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Stammdaten
            </NavLink>
            <NavLink to={`${this.props.match.url}/OriginatingOffice`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Stammbüros
            </NavLink>
            <NavLink to={`${this.props.match.url}/ContractData`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Vertragsdaten
            </NavLink>
            <NavLink to={`${this.props.match.url}/PersonalAccess`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Persönliche Zugänge
            </NavLink>
            <NavLink to={`${this.props.match.url}/Trainings`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Schulungen / Inforeisen
            </NavLink>
            <NavLink to={`${this.props.match.url}/Images`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Dateien
            </NavLink>
            <NavLink to={`${this.props.match.url}/Travelcosts`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Reisekosten
            </NavLink>
            <NavLink to={`${this.props.match.url}/TravelExpensesRequest`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Reisekostenanträge
            </NavLink>
          </div>
          <div className='FormArea-Body-Content'>
            <div className='FormArea-Body-Content-Center'>
              <div className='FormArea-Form'>
                <Switch>
                  <Route path={`${this.props.match.path}/ContractData`} render={() => <ContractData UserGuid={this.state.guid} />} />
                  <Route path={`${this.props.match.path}/OriginatingOffice`} render={() => <OriginatingOffice UserGuid={this.state.guid} />} />
                  <Route path={`${this.props.match.path}/PersonalAccess`} render={() => <PersonalAccess UserGuid={this.state.guid} />} />
                  <Route path={`${this.props.match.path}/Trainings`} render={() => <Trainings UserGuid={this.state.guid} />} />
                  <Route path={`${this.props.match.path}/Images`} render={() => <Images UserGuid={this.state.guid} />} />
                  <Route
                    path={`${this.props.match.path}/TravelExpensesRequest`}
                    render={() => <TravelExpensesRequest UserGuid={this.state.guid} />}
                  />
                  <Route render={() => <Basedata UserGuid={this.state.guid} />} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Benutzerverwaltung.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default Benutzerverwaltung;
