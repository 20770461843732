import React from 'react';
import PropTypes from 'prop-types';
import bytes from 'bytes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTimes } from '@fortawesome/pro-solid-svg-icons';
import Button from './Button';
import moment from 'moment';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

const FilePreview = props => {
  const { file } = props;

  return (
    <div className={`FilePreview ${props.marked ? 'FilePreview--marked' : ''}`}>
      <div className='FilePreview-title'>
        {file.Filename}
        {file.Filesize && <span className='FilePreview-size'>{bytes(parseInt(file.Filesize, 10))}</span>}
        {props.deleteable && file.CreateDate && file.CreateUserName && (
          <span className='FilePreview-created'>
            Hochgeladen von: {file.CreateUserName} am {moment(file.CreateDate, 'MM/DD/YYYY HH:mm').format('DD.MM.YYYY [um] HH:mm')}
          </span>
        )}
      </div>
      {!props.marked && props.onDownload && file.Data !== 'stream not found' && (
        <Button onClick={() => props.onDownload(file)}>
          <FontAwesomeIcon icon={faDownload} />
        </Button>
      )}
      {!props.marked && props.onRemove && props.deleteable && (
        <Button type='danger' onClick={() => props.onRemove(file)}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      )}
      {props.marked && props.onReAdd && props.deleteable && (
        <Button onClick={() => props.onReAdd(file)}>
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      )}
    </div>
  );
};

FilePreview.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.string,
  }).isRequired,
  marked: PropTypes.bool,
  deleteable: PropTypes.bool,
  onDownload: PropTypes.func,
  onRemove: PropTypes.func,
  onReAdd: PropTypes.func,
};

export default FilePreview;
