import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { NavLink, Switch, Route } from 'react-router-dom';

import Loader from '../../../components/Loader';

import Basedata from './tabs/Basedata';
import Registrations from './tabs/Registrations';

class WorkshopsDetail extends Component {
  constructor(props) {
    super(props);
    this.guid = props.match.params.id;
    this.state = {};
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>Details</p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Tabs'>
              <NavLink exact to={`${this.props.match.url}`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
                Informationen
              </NavLink>
              <NavLink to={`${this.props.match.url}/Registrations`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
                Anmeldungen
              </NavLink>
            </div>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <Switch>
                    <Route path={`${this.props.match.path}/Registrations`} render={() => <Registrations workshopGuid={this.guid} />} />
                    <Route render={() => <Basedata workshopGuid={this.guid} />} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

WorkshopsDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      guid: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default WorkshopsDetail;
