import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../settings';

import RightSide from '../../../../components/FormArea/RightSide';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import ContentBox from '../../../../components/FormArea/ContentBox';
import Input from '../../../../components/Inputs/Input';
import Dropdown from '../../../../components/Inputs/Dropdown';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../logic/dataContainer';

class EMailClientNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');

    this.state = {
      loading: false,
      protocols: [],
    };

    this.dataContainer = new DataContainer();
    this.dataContainer.add('Basic', {
      Guid: '',
      Typ: 'EMailClient',
    });
    this.dataContainer.add('Generals', {
      Protocol: '',
      ClientName: '',
      ClientPassword: '',
      Host: '',
      Port: '',
    });

    reqwest({
      method: 'GET',
      url: settings.constants.getList,
      data: {
        Token: localStorage.getItem('token'),
        typ: 'EMailProtocols',
      },
    }).then(protocols => {
      const protocolsJson = JSON.parse(protocols);
      const result = protocolsJson.map(protocol => ({
        value: protocol.Guid,
        label: protocol.Name,
      }));

      this.setState({
        protocols: result,
      });
    });
  }

  save = () => {
    const EmailClient = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.systemmanagement.emailclient.save,
      data: {
        Token: localStorage.getItem('token'),
        EmailClient,
      },
    }).then(() => {
      swal({
        title: 'Erfolg!',
        text: 'Der E-Mail Nutzer wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='E-Mail Nutzer'>
            <Dropdown
              label='Protokoll:'
              name='Protokoll:'
              isSearchable
              getSelected={value => {
                this.dataContainer.set('Generals', 'Protocol')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'Protocol')}
              options={this.state.protocols}
            />
            <Input
              type='text'
              label='UserName:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('Generals', 'ClientName')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'ClientName')}
              name='ClientName'
            />
            <Input
              type='text'
              label='Passwort:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('Generals', 'ClientPassword')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'ClientPassword')}
              name='ClientPassword'
            />
            <Input
              type='text'
              label='Host:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('Generals', 'Host')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'Host')}
              name='Host'
            />
            <Input
              type='text'
              label='Port:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('Generals', 'Port')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'Port')}
              name='Port'
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default EMailClientNew;
