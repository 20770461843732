import React, { Component } from 'react';
import swal from 'sweetalert';
import { Route, Switch } from 'react-router-dom';

import Navigation from './components/Navigation';

import Dashboard from './routes/Dashboard';
import Usermanagement from './routes/Usermanagement';
import Officemanagement from './routes/Officemanagement';
import Franchisemanagement from './routes/Franchisemanagement';
import Promotermanagement from './routes/Promotermanagement';
import Systemmanagement from './routes/Systemmanagement';
import AZN from './routes/AZN';
import DutyRoster from './routes/DutyRoster';
import News from './routes/News';
import Workshops from './routes/Workshops';
import Visits from './routes/Visits';

class LoggedInArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      httpTesterOpen: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    if (error && error.message) {
      if (error.message.includes('[SERVER]')) {
        return {
          error: 'Wir haben eine unerwartete Antwort vom Server erhalten:',
          errorDetail: error.message
            .replace('[SERVER]', '')
            .replace('Error:', '')
            .replace('Network error:', '')
            .trim(),
        };
      }
      return {
        error: 'Die Anwendung wurde mit folgender Fehlermeldung unerwartet beendet.',
        errorDetail: error.message.trim(),
      };
    }

    return {
      error: 'Die Anwendung wurde unerwartet beendet.',
    };
  }

  render() {
    if (this.state.error) {
      const wrapper = document.createElement('pre');
      if (this.state.errorDetail) {
        wrapper.innerHTML = this.state.errorDetail;
      }
      swal({
        closeOnClickOutside: false,
        closeOnEsc: false,
        title: 'Uh oh!',
        text: this.state.error,
        icon: 'error',
        content: wrapper,
      }).then(() => {
        this.props.history.push('/');
      });
      return null;
    }

    return (
      <div className='LoggedInArea'>
        <Navigation />
        <div className='ContentArea'>
          <Route path='/' component={Dashboard} />
          <Switch>
            <Route path='/Usermanagement' component={Usermanagement} />
            <Route path='/Officemanagement' component={Officemanagement} />
            <Route path='/Franchisemanagement' component={Franchisemanagement} />
            <Route path='/Promotermanagement' component={Promotermanagement} />
            <Route path='/Systemmanagement' component={Systemmanagement} />
            <Route path='/Dutyroster' component={DutyRoster} />
            <Route path='/AZN' component={AZN} />
            <Route path='/News' component={News} />
            <Route path='/Workshops' component={Workshops} />
            <Route path='/Visits' component={Visits} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default LoggedInArea;
