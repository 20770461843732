import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Details from './Detail';
import Overview from './Overview';
import New from './New';
import Edit from './Edit';
import Modal from '../../components/Modal';

const Workshops = props => {
  const paths = {
    details: `${props.match.path}/:id`,
    search: `${props.match.path}/search/:search?`,
    new: `${props.match.path}/new`,
    edit: `${props.match.path}/edit/:id`,
  };

  return (
    <Modal title='Schulungen / Inforeisen'>
      <Switch>
        <Route path={paths.edit} component={Edit} />
        <Route path={paths.new} component={New} />
        <Route path={paths.search} component={Overview} />
        <Route path={paths.details} component={Details} />
        <Route component={Overview} />
      </Switch>
    </Modal>
  );
};

export default withRouter(Workshops);
