import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import 'moment/locale/de';

import validate from '../../logic/validator';

class Datepicker extends Component {
  constructor(props) {
    super(props);
    this.value = props.defaultValue || props.value || null;
    this.state = {
      error: '',
    };
  }

  /**
   * This function exists for "Form"
   * Do not delete unless you delete "Form"!
   */
  validate = async () => {
    const validator = await validate(this.value, [
      {
        type: 'date',
        error: 'Bitte geben Sie ein valides Datum ein',
      },
      ...this.props.validator,
    ]);
    this.setState({
      error: validator.error,
    });
    if (this.value === undefined) {
      this.setState({
        error: 'Bitte geben Sie ein valides Datum ein',
      });
    }
    return validator.isValid;
  };

  handleChange = (day, modifiers, dayPickerInput) => {
    let value = moment(day, 'DD.MM.YYYY').format('DD.MM.YYYY');

    if (value === 'Invalid date') {
      value = dayPickerInput.getInput().value;
    }
    this.value = value;

    this.validate();

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  render() {
    return (
      <div className={`Datepicker ${this.props.fullWidth ? 'Input--fullWidth' : ''}`}>
        <label className='Datepicker-label' htmlFor={this.props.name}>
          {this.props.label}
        </label>
        <DayPickerInput
          format={'DD.MM.YYYY'}
          formatDate={formatDate}
          parseDate={parseDate}
          placeholder='Datum eingeben'
          dayPickerProps={{ showWeekNumbers: true, locale: 'de', localeUtils: MomentLocaleUtils }}
          getInput={node => {
            this.input = node;
          }}
          onDayChange={this.handleChange}
          value={this.value ? this.value : ''}
          isDisabled={this.props.isDisabled}
        />
        {this.state.error ? <span className='Datepicker-error'>{this.state.error}</span> : null}
      </div>
    );
  }
}

Datepicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  validator: PropTypes.arrayOf(
    PropTypes.shape({
      required: PropTypes.bool,
      error: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

Datepicker.defaultProps = {
  fullWidth: false,
  placeholder: '',
  onChange: () => null,
  validator: [],
};

export default Datepicker;
