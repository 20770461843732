import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Overview from './Overview';

const UsermanagementTrainings = props => {
  return (
    <Switch>
      <Route render={routerProps => <Overview {...routerProps} {...props} />} />
    </Switch>
  );
};

export default withRouter(UsermanagementTrainings);
