import React from 'react';
import PropTypes from 'prop-types';

import reqwest from 'reqwest';
import swal from 'sweetalert';
import moment from 'moment';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import Loader from '../../../../../components/Loader';
import RightSide from '../../../../../components/FormArea/RightSide';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import Datepicker from '../../../../../components/Inputs/Datepicker';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';

class OriginatingOfficeNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp('/new$'), '');

    this.dataContainer = new DataContainer();
    this.dataContainer.add('Basic', {
      Guid: '',
      Typ: 'UserOffice',
    });
    this.dataContainer.add('Generals', {
      OfficeGuid: '',
      OfficeName: '',
      From: '',
      To: '',
      UserGuid: this.props.UserGuid,
    });

    reqwest({
      method: 'get',
      url: settings.usermanagement.originatingOffice.getOfficeList,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(officeList => {
      officeList = JSON.parse(officeList);

      let result = officeList.map(element => {
        return {
          value: element.Params.Guid,
          label: `${element.Params.Shortname}`,
        };
      });
      this.setState({
        loading: false,
        OfficeList: result,
      });
    });

    this.state = {
      OfficeList: [],
    };
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const newOriginatingOffice = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.usermanagement.originatingOffice.save,
      data: {
        Token: localStorage.getItem('token'),
        UserOffice: newOriginatingOffice,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Das Stammbüro für den Benutzer wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Neues Stammbüro'>
              <Dropdown
                label='Büro:'
                isSearchable
                defaultValue={this.dataContainer.get('Generals', 'OfficeGuid')}
                options={this.state.OfficeList}
                getSelected={value => {
                  let officeName = this.state.OfficeList.filter(office => office.value === value)[0].label;
                  this.dataContainer.set('Generals', 'OfficeGuid')(value);
                  this.dataContainer.set('Generals', 'OfficeName')(officeName);
                }}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                name='OfficeGuid'
              />
              <Datepicker
                label='Von:'
                onChange={day => {
                  this.dataContainer.set('Generals', 'From')(day);
                }}
                defaultValue={this.dataContainer.get('Generals', 'From')}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                  {
                    custom: value => {
                      if (!this.dataContainer.get('Generals', 'To')) {
                        return true;
                      }
                      const validFrom = moment(value, 'DD.MM.YYYY');
                      const validTo = moment(this.dataContainer.get('Generals', 'To'), 'DD.MM.YYYY');
                      return validFrom.isBefore(validTo);
                    },
                    error: 'Von darf nicht nach Bis liegen.',
                  },
                ]}
                name='From'
              />
              <Datepicker
                label='Bis:'
                onChange={day => {
                  this.dataContainer.set('Generals', 'To')(day);
                }}
                defaultValue={this.dataContainer.get('Generals', 'To')}
                validator={[
                  {
                    custom: value => {
                      if (!this.dataContainer.get('Generals', 'From')) {
                        return true;
                      }
                      const validFrom = moment(this.dataContainer.get('Generals', 'From'), 'DD.MM.YYYY');
                      const validTo = moment(value, 'DD.MM.YYYY');
                      return validTo.isAfter(validFrom);
                    },
                    error: 'Bis darf nicht vor Von liegen.',
                  },
                ]}
                name='To'
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

OriginatingOfficeNew.propTypes = {
  UserGuid: PropTypes.string.isRequired,
};

export default OriginatingOfficeNew;
