import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Inputs/Input';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Switch from '../../../../../components/Inputs/Switch';
import { faTrash, faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';

class AppearanceDetails extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');

    this.state = {
      loading: true,
    };

    reqwest({
      method: 'GET',
      url: settings.franchise.appearance.get,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
        Guid: props.match.params.id,
      },
    }).then(appearanceResponse => {
      this.dataContainer = new DataContainer(appearanceResponse);

      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const newAppearance = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.franchise.appearance.save,
      data: {
        Appearance: newAppearance,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Ihr Erscheinungsbild wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie dieses Erscheinungsbild wirklich löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'GET',
          url: settings.franchise.appearance.delete,
          data: {
            Guid: this.dataContainer.get('Basic', 'Guid'),
          },
        }).then(() => {
          swal({
            title: 'Erfolgreich!',
            text: 'Ihr Erscheinungsbild wurde erfolgreich gelöscht.',
            icon: 'success',
          });
          this.props.history.replace(this.parentRoute);
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Erscheinungsbild'>
              <Input
                type='text'
                label='Außenwerbung:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Generals', 'Promotion')(value);
                }}
                name='Promotion'
                defaultValue={this.dataContainer.get('Generals', 'Promotion')}
              />
              <Input
                type='text'
                label='Anzahl Schaufenster:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Generals', 'CountShowcase')(value);
                }}
                name='CountShowcase'
                defaultValue={this.dataContainer.get('Generals', 'CountShowcase')}
              />
              <Switch
                label='Erstschaufenster:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'FirstShowcase')(value);
                }}
                validator={[]}
                name='FirstShowcase'
                defaultChecked={this.dataContainer.get('Generals', 'FirstShowcase')}
              />
              <Switch
                label='Zweitschaufenster:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'SecondShowcase')(value);
                }}
                validator={[]}
                name='SecondShowcase'
                defaultChecked={this.dataContainer.get('Generals', 'SecondShowcase')}
              />
              <Switch
                label='Katalogwand:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'NewsletterWall')(value);
                }}
                validator={[]}
                name='NewsletterWall'
                defaultChecked={this.dataContainer.get('Generals', 'NewsletterWall')}
              />
              <Switch
                label='Klemmschiene 80 cm:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'TerminalStrip')(value);
                }}
                validator={[]}
                name='TerminalStrip'
                defaultChecked={this.dataContainer.get('Generals', 'TerminalStrip')}
              />
              <Switch
                label='Sortimentsdisplay:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'AssortmentDisplay')(value);
                }}
                validator={[]}
                name='AssortmentDisplay'
                defaultChecked={this.dataContainer.get('Generals', 'AssortmentDisplay')}
              />
              <Input
                type='text'
                label='Sontiges:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Generals', 'Other')(value);
                }}
                name='Other'
                defaultValue={this.dataContainer.get('Generals', 'Other')}
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide>
          <Button big type='danger' icon={faTrash} onClick={this.delete}>
            Löschen
          </Button>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default AppearanceDetails;
