import React from 'react';
import PropTypes from 'prop-types';

import reqwest from 'reqwest';
import swal from 'sweetalert';
import md5 from 'md5';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Inputs/Input';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import { faSave } from '@fortawesome/pro-solid-svg-icons';
import { faSignIn } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';

class PWManagerDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      to: false,
      username: '',
      password: '',
      loading: true,
    };

    reqwest({
      method: 'GET',
      url: settings.franchise.pwmanager.get,
      contentType: 'JSON',
      data: {
        Token: localStorage.getItem('token'),
        FranGuid: this.props.franchiseGuid,
      },
    }).then(pwmanagerResponse => {
      this.dataContainer = new DataContainer(pwmanagerResponse);

      this.setState({
        loading: false,
      });
    });
  }

  save = () => {
    if (!this.form.validate()) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const data = this.dataContainer.getStringified();

    reqwest({
      method: 'post',
      url: settings.franchise.pwmanager.save,
      data: {
        Token: localStorage.getItem('token'),
        AccessData: data,
      },
    }).then(() => {
      swal({
        title: 'Erfolg!',
        text: 'Ihr Passwort wurde erfolgreich gespeichert.',
        icon: 'success',
      });
    });
  };

  _generateAcconLogin = () => {
    const { username, password } = this.state;

    let to = '';

    if (username.length && password.length) {
      const date = moment().format('DDMMYYYY');
      const loginString = md5(`${password}${date}`);
      to = `https://planung.accon-rvs.de:443/tool/click_rlogin.do?j_username=${username}&j_password=${loginString}`;
    }
    this.setState({
      to,
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Passwortmanager'>
              <Input
                type='text'
                validator={[]}
                label='Benutzername:'
                autoComplete='off'
                onChange={value => {
                  this.setState(
                    {
                      username: value,
                    },
                    () => {
                      this._generateAcconLogin();
                    },
                  );
                  this.dataContainer.set('Generals', 'Username')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Username')}
                name='Benutzername'
              />
              <Input
                type='password'
                passwordShowButton
                autoComplete='off'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                label='Passwort:'
                onChange={value => {
                  this.setState(
                    {
                      password: value,
                    },
                    () => {
                      this._generateAcconLogin();
                    },
                  );
                  this.dataContainer.set('Generals', 'Password')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Password')}
                name='Passwort'
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide
          createDate={this.dataContainer.get('RightSide', 'CreateDate')}
          createUserName={this.dataContainer.get('RightSide', 'CreateUserName')}
          updateDate={this.dataContainer.get('RightSide', 'UpdateDate')}
          updateUserName={this.dataContainer.get('RightSide', 'UpdateUserName')}
        >
          <Button big icon={faSignIn} disabled={this.state.to ? false : true} href={this.state.to ? this.state.to : ' '}>
            Bei ACCON anmelden
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

PWManagerDetail.propTypes = {
  franchiseGuid: PropTypes.string.isRequired,
};

export default PWManagerDetail;
