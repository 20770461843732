import React from 'react';
import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';

import settings from '../../../settings';

import RightSide from '../../components/FormArea/RightSide';
import Loader from '../../components/Loader';
import Form from '../../components/Form';
import Button from '../../components/Button';
import File from '../../components/Inputs/File';
import Input from '../../components/Inputs/Input';
import ContentBox from '../../components/FormArea/ContentBox';
import Datepicker from '../../components/Inputs/Datepicker';
import Dropdown from '../../components/Inputs/Dropdown';
import DataContainer from '../../logic/dataContainer';
import getConstants from '../../logic/constants';

class WorkshopsNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');

    this.state = {
      loading: true,
      statuses: [
        {
          label: 'Anmeldungen offen',
          value: 'open',
        },
        {
          label: 'Anmeldungen geschlossen',
          value: 'closed',
        },
      ],
      types: [],
      files: [],
    };

    this.container = new DataContainer();
    this.container.add('Basic', {
      Guid: '',
      Typ: 'Training',
    });
    this.container.add('General', {
      Title: '',
      From: '',
      To: '',
      Description: '',
      Files: '',
      Hours: '',
      Location: '',
      Costs: '',
      Deadline: '',
      Type: '',
      Trainer: '',
      Places: '',
      ThreeLetterCode: '',
      Status: 'open',
    });
    this.container.add('RightSide', {
      CreateDate: '',
      CreateUserGuid: '',
      CreateUserName: '',
      UpdateDate: '',
      UpdateUserGuid: '',
      UpdateUserName: '',
    });

    const workshopTypes = getConstants('WorkshopType').then(types => {
      this.setState({
        types,
      });
    });

    Promise.all([workshopTypes]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const files = [];

    swal({
      title: 'Bitte warten...',
      content: <Loader text='Ihre Dateien werden hochgeladen!' />,
      button: { visible: false },
    });

    // TODO: Switch to List of Datacontainers
    for (let i = 0; i < this.state.files.length; i++) {
      const file = this.state.files[i];
      await reqwest({
        method: 'post',
        url: settings.files.save,
        data: {
          Token: localStorage.getItem('token'),
          Files: JSON.stringify({
            ParamName: 'Entry',
            Params: {
              Filesize: file.options.file.size,
              Filename: file.filename,
              DataType: file.options.file.type,
              Data: file.file,
            },
          }),
        },
      }).then(fileResult => {
        fileResult = JSON.parse(fileResult).Params;

        if (fileResult.SaveResult === 'Saved') {
          files.push(fileResult.Guid);
        }
      });
    }

    swal({
      title: 'Bitte warten...',
      content: <Loader text='Ihre Schulung / Inforeise wird veröffentlicht!' />,
      button: { visible: false },
    });

    this.container.set('General', 'Files')(files.join(';'));
    const newTraining = this.container.getStringified();

    reqwest({
      method: 'POST',
      url: settings.trainings.general.save,
      data: {
        Token: localStorage.getItem('token'),
        Training: newTraining,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Ihre Schulung / Inforeise wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  _addFile = (err, file) => {
    if (err) {
      swal({
        title: 'Fehler!',
        text: 'Es ist ein Fehler aufgetreten beim laden der Datei.',
        icon: 'error',
      });
    }

    if (file.origin === 1) {
      this.setState({
        files: [
          ...this.state.files,
          {
            identifier: file.id,
            file: file.getFileEncodeDataURL(),
            filename: file.filename,
            source: file.filename,
            options: {
              type: 'local',
              file: {
                name: file.filename,
                size: file.fileSize,
                type: file.fileType,
              },
            },
          },
        ],
      });
    }
  };

  _removeFileFromQueue = (err, file) => {
    if (err) {
      swal({
        title: 'Fehler!',
        text: 'Es ist ein Fehler aufgetreten beim löschen der Datei.',
        icon: 'error',
      });
    }

    let newFiles = [];
    [...this.state.files].forEach(oFile => {
      if (oFile.identifier !== file.id) {
        newFiles.push(oFile);
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>Neue Schulung / Inforeise erstellen</p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <div className='FormArea-Form-Left'>
                    <Form ref={node => (this.form = node)}>
                      <ContentBox title='Allgemein'>
                        <Input
                          type='text'
                          label='Titel:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.container.set('General', 'Title')(value);
                          }}
                          name='Titel'
                          defaultValue={this.container.get('General', 'Title')}
                        />

                        <Input
                          type='textarea'
                          label='Beschreibung:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.container.set('General', 'Description')(value);
                          }}
                          name='Beschreibung'
                          defaultValue={this.container.get('General', 'Description')}
                        />

                        <File editable label='Anhänge:' maxFiles={10} onAddFile={this._addFile} onRemoveFile={this._removeFileFromQueue} />

                        <Input
                          type='text'
                          label='Ort:'
                          validator={[]}
                          onChange={value => {
                            this.container.set('General', 'Location')(value);
                          }}
                          name='Ort'
                          defaultValue={this.container.get('General', 'Location')}
                        />
                        <Input
                          type='text'
                          label='Kosten:'
                          validator={[]}
                          onChange={value => {
                            this.container.set('General', 'Costs')(value);
                          }}
                          name='Kosten'
                          defaultValue={this.container.get('General', 'Costs')}
                        />

                        <Input
                          type='text'
                          label='Trainer / Veranstalter:'
                          validator={[]}
                          onChange={value => {
                            this.container.set('General', 'Trainer')(value);
                          }}
                          name='Trainer / Veranstalter'
                          defaultValue={this.container.get('General', 'Trainer')}
                        />
                        <Input
                          type='text'
                          label='Dreilettercode:'
                          validator={[]}
                          onChange={value => {
                            this.container.set('General', 'ThreeLetterCode')(value);
                          }}
                          name='Dreilettercode'
                          defaultValue={this.container.get('General', 'ThreeLetterCode')}
                        />
                      </ContentBox>
                      <ContentBox title='Einstellungen'>
                        <Datepicker
                          label='Von:'
                          onChange={day => {
                            this.container.set('General', 'From')(day);
                          }}
                          defaultValue={this.container.get('General', 'From')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='From'
                        />
                        <Datepicker
                          label='Bis:'
                          onChange={day => {
                            this.container.set('General', 'To')(day);
                          }}
                          defaultValue={this.container.get('General', 'To')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='To'
                        />
                        <Datepicker
                          label='Anmeldeschluss:'
                          onChange={day => {
                            this.container.set('General', 'Deadline')(day);
                          }}
                          defaultValue={this.container.get('General', 'Deadline')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='Deadline'
                        />
                        <Input
                          type='text'
                          label='Stundenzahl:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                            {
                              type: 'number',
                              error: 'Bitte geben Sie nur Zahlen an',
                            },
                          ]}
                          onChange={value => {
                            this.container.set('General', 'Hours')(value);
                          }}
                          name='Stundenzahl'
                          defaultValue={this.container.get('General', 'Hours')}
                        />
                        <Input
                          type='text'
                          label='Plätze:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                            {
                              type: 'number',
                              error: 'Bitte geben Sie nur Zahlen an',
                            },
                          ]}
                          onChange={value => {
                            this.container.set('General', 'Places')(value);
                          }}
                          name='Plätze'
                          defaultValue={this.container.get('General', 'Places')}
                        />
                        <Dropdown
                          label='Art:'
                          isSearchable
                          getSelected={value => {
                            this.container.set('General', 'Type')(value);
                          }}
                          defaultValue={this.container.get('General', 'Type')}
                          options={this.state.types}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='Type'
                        />
                        <Dropdown
                          label='Status:'
                          isSearchable
                          getSelected={value => {
                            this.container.set('General', 'Status')(value);
                          }}
                          defaultValue={this.container.get('General', 'Status')}
                          options={this.state.statuses}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='Status'
                        />
                      </ContentBox>
                    </Form>
                  </div>
                  <RightSide>
                    <Button big icon={faTimes} to={this.parentRoute}>
                      Abbrechen
                    </Button>
                    <Button big type='primary' icon={faSave} onClick={this.save}>
                      Speichern
                    </Button>
                  </RightSide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WorkshopsNew;
