import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';

import settings from '../../../../../../settings';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Button from '../../../../../components/Button';
import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

class FileOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      loading: false,
    };

    reqwest({
      method: 'get',
      url: settings.usermanagement.files.getList,
      data: {
        Token: localStorage.getItem('token'),
        UserGuid: this.props.UserGuid,
      },
    })
      .then(res => {
        res = JSON.parse(res);

        this.setState({
          files: res,
          loading: false,
        });
      })
      .fail(err => {
        swal({
          title: 'Fehler beim Laden der Dateien!',
          content: (
            <p>
              Es ist ein Fehler beim Laden der Dateien aufgetreten.
              <span className='Indented'>
                {err.status}: {err.statusText}
              </span>
            </p>
          ),
          icon: 'error',
        });
      });
  }

  render() {
    if (this.state.loading) {
      return <Loader text='Die Dateien werden geladen...' />;
    }

    const data = this.state.files.filter(row => row.Params?.Guid);

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Dateien'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => this.props.history.replace(`${this.props.match.url}/${original.Params.Guid}`),
              })}
              data={data}
              pageSize={data.length}
              noDataText='Es liegen keine Dateien für den ausgewählten Mitarbeiter vor.'
              columns={[
                {
                  Header: 'Dateiname',
                  accessor: 'Params.Title',
                },
                {
                  Header: 'Zuletzt geändert',
                  accessor: 'Params.DateTime',
                },
              ]}
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faPlus} to={`${this.props.match.url}/new`} replace>
            Neue Datei
          </Button>
        </RightSide>
      </>
    );
  }
}

FileOverview.propTypes = {
  UserGuid: PropTypes.string.isRequired,
};

export default FileOverview;
