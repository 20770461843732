import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Details from './Details';
import Overview from './Overview';

const Registrations = props => {
  const paths = {
    details: `${props.match.path}/:id`,
  };

  return (
    <Switch>
      <Route exact path={paths.details} render={routerProps => <Details {...props} {...routerProps} />} />
      <Route render={routerProps => <Overview {...props} {...routerProps} />} />
    </Switch>
  );
};

export default withRouter(Registrations);
