import React from 'react';
import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';

import settings from '../../../../settings';

import Editor from '../../../components/Editor';
import RightSide from '../../../components/FormArea/RightSide';
import Loader from '../../../components/Loader';
import Form from '../../../components/Form';
import Button from '../../../components/Button';
import Input from '../../../components/Inputs/Input';
import ContentBox from '../../../components/FormArea/ContentBox';
import Switch from '../../../components/Inputs/Switch';
import Dropdown from '../../../components/Inputs/Dropdown';
import Datepicker from '../../../components/Inputs/Datepicker';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../logic/dataContainer';
import File from '../../../components/Inputs/File';

class FranchiseNewsEdit extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/edit/${props.match.params.id}$`), '');
    this.userRoles = JSON.parse(localStorage.getItem('userdata')).roles;

    this.state = {
      loading: true,
      priorities: [
        {
          label: 'Priorität 1',
          value: '1',
        },
        {
          label: 'Priorität 2',
          value: '2',
        },
        {
          label: 'Priorität 3',
          value: '3',
        },
      ],
      categories: [
        {
          label: 'Kategorie 1',
          value: '1',
        },
        {
          label: 'Kategorie 2',
          value: '2',
        },
        {
          label: 'Kategorie 3',
          value: '3',
        },
      ],
    };

    reqwest({
      method: 'GET',
      url: settings.news.default.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: props.match.params.id,
      },
    }).then(newsResult => {
      this.dataContainer = new DataContainer(newsResult);

      this.setState({
        ExpiryDateActive: this.dataContainer.get('General', 'ExpiryDateActive'),
        Priority: this.dataContainer.get('General', 'Priority'),
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    swal({
      title: 'Bitte warten...',
      content: <Loader text='Ihre Dateien werden hochgeladen und Ihre News veröffentlicht!' />,
      button: { visible: false },
    });

    this.files.saveFiles().then(guids => {
      this.dataContainer.set('General', 'Files')(guids);
      reqwest({
        method: 'POST',
        url: settings.news.default.save,
        data: {
          Token: localStorage.getItem('token'),
          News: this.dataContainer.getStringified(),
        },
      }).then(() => {
        swal({
          title: 'Erfolgreich!',
          text: 'Ihre News wurde erfolgreich gespeichert.',
          icon: 'success',
        });
        this.props.history.replace(this.parentRoute);
      });
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>News bearbeiten</p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <div className='FormArea-Form-Left'>
                    <Form ref={node => (this.form = node)}>
                      <ContentBox title='Inhalt'>
                        <Dropdown
                          label='Kategorien:'
                          isSearchable
                          isMulti
                          name='Kategorien'
                          getSelected={value => {
                            this.dataContainer.set('General', 'Categories')(value);
                          }}
                          defaultValue={this.dataContainer.get('General', 'Categories')}
                          options={this.state.categories}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                        />
                        <Input
                          type='text'
                          label='Titel:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('General', 'Title')(value);
                          }}
                          isDisabled={true}
                          name='Title'
                          defaultValue={this.dataContainer.get('General', 'Title')}
                        />
                        <Editor
                          editable
                          onChange={value => {
                            this.dataContainer.set('General', 'Text')(value);
                          }}
                          validator={[]}
                        >
                          {this.dataContainer.get('General', 'Text')}
                        </Editor>
                        <File
                          ref={node => (this.files = node)}
                          editable
                          name='files'
                          label='Anhänge:'
                          defaultFiles={this.dataContainer.get('General', 'Files')}
                          validator={[]}
                        />
                      </ContentBox>
                      <ContentBox title='Einstellungen'>
                        <Dropdown
                          label='Priorität:'
                          isSearchable
                          getSelected={value => {
                            this.dataContainer.set('General', 'Priority')(value);
                            this.setState({
                              Priority: value,
                            });
                          }}
                          defaultValue={this.dataContainer.get('General', 'Priority')}
                          options={this.state.priorities}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='Priority'
                        />
                        {(this.state.Priority === '1' || this.state.Priority === '2') && (
                          <Switch
                            label='E-Mail an RL / GF:'
                            onChange={value => {
                              this.dataContainer.set('General', 'SendMail')(value);
                            }}
                            name='SendMail'
                            defaultChecked={this.dataContainer.get('General', 'SendMail')}
                            validator={[]}
                          />
                        )}
                        <Switch
                          label='Muss bestätigt werden:'
                          onChange={value => {
                            this.dataContainer.set('General', 'Confirm')(value);
                          }}
                          name='Confirm'
                          defaultChecked={this.dataContainer.get('General', 'Confirm')}
                          validator={[]}
                        />
                        <Switch
                          label='Kommentare zulassen:'
                          onChange={value => {
                            this.dataContainer.set('General', 'AllowComments')(value);
                          }}
                          name='AllowComments'
                          defaultChecked={this.dataContainer.get('General', 'AllowComments')}
                          validator={[]}
                        />
                        <Switch
                          label='Veröffentlicht:'
                          onChange={value => {
                            this.dataContainer.set('General', 'Released')(value);
                          }}
                          name='Released'
                          defaultChecked={this.dataContainer.get('General', 'Released')}
                          validator={[]}
                        />
                        <Switch
                          label='Ablaufdatum aktiv:'
                          onChange={value => {
                            this.dataContainer.set('General', 'ExpiryDateActive')(value);
                            this.setState({
                              ExpiryDateActive: value,
                            });
                          }}
                          name='ExpiryDateActive'
                          defaultChecked={this.dataContainer.get('General', 'ExpiryDateActive')}
                          validator={[]}
                        />
                        {this.state.ExpiryDateActive && (
                          <Datepicker
                            label='Ablaufdatum:'
                            defaultValue={this.dataContainer.get('General', 'ExpiryDate')}
                            onChange={value => {
                              this.dataContainer.set('General', 'ExpiryDate')(value);
                            }}
                            validator={[]}
                            name='ExpiryDate'
                          />
                        )}
                        <Dropdown
                          label='Info an:'
                          isSearchable
                          getSelected={value => {
                            this.dataContainer.set('General', 'Inform')(value);
                          }}
                          defaultValue={this.dataContainer.get('General', 'Inform')}
                          options={[
                            {
                              label: 'Jeden Mitarbeiter',
                              value: 'everyone',
                            },
                            {
                              label: 'KST',
                              value: 'kst',
                            },
                          ]}
                          validator={[]}
                          name='Inform'
                        />
                      </ContentBox>
                    </Form>
                  </div>
                  <RightSide
                    createDate={this.dataContainer.get('RightSide', 'CreateDate')}
                    createUserName={this.dataContainer.get('RightSide', 'CreateUserName')}
                    updateDate={this.dataContainer.get('RightSide', 'UpdateDate')}
                    updateUserName={this.dataContainer.get('RightSide', 'UpdateUserName')}
                  >
                    <Button big icon={faTimes} to={this.parentRoute}>
                      Abbrechen
                    </Button>
                    <Button big type='primary' icon={faSave} onClick={this.save}>
                      Speichern
                    </Button>
                  </RightSide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FranchiseNewsEdit;
