import React, { Component } from 'react';
import ReactTable from 'react-table';
import moment from 'moment';

import reqwest from 'reqwest';
import settings from '../../../../settings';
import Loader from '../../../components/Loader';
import Dropdown from '../../../components/Inputs/Dropdown';
import Button from '../../../components/Button';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

class VacationRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: [],
      SelectedMonth: moment().format('MM'),
      SelectedYear: moment().format('YYYY'),
    };

    const userData = JSON.parse(localStorage.getItem('userdata'));
    this.UserGuid = userData.userGuid;
  }

  componentDidMount() {
    this._getVacationRequests();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.SelectedMonth !== this.state.SelectedMonth || prevState.SelectedYear !== this.state.SelectedYear) {
      this._getVacationRequests();
    }
  }

  _getVacationRequests = () => {
    this.setState({
      loading: true,
    });
    reqwest({
      method: 'GET',
      url: settings.vacation.getAbsenceOverview,
      data: {
        Token: localStorage.getItem('token'),
        UserGuid: this.UserGuid,
        Month: this.state.SelectedMonth,
        Year: this.state.SelectedYear,
        Typ: 'Vacation',
      },
    })
      .then(res => {
        let data = JSON.parse(res);
        if (data) {
          this.setState({
            data: [...data],
            loading: false,
          });
        }
      })
      .catch(() => {
        throw new Error('Something went very very wrong...');
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.state.data.filter(row => row.Params.Guid);

    return (
      <>
        <div className='Toolbar'>
          <Dropdown
            defaultValue={this.state.SelectedMonth}
            placeholder='Monat'
            options={[
              { label: 'Alle', value: '' },
              { label: 'Januar', value: '01' },
              { label: 'Februar', value: '02' },
              { label: 'März', value: '03' },
              { label: 'April', value: '04' },
              { label: 'Mai', value: '05' },
              { label: 'Juni', value: '06' },
              { label: 'Juli', value: '07' },
              { label: 'August', value: '08' },
              { label: 'September', value: '09' },
              { label: 'Oktober', value: '10' },
              { label: 'November', value: '11' },
              { label: 'Dezember', value: '12' },
            ]}
            getSelected={value => {
              this.setState({
                SelectedMonth: value,
              });
            }}
            name='Month'
          />
          <Dropdown
            defaultValue={this.state.SelectedYear}
            placeholder='Jahr'
            options={Array.from({ length: 10 }, (v, i) => new Date().getFullYear() - i).map(year => ({ label: year, value: `${year}` }))}
            getSelected={value => {
              this.setState({
                SelectedYear: value,
              });
            }}
            name='Year'
          />
          <div className='Toolbar-buttons'>
            <Button big icon={faPlus} to={`${this.props.match.url}/new`} replace>
              Neuer Urlaubsantrag
            </Button>
          </div>
        </div>
        <ReactTable
          getTrProps={(_, { original }) => ({
            onClick: () => this.props.history.push(`${this.props.match.url}/${original.Params.Guid}`),
          })}
          data={data}
          pageSize={data.length}
          noDataText='Es liegen keine Urlaubstage für den ausgewählten Zeitraum vor.'
          columns={[
            {
              Header: 'Von',
              accessor: 'Params.From',
              maxWidth: 200,
            },
            {
              Header: 'Bis',
              accessor: 'Params.To',
              maxWidth: 200,
            },
            {
              Header: 'Status',
              accessor: 'Params.Status',
              maxWidth: 200,
            },
            {
              Header: 'Bemerkungen',
              accessor: 'Params.Comment',
            },
          ]}
        />
      </>
    );
  }
}

export default VacationRequest;
