import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Details from './Detail';
import New from './New';

const CorrectionRequest = props => {
  const paths = {
    details: `${props.match.path}/:id`,
    new: `${props.match.path}/new/:date`,
  };

  return (
    <Switch>
      <Route exact path={paths.new} component={New} />
      <Route exact path={paths.details} component={Details} />
    </Switch>
  );
};

export default withRouter(CorrectionRequest);
