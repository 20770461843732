import React from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../settings';

import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import RightSide from '../../../../components/FormArea/RightSide';
import ContentBox from '../../../../components/FormArea/ContentBox';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

class SchedulerOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      schedules: [],
      loading: false,
    };

    // reqwest({
    //   method: 'get',
    //   url: settings.permissions.GetAllRoles,
    //   contentType: 'json',
    //   data: {
    //     Token: localStorage.getItem('token'),
    //   },
    // }).then(getAllSchedulesResponse => {
    //   const schedules = JSON.parse(getAllSchedulesResponse);

    //   this.setState({
    //     schedules,
    //     loading: false,
    //   });
    // });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Jobs'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => this.props.history.push(`${this.props.match.url}/${original.Params.Guid}`),
              })}
              data={this.state.schedules}
              pageSize={this.state.schedules.length}
              noDataText='Es liegen keine Jobs vor.'
              columns={[
                {
                  Header: 'Name',
                  accessor: 'Params.Name',
                },
                {
                  Header: 'Bemerkung',
                  accessor: 'Params.Bemerkung',
                },
              ]}
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faPlus} to={`${this.props.match.url}/new`}>
            Neuer Job
          </Button>
        </RightSide>
      </>
    );
  }
}

export default SchedulerOverview;
