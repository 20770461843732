import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faBell } from '@fortawesome/pro-light-svg-icons';

class Alert extends Component {
  constructor(props) {
    super(props);

    this._dismissAlert.bind(this);

    this.state = {
      classname: `Alert Alert--${this.props.type} `,
    };

    switch (this.props.type) {
      case 'error':
        this.icon = faTimes;
        break;
      case 'warning':
        this.icon = faBell;
        break;
      default:
        break;
    }

    this.state = {
      classname: `Alert Alert--${this.props.type} Alert--is-Active`,
    };
  }

  _dismissAlert = () => {
    this.props.onDismiss();
  };

  render() {
    return (
      <div className={`${this.state.classname}`}>
        <div className='Alert-Top'>
          <div className='Alert-Icon-Wrapper'>
            <FontAwesomeIcon size='5x' className='Alert-Icon' icon={this.icon} />
          </div>
          <div className='Alert-Title'>{this.props.title}</div>
        </div>
        <div className='Alert-Text'>{this.props.children}</div>
        {!this.props.dismissTime && (
          <button className='Alert-Button' key='dismissButton' type='button' onClick={this._dismissAlert.bind(this)}>
            {this.props.buttonText ? this.props.buttonText : 'Verstanden'}
          </button>
        )}
      </div>
    );
  }
}

Alert.propTypes = {
  type: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  needsResponse: PropTypes.bool,
  dismissTime: PropTypes.number,
};

export default Alert;
