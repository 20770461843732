import React from 'react';
import PropTypes from 'prop-types';

import reqwest from 'reqwest';
import swal from 'sweetalert';
import moment from 'moment';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import Datepicker from '../../../../../components/Inputs/Datepicker';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import { faTimes, faTrash, faSave } from '@fortawesome/pro-solid-svg-icons';

class OriginatingOfficeDetail extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');

    this.state = {
      loading: true,
      OfficeList: [],
    };

    const officePromise = reqwest({
      method: 'GET',
      url: settings.usermanagement.originatingOffice.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: props.match.params.id,
      },
    }).then(officeResult => {
      this.dataContainer = new DataContainer(officeResult);
    });

    const allOfficePromise = reqwest({
      method: 'get',
      url: settings.usermanagement.originatingOffice.getOfficeList,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(officeList => {
      officeList = JSON.parse(officeList);

      let result = officeList.map(element => {
        return {
          value: element.Params.Guid,
          label: `${element.Params.Shortname}`,
        };
      });
      this.setState({
        OfficeList: result,
      });
    });

    Promise.all([officePromise, allOfficePromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie das Stammbüro wirklich aus dem Mitarbeiter löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'GET',
          url: settings.usermanagement.originatingOffice.delete,
          data: {
            Token: localStorage.getItem('token'),
            Guid: this.props.match.params.id,
          },
        }).then(() => {
          swal({
            title: 'Erfolgreich!',
            text: 'Das Stammbüro wurde erfolgreich gelöscht.',
            icon: 'success',
          });
          this.props.history.replace(this.parentRoute);
        });
      } else {
        swal({ title: 'Abgebrochen!', text: 'Das Stammbüro wurde nicht gelöscht.', icon: 'info' });
      }
    });
  };

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const newOriginatingOffice = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.usermanagement.originatingOffice.save,
      data: {
        Token: localStorage.getItem('token'),
        UserOffice: newOriginatingOffice,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Das Stammbüro wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title={`${this.dataContainer.get('Generals', 'From')} - ${this.dataContainer.get('Generals', 'To')}`}>
              <Dropdown
                label='Büro:'
                isSearchable
                defaultValue={this.dataContainer.get('Generals', 'OfficeGuid')}
                options={this.state.OfficeList}
                getSelected={value => {
                  let officeName = this.state.OfficeList.filter(office => office.value === value)[0].label;
                  this.dataContainer.set('Generals', 'OfficeGuid')(value);
                  this.dataContainer.set('Generals', 'OfficeName')(officeName);
                }}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                name='OfficeGuid'
              />
              <Datepicker
                label='Von:'
                onChange={day => {
                  this.dataContainer.set('Generals', 'From')(day);
                }}
                defaultValue={this.dataContainer.get('Generals', 'From')}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                  {
                    custom: value => {
                      if (!this.dataContainer.get('Generals', 'To')) {
                        return true;
                      }
                      const validFrom = moment(value, 'DD.MM.YYYY');
                      const validTo = moment(this.dataContainer.get('Generals', 'To'), 'DD.MM.YYYY');
                      return validFrom.isBefore(validTo);
                    },
                    error: 'Von darf nicht nach Bis liegen.',
                  },
                ]}
                name='From'
              />
              <Datepicker
                label='Bis:'
                onChange={day => {
                  this.dataContainer.set('Generals', 'To')(day);
                }}
                defaultValue={this.dataContainer.get('Generals', 'To')}
                validator={[
                  {
                    custom: value => {
                      if (!this.dataContainer.get('Generals', 'From')) {
                        return true;
                      }
                      const validFrom = moment(this.dataContainer.get('Generals', 'From'), 'DD.MM.YYYY');
                      const validTo = moment(value, 'DD.MM.YYYY');
                      return validTo.isAfter(validFrom);
                    },
                    error: 'Bis darf nicht vor Von liegen.',
                  },
                ]}
                name='To'
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide
          createDate={this.dataContainer.get('RightSide', 'CreateDate')}
          createUserName={this.dataContainer.get('RightSide', 'CreateUserName')}
          updateDate={this.dataContainer.get('RightSide', 'UpdateDate')}
          updateUserName={this.dataContainer.get('RightSide', 'UpdateUserName')}
        >
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='danger' icon={faTrash} onClick={this.delete}>
            Löschen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

OriginatingOfficeDetail.propTypes = {
  UserGuid: PropTypes.string.isRequired,
};

export default OriginatingOfficeDetail;
