import React, { Component } from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';

import Loader from '../../../../../components/Loader';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Form from '../../../../../components/Form';
import Input from '../../../../../components/Inputs/Input';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import Datepicker from '../../../../../components/Inputs/Datepicker';
import RightSide from '../../../../../components/FormArea/RightSide';
import Button from '../../../../../components/Button';
import { faSave } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../../logic/dataContainer';
import getConstants from '../../../../../logic/constants';

class PhoneSystemDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      phoneType: [],
    };

    const dataPromise = reqwest({
      method: 'get',
      url: settings.office.phonesystem.get,
      data: {
        OfficeGuid: this.props.OfficeGuid,
      },
    }).then(res => {
      this.container = new DataContainer(res);
    });

    const promisePhoneType = getConstants('PhoneType').then(phoneType => {
      this.setState({
        phoneType,
      });
    });

    Promise.all([dataPromise, promisePhoneType]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const data = this.container.getStringified();

    reqwest({
      method: 'post',
      url: settings.office.phonesystem.save,
      data: {
        Phonesystem: data,
        OfficeGuid: this.props.OfficeGuid,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Die Telefonanlage wurde erfolgreich gespeichert.',
        icon: 'success',
      });
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            {this.container.get('Generals', 'CanSee') && (
              <ContentBox title='Telefonanlage'>
                <Dropdown
                  label='Telefontyp:'
                  isSearchable
                  getSelected={value => {
                    this.container.set('Generals', 'Phonetype')(value);
                  }}
                  defaultValue={this.container.get('Generals', 'Phonetype')}
                  options={this.state.phoneType}
                  isDisabled={!this.container.get('Generals', 'CanEdit')}
                  validator={[]}
                  name='Phonetype'
                />
                <Input
                  type='text'
                  label='Gerät:'
                  validator={[]}
                  onChange={value => {
                    this.container.set('Generals', 'Device')(value);
                  }}
                  defaultValue={this.container.get('Generals', 'Device')}
                  name='Gerät'
                  isDisabled={!this.container.get('Generals', 'CanEdit')}
                />
                <Datepicker
                  label='Anschaffung:'
                  onChange={day => {
                    this.container.set('Generals', 'Purchase')(day);
                  }}
                  defaultValue={this.container.get('Generals', 'Purchase')}
                  isDisabled={!this.container.get('Generals', 'CanEdit')}
                  validator={[]}
                  name='Purchase'
                />
                <Datepicker
                  label='Vertragsende:'
                  onChange={day => {
                    this.container.set('Generals', 'ExparationDate')(day);
                  }}
                  defaultValue={this.container.get('Generals', 'ExparationDate')}
                  isDisabled={!this.container.get('Generals', 'CanEdit')}
                  validator={[]}
                  name='ExpirationDate'
                />
                <Input
                  type='text'
                  label='Kosten:'
                  validator={[]}
                  onChange={value => {
                    this.container.set('Generals', 'Costs')(value);
                  }}
                  defaultValue={this.container.get('Generals', 'Costs')}
                  name='Kosten'
                  isDisabled={!this.container.get('Generals', 'CanEdit')}
                />
                <Input
                  type='number'
                  label='Anzahl Endgeräte:'
                  validator={[]}
                  onChange={value => {
                    this.container.set('Generals', 'CountEndpoints')(value);
                  }}
                  defaultValue={this.container.get('Generals', 'CountEndpoints')}
                  name='Anzahl Endgeräte'
                  isDisabled={!this.container.get('Generals', 'CanEdit')}
                />
                <Input
                  type='textarea'
                  label='Bemerkungen:'
                  validator={[]}
                  onChange={value => {
                    this.container.set('Generals', 'Commit')(value);
                  }}
                  defaultValue={this.container.get('Generals', 'Commit')}
                  name='Bemerkungen'
                  isDisabled={!this.container.get('Generals', 'CanEdit')}
                />
              </ContentBox>
            )}
          </Form>
        </div>
        <RightSide
          createDate={this.container.get('RightSide', 'CreateDate')}
          createUserName={this.container.get('RightSide', 'CreateUserName')}
          updateDate={this.container.get('RightSide', 'UpdateDate')}
          updateUserName={this.container.get('RightSide', 'UpdateUserName')}
        >
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default PhoneSystemDetail;
