import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import reqwest from 'reqwest';

import history from '../../../history';
import settings from '../../../../settings';

import Loader from '../../../components/Loader';
import Button from '../../../components/Button';
import Searchbar from '../../../components/Searchbar';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

class SectorMagazineOverview extends Component {
  constructor(props) {
    super(props);

    let searchString = '';
    if (props.match.params.search) {
      searchString = props.match.params.search;
    }

    // TODO: Check for correct Roles
    this.rolesThatCanCreateNews = ['Superuser', 'Geschäftsführer', 'GF', 'Regionalleitung', 'Marketing', 'Schulung / Personal'];

    this.userRoles = JSON.parse(localStorage.getItem('userdata')).roles;

    this.state = {
      loading: true,
      data: [],
      searchString,
    };

    // TODO: Introduce some kind of type
    const promise = reqwest({
      method: 'get',
      url: settings.news.default.getList,
      data: {
        Token: localStorage.getItem('token'),
        Type: 'SectorMagazine',
      },
    }).then(res => {
      this.setState({
        data: JSON.parse(res),
      });
    });

    Promise.all([promise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  _search = value => {
    const val = value.toLowerCase();

    history.replace(val ? `/News/SectorMagazine/search/${val}` : '/News/SectorMagazine');

    this.setState({
      searchString: val,
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    let data = [];
    if (this.state.data[0].Params?.SearchAllResult === 'Entry not found') {
      data = [];
    } else {
      data = this.state.data.filter(rowData =>
        [rowData.Params.Title, rowData.Params.DateTime]
          .map(e => e.toLowerCase())
          .some(v => {
            return v.indexOf(this.state.searchString) >= 0;
          }),
      );
    }

    return (
      <>
        <div className='Toolbar'>
          <Searchbar placeholder='Branchenmagazin suchen...' defaultValue={this.state.searchString} onChange={this._search} />
          <div className='Toolbar-buttons'>
            {this.userRoles.some(role => this.rolesThatCanCreateNews.includes(role)) && (
              <Button icon={faPlus} to={`${this.props.match.url}/new`}>
                Branchenmagazin erstellen
              </Button>
            )}
          </div>
        </div>
        <ReactTable
          getTrProps={(_, row) => ({
            style: {
              display: !row ? 'none' : '',
            },
            onClick: () => row && this.props.history.replace(`${this.props.match.url}/${row.original.Params.Guid}`),
          })}
          data={data}
          pageSize={data.length}
          noDataText='Es wurden keine Branchenmagazine gefunden.'
          defaultSorted={[
            {
              id: 'Datum',
              desc: true,
            },
          ]}
          defaultFiltered={[
            {
              id: 'Released',
              value: 'true',
            },
          ]}
          columns={[
            {
              Header: 'Titel',
              accessor: 'Params.Title',
            },
            {
              Header: 'Datum',
              accessor: 'Params.CreateDate',
            },
            {
              id: 'Released',
              Header: 'Veröffentlicht',
              filterable: true,
              Cell: ({ original }) => <>{original.Params.Released === 'true' ? 'Veröffentlicht' : 'Nicht veröffentlicht'}</>,
              filterMethod: (filter, row) => {
                if (filter.value === 'all') {
                  return true;
                }
                if (filter.value === 'true') {
                  return row._original.Params[filter.id] === 'true';
                }
                return row._original.Params[filter.id] !== 'true';
              },
              Filter: ({ filter, onChange }) => (
                <select onChange={event => onChange(event.target.value)} style={{ width: '100%' }} value={filter ? filter.value : 'true'}>
                  <option value='all'>Alle</option>
                  <option value='true'>Veröffentlicht</option>
                  <option value='false'>nicht Veröffentlicht</option>
                </select>
              ),
            },
          ]}
        />
      </>
    );
  }
}

export default withRouter(SectorMagazineOverview);
