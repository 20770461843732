import React from 'react';
import PropTypes from 'prop-types';

import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';

import downloadB64 from '../../../../../logic/base64Downloader';
import history from '../../../../../history';
import settings from '../../../../../../settings';
import FileDetail from '../../../../../components/FileDetail';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import { faSave, faTimes, faTrash, faDownload } from '@fortawesome/pro-solid-svg-icons';

class FileDetails extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');

    this.state = {
      loading: true,
    };

    reqwest({
      method: 'get',
      url: settings.office.invoice.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: props.match.params.id,
      },
    }).then(res => {
      res = JSON.parse(res);
      this.setState({
        loading: false,
        headGuid: res[0].Params.Guid,
        fileGuid: res[1].Params.Guid,
        streamGuid: res[1].Params.StreamGuid,
        base64File: res[1].Params.Data,
        file: {
          file: res[1].Params.Data,
          title: res[0].Params.Title,
          filename: res[1].Params.Filename ? res[1].Params.Filename : res[0].Params.Title,
          source: res[1].Params.Filename ? res[1].Params.Filename : res[0].Params.Title,
          options: {
            type: 'local',
            file: {
              name: res[1].Params.Filename ? res[1].Params.Filename : res[0].Params.Title,
              size: res[1].Params.Filesize,
              type: res[1].Params.DataType,
            },
          },
        },
      });
    });
  }

  _addFile = (error, file) => {
    if (error) {
      swal({
        title: 'Fehler',
        content: <p>Beim hinzufügen der Datei ist ein Fehler aufgetreten!</p>,
        icon: 'error',
      });
    }

    if (file.origin === 1) {
      // User has uploaded File and it's not set by Server or else
      this.setState({
        base64File: file.getFileEncodeDataURL(),
        file: {
          ...this.state.file,
          file: file.getFileEncodeDataURL(),
          filename: file.filename,
          source: file.filename,
          options: {
            type: 'local',
            file: {
              name: file.filename,
              size: file.fileSize,
              type: file.fileType,
            },
          },
        },
      });
    }
  };

  _delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      content: (
        <p>
          Möchten Sie die Datei <span className='Indented'>{this.state.file.filename}</span> wirklich löschen?
        </p>
      ),
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'get',
          url: settings.office.invoice.delete,
          data: {
            Token: localStorage.getItem('token'),
            Guid: this.state.headGuid,
          },
        }).then(() => {
          swal({
            title: 'Erfolgreich!',
            content: (
              <p>
                Die Datei <span className='Indented'>{this.state.file.filename}</span> wurde erfolgreich gelöscht!
              </p>
            ),
            icon: 'success',
          });
          history.push(this.parentRoute);
        });
      }
    });
  };

  _save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    reqwest({
      method: 'post',
      url: settings.files.save,
      data: {
        Token: localStorage.getItem('token'),
        Files: JSON.stringify({
          ParamName: 'File',
          Params: {
            Guid: this.state.fileGuid,
            StreamGuid: this.state.streamGuid,
            Filesize: this.state.file.options.file.size,
            Filename: this.state.file.options.file.name,
            DataType: this.state.file.options.file.type,
            Data: this.state.file.file,
          },
        }),
      },
    }).then(res => {
      res = JSON.parse(res);

      if (res.Params.SaveResult === 'Saved' || res.Params.SaveResult === 'Updated') {
        let dataContainer = {
          ParamName: 'Entry',
          Params: {
            Guid: this.state.headGuid,
            FranGuid: this.props.OfficeGuid,
            Typ: 'Invoice',
            Title: this.title.value,
            File: this.state.fileGuid,
          },
        };

        reqwest({
          method: 'post',
          url: settings.office.invoice.save,
          data: {
            Token: localStorage.getItem('token'),
            FilesContainer: JSON.stringify(dataContainer),
          },
        })
          .then(res => {
            res = JSON.parse(res);
            if (res.Params.SaveResult === 'Updated') {
              swal({
                title: 'Erfolgreich!',
                text: 'Die Datei wurde erfolgreich gespeichert!.',
                icon: 'success',
              });
            } else {
              swal({
                title: 'Fehler!',
                text: 'Beim speichern der Datei ist ein unbekannter Fehler aufgetreten.',
                icon: 'error',
              });
            }
          })
          .fail(() => {
            swal({
              title: 'Fehler!',
              text: 'Beim speichern der Datei ist ein unbekannter Fehler aufgetreten.',
              icon: 'error',
            });
          });
      } else {
        swal({
          title: 'Fehler!',
          text: 'Beim speichern der Datei ist ein unbekannter Fehler aufgetreten.',
          icon: 'error',
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title={this.state.file.filename}>
              {this.state.file && (
                <FileDetail
                  filename={this.state.file.filename}
                  title={this.state.file.title}
                  file={this.state.file}
                  maxFiles={1}
                  onAddFile={this._addFile}
                  pondRef={node => (this.pond = node)}
                  titleRef={node => (this.title = node)}
                />
              )}
            </ContentBox>
          </Form>
        </div>
        <RightSide>
          <Button big type={'danger'} icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type={'danger'} icon={faTrash} onClick={this._delete}>
            Löschen
          </Button>
          <Button
            big
            icon={faDownload}
            onClick={() => {
              downloadB64(this.state.file.file, this.state.file.filename);
            }}
          >
            Download
          </Button>
          <Button big type='primary' icon={faSave} onClick={this._save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

FileDetails.propTypes = {
  OfficeGuid: PropTypes.string.isRequired,
};

export default FileDetails;
