import React from 'react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import reqwest from 'reqwest';
import settings from '../../../../../settings';

import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';

import RightSide from '../../../../components/FormArea/RightSide';
import ContentBox from '../../../../components/FormArea/ContentBox';

import { faTimes, faPlus, faGripLinesVertical, faSave, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uuid from 'uuid';
import swal from 'sweetalert';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class ConstantsDetail extends React.Component {
  constructor(props) {
    super(props);

    this.type = props.match.params.id;
    this.parentRoute = this.props.match.url.replace(new RegExp(`/${this.type}$`), '');

    reqwest({
      method: 'GET',
      url: settings.constants.getList,
      data: {
        Token: localStorage.getItem('token'),
        Typ: this.type,
      },
    }).then(constant => {
      constant = JSON.parse(constant);

      const fields = [...this.state.fields];
      if (constant[0]?.Title) {
        fields.push({
          label: constant[0].Title,
          value: 'ExtraField',
        });
      }

      constant = constant
        .filter(con => con.Guid)
        .sort((a, b) => {
          if (a.Ord > b.Ord) return 1;
          if (a.Ord < b.Ord) return -1;
          return 0;
        });

      this.setState({
        loading: false,
        constant,
        fields,
      });
    });

    this.state = {
      loading: true,
      constant: [],
      fields: [
        {
          label: 'Name',
          value: 'Name',
        },
        {
          label: 'Kurzkennzeichen (KKZ)',
          value: 'KKZ',
        },
      ],
    };
  }

  save = () => {
    const savePromises = [];
    [...this.state.constant].forEach((constant, index) => {
      constant.Ord = index;
      if (constant.Changed === true) {
        delete constant.Changed;
        if (constant.Guid.startsWith('new:')) {
          constant.Guid = '';
        }
        savePromises.push(
          reqwest({
            method: 'POST',
            url: settings.constants.save,
            data: {
              Token: localStorage.getItem('token'),
              Constant: JSON.stringify(constant),
            },
          }).then(saveResult => {
            saveResult = JSON.parse(saveResult);
            return saveResult.SaveResult;
          }),
        );
      }
    });

    Promise.all(savePromises).then(saveResults => {
      if (saveResults.length) {
        const isValid = saveResults.every(result => result !== 'Updated' || result !== 'Saved');
        if (isValid) {
          swal({
            title: 'Erfolgreich!',
            text: 'Die Konstante wurde erfolgreich gespeichert.',
            icon: 'success',
          });
        }
      }
    });
  };

  delete = guid => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diese Konstante wirklich löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        if (!guid.startsWith('new:')) {
          reqwest({
            method: 'GET',
            url: settings.constants.delete,
            data: {
              Token: localStorage.getItem('token'),
              Guid: guid,
            },
          }).then(deleteResult => {
            deleteResult = JSON.parse(deleteResult);

            if (deleteResult.DeleteResult === 'Deleted') {
              swal({
                title: 'Erfolgreich!',
                text: 'Die Konstante wurde erfolgreich gelöscht.',
                icon: 'success',
              });

              const constant = [...this.state.constant].filter(con => con.Guid !== guid);
              this.setState({
                constant,
              });
            }
          });
        } else {
          const constant = [...this.state.constant].filter(con => con.Guid !== guid);
          this.setState({
            constant,
          });
        }
      }
    });
  };

  onDragEnd = result => {
    if (!result.destination) return;

    let items = reorder(this.state.constant, result.source.index, result.destination.index);
    items = items.map((item, index) => {
      item.Ord = index;
      item.Changed = true;
      return item;
    });

    this.setState({
      constant: items,
    });
  };

  addConstant = () => {
    const constant = this.state.constant;

    let newConstant = {
      Guid: 'new:' + uuid(),
      Typ: this.type,
      Name: '',
      KKZ: '',
      Ord: '',
    };

    if (constant[0]?.Title) {
      newConstant = {
        ...newConstant,
        Title: constant[0].Title,
        FieldType: constant[0].FieldType,
        ExtraField: '',
      };
    }

    this.setState({
      constant: [...constant, newConstant],
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const maxWidth = `calc((100% - 80px) / ${this.state.fields.length})`;
    const minWidth = `calc((100% - 80px) / ${this.state.fields.length})`;

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title={this.props.match.params.id}>
            <div className='Table'>
              <div className='Table-head'>
                <div
                  className='Table-head-field'
                  style={{
                    maxWidth: '40px',
                    minWidth: '40px',
                  }}
                >
                  {' '}
                </div>
                {this.state.fields.map(field => (
                  <div key={field.value} className='Table-head-field' style={{ maxWidth, minWidth }}>
                    {field.label}
                  </div>
                ))}
                <div
                  className='Table-head-field'
                  style={{
                    maxWidth: '40px',
                    minWidth: '40px',
                  }}
                >
                  {' '}
                </div>
              </div>
              <div className='Table-body'>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId='Table-body'>
                    {provided => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {this.state.constant.map((constant, cIndex) => (
                          <Draggable key={constant.Guid} draggableId={constant.Guid} index={cIndex}>
                            {provided => (
                              <div className='Table-body-row' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <div
                                  className='Table-body-data'
                                  style={{
                                    maxWidth: '40px',
                                    minWidth: '40px',
                                  }}
                                >
                                  <FontAwesomeIcon className='Table-body-data-icon' icon={faGripLinesVertical} />
                                </div>
                                {this.state.fields.map(field => (
                                  <div key={field.value} className='Table-body-data' style={{ maxWidth, minWidth }}>
                                    <input
                                      type='text'
                                      className='Table-body-data-value'
                                      defaultValue={constant[field.value]}
                                      onChange={event => {
                                        event.persist();
                                        let constant = [...this.state.constant];
                                        constant[cIndex][field.value] = event.target.value;
                                        constant[cIndex]['Changed'] = true;

                                        this.setState({
                                          constant,
                                        });
                                      }}
                                    />
                                  </div>
                                ))}
                                <div
                                  className='Table-body-data'
                                  style={{
                                    maxWidth: '40px',
                                    minWidth: '40px',
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className='Table-body-data-icon'
                                    icon={faTrash}
                                    onClick={() => {
                                      this.delete(constant.Guid);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big icon={faPlus} onClick={this.addConstant}>
            Konstanten hinzufügen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default ConstantsDetail;
