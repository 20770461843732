import React, { Component } from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';

import Loader from '../../../../../components/Loader';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Form from '../../../../../components/Form';
import File from '../../../../../components/Inputs/File';
import Input from '../../../../../components/Inputs/Input';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import RightSide from '../../../../../components/FormArea/RightSide';
import Button from '../../../../../components/Button';
import { faSave, faDownload } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../../logic/dataContainer';
import base64Downloader from '../../../../../logic/base64Downloader';
import getConstants from '../../../../../logic/constants';

class InventoryDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      inventoryStyleType: [],
    };

    const dataPromise = reqwest({
      method: 'get',
      url: settings.office.inventory.get,
      data: {
        OfficeGuid: this.props.OfficeGuid,
      },
    }).then(res => {
      this.container = new DataContainer(res);
    });

    const promiseStyle = getConstants('InventoryStyleType').then(inventoryStyleType => {
      this.setState({
        inventoryStyleType,
      });
    });

    Promise.all([dataPromise, promiseStyle]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    this.filePond.saveFiles().then(guids => {
      this.container.set('Generals', 'Files')(guids);

      reqwest({
        method: 'POST',
        url: settings.office.inventory.save,
        data: {
          Inventory: this.container.getStringified(),
          OfficeGuid: this.props.OfficeGuid,
        },
      }).then(() => {
        swal({
          title: 'Erfolgreich!',
          text: 'Das Inventar wurde erfolgreich gespeichert.',
          icon: 'success',
        });
      });
    });
  };

  download = () => {
    base64Downloader(this.state.file.file, this.state.file.filename);
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            {this.container.get('Generals', 'CanSee') && (
              <ContentBox title='Inventar'>
                <Input
                  type='number'
                  label='Anzahl Counter:'
                  validator={[]}
                  onChange={value => {
                    this.container.set('Generals', 'NumberCounter')(value);
                  }}
                  defaultValue={this.container.get('Generals', 'NumberCounter')}
                  name='Anzahl Counter'
                  isDisabled={!this.container.get('Generals', 'CanEdit')}
                />
                <Dropdown
                  label='Einrichtungslinie:'
                  isSearchable
                  getSelected={value => {
                    this.container.set('Generals', 'Style')(value);
                  }}
                  defaultValue={this.container.get('Generals', 'Style')}
                  options={this.state.inventoryStyleType}
                  isDisabled={!this.container.get('Generals', 'CanEdit')}
                  validator={[]}
                  name='Style'
                />
                <Input
                  type='text'
                  label='Dekoelemente:'
                  validator={[]}
                  onChange={value => {
                    this.container.set('Generals', 'Decoration')(value);
                  }}
                  defaultValue={this.container.get('Generals', 'Decoration')}
                  name='Dekoelemente'
                  isDisabled={!this.container.get('Generals', 'CanEdit')}
                />

                <File
                  ref={node => (this.filePond = node)}
                  name='File'
                  label='Datei:'
                  maxFiles={1}
                  defaultFiles={this.container.get('Generals', 'Files').replace(';', '')}
                  editable
                />
              </ContentBox>
            )}
          </Form>
        </div>
        <RightSide
          createDate={this.container.get('RightSide', 'CreateDate')}
          createUserName={this.container.get('RightSide', 'CreateUserName')}
          updateDate={this.container.get('RightSide', 'UpdateDate')}
          updateUserName={this.container.get('RightSide', 'UpdateUserName')}
        >
          {this.state.file?.file && (
            <Button big type='default' icon={faDownload} onClick={this.download}>
              Download
            </Button>
          )}
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default InventoryDetail;
