import React, { Component } from 'react';
import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';
import sha512 from 'sha512';

import settings from '../../../settings';

import Loader from '../../components/Loader';
import ContentBox from '../../components/FormArea/ContentBox';
import RightSide from '../../components/FormArea/RightSide';
import Form from '../../components/Form';
import Button from '../../components/Button';
import Input from '../../components/Inputs/Input';
import DataContainer from '../../logic/dataContainer';
import { faSave, faTrash } from '@fortawesome/pro-solid-svg-icons';
import checkAvailable from '../../logic/checkAvailable';

class BasedataDetail extends Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.guid}$`), '');

    this.state = {
      loading: true,
    };

    reqwest({
      method: 'get',
      url: settings.promoter.general.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: props.match.params.guid,
      },
    }).then(res => {
      this.dataContainer = new DataContainer(res);
      this.setState({
        loading: false,
      });
    });
  }

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      content: (
        <p>
          Möchten Sie diesen Veranstalter wirklich löschen?
          <br />
          Der Nutzer wird damit endgültig gelöscht.
        </p>
      ),
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'POST',
          url: settings.promoter.general.delete,
          data: {
            Guid: this.props.match.params.guid,
          },
        })
          .then(res => {
            res = JSON.parse(res);

            if (res.DeleteResult === 'Deleted') {
              swal({
                title: 'Erfolgreich!',
                content: <p>Der Veranstalter wurde erfolgreich gelöscht.</p>,
                icon: 'success',
              });
              this.props.history.replace(this.parentRoute);
            }
          })
          .catch(() => {
            throw new Error('Something went very very wrong...');
          });
      }
    });
  };

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    reqwest({
      method: 'POST',
      url: settings.promoter.general.save,
      data: {
        Token: localStorage.getItem('token'),
        Promoter: this.dataContainer.getStringified(),
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Der Veranstalter wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>Veranstalter bearbeiten</p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <div className='FormArea-Form-Left'>
                    <Form ref={node => (this.form = node)}>
                      <ContentBox title='Stammdaten'>
                        <Input
                          type='text'
                          label='Name:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('Generals', 'Description')(value);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'Description')}
                          name='desc'
                        />
                        <Input
                          type='text'
                          label='E-Mail:'
                          onChange={value => {
                            this.dataContainer.set('Generals', 'EMail')(value);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'EMail')}
                          name='EMail'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                            {
                              type: 'email',
                              error: 'Bitte geben Sie eine valide E-Mail Adresse ein',
                            },
                            {
                              custom: async value => {
                                if (this.dataContainer.getInitial('Generals', 'EMail') !== value) {
                                  return await checkAvailable('email', value);
                                }
                                return true;
                              },
                              error: 'Diese E-Mail Adresse wird bereits verwendet',
                            },
                          ]}
                        />
                      </ContentBox>
                      <ContentBox title='Stammdaten'>
                        <Input
                          type='text'
                          label='Benutzername:'
                          onChange={value => {
                            this.dataContainer.set('Generals', 'Username')(value);
                          }}
                          fullWidth
                          defaultValue={this.dataContainer.get('Generals', 'Username')}
                          name='username'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                        />
                        <Input
                          type='password'
                          label='Passwort:'
                          validator={[]}
                          passwordValidator
                          passwordShowButton
                          onChange={value => {
                            this.setState({
                              Password: value,
                            });
                            this.dataContainer.set('Generals', 'Password')(sha512(value).toString('hex'));
                          }}
                          name='Password'
                        />
                        <Input
                          type='password'
                          label='Passwort wiederholen:'
                          validator={[
                            {
                              match: this.state.Password,
                              error: 'Die Passwörter müssen übereinstimmen.',
                            },
                          ]}
                          name='PasswordTwo'
                        />
                      </ContentBox>
                    </Form>
                  </div>
                  <RightSide
                    createDate={this.dataContainer.get('RightSide', 'CreateDate')}
                    createUserName={this.dataContainer.get('RightSide', 'CreateUserName')}
                    updateDate={this.dataContainer.get('RightSide', 'UpdateDate')}
                    updateUserName={this.dataContainer.get('RightSide', 'UpdateUserName')}
                  >
                    <Button big type='danger' icon={faTrash} onClick={this.delete}>
                      Löschen
                    </Button>
                    <Button big type='primary' icon={faSave} onClick={this.save}>
                      Speichern
                    </Button>
                  </RightSide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BasedataDetail;
