import React, { Component } from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../../settings';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Button from '../../../../../components/Button';
import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

class AgencynumberOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agencynumberList: [],
      actionTypes: [],
      promoter: [],
      loading: true,
    };

    const agencynumberPromise = reqwest({
      method: 'get',
      url: settings.office.agencynumbers.getList,
      data: {
        Token: localStorage.getItem('token'),
        OfficeGuid: this.props.OfficeGuid,
      },
    }).then(agencynumberResponse => {
      const agencynumberList = JSON.parse(agencynumberResponse);
      this.setState({
        agencynumberList,
      });
    });

    const promoterPromise = reqwest({
      method: 'GET',
      url: settings.promoter.general.getList,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(promoter => {
      promoter = JSON.parse(promoter);

      this.setState({
        promoter: promoter.map(pro => {
          return {
            value: pro.Params.Guid,
            label: `${pro.Params.Firstname} ${pro.Params.Lastname}`,
          };
        }),
      });
    });

    Promise.all([agencynumberPromise, promoterPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    let data = this.state.agencynumberList.filter(row => row.Params.Guid);
    data = data.map(agencyNumber => {
      const pro = this.state.promoter.find(e => e.value === agencyNumber.Params.Organiser);
      return {
        ...agencyNumber,
        Params: {
          ...agencyNumber.Params,
          Agencynumber: agencyNumber.Params.Agencynumber,
          Organiser: pro.label,
        },
      };
    });

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Agenturnummern'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => this.props.history.replace(`${this.props.match.url}/${original.Params.Guid}`),
              })}
              data={data}
              pageSize={data.length}
              noDataText='Es liegen keine Agenturnummern für das ausgewählte Büro vor.'
              columns={[
                {
                  Header: 'Veranstalter',
                  accessor: 'Params.Organiser',
                },
                {
                  Header: 'Agenturnummer',
                  accessor: 'Params.Agencynumber',
                },
              ]}
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faPlus} to={`${this.props.match.url}/new`} replace>
            Neue Agenturnummer
          </Button>
        </RightSide>
      </>
    );
  }
}

export default AgencynumberOverview;
