import React, { Component } from 'react';
import moment from 'moment';
import swal from '@sweetalert/with-react';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import reqwest from 'reqwest';

import Loader from '../../../components/Loader';
import Form from '../../../components/Form';
import Datepicker from '../../../components/Inputs/Datepicker';
import Dropdown from '../../../components/Inputs/Dropdown';
import ContentBox from '../../../components/FormArea/ContentBox';
import Button from '../../../components/Button';
import settings from '../../../../settings';
import RightSide from '../../../components/FormArea/RightSide';
import DataContainer from '../../../logic/dataContainer';
import getConstants from '../../../logic/constants';

class SickDaysNew extends Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');

    this.state = {
      loading: true,
      sickTypes: [],
      notificationTypes: [],
      employees: [],
    };

    getConstants('SickType').then(sickTypes => {
      this.setState({
        sickTypes,
      });
    });

    getConstants('NotificationType').then(notificationTypes => {
      this.setState({
        notificationTypes,
      });
    });

    const employeesPromise = reqwest({
      method: 'get',
      url: settings.usermanagement.general.getList,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(res => {
      const data = JSON.parse(res).userList;
      this.setState({
        employees: data.map(element => {
          return {
            value: element.Guid,
            label: `${element.Firstname} ${element.Lastname} (${element.Expnr})`,
          };
        }),
      });
    });

    Promise.all([employeesPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });

    const userData = JSON.parse(localStorage.getItem('userdata'));

    this.dataContainer = new DataContainer();
    this.dataContainer.add('Basic', {
      Guid: '',
      Typ: 'Sick',
    });
    this.dataContainer.add('Generals', {
      UserGuid: userData.userGuid,
      From: moment().format('DD.MM.YYYY'),
      To: moment().format('DD.MM.YYYY'),
      SickType: '',
      NotificationType: '',
      NotificationTime: moment().format('DD.MM.YYYY'),
      Messenger: userData.userGuid,
      Calc: 1,
      Factor: 1,
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    reqwest({
      method: 'POST',
      url: settings.sick.save,
      data: {
        Sick: this.dataContainer.getStringified(),
      },
    })
      .then(() => {
        swal({
          title: 'Erfolgreich!',
          text: 'Krankheitstag wurde erfolgreich angelegt.',
          icon: 'success',
        });
        this.props.history.replace(this.parentRoute);
      })
      .catch(() => {
        throw new Error('Something went very very wrong...');
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>Neuer Krankheitstag</p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <div className='FormArea-Form-Left'>
                    <Form ref={node => (this.form = node)}>
                      <ContentBox>
                        <Dropdown
                          label='Mitarbeiter: '
                          isSearchable
                          getSelected={value => {
                            this.dataContainer.set('Generals', 'UserGuid')(value);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'UserGuid')}
                          options={this.state.employees}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='UserGuid'
                        />
                        <Datepicker
                          label='Von: '
                          onChange={date => {
                            this.dataContainer.set('Generals', 'From')(date);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'From')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='From'
                        />
                        <Datepicker
                          label='Bis: '
                          onChange={date => {
                            this.dataContainer.set('Generals', 'To')(date);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'To')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='To'
                        />
                        <Dropdown
                          label='Krankheitsart: '
                          isSearchable
                          getSelected={value => {
                            this.dataContainer.set('Generals', 'SickType')(value);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'SickType')}
                          options={this.state.sickTypes}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='SickType'
                        />
                        <Dropdown
                          label='Meldungsart: '
                          isSearchable
                          getSelected={value => {
                            this.dataContainer.set('Generals', 'NotificationType')(value);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'SickType')}
                          options={this.state.notificationTypes}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='NotificationType'
                        />
                        <Datepicker
                          label='Gemeldet am: '
                          onChange={date => {
                            this.dataContainer.set('Generals', 'NotificationTime')(date);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'NotificationTime')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='NotificationTime'
                        />
                      </ContentBox>
                    </Form>
                  </div>
                  <RightSide>
                    <Button big icon={faTimes} to={this.parentRoute}>
                      Abbrechen
                    </Button>
                    <Button big type='primary' icon={faSave} onClick={this.save}>
                      Speichern
                    </Button>
                  </RightSide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SickDaysNew);
