import React, { Component } from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import Loader from '../../../../../components/Loader';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Form from '../../../../../components/Form';
import Input from '../../../../../components/Inputs/Input';
import RightSide from '../../../../../components/FormArea/RightSide';
import Button from '../../../../../components/Button';
import { faSave } from '@fortawesome/pro-solid-svg-icons';

class BankdataDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      Bank: {},
      RightSide: {},
    };

    const bankPromise = reqwest({
      method: 'GET',
      url: settings.franchise.general.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: this.props.franchiseGuid,
        ConTyp: 'Bank',
      },
    }).then(res => {
      this.dataContainer = new DataContainer(res);
    });

    const privPromise = reqwest({
      method: 'GET',
      url: settings.franchise.general.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: this.props.franchiseGuid,
        ConTyp: 'Priv',
      },
    }).then(res => {
      this.privContainer = new DataContainer(res);
    });

    Promise.all([bankPromise, privPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const data = this.dataContainer.getStringified();

    reqwest({
      method: 'post',
      url: settings.franchise.general.save,
      data: {
        FranchiseItem: data,
      },
    }).then(result => {
      result = JSON.parse(result);
      if (result.Params.SaveResult === 'Saved' || result.Params.SaveResult === 'Updated') {
        reqwest({
          method: 'post',
          url: settings.franchise.general.save,
          data: {
            FranchiseItem: this.privContainer.getStringified(),
          },
        }).then(resultTwo => {
          resultTwo = JSON.parse(resultTwo);
          if (resultTwo.Params.SaveResult === 'Saved' || resultTwo.Params.SaveResult === 'Updated') {
            swal({
              title: 'Erfolgreich!',
              text: 'Das Franchise wurde erfolgreich gespeichert.',
              icon: 'success',
            });
          }
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <>
        <Form ref={node => (this.form = node)}>
          <ContentBox title='Bankdaten'>
            <Input
              type='text'
              label='Debitor:'
              validator={[]}
              onChange={value => {
                this.privContainer.set('FPriv', 'Debitor')(value);
              }}
              defaultValue={this.privContainer.get('FPriv', 'Debitor')}
              name='Debitor'
            />
            <Input
              type='text'
              label='Bank:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FBank', 'Bank')(value);
              }}
              defaultValue={this.dataContainer.get('FBank', 'Bank')}
              name='Bank'
            />
            <Input
              type='text'
              label='iBan:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FBank', 'IBan')(value);
              }}
              defaultValue={this.dataContainer.get('FBank', 'IBan')}
              name='iBan'
            />
            <Input
              type='text'
              label='BIC:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('FBank', 'BIC')(value);
              }}
              defaultValue={this.dataContainer.get('FBank', 'BIC')}
              name='BIC'
            />
          </ContentBox>
        </Form>
        <RightSide
          createDate={this.dataContainer.get('FRightSide', 'CreateDate')}
          createUserName={this.dataContainer.get('FRightSide', 'CreateUserName')}
          updateDate={this.dataContainer.get('FRightSide', 'UpdateDate')}
          updateUserName={this.dataContainer.get('FRightSide', 'UpdateUserName')}
        >
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default BankdataDetail;
