import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Detail from './Detail';

const OfficeEDV = props => {
  return (
    <Switch>
      <Route render={routerProps => <Detail {...routerProps} OfficeGuid={props.OfficeGuid} />} />
    </Switch>
  );
};

export default withRouter(OfficeEDV);
