import React, { Component } from 'react';

// import Select from 'react-select';
import { SortableContainer, arrayMove } from 'react-sortable-hoc';

import settings from '../../settings';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import DetermineWidgetOptions /*, { getSelectOptions, getSelectStyles } */ from '../logic/widgetoptions';

import Loader from '../components/Loader';
// import Button from '../components/Button';
import Widget from '../components/Widgets';
// import { faSave, faTrash } from '@fortawesome/pro-solid-svg-icons';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.userFirstname = JSON.parse(localStorage.getItem('userdata')).firstname;

    this.state = {
      loading: true,
      editMode: false,
      supportMode: false,
    };

    reqwest({
      url: settings.widgets.general.get,
      method: 'get',
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(result => {
      result = JSON.parse(result);
      result.enabled = false;

      this.setState({
        loading: false,
        dnd: {
          ...result,
        },
      });

      localStorage.setItem('widgets', JSON.stringify(result));
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.loading !== nextState.loading ||
      this.state.editMode !== nextState.editMode ||
      this.state.dnd !== nextState.dnd ||
      this.state.dndSaved !== nextState.dndSaved ||
      this.state.supportMode !== nextState.supportMode
    );
  }

  _editMode = () => {
    const editModeEnabled = !this.state.editMode;

    const newDndState = {
      ...this.state.dnd,
      enabled: !this.state.dnd.enabled,
    };

    this.setState({
      editMode: editModeEnabled,
      dnd: newDndState,
      dndSaved: newDndState,
    });

    if (editModeEnabled) {
      document.addEventListener('keyup', this._handleKeyupInEditMode);
    } else {
      document.removeEventListener('keyup', this._handleKeyupInEditMode);
    }
  };

  _handleKeyupInEditMode = event => {
    if (event.keyCode === 27) {
      this._revertChanges();
    }
    return true;
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(prevState => {
      return {
        dnd: {
          ...prevState.dnd,
          order: arrayMove(prevState.dnd.order, oldIndex, newIndex),
        },
      };
    });
  };

  _revertChanges = () => {
    this.setState(
      {
        editMode: true,
        dnd: {
          ...this.state.dndSaved,
          enabled: true,
        },
      },
      () => {
        this._editMode();
      },
    );
  };

  _saveChanges = () => {
    const dragndrop = this.state.dnd;
    dragndrop.enabled = false;

    reqwest({
      method: 'post',
      url: settings.widgets.general.save,
      data: {
        Token: localStorage.getItem('token'),
        widgets: JSON.stringify(dragndrop),
      },
    })
      .then(res => {
        if (res.status === 200) {
          swal({
            title: 'Erfolgreich!',
            text: 'Ihr Dashboard wurde erfolgreich gespeichert.',
            icon: 'success',
          });

          localStorage.setItem('widgets', JSON.stringify(dragndrop));

          const newDndState = {
            ...dragndrop,
            enabled: true,
          };

          this.setState(
            {
              editMode: true,
              dnd: newDndState,
              dndSaved: newDndState,
            },
            () => {
              this._editMode();
            },
          );
        }
      })
      .fail(() => {
        swal({
          title: 'Fehler!',
          text: 'Ihr Dashboard wurde konnte nicht gespeichert werden.',
          icon: 'error',
        });
      });
  };

  _addWidget = () => {
    const { [Object.keys(this.state.dnd.widgets).pop()]: lastWidget } = this.state.dnd.widgets;
    const lastWidgetId = parseInt(lastWidget.id.split('-')[1]);
    const newWidget = this.newWidget.state.value.value;
    const newWidgetName = `${newWidget.toLowerCase()}-${lastWidgetId + 1}`;
    const newWidgetObject = DetermineWidgetOptions(newWidget, lastWidgetId + 1);

    this.setState(prevState => ({
      dnd: {
        ...prevState.dnd,
        widgets: {
          ...prevState.dnd.widgets,
          ...newWidgetObject,
        },
        order: [...prevState.dnd.order, newWidgetName],
      },
    }));
  };

  _supportMode = () => {
    const newDndState = {
      ...this.state.dnd,
      enabled: false,
    };

    this.setState({
      supportMode: !this.state.supportMode,
      editMode: false,
      dnd: newDndState,
      dndSaved: newDndState,
    });
  };

  _removeWidget = widgetId => {
    const dnd = {
      ...this.state.dnd,
    };

    delete dnd.widgets[widgetId];

    const widgetIndex = dnd.order.indexOf(widgetId);

    if (widgetIndex > -1) {
      dnd.order.splice(widgetIndex, 1);
    }

    this.setState({
      dnd,
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader text='Ihr Dashboard wird geladen...' />;
    }

    const SortableWidgets = SortableContainer(({ children }) => {
      return <div className='Widgets'>{children}</div>;
    });

    return (
      <div className='Dashboard'>
        <div className='Dashboard-Top'>
          <div className='Dashboard-Title'>
            {this.userFirstname}, Dein Dashboard enthält {this.state.dnd.order?.length} Widgets
          </div>
          {/* <div className='EditButtons'>
            <Button small onClick={this._supportMode}>
              Supportmodus
            </Button>
            {this.state.editMode && !this.state.supportMode && (
              <>
                <div className='AddWidget-Wrapper'>
                  <div className='Selectbox-wrapper Selectbox-wrapper--small'>
                    <Select
                      classNamePrefix={'Selectbox'}
                      noOptionsMessage={() => {
                        return 'Nicht vorhanden...';
                      }}
                      placeholder='Bitte wählen...'
                      formatGroupLabel='Ok'
                      defaultValue={{ value: 'Time', label: 'Uhr' }}
                      styles={getSelectStyles()}
                      options={getSelectOptions()}
                      ref={node => {
                        this.newWidget = node;
                      }}
                    />
                  </div>
                  <Button small onClick={this._addWidget}>
                    Widget hinzufügen
                  </Button>
                </div>
                <Button small type='primary' icon={faSave} onClick={this._saveChanges}>
                  Speichern
                </Button>
                <Button small type='danger' icon={faTrash} onClick={this._revertChanges}>
                  Abbrechen
                </Button>
              </>
            )}
            {!this.state.editMode && !this.state.supportMode && (
              <Button small onClick={this._editMode}>
                Dashboard Editieren
              </Button>
            )}
          </div> */}
        </div>
        <SortableWidgets axis='xy' onSortEnd={this.onSortEnd} distance={20}>
          {this.state.dnd.order?.map((widgetId, index) => {
            const widget = this.state.dnd.widgets[widgetId];

            return (
              <Widget
                editMode={this.state.dnd.enabled}
                disabled={!this.state.dnd.enabled}
                removeWidget={this._removeWidget}
                name={widget.name}
                key={widget.id}
                widget={widget}
                index={index}
              />
            );
          })}
        </SortableWidgets>
      </div>
    );
  }
}

export default Dashboard;
