const getSelectOptions = () => {
  return [
    { value: 'Time', label: 'Uhr' },
    { value: 'News', label: 'News' },
    { value: 'Quote', label: 'Zitat' },
    { value: 'Azn', label: 'Stoppuhr' },
    { value: 'Weather', label: 'Wetter' },
    { value: 'Who', label: 'Wer eigentlich?' },
    { value: 'Access', label: 'Meine Zugänge' },
    { value: 'Turnover', label: 'Mein Umsatz' },
    { value: 'Magazine', label: 'Branchenmagazin' },
    { value: 'DutySchedule', label: 'Mein Dienstplan' },
  ];
};

const getSelectStyles = () => {
  return {
    menu: () => {
      const position = 'absolute';
      const background = '#ffffff';
      const width = '100%';
      const marginTop = '10px';
      const boxShadow = '0px 3px 9px rgba(0, 0, 0, 0.16)';
      const padding = '5px 0';
      const borderRadius = '5px';
      const border = '1px solid rgba(80, 97, 131, 0.5)';
      const zIndex = '999999';

      return {
        position,
        boxShadow,
        width,
        background,
        marginTop,
        padding,
        borderRadius,
        border,
        zIndex,
      };
    },
  };
};

export { getSelectOptions, getSelectStyles };

export default function determine(widgetName, widgetId) {
  widgetName = widgetName.toLowerCase();
  let result = {
    [widgetName + '-' + widgetId]: {},
  };

  switch (widgetName) {
    case 'weather': {
      result = {
        [widgetName + '-' + widgetId]: {
          id: widgetName + '-' + widgetId,
          name: 'Weather',
          size: '1x',
          favorite: false,
          customOptions: {
            zip: '21335',
          },
        },
      };
      break;
    }
    case 'quote': {
      result = {
        [widgetName + '-' + widgetId]: {
          id: widgetName + '-' + widgetId,
          name: 'Quote',
          size: '1x',
          favorite: false,
          customOptions: {
            title: 'Schon gewusst?',
            text: 'Im Intranet können Sie über die Suche oben im Kopf Kontakte, Zugänge, Stammdaten und weitere Informationen schnell finden.',
          },
        },
      };
      break;
    }
    case 'time': {
      result = {
        [widgetName + '-' + widgetId]: {
          id: widgetName + '-' + widgetId,
          name: 'Time',
          size: '1x',
          favorite: false,
          customOptions: {},
        },
      };
      break;
    }
    case 'azn': {
      result = {
        [widgetName + '-' + widgetId]: {
          id: widgetName + '-' + widgetId,
          name: 'Azn',
          size: '1x',
          favorite: false,
          customOptions: {},
        },
      };
      break;
    }
    case 'dutyschedule': {
      result = {
        [widgetName + '-' + widgetId]: {
          id: widgetName + '-' + widgetId,
          name: 'DutySchedule',
          size: '3x',
          favorite: false,
          customOptions: {
            duty: ['02.09.2019', '03.09.2019', '04.09.2019', '05.09.2019', '09.09.2019', '10.09.2019', '18.09.2019', '19.09.2019'],
            vacation: ['26.09.2019', '27.09.2019'],
          },
        },
      };
      break;
    }
    case 'news': {
      result = {
        [widgetName + '-' + widgetId]: {
          id: widgetName + '-' + widgetId,
          name: 'News',
          size: '2x',
          favorite: false,
          customOptions: {
            news: [
              {
                guid: '1',
                pinned: true,
                title: 'Schulungs-Info 038 / 2019',
                text: 'lorem ipsum',
                date: '06.05.2019',
                location: 'ZENTRALE',
              },
              {
                guid: '2',
                pinned: false,
                title: 'Umsatz April',
                text: 'lorem ipsum',
                date: '01.05.2019',
                location: 'REGION',
              },
              {
                guid: '3',
                pinned: false,
                title: 'Bzgl. Kreuzfahrtreisen',
                text: 'lorem ipsum',
                date: '26.04.2019',
                location: 'REGION',
              },
            ],
          },
        },
      };
      break;
    }
    case 'magazine': {
      result = {
        [widgetName + '-' + widgetId]: {
          id: widgetName + '-' + widgetId,
          name: 'Magazine',
          size: '3x',
          favorite: false,
          customOptions: {
            magazines: [
              {
                guid: '1',
                publisher: '',
                image: '',
                title: 'Erster am Strand!',
                text: 'Top Preise bei unseren Mailights in ausgewählten Hotels - Reisen zu Nebensaison-Preisen!',
              },
            ],
          },
        },
      };
      break;
    }
    case 'turnover': {
      result = {
        [widgetName + '-' + widgetId]: {
          id: widgetName + '-' + widgetId,
          name: 'Turnover',
          size: '3x',
          favorite: false,
          customOptions: {
            title: 'Mein Umsatz (Stand Mai)',
            percent: '52%',
          },
        },
      };
      break;
    }
    case 'access': {
      result = {
        [widgetName + '-' + widgetId]: {
          id: widgetName + '-' + widgetId,
          name: 'Access',
          size: '1x',
          favorite: true,
          customOptions: {
            title: 'Meine Zugänge',
          },
        },
      };
      break;
    }
    case 'who': {
      result = {
        [widgetName + '-' + widgetId]: {
          id: widgetName + '-' + widgetId,
          name: 'Who',
          size: '2x',
          favorite: false,
          customOptions: {
            title: 'Wer eigentlich?',
            lastSeachedContactGuid: '',
          },
        },
      };
      break;
    }
    default:
      break;
  }

  return result;
}
