import React, { Component } from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../../settings';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Loader from '../../../../../components/Loader';

class RegistrationsOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registrations: [],
      loading: true,
    };

    const promise = reqwest({
      method: 'get',
      url: settings.trainings.registrations.getForTraining,
      data: {
        Token: localStorage.getItem('token'),
        TrainingGuid: this.props.workshopGuid,
      },
    }).then(res => {
      let registrations;
      try {
        registrations = JSON.parse(res);
      } catch (e) {
        registrations = [];
      }
      this.setState({
        registrations,
      });
    });

    Promise.all([promise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.state.registrations.filter(row => row.Params.Guid);

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Anmeldungen'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => this.props.history.replace(`${this.props.match.url}/${original.Params.Guid}`),
              })}
              data={data}
              pageSize={data.length}
              noDataText='Es liegen noch keine Anmeldungen für die ausgewählte Schulung / Inforeise vor.'
              columns={[
                {
                  Header: 'ExpNr',
                  accessor: 'Params.Expnr',
                  maxWidth: 200,
                },
                {
                  Header: 'Vorname',
                  accessor: 'Params.Firstname',
                },
                {
                  Header: 'Nachname',
                  accessor: 'Params.Lastname',
                },
                {
                  Header: 'Status',
                  accessor: 'Params.Status',
                  maxWidth: 300,
                },
              ]}
            />
          </ContentBox>
        </div>
      </>
    );
  }
}

export default RegistrationsOverview;
