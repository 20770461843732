import React from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../settings';

import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import RightSide from '../../../../components/FormArea/RightSide';
import ContentBox from '../../../../components/FormArea/ContentBox';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

class RolesOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      loading: true,
    };

    reqwest({
      method: 'get',
      url: settings.permissions.GetAllRoles,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(getAllRolesResponse => {
      const roles = JSON.parse(getAllRolesResponse);

      this.setState({
        roles,
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Rollen'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => this.props.history.push(`${this.props.match.url}/${original.Params.Guid}`),
              })}
              data={this.state.roles}
              pageSize={this.state.roles.length}
              noDataText='Es liegen keine Rollen vor.'
              columns={[
                {
                  Header: 'Name',
                  accessor: 'Params.Name',
                },
                {
                  Header: 'Bemerkung',
                  accessor: 'Params.Bemerkung',
                },
              ]}
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faPlus} to={`${this.props.match.url}/new`}>
            Neue Rolle
          </Button>
        </RightSide>
      </>
    );
  }
}

export default RolesOverview;
