import 'react-app-polyfill/ie11';
import React from 'react';
import { render } from 'react-dom';
import { Router, Switch, Route } from 'react-router-dom';
import { ReactTableDefaults } from 'react-table';

import customHistory from './js/history';

import PrivateRoute from './js/components/PrivateRoute';

import Login from './js/routes/Login';
import Forgot from './js/routes/Forgot/index';
import Contact from './js/routes/Contact';
import LoggedInArea from './js/LoggedInArea';
import Impressum from './js/routes/Impressum';

import 'react-table/react-table.css';
import './css/flexboxgrid.min.css';
import './css/main.css';

Object.assign(ReactTableDefaults, {
  showPagination: false,
  resizable: false,
});

render(
  <Router history={customHistory}>
    <Switch>
      <Route exact path='/Login' component={Login} />
      <Route path='/Forgot' component={Forgot} />
      <Route exact path='/Contact' component={Contact} />
      <Route exact path='/Impressum' component={Impressum} />
      <PrivateRoute component={LoggedInArea} />
    </Switch>
  </Router>,
  document.getElementById('app'),
);
