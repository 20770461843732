import React, { Component } from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../../settings';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Button from '../../../../../components/Button';
import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import getConstants from '../../../../../logic/constants';
import sortByDate from '../../../../../logic/sortByDate';

class CentralMarketingOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cmElements: [],
      actionTypes: [],
      loading: true,
    };

    const cmPromise = reqwest({
      method: 'GET',
      url: settings.franchise.marketing.getList,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
        FranGuid: this.props.franchiseGuid,
      },
    }).then(marketingResponse => {
      const cmElements = JSON.parse(marketingResponse);
      this.setState({
        cmElements,
        loading: false,
      });
    });

    const cmActionsPromise = getConstants('CentralMarketingActionTypes').then(actionTypes => {
      this.setState({
        actionTypes,
      });
    });

    Promise.all([cmPromise, cmActionsPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.state.cmElements.filter(row => row.Guid);

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Zentrales Marketing'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => this.props.history.replace(`${this.props.match.url}/${original.Guid}`),
              })}
              data={data}
              pageSize={data.length}
              noDataText='Es liegt kein Zentrales Marketing für das ausgewählte Büro vor.'
              defaultSorted={[
                {
                  id: 'Date',
                  desc: true,
                },
              ]}
              columns={[
                {
                  Header: 'Datum',
                  accessor: 'Date',
                  sortMethod: sortByDate,
                },
                {
                  Header: 'Aktion',
                  accessor: 'Action',
                  Cell: ({ value }) => this.state.actionTypes.map(type => (type.value === value ? type.label : null)),
                },
              ]}
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faPlus} to={`${this.props.match.url}/new`} replace>
            Neues Marketing
          </Button>
        </RightSide>
      </>
    );
  }
}

export default CentralMarketingOverview;
