import React, { Component } from 'react';
import reqwest from 'reqwest';
import swal from '@sweetalert/with-react';

import settings from '../../../../../../settings';

import DataContainer from '../../../../../logic/dataContainer';

import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Datepicker from '../../../../../components/Inputs/Datepicker';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import RightSide from '../../../../../components/FormArea/RightSide';
import Button from '../../../../../components/Button';
import { faSave, faTrash } from '@fortawesome/pro-solid-svg-icons';
import Input from '../../../../../components/Inputs/Input';
import getConstants from '../../../../../logic/constants';
import checkAvailable from '../../../../../logic/checkAvailable';

class BasedataDetail extends Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.userId}$`), '');

    this.state = {
      loading: true,
      positions: [],
      Workdata: {},
      Privatedata: {},
      Additionalinfos: {},
      RightSide: {},
      Baseoffice: {},
    };

    const dataPromise = reqwest({
      method: 'get',
      url: settings.usermanagement.general.get,
      data: {
        Token: localStorage.getItem('token'),
        Usernr: this.props.UserGuid,
      },
    }).then(res => {
      this.dataContainer = new DataContainer(res);
    });

    const positionPromise = getConstants('Positiontype').then(positions => {
      this.setState({
        positions,
      });
    });

    const maritalStatusPromise = getConstants('MaritalStatus').then(maritalStatus => {
      this.setState({
        maritalStatus,
      });
    });

    const rlPromise = reqwest({
      method: 'get',
      url: settings.usermanagement.general.getUserForPosition,
      data: {
        Token: localStorage.getItem('token'),
        Position: 'RL',
      },
    }).then(users => {
      users = JSON.parse(users);

      this.setState({
        users: users.map(element => {
          return {
            value: element.Params.Guid,
            label: `${element.Params.Firstname} ${element.Params.Lastname} (${element.Params.Expnr})`,
          };
        }),
      });
    });

    Promise.all([dataPromise, positionPromise, maritalStatusPromise, rlPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      content: (
        <p>
          Möchten Sie{' '}
          <span className='Indented'>
            {this.dataContainer.get('Workdata', 'Lastname')}, {this.dataContainer.get('Workdata', 'Firstname')}
          </span>{' '}
          wirklich löschen?
          <br />
          Der Nutzer wird damit endgültig gelöscht.
        </p>
      ),
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'POST',
          url: settings.usermanagement.general.delete,
          data: {
            Guid: this.props.UserGuid,
          },
        })
          .then(res => {
            res = JSON.parse(res);

            if (res.DeleteResult === 'Deleted') {
              swal({
                title: 'Erfolgreich!',
                content: (
                  <p>
                    <span className='Indented'>
                      {this.dataContainer.get('Workdata', 'Lastname')}, {this.dataContainer.get('Workdata', 'Firstname')}
                    </span>{' '}
                    wurde erfolgreich gelöscht.
                  </p>
                ),
                icon: 'success',
              });
              this.props.history.replace(this.parentRoute);
            }
          })
          .catch(() => {
            throw new Error('Something went very very wrong...');
          });
      }
    });
  };

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const data = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.usermanagement.general.save,
      data: {
        Token: localStorage.getItem('token'),
        User: data,
      },
    }).then(() => {
      document.querySelector('.FormArea-Title').innerHTML = `#${this.dataContainer.get('Workdata', 'Expnr')} ${this.dataContainer.get(
        'Workdata',
        'Firstname',
      )} ${this.dataContainer.get('Workdata', 'Lastname')}`;
      swal({
        title: 'Erfolgreich!',
        text: 'Der Nutzer wurde erfolgreich gespeichert.',
        icon: 'success',
      });
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Stammdaten'>
              <Input
                type='text'
                validator={[
                  {
                    required: true,
                    error: 'Bitte geben Sie eine Exp Nr. ein',
                  },
                  {
                    custom: async value => {
                      if (this.dataContainer.getInitial('Workdata', 'Expnr') !== value) {
                        return await checkAvailable('expnr', value);
                      }
                      return true;
                    },
                    error: 'Diese Exp Nr. wird bereits verwendet',
                  },
                ]}
                onChange={value => {
                  this.dataContainer.set('Workdata', 'Expnr')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Expnr')}
                label='Exp Nr.'
                name='expnr'
              />
              <Input
                type='text'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Workdata', 'PersonalNr')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'PersonalNr')}
                label='Personal Nr.'
                name='personalnr'
              />
              <Dropdown
                label='Regionalbetreuer:'
                isSearchable
                getSelected={value => {
                  this.dataContainer.set('Workdata', 'RegionalAdvisor')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'RegionalAdvisor')}
                options={this.state.users}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                name='RegionalAdvisor'
              />
              <Dropdown
                label='Position:'
                isSearchable
                getSelected={value => {
                  this.dataContainer.set('Workdata', 'Position')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Position')}
                options={this.state.positions}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                name='Position'
              />
              <Dropdown
                label='Anrede:'
                isSearchable
                getSelected={value => {
                  this.dataContainer.set('Workdata', 'Salutation')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Salutation')}
                options={[
                  {
                    value: 'Herr',
                    label: 'Herr',
                  },
                  {
                    value: 'Frau',
                    label: 'Frau',
                  },
                  {
                    value: 'Divers',
                    label: 'Divers',
                  },
                ]}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                name='Salutation'
              />
              <Input
                type='text'
                validator={[
                  {
                    required: true,
                    error: 'Bitte geben Sie einen Vornamen ein',
                  },
                ]}
                onChange={value => {
                  this.dataContainer.set('Workdata', 'Firstname')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Firstname')}
                label='Vorname'
                name='firstname'
              />
              <Input
                type='text'
                validator={[
                  {
                    required: true,
                    error: 'Bitte geben Sie einen Nachnamen ein',
                  },
                ]}
                onChange={value => {
                  this.dataContainer.set('Workdata', 'Lastname')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Lastname')}
                label='Nachname'
                name='lastname'
              />
              <Input
                type='text'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Workdata', 'BirthName')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'BirthName')}
                label='Geburtsname'
                name='BirthName'
              />
              <Datepicker
                label='Geburtsdatum:'
                name='dob'
                onChange={day => {
                  this.dataContainer.set('Workdata', 'Dateofbirth')(day);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Dateofbirth')}
                validator={[]}
              />
              <Input
                type='text'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Workdata', 'BirthPlace')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'BirthPlace')}
                label='Geburtsort'
                name='BirthPlace'
              />
              <Input
                type='text'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                  {
                    type: 'email',
                    error: 'Bitte geben Sie eine valide E-Mail Adresse ein',
                  },
                  {
                    custom: async value => {
                      if (this.dataContainer.getInitial('Workdata', 'EMail') !== value) {
                        return await checkAvailable('email', value);
                      }
                      return true;
                    },
                    error: 'Diese E-Mail Adresse wird bereits verwendet',
                  },
                ]}
                onChange={value => {
                  this.dataContainer.set('Workdata', 'EMail')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'EMail')}
                label='E-Mail'
                name='EMail'
              />
              <Input
                type='text'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Workdata', 'Tel')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Tel')}
                label='Telefon Stammbüro:'
                name='Phone'
              />
              <Input
                type='text'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Workdata', 'Nationality')(value);
                }}
                defaultValue={this.dataContainer.get('Workdata', 'Nationality')}
                label='Staatsangehörigkeit'
                name='Nationality'
              />
            </ContentBox>
            <ContentBox title='Privatdaten'>
              <Input
                type='text'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                onChange={value => {
                  this.dataContainer.set('Privatedata', 'Street')(value);
                }}
                defaultValue={this.dataContainer.get('Privatedata', 'Street')}
                label='Straße'
                name='Street'
              />
              <Input
                type='text'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                onChange={value => {
                  this.dataContainer.set('Privatedata', 'Zip')(value);
                }}
                defaultValue={this.dataContainer.get('Privatedata', 'Zip')}
                label='PLZ'
                name='Zip'
              />
              <Input
                type='text'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                onChange={value => {
                  this.dataContainer.set('Privatedata', 'City')(value);
                }}
                defaultValue={this.dataContainer.get('Privatedata', 'City')}
                label='Ort'
                name='City'
              />
              <Input
                type='text'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Privatedata', 'Telpriv')(value);
                }}
                defaultValue={this.dataContainer.get('Privatedata', 'Telpriv')}
                label='Telefon privat'
                name='PhoneP'
              />
              <Input
                type='text'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Privatedata', 'HandyP')(value);
                }}
                defaultValue={this.dataContainer.get('Privatedata', 'HandyP')}
                label='Handy privat'
                name='HandyP'
              />
              <Input
                type='text'
                validator={[
                  {
                    type: 'email',
                    error: 'Bitte geben Sie eine valide E-Mail Adresse ein',
                  },
                ]}
                onChange={value => {
                  this.dataContainer.set('Privatedata', 'EMailpriv')(value);
                }}
                defaultValue={this.dataContainer.get('Privatedata', 'EMailpriv')}
                label='E-Mail privat'
                name='EMailpriv'
              />
              <Input
                type='text'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Privatedata', 'Kids')(value);
                }}
                defaultValue={this.dataContainer.get('Privatedata', 'Kids')}
                label='Anzahl Kinder'
                name='Kids'
              />
              <Input
                type='text'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Privatedata', 'DateOfBirthKids')(value);
                }}
                defaultValue={this.dataContainer.get('Privatedata', 'DateOfBirthKids')}
                label='Geb-Kinder'
                name='Geb-Kinder'
              />
              <Dropdown
                label='Familienstand:'
                isSearchable
                getSelected={value => {
                  this.dataContainer.set('Privatedata', 'FamilyStatus')(value);
                }}
                defaultValue={this.dataContainer.get('Privatedata', 'FamilyStatus')}
                options={this.state.maritalStatus}
                validator={[]}
                name='Familienstand'
              />
            </ContentBox>
            <ContentBox title='Weitere Informationen'>
              <Datepicker
                label='Austrittsdatum:'
                name='DateOfLeaving'
                onChange={day => {
                  this.dataContainer.set('Additionalinfos', 'DateOfLeaving')(day);
                }}
                defaultValue={this.dataContainer.get('Additionalinfos', 'DateOfLeaving')}
                validator={[]}
              />
              <Datepicker
                label='Prüfungstermin:'
                name='ExaminationDate'
                onChange={day => {
                  this.dataContainer.set('Additionalinfos', 'ExaminationDate')(day);
                }}
                defaultValue={this.dataContainer.get('Additionalinfos', 'ExaminationDate')}
                validator={[]}
              />
              <Datepicker
                label='Ausbildung beendet am:'
                name='TrainingEnd'
                onChange={day => {
                  this.dataContainer.set('Additionalinfos', 'TrainingEnd')(day);
                }}
                defaultValue={this.dataContainer.get('Additionalinfos', 'TrainingEnd')}
                validator={[]}
              />
              <Input
                type='text'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Additionalinfos', 'Ada')(value);
                }}
                defaultValue={this.dataContainer.get('Additionalinfos', 'Ada')}
                label='ADA'
                name='ADA'
              />
              <Input
                type='text'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Additionalinfos', 'ImageRights')(value);
                }}
                defaultValue={this.dataContainer.get('Additionalinfos', 'ImageRights')}
                label='Bildrechte'
                name='Bildrechte'
              />
              <Input
                type='textarea'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Additionalinfos', 'Notes')(value);
                }}
                defaultValue={this.dataContainer.get('Additionalinfos', 'Notes')}
                label='Bemerkungen'
                name='Bemerkungen'
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide
          createDate={this.dataContainer.get('RightSide', 'CreateDate')}
          createUserName={this.dataContainer.get('RightSide', 'CreateUserName')}
          updateDate={this.dataContainer.get('RightSide', 'UpdateDate')}
          updateUserName={this.dataContainer.get('RightSide', 'UpdateUserName')}
        >
          <Button big type='danger' icon={faTrash} onClick={this.delete}>
            Löschen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default BasedataDetail;
