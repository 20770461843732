import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';
import sha512 from 'sha512';

import settings from '../../../settings';

import RightSide from '../../components/FormArea/RightSide';
import Form from '../../components/Form';
import Button from '../../components/Button';
import Input from '../../components/Inputs/Input';
import ContentBox from '../../components/FormArea/ContentBox';
import DataContainer from '../../logic/dataContainer';

import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import checkAvailable from '../../logic/checkAvailable';

class PromoterNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Password: '',
    };

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');

    this.dataContainer = new DataContainer();
    this.dataContainer.add('Basic', {
      Guid: '',
      Typ: 'Promoter',
    });
    this.dataContainer.add('Generals', {
      Name: '',
      Username: '',
      Password: '',
      EMail: '',
      Description: '',
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    reqwest({
      method: 'POST',
      url: settings.promoter.general.save,
      data: {
        Token: localStorage.getItem('token'),
        Promoter: this.dataContainer.getStringified(),
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Der Veranstalter wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>Neuen Veranstalter anlegen</p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <div className='FormArea-Form-Left'>
                    <Form ref={node => (this.form = node)}>
                      <ContentBox title='Stammdaten'>
                        <Input
                          type='text'
                          label='Name:'
                          validator={[]}
                          onChange={value => {
                            this.dataContainer.set('Generals', 'Description')(value);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'Description')}
                          name='desc'
                        />
                        <Input
                          type='text'
                          label='E-Mail:'
                          onChange={value => {
                            this.dataContainer.set('Generals', 'EMail')(value);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'EMail')}
                          name='EMail'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                            {
                              type: 'email',
                              error: 'Bitte geben Sie eine valide E-Mail Adresse ein',
                            },
                            {
                              custom: async value => {
                                if (this.dataContainer.getInitial('Generals', 'EMail') !== value) {
                                  return await checkAvailable('email', value);
                                }
                                return true;
                              },
                              error: 'Diese E-Mail Adresse wird bereits verwendet',
                            },
                          ]}
                        />
                      </ContentBox>
                      <ContentBox title='Anmeldedaten'>
                        <Input
                          type='text'
                          label='Benutzername:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          fullWidth
                          onChange={value => {
                            this.dataContainer.set('Generals', 'Username')(value);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'Username')}
                          name='Username'
                        />
                        <Input
                          type='password'
                          label='Passwort:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          passwordValidator
                          passwordShowButton
                          onChange={value => {
                            this.setState({
                              Password: value,
                            });
                            this.dataContainer.set('Generals', 'Password')(sha512(value).toString('hex'));
                          }}
                          name='Password'
                        />
                        <Input
                          type='password'
                          label='Passwort wiederholen:'
                          validator={[
                            {
                              match: this.state.Password,
                              error: 'Die Passwörter müssen übereinstimmen.',
                            },
                          ]}
                          name='PasswordTwo'
                        />
                      </ContentBox>
                    </Form>
                  </div>
                  <RightSide>
                    <Button big icon={faTimes} to={this.parentRoute}>
                      Abbrechen
                    </Button>
                    <Button big type='primary' icon={faSave} onClick={this.save}>
                      Speichern
                    </Button>
                  </RightSide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PromoterNew;
