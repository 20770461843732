import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Details from './Detail';
import Overview from './Overview';
import Modal from '../../components/Modal';

const DutyRoster = props => {
  const paths = {
    details: `${props.match.path}/:officeGuid/:year/:month`,
    search: `${props.match.path}/search/:search?`,
  };

  return (
    <Modal title='Dienstplan'>
      <Switch>
        <Route path={paths.search} component={Overview} />
        <Route path={paths.details} component={Details} />
        <Route component={Overview} />
      </Switch>
    </Modal>
  );
};

export default withRouter(DutyRoster);
