import moment from 'moment';

export default function sortByDate(a, b) {
  // Return either 1 or -1 to indicate a sort priority
  if (moment(a, 'DD.MM.YYYY').isBefore(moment(b, 'DD.MM.YYYY'))) {
    return -1;
  }
  if (moment(b, 'DD.MM.YYYY').isBefore(moment(a, 'DD.MM.YYYY'))) {
    return 1;
  }
  return 0;
}
