import React, { Component } from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../../settings';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Button from '../../../../../components/Button';
import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

class FixedCostOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fixedCostList: [],
      loading: true,
    };

    const fixedCostPromise = reqwest({
      method: 'get',
      url: settings.office.fixedCosts.getList,
      data: {
        Token: localStorage.getItem('token'),
        OfficeGuid: this.props.OfficeGuid,
      },
    }).then(fixedCostResponse => {
      const fixedCostList = JSON.parse(fixedCostResponse);
      this.setState({
        fixedCostList,
      });
    });

    Promise.all([fixedCostPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.state.fixedCostList.filter(row => row.Params.Guid);

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Fixkosten'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => this.props.history.replace(`${this.props.match.url}/${original.Params.Guid}`),
              })}
              data={data}
              pageSize={data.length}
              noDataText='Es liegen keine Fixkosten für das ausgewählte Büro vor.'
              defaultSorted={[
                {
                  id: 'Vermieter',
                  desc: false,
                },
              ]}
              columns={[
                {
                  Header: 'Vermieter',
                  accessor: 'Params.Landlord',
                },
                {
                  Header: 'Miete',
                  accessor: 'Params.Rent',
                },
              ]}
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faPlus} to={`${this.props.match.url}/new`} replace>
            Neue Fixkosten
          </Button>
        </RightSide>
      </>
    );
  }
}

export default FixedCostOverview;
