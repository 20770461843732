import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Detail from './Detail';

const FranchiseOwner = props => {
  return (
    <Switch>
      <Route render={routerProps => <Detail {...routerProps} franchiseGuid={props.franchiseGuid} />} />
    </Switch>
  );
};

export default withRouter(FranchiseOwner);
