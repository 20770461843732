import React, { Component } from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../../settings';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Button from '../../../../../components/Button';
import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

class RetirementOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      retirementList: [],
      actionTypes: [],
      loading: true,
    };

    const retirementPromise = reqwest({
      method: 'get',
      url: settings.office.retirement.getList,
      data: {
        Token: localStorage.getItem('token'),
        OfficeGuid: this.props.OfficeGuid,
      },
    }).then(retirementResponse => {
      const retirementList = JSON.parse(retirementResponse);
      this.setState({
        retirementList,
      });
    });

    Promise.all([retirementPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.state.retirementList.filter(row => row.Params.Guid);

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Ausbuchungen'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => this.props.history.replace(`${this.props.match.url}/${original.Params.Guid}`),
              })}
              data={data}
              pageSize={data.length}
              noDataText='Es liegen keine Ausbuchungen für das ausgewählte Büro vor.'
              defaultSorted={[
                {
                  id: 'Datum',
                  desc: true,
                },
              ]}
              columns={[
                {
                  Header: 'Datum',
                  accessor: 'Params.Date',
                },
                {
                  Header: 'Mitarbeiter',
                  accessor: 'Params.Employee',
                },
              ]}
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faPlus} to={`${this.props.match.url}/new`} replace>
            Neue Ausbuchung
          </Button>
        </RightSide>
      </>
    );
  }
}

export default RetirementOverview;
