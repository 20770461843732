import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Switch, NavLink, Route } from 'react-router-dom';
import moment from 'moment';
import reqwest from 'reqwest';

import settings from '../../../../settings';

import Basedata from './tabs/Basedata/index';
import OpeningHours from './tabs/OpeningHours/index';
import InventoryModule from './tabs/InventoryModule/index';
import EDV from './tabs/EDV/index';
import Inventory from './tabs/Inventory/index';
import Phonesystem from './tabs/Phonesystem/index';
import Marketing from './tabs/Marketing/index';
import Agencynumbers from './tabs/Agencynumbers/index';
import Retirements from './tabs/Retirements/index';
import FixedCosts from './tabs/FixedCosts/index';
import Invoice from './tabs/Invoice/index';
import Images from './tabs/Images/index';

class Bueroverwaltung extends Component {
  constructor(props) {
    super(props);

    this.ccr = props.match.params.ccr;

    reqwest({
      method: 'get',
      url: settings.inventory.GetGroups,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(groupsResponse => {
      const groups = JSON.parse(groupsResponse);
      this.setState({
        groups: groups
          .filter(
            group =>
              !!group[0].Params.Guid &&
              group[1].Params.Active === 'true' &&
              (group[1].Params.ValidFor === 'Eigentum' || group[1].Params.ValidFor === 'beide'),
          )
          .sort((a, b) => {
            if (a[1].Params.Weight > b[1].Params.Weight) return 1;
            if (a[1].Params.Weight < b[1].Params.Weight) return -1;
            return 0;
          }),
        loading: false,
      });
    });

    this.state = {
      groups: [],
    };
  }

  render() {
    return (
      <div className='FormArea'>
        <div className='FormArea-Top'>
          <p className='FormArea-Title'>Büro</p>
        </div>
        <div className='FormArea-Body'>
          <div className='FormArea-Body-Tabs'>
            <NavLink exact to={`${this.props.match.url}`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Stammdaten
            </NavLink>
            <NavLink to={`${this.props.match.url}/Openinghours`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Öffnungszeiten
            </NavLink>
            <NavLink to={`${this.props.match.url}/Edv`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              EDV
            </NavLink>
            <NavLink to={`${this.props.match.url}/Inventory2`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Inventar
            </NavLink>
            <NavLink to={`${this.props.match.url}/Phonesystem`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Telefonanlage
            </NavLink>
            <NavLink to={`${this.props.match.url}/Marketing`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Marketingaktionen
            </NavLink>
            <NavLink to={`${this.props.match.url}/Agencynumbers`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Agenturnummern
            </NavLink>
            <NavLink to={`${this.props.match.url}/Retirements`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Ausbuchungen
            </NavLink>
            <NavLink to={`${this.props.match.url}/FixedCosts`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Fixkosten
            </NavLink>
            <NavLink to={`${this.props.match.url}/Invoice`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Rechnungen
            </NavLink>
            <NavLink to={`${this.props.match.url}/Images`} className='FormArea-Body-Tab' activeClassName='is-Active' replace>
              Bilder
            </NavLink>
            <NavLink
              to={`/dutyroster/${this.ccr}/${moment().format('YYYY')}/${moment().format('M')}`}
              className='FormArea-Body-Tab'
              activeClassName='is-Active'
            >
              Dienstplan
            </NavLink>
            {this.state.groups.map(group => {
              return (
                <NavLink
                  key={group[0].Params.Guid}
                  to={`${this.props.match.url}/Inventory/${group[0].Params.Guid}`}
                  className='FormArea-Body-Tab'
                  activeClassName='is-Active'
                  replace
                >
                  {group[1].Params.Name}
                </NavLink>
              );
            })}
          </div>
          <div className='FormArea-Body-Content'>
            <div className='FormArea-Body-Content-Center'>
              <div className='FormArea-Form'>
                <Switch>
                  <Route path={`${this.props.match.path}/Openinghours`} render={() => <OpeningHours OfficeGuid={this.ccr} />} />
                  <Route path={`${this.props.match.path}/Edv`} render={() => <EDV OfficeGuid={this.ccr} />} />
                  <Route path={`${this.props.match.path}/Inventory2`} render={() => <Inventory OfficeGuid={this.ccr} />} />
                  <Route path={`${this.props.match.path}/Inventory/:GroupGuid`} render={() => <InventoryModule OfficeGuid={this.ccr} />} />
                  <Route path={`${this.props.match.path}/Phonesystem`} render={() => <Phonesystem OfficeGuid={this.ccr} />} />
                  <Route path={`${this.props.match.path}/Marketing`} render={() => <Marketing OfficeGuid={this.ccr} />} />
                  <Route path={`${this.props.match.path}/Agencynumbers`} render={() => <Agencynumbers OfficeGuid={this.ccr} />} />
                  <Route path={`${this.props.match.path}/Retirements`} render={() => <Retirements OfficeGuid={this.ccr} />} />
                  <Route path={`${this.props.match.path}/FixedCosts`} render={() => <FixedCosts OfficeGuid={this.ccr} />} />
                  <Route path={`${this.props.match.path}/Invoice`} render={() => <Invoice OfficeGuid={this.ccr} />} />
                  <Route path={`${this.props.match.path}/Images`} render={() => <Images OfficeGuid={this.ccr} />} />
                  <Route render={() => <Basedata OfficeGuid={this.ccr} />} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Bueroverwaltung.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      ccr: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default Bueroverwaltung;
