import React, { Component } from 'react';
import reqwest from 'reqwest';

import settings from '../../settings';
import validate from '../logic/validator';

import Logo from '../../Images/logo.png';
import swal from 'sweetalert';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textarea: {
        counterActive: false,
        counterRed: false,
        maxChars: 50,
        valuelength: 0,
        value: '',
      },
    };

    this.validators = {
      text: [
        {
          required: true,
          error: 'Bitte geben Sie eine Nachricht ein.',
        },
        {
          max: this.state.textarea.maxChars,
          error: `Bitte geben Sie maximal ${this.state.textarea.maxChars} Zeichen ein!`,
        },
      ],
    };
  }

  _forgot = async () => {
    const email = this.mail.value;

    let validator = {
      isValid: true,
      error: '',
    };

    validator = validator.isValid ? await validate(email, this.validators.mail) : validator;

    if (!validator.isValid) {
      this.setState({
        error: validator.error,
      });
      return;
    }

    reqwest({
      method: 'POST',
      url: settings.forgot.new,
      data: {
        Email: email,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Wir haben eine E-Mail an Ihre E-Mail geschickt um Ihr Passwort zurück zu setzen.',
        icon: 'success',
      });
    });
  };

  contact = async event => {
    event.preventDefault();
    //const name = this.name.value;
    const mail = this.mail.value;
    const tele = this.tel.value;
    const text = this.text.value;

    let validator = {
      isValid: true,
      error: '',
    };

    validator = validator.isValid ? await validate(mail, this.validators.mail) : validator;
    validator = validator.isValid ? await validate(tele, this.validators.telephone) : validator;
    validator = validator.isValid ? await validate(text, this.validators.text) : validator;

    if (!validator.isValid) {
      this.setState({
        error: validator.error,
      });
      return;
    }
  };

  changeTextarea = () => {
    const text = this.text.value.slice(0, this.state.textarea.maxChars);
    const maxChars = this.state.textarea.maxChars;
    const counterChars = maxChars - 30;

    this.text.value = text;
    this.setState({
      textarea: {
        ...this.state.textarea,
        length: text.length,
        counterActive: text.length > counterChars ? true : false,
        counterRed: text.length === maxChars ? true : false,
      },
    });
  };

  render() {
    return (
      <div className='Login-Form-box Login-Form-box-contact'>
        <img src={Logo} alt='alltours Reisecenter' className='Login-Form-logo' />
        <div className='Login-Form-title'>Kontaktieren Sie uns</div>
        <div className='Login-Form radius'>
          <input
            key='name'
            name='name'
            placeholder='Ihr Name'
            autoComplete='name'
            className='field field-border-bottom radius-top'
            ref={node => {
              this.name = node;
            }}
          />
          <input
            key='mail'
            name='mail'
            autoComplete='email'
            placeholder='Ihre E-Mail Adresse*'
            className='field field-border-bottom'
            ref={node => {
              this.mail = node;
            }}
          />
          <input
            key='tel'
            name='tel'
            autoComplete='tel'
            placeholder='Ihre Telefonnummer'
            className='field field-border-bottom'
            ref={node => {
              this.tel = node;
            }}
          />
          <div className='fieldTextarea-Wrapper'>
            <textarea
              key='text'
              name='text'
              placeholder='Ihre Nachricht*'
              className='field fieldTextarea field-border-bottom'
              ref={node => {
                this.text = node;
              }}
              onChange={this.changeTextarea}
            />
            <div
              className={`fieldTextareaCounter ${this.state.textarea.counterActive ? 'is-Active' : ''} ${
                this.state.textarea.counterRed ? 'is-Red' : ''
              }`}
            >
              <span className='fieldTextareaCounter-Current'>{this.state.textarea.length}</span> /{' '}
              <span className='fieldTextareaCounter-Max'>{this.state.textarea.maxChars}</span>
            </div>
          </div>
          <input key='contact' name='contact' type='submit' value='Abschicken' className='Login-submit' onClick={this.contact} />
        </div>
        <div className='Login-Form-actions'>
          <button
            type='button'
            className='Link Link--underlined'
            onClick={() => {
              this.setState({
                tabs: { contact: !this.state.tabs.contact },
              });
            }}
          >
            Zurück zum Login
          </button>
        </div>
      </div>
    );
  }
}

export default Contact;
