import React from 'react';
import PropTypes from 'prop-types';

import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';

import Button from '../Button';
import Input from '../Inputs/Input';

class ComposeComment extends React.Component {
  _onSaveClick = event => {
    this.text.input.value = '';

    this.props.saveOnClick(event);
  };

  render() {
    return (
      <div className='ComposeComment'>
        <Input
          type='textarea'
          onChange={this.props.textareaOnChange}
          className='ComposeComment-Text'
          placeholder='Kommentar schreiben...'
          ref={node => (this.text = node)}
          validator={[
            {
              required: true,
              error: 'Bitte füllen Sie dieses Feld aus',
            },
          ]}
          name='Comment'
        />
        <Button
          onClick={() => {
            this.text.input.value = '';
          }}
          icon={faTimes}
          type='danger'
          small
        >
          Leeren
        </Button>
        <Button onClick={this._onSaveClick} icon={faSave} type='primary' small>
          Speichern
        </Button>
      </div>
    );
  }
}

ComposeComment.propTypes = {
  textareaOnChange: PropTypes.func,
  saveOnClick: PropTypes.func,
};

export default ComposeComment;
