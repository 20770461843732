import React from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../settings';

import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import RightSide from '../../../../components/FormArea/RightSide';
import ContentBox from '../../../../components/FormArea/ContentBox';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

class RightsOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rights: [],
      loading: true,
    };

    reqwest({
      method: 'get',
      url: settings.permissions.GetAllRights,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(rightsResult => {
      const rights = JSON.parse(rightsResult);

      this.setState({
        rights,
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Rechte'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => this.props.history.push(`${this.props.match.url}/${original.Params.Guid}`),
              })}
              data={this.state.rights}
              pageSize={this.state.rights.length}
              noDataText='Es liegen keine Rechte vor.'
              columns={[
                {
                  Header: 'Name',
                  accessor: 'Params.Name',
                },
                {
                  Header: 'Bemerkung',
                  accessor: 'Params.Bemerkung',
                },
              ]}
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faPlus} to={`${this.props.match.url}/new`}>
            Neues Recht
          </Button>
        </RightSide>
      </>
    );
  }
}

export default RightsOverview;
