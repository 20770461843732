import React, { Component } from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import history from '../../history';
import settings from '../../../settings';
import Loader from '../../components/Loader';
import Searchbar from '../../components/Searchbar';
import Button from '../../components/Button';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import getConstants from '../../logic/constants';

class WorkshopsOverview extends Component {
  constructor(props) {
    super(props);

    let searchString = '';
    if (props.match.params.search) {
      searchString = props.match.params.search;
    }

    this.state = {
      loading: true,
      data: [],
      types: [
        {
          label: 'Schulung',
          value: 'schulung',
        },
        {
          label: 'Inforeise',
          value: 'inforeise',
        },
      ],
      searchString,
    };

    const workshopTypes = getConstants('WorkshopType').then(types => {
      this.setState({
        types,
      });
    });

    const promise = reqwest({
      method: 'get',
      url: settings.trainings.general.getList,
      data: {
        Token: localStorage.getItem('token'),
        Typ: 'Training',
      },
    }).then(res => {
      let data;
      try {
        data = JSON.parse(res);
      } catch (e) {
        data = [];
      }
      this.setState({
        data,
      });
    });
    Promise.all([promise, workshopTypes]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  _search = value => {
    const val = value.toLowerCase();

    history.replace(val ? `/workshops/search/${val}` : '/workshops');

    this.setState({
      searchString: val,
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    let data = [];
    if (this.state.data !== []) {
      data = this.state.data.filter(
        rowData =>
          rowData.Params.Guid &&
          [rowData.Params.From, rowData.Params.To, rowData.Params.Title, rowData.Params.Type]
            .map(e => e.toLowerCase())
            .some(v => {
              return v.indexOf(this.state.searchString) >= 0;
            }),
      );
    }

    return (
      <>
        <div className='Toolbar'>
          <Searchbar placeholder='Schulung / Inforeise suchen...' defaultValue={this.state.searchString} onChange={this._search} />
          <div className='Toolbar-buttons'>
            <Button icon={faPlus} to='/workshops/new'>
              Schulung / Inforeise erstellen
            </Button>
          </div>
        </div>
        <ReactTable
          getTrProps={(_, { original }) => ({
            onClick: () => this.props.history.push(`/Workshops/${original.Params.Guid}`),
          })}
          data={data}
          pageSize={data.length}
          noDataText='Es liegen noch keine Schulungen / Inforeisen vor.'
          columns={[
            {
              Header: 'Von',
              accessor: 'Params.From',
              maxWidth: 250,
            },
            {
              Header: 'Bis',
              accessor: 'Params.To',
              maxWidth: 250,
            },
            {
              Header: 'Titel',
              accessor: 'Params.Title',
            },
            {
              Header: 'Art',
              accessor: 'Params.Type',
              Cell: ({ value }) => this.state.types.map(type => (type.value === value ? type.label : null)),
            },
          ]}
        />
      </>
    );
  }
}

export default WorkshopsOverview;
