import React from 'react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import reqwest from 'reqwest';
import settings from '../../../../../settings';

import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';

import RightSide from '../../../../components/FormArea/RightSide';
import ContentBox from '../../../../components/FormArea/ContentBox';
import Input from '../../../../components/Inputs/Input';
import Dropdown from '../../../../components/Inputs/Dropdown';
import Switch from '../../../../components/Inputs/Switch';

import { faTrash, faTimes, faPlus, faGripLinesVertical, faSave } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uuid from 'uuid';
import swal from 'sweetalert';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class InventoryDetail extends React.Component {
  constructor(props) {
    super(props);

    this.type = props.match.params.id;
    this.parentRoute = this.props.match.url.replace(new RegExp(`/${this.type}$`), '');

    this.state = {
      loading: true,
      constant: [],
      Basic: {},
      Generals: {},
      RightSide: {},
      fields: [
        {
          label: 'Name',
          value: 'Name',
        },
        {
          label: 'In Liste (true/false)',
          value: 'InOverview',
        },
      ],
    };

    const fPromise = reqwest({
      method: 'GET',
      url: settings.inventory.GetFields,
      data: {
        Token: localStorage.getItem('token'),
        GroupGuid: this.type,
      },
    }).then(constant => {
      constant = JSON.parse(constant);

      const fields = [...this.state.fields];

      constant = constant
        .filter(con => con[0].Params.Guid)
        .sort((a, b) => {
          if (a[1].Params.Weight > b[1].Params.Weight) return 1;
          if (a[1].Params.Weight < b[1].Params.Weight) return -1;
          return 0;
        });

      return {
        constant,
        fields,
      };
    });

    const gPromise = reqwest({
      method: 'GET',
      url: settings.inventory.GetGroups,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(groupsResult => {
      const groups = JSON.parse(groupsResult);

      const group = groups.find(group => group[0].Params.Guid === this.type);
      return {
        Basic: group[0].Params,
        Generals: group[1].Params,
        RightSide: group[2].Params,
      };
    });

    Promise.all([fPromise, gPromise]).then(([f, g]) => {
      this.setState({
        ...f,
        ...g,
        loading: false,
      });
    });
  }

  save = () => {
    const savePromises = [];
    [...this.state.constant].forEach((constant, index) => {
      constant[1].Params.Weight = index;
      if (constant.Changed === true) {
        delete constant.Changed;
        if (constant[0].Params.Guid.startsWith('new:')) {
          constant[0].Params.Guid = '';
        }
        savePromises.push(
          reqwest({
            method: 'POST',
            url: settings.inventory.SaveField,
            data: {
              Token: localStorage.getItem('token'),
              Field: JSON.stringify(constant),
            },
          }).then(saveResult => {
            saveResult = JSON.parse(saveResult);
            return saveResult.SaveResult;
          }),
        );
      }
    });

    const Group = JSON.stringify([
      { ParamName: 'Basic', Params: this.state.Basic },
      { ParamName: 'Generals', Params: this.state.Generals },
      { ParamName: 'RightSide', Params: this.state.RightSide },
    ]);

    savePromises.push(
      reqwest({
        method: 'POST',
        url: settings.inventory.SaveGroup,
        data: {
          Token: localStorage.getItem('token'),
          Group,
        },
      }),
    );

    Promise.all(savePromises).then(saveResults => {
      if (saveResults.length) {
        const isValid = saveResults.every(result => result !== 'Updated' || result !== 'Saved');
        if (isValid) {
          swal({
            title: 'Erfolgreich!',
            text: 'Das Feld wurde erfolgreich gespeichert.',
            icon: 'success',
          });
        }
      }
    });
  };

  delete = (guid, name) => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: `Möchten Sie ${name === 'Gruppe' ? 'diese' : 'dieses'} ${name} wirklich löschen?`,
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        if (!guid.startsWith('new:')) {
          reqwest({
            method: 'POST',
            url: settings.inventory.Delete,
            data: {
              Token: localStorage.getItem('token'),
              Guid: guid,
            },
          }).then(deleteResult => {
            if (deleteResult === 'Deleted') {
              swal({
                title: 'Erfolgreich!',
                text: `${name === 'Gruppe' ? 'Die' : 'Das'} ${name} wurde erfolgreich gelöscht.`,
                icon: 'success',
              });

              if (name === 'Gruppe') {
                this.props.history.replace(this.parentRoute);
              }

              const constant = [...this.state.constant].filter(con => con[0].Params.Guid !== guid);
              this.setState({
                constant,
              });
            }
          });
        } else {
          const constant = [...this.state.constant].filter(con => con[0].Params.Guid !== guid);
          this.setState({
            constant,
          });
        }
      }
    });
  };

  onDragEnd = result => {
    if (!result.destination) return;

    let items = reorder(this.state.constant, result.source.index, result.destination.index);
    items = items.map((item, index) => {
      item[1].Params.Weight = index;
      item.Changed = true;
      return item;
    });

    this.setState({
      constant: items,
    });
  };

  addConstant = () => {
    const constant = this.state.constant;

    let newConstant = [
      { ParamName: 'Basic', Params: { Guid: 'new:' + uuid(), OfficeGuid: '', Typ: 'Field' } },
      { ParamName: 'Generals', Params: { Name: '', GroupGuid: this.type, Weight: '1', InOverview: 'true' } },
      {
        ParamName: 'RightSide',
        Params: { CreateDate: '', CreateUserGuid: '', CreateUserName: '', UpdateDate: '', UpdateUserGuid: '', UpdateUserName: '' },
      },
    ];

    this.setState({
      constant: [...constant, newConstant],
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const maxWidth = `calc((100% - 80px) / ${this.state.fields.length})`;
    const minWidth = `calc((100% - 80px) / ${this.state.fields.length})`;

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Gruppe'>
            <Input
              type='text'
              label='Name:'
              validator={[]}
              onChange={value => {
                this.setState(prevState => ({
                  Generals: {
                    ...prevState.Generals,
                    Name: value,
                  },
                }));
              }}
              defaultValue={this.state.Generals.Name}
              name='Name'
            />
            <Dropdown
              label='Gültig für:'
              validator={[]}
              options={[
                { label: 'Eigentum', value: 'Eigentum' },
                { label: 'Franchise', value: 'Franchise' },
                { label: 'beide', value: 'beide' },
              ]}
              getSelected={value => {
                this.setState(prevState => ({
                  Generals: {
                    ...prevState.Generals,
                    ValidFor: value,
                  },
                }));
              }}
              defaultValue={this.state.Generals.ValidFor}
              name='ValidFor'
            />
            <Input
              type='text'
              label='Gewichtung:'
              validator={[]}
              onChange={value => {
                this.setState(prevState => ({
                  Generals: {
                    ...prevState.Generals,
                    Weight: value,
                  },
                }));
              }}
              defaultValue={this.state.Generals.Weight}
              name='Weight'
            />
            <Switch
              label='Aktiv?'
              validator={[]}
              onChange={value => {
                this.setState(prevState => ({
                  Generals: {
                    ...prevState.Generals,
                    Active: value,
                  },
                }));
              }}
              name='Active'
              defaultChecked={JSON.parse(this.state.Generals.Active)}
            />
          </ContentBox>
          <ContentBox title='Felder'>
            <div className='Table'>
              <div className='Table-head'>
                <div
                  className='Table-head-field'
                  style={{
                    maxWidth: '40px',
                    minWidth: '40px',
                  }}
                >
                  {' '}
                </div>
                {this.state.fields.map(field => (
                  <div key={field.value} className='Table-head-field' style={{ maxWidth, minWidth }}>
                    {field.label}
                  </div>
                ))}
                <div
                  className='Table-head-field'
                  style={{
                    maxWidth: '40px',
                    minWidth: '40px',
                  }}
                >
                  {' '}
                </div>
              </div>
              <div className='Table-body'>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId='Table-body'>
                    {provided => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {this.state.constant.map((constant, cIndex) => (
                          <Draggable key={constant[0].Params.Guid} draggableId={constant[0].Params.Guid} index={cIndex}>
                            {provided => (
                              <div className='Table-body-row' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <div
                                  className='Table-body-data'
                                  style={{
                                    maxWidth: '40px',
                                    minWidth: '40px',
                                  }}
                                >
                                  <FontAwesomeIcon className='Table-body-data-icon' icon={faGripLinesVertical} />
                                </div>
                                {this.state.fields.map(field => (
                                  <div key={field.value} className='Table-body-data' style={{ maxWidth, minWidth }}>
                                    <input
                                      type='text'
                                      className='Table-body-data-value'
                                      defaultValue={constant[1].Params[field.value]}
                                      onChange={event => {
                                        event.persist();
                                        let constant = [...this.state.constant];
                                        constant[cIndex][1].Params[field.value] = event.target.value;
                                        constant[cIndex]['Changed'] = true;

                                        this.setState({
                                          constant,
                                        });
                                      }}
                                    />
                                  </div>
                                ))}
                                <div
                                  className='Table-body-data'
                                  style={{
                                    maxWidth: '40px',
                                    minWidth: '40px',
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className='Table-body-data-icon'
                                    icon={faTrash}
                                    onClick={() => {
                                      this.delete(constant[0].Params.Guid, 'Feld');
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button
            type='danger'
            big
            icon={faTrash}
            onClick={() => {
              this.delete(this.type, 'Gruppe');
            }}
          >
            Gruppe löschen
          </Button>
          <Button big icon={faPlus} onClick={this.addConstant}>
            Feld hinzufügen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default InventoryDetail;
