import React, { Component } from 'react';
import { SortableElement } from 'react-sortable-hoc';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

class Widget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openSettings: false,
      widget: undefined,
    };

    import(`./${this.props.name}`).then(module => {
      if (module.default) {
        this.setState({
          widget: module.default,
        });
      }
    });
  }

  render() {
    return (
      <div className={`Widget Widget-Size-${this.props.widget.size} ${this.props.widget.favorite && 'is-Favorite'}`}>
        {this.props.editMode && (
          <button className='Widget-Action-Button Widget-Action-Button--Remove' onClick={() => this.props.removeWidget(this.props.widget.id)}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        {this.state.widget && <this.state.widget options={this.props.widget.customOptions} settingsOpen={this.state.openSettings} />}
      </div>
    );
  }
}

export default SortableElement(Widget);
