import React, { Component } from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';

import File from '../../../../../components/Inputs/File';
import Loader from '../../../../../components/Loader';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Row from '../../../../../components/FormArea/Row';
import RightSide from '../../../../../components/FormArea/RightSide';
import Button from '../../../../../components/Button';
import DataContainer from '../../../../../logic/dataContainer';
import getConstants from '../../../../../logic/constants';

class BasedataDetail extends Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');

    this.state = {
      loading: true,
      types: [],
    };

    const workshopTypes = getConstants('WorkshopType').then(types => {
      this.setState({
        types,
      });
    });

    const dataPromise = reqwest({
      method: 'GET',
      url: settings.trainings.general.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: this.props.workshopGuid,
      },
    }).then(res => {
      this.container = new DataContainer(res);
    });

    Promise.all([dataPromise, workshopTypes]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diese Schulung / Inforeise wirklich löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'POST',
          url: settings.trainings.general.delete,
          data: {
            Token: localStorage.getItem('token'),
            Guid: this.props.workshopGuid,
          },
        }).then(() => {
          swal({
            title: 'Erfolgreich!',
            text: 'Ihre Schulung / Inforeise wurde erfolgreich gelöscht.',
            icon: 'success',
          });
          this.props.history.replace(this.parentRoute);
        });
      } else {
        swal({ title: 'Abgebrochen!', text: 'Die Schulung / Inforeise wurde nicht gelöscht.', icon: 'info' });
      }
    });
  };

  register = () => {
    const UserData = JSON.parse(localStorage.getItem('userdata'));
    const container = new DataContainer();
    container.add('Basic', {
      Guid: '',
      Typ: 'Registration',
      TrainingGuid: this.props.workshopGuid,
      UserGuid: UserData.userGuid,
    });
    container.add('General', {
      Title: this.container.get('General', 'Title'),
      Hours: this.container.get('General', 'Hours'),
      Firstname: UserData.firstname,
      Lastname: UserData.lastname,
      Expnr: UserData.expnr,
      Status: 'beantragt',
      From: this.container.get('General', 'From'),
      To: this.container.get('General', 'To'),
    });
    container.add('RightSide', {
      CreateDate: '',
      CreateUserGuid: '',
      CreateUserName: '',
      UpdateDate: '',
      UpdateUserGuid: '',
      UpdateUserName: '',
    });

    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie sich wirklich für diese Schulung / Inforeise anmelden?',
      icon: 'info',
      buttons: ['Abbrechen', 'Ja, jetzt anmelden'],
    }).then(response => {
      if (response) {
        this.setState({
          loading: true,
        });
        reqwest({
          method: 'POST',
          url: settings.trainings.registrations.save,
          data: {
            Token: localStorage.getItem('token'),
            Registration: container.getStringified(),
          },
        })
          .then(() => {
            swal({
              title: 'Erfolgreich!',
              text: 'Sie haben sich erfolgreich für diese Schulung / Inforeise angemeldet.',
              icon: 'success',
            });
            this.setState({
              loading: false,
            });
          })
          .fail(() => {
            swal({
              title: 'Fehler...',
              text: 'Da ist etwas schief gelaufen. Sie wurden nicht für die Schulung / Inforeise angemeldet. Versuchen Sie es später noch einmal...',
              icon: 'error',
            });
            this.setState({
              loading: false,
            });
          });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div style={{ width: '100%' }}>
          <ContentBox>
            <Row label='Titel:'>{this.container.get('General', 'Title')}</Row>
            <Row label='Von:'>{this.container.get('General', 'From')}</Row>
            <Row label='Bis:'>{this.container.get('General', 'To')}</Row>
            <Row label='Beschreibung:'>{this.container.get('General', 'Description')}</Row>
            <Row label='Stundenzahl:'>{this.container.get('General', 'Hours')}</Row>
            <Row label='Ort:'>{this.container.get('General', 'Location')}</Row>
            <Row label='Kosten:'>{this.container.get('General', 'Costs')}</Row>
            <Row label='Anmeldeschluss:'>{this.container.get('General', 'Deadline')}</Row>
            <Row label='Art:'>{this.state.types.map(type => (type.value === this.container.get('General', 'Type') ? type.label : null))}</Row>
            <Row label='Trainer / Veranstalter:'>{this.container.get('General', 'Trainer')}</Row>
            <Row label='Dreilettercode:'>{this.container.get('General', 'ThreeLetterCode')}</Row>
            <File label='Anhänge:' defaultFiles={this.container.get('General', 'Files')} />
          </ContentBox>
        </div>
        <RightSide
          createDate={this.container.get('RightSide', 'CreateDate')}
          createUserName={this.container.get('RightSide', 'CreateUserName')}
          updateDate={this.container.get('RightSide', 'UpdateDate')}
          updateUserName={this.container.get('RightSide', 'UpdateUserName')}
        >
          <Button big type='warning' to={`/Workshops/edit/${this.props.workshopGuid}`}>
            Bearbeiten
          </Button>
          <Button type='danger' big onClick={this.delete}>
            Löschen
          </Button>
          <Button big onClick={this.register}>
            Jetzt anmelden
          </Button>
        </RightSide>
      </>
    );
  }
}

export default BasedataDetail;
