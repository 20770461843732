import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../settings';

import RightSide from '../../../../components/FormArea/RightSide';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import Input from '../../../../components/Inputs/Input';
import Switch from '../../../../components/Inputs/Switch';
import Datepicker from '../../../../components/Inputs/Datepicker';
import ContentBox from '../../../../components/FormArea/ContentBox';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../logic/dataContainer';

class IntervalPasswordResetDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isInterval: false,
    };

    const IntervalPasswordResetPromise = reqwest({
      method: 'GET',
      url: settings.systemmanagement.intervalpasswordreset.get,
      contentType: 'JSON',
      data: {
        Token: localStorage.getItem('token'),
      },
    });

    Promise.all([IntervalPasswordResetPromise]).then(IntervalPasswordResetData => {
      this.dataContainer = new DataContainer(IntervalPasswordResetData);

      this.dataContainer = new DataContainer();
      this.dataContainer.add('General', {
        Title: '',
        IsActive: '',
        Initialdate: '',
        IsInterval: false,
      });
      this.dataContainer.add('IntervalOptions', {
        Interval: '',
        Enddate: '',
      });

      this.setState({
        loading: false,
      });
    });
  }

  componentDidMount() {}

  save = () => {
    const IntervalPasswordReset = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.systemmanagement.intervalpasswordreset.save,
      data: {
        Token: localStorage.getItem('token'),
        IntervalPasswordReset,
      },
    }).then(() => {
      swal({
        title: 'Erfolg!',
        text: 'Der Interval wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Passwort Interval'>
            <Input
              type='text'
              label='Titel:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('General', 'Title')(value);
              }}
              defaultValue={this.dataContainer.get('General', 'Title')}
              name='Title'
            />
            <Switch
              label='Aktiv?:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('General', 'IsActiv')(value);
              }}
              defaultChecked={this.dataContainer.get('General', 'IsActiv')}
              name='IsActiv'
            />
            <Datepicker
              label='Startdatum:'
              name='Initialdate:'
              validator={[]}
              onChange={day => {
                this.dataContainer.set('General', 'Initialdate')(day);
              }}
              defaultValue={this.dataContainer.get('General', 'Initialdate')}
            />
            <Switch
              label='Interval?:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('General', 'IsInterval')(value);
                this.setState({ isInterval: value });
              }}
              defaultChecked={this.dataContainer.get('General', 'IsInterval')}
              name='IsInterval'
            />
          </ContentBox>
          {this.state.isInterval ? (
            <ContentBox title='Interval'>
              <Input
                type='number'
                label='Interval in Tagen:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('IntervalOptions', 'Interval')(value);
                }}
                defaultValue={this.dataContainer.get('IntervalOptions', 'Interval')}
                name='Interval'
              />
              <Datepicker
                label='Enddate:'
                name='Enddate:'
                onChange={day => {
                  this.dataContainer.set('IntervalOptions', 'Enddate')(day);
                }}
                defaultValue={this.dataContainer.get('IntervalOptions', 'Enddate')}
              />
            </ContentBox>
          ) : (
            ''
          )}
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default IntervalPasswordResetDetails;
