import React, { Component } from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import Loader from '../../../../../components/Loader';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Form from '../../../../../components/Form';
import Input from '../../../../../components/Inputs/Input';
import RightSide from '../../../../../components/FormArea/RightSide';
import Button from '../../../../../components/Button';
import { faSave } from '@fortawesome/pro-solid-svg-icons';
import Switch from '../../../../../components/Inputs/Switch';

class TechnicDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    reqwest({
      method: 'get',
      url: settings.franchise.technic.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: this.props.franchiseGuid,
      },
    }).then(res => {
      this.dataContainer = new DataContainer(res);

      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const data = this.dataContainer.getStringified();

    reqwest({
      method: 'post',
      url: settings.franchise.technic.save,
      data: {
        Token: localStorage.getItem('token'),
        Technic: data,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Das Franchise wurde erfolgreich gespeichert.',
        icon: 'success',
      });
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Technik'>
              <Input
                type='text'
                label='CRS-System:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Generals', 'CRS')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'CRS')}
                name='CRS-System'
              />
              <Input
                type='text'
                label='Weiteres CRS-System:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Generals', 'OtherCRS')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'OtherCRS')}
                name='Weiteres CRS-System'
              />
              <Input
                type='text'
                label='Midoffice System:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Generals', 'MidofficeSystem')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'MidofficeSystem')}
                name='Midoffice System'
              />
              <Input
                type='text'
                label='myJACK Unit:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Generals', 'MyJackUnit')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'MyJackUnit')}
                name='myJACK Unit'
              />
              <Input
                type='text'
                label='myJACK Debitor:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Generals', 'MyJackDebitor')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'MyJackDebitor')}
                name='myJACK Debitor'
              />
              <Input
                type='text'
                label='myJACK CRS:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Generals', 'MyJackCRS')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'MyJackCRS')}
                name='myJACK CRS'
              />
              <Switch
                label='Reisecenter TV:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'TV')(value);
                }}
                validator={[]}
                name='Reisecenter TV'
                defaultChecked={this.dataContainer.get('Generals', 'TV')}
              />
              <Switch
                label='Internetseite:'
                onChange={value => {
                  this.dataContainer.set('Generals', 'Website')(value);
                }}
                validator={[]}
                name='Internetseite'
                defaultChecked={this.dataContainer.get('Generals', 'Website')}
              />
              <Input
                type='textarea'
                label='Sonstiges:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('Generals', 'Other')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Other')}
                name='Sonstiges'
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide
          createDate={this.dataContainer.get('RightSide', 'CreateDate')}
          createUserName={this.dataContainer.get('RightSide', 'CreateUserName')}
          updateDate={this.dataContainer.get('RightSide', 'UpdateDate')}
          updateUserName={this.dataContainer.get('RightSide', 'UpdateUserName')}
        >
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default TechnicDetail;
