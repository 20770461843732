import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class RightSide extends Component {
  render() {
    return (
      <div className='FormArea-Form-RightSide'>
        <div className='RightSide-Values'>
          {this.props.createDate && (
            <div className='RightSide-Value CreateDate'>
              <label className='RightSide-Label'>Angelegt am: </label>
              <span className='RightSide-Value-text'>
                {this.props.createDate !== ''
                  ? moment(this.props.createDate, this.props.createDate.includes('/') ? 'MM/DD/YYYY HH:mm:ss' : 'DD.MM.YYYY HH:mm:ss').format(
                      'DD.MM.YYYY [um] HH:mm',
                    )
                  : ''}
              </span>
            </div>
          )}
          {this.props.createUserName && (
            <div className='RightSide-Value CreateUserName'>
              <label className='RightSide-Label'>Erstellt von: </label>
              <span className='RightSide-Value-text'>{this.props.createUserName}</span>
            </div>
          )}
          {this.props.updateDate && (
            <div className='RightSide-Value UpdateDate'>
              <label className='RightSide-Label'>Letzte Änderung am: </label>
              <span className='RightSide-Value-text'>
                {this.props.updateDate !== ''
                  ? moment(this.props.updateDate, this.props.updateDate.includes('/') ? 'MM/DD/YYYY HH:mm:ss' : 'DD.MM.YYYY HH:mm:ss').format(
                      'DD.MM.YYYY [um] HH:mm',
                    )
                  : ''}
              </span>
            </div>
          )}
          {this.props.updateUserName && (
            <div className='RightSide-Value updateUserName'>
              <label className='RightSide-Label'>Bearbeitet von: </label>
              <span className='RightSide-Value-text'>{this.props.updateUserName}</span>
            </div>
          )}
        </div>
        {this.props.children && <div className='RightSide-Buttons'>{this.props.children}</div>}
      </div>
    );
  }
}

RightSide.propTypes = {
  createDate: PropTypes.string,
  createUserName: PropTypes.string,
  updateDate: PropTypes.string,
  updateUserName: PropTypes.string,
};

export default RightSide;
