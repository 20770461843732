import React, { Component } from 'react';
import moment from 'moment';

import { withRouter } from 'react-router-dom';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import Loader from '../../../components/Loader';
import Form from '../../../components/Form';
import Datepicker from '../../../components/Inputs/Datepicker';
import Dropdown from '../../../components/Inputs/Dropdown';
import ContentBox from '../../../components/FormArea/ContentBox';
import Button from '../../../components/Button';
import settings from '../../../../settings';
import RightSide from '../../../components/FormArea/RightSide';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import Input from '../../../components/Inputs/Input';
import DataContainer from '../../../logic/dataContainer';

class VacationRequest extends Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');

    this.state = {
      loading: false,
      from: moment(new Date()).format('DD.MM.YYYY'),
      to: moment(new Date()).format('DD.MM.YYYY'),
      type: 'Fullday',
      comment: '',
      vacationtype: 'Urlaub',
    };

    const userData = JSON.parse(localStorage.getItem('userdata'));

    this.dataContainer = new DataContainer();
    this.dataContainer.add('Basic', {
      Guid: '',
      UserGuid: userData.userGuid,
      Typ: 'Vacation',
    });
    this.dataContainer.add('General', {
      From: '',
      To: '',
      Type: '',
      Comment: '',
      VacationType: '',
    });
    this.dataContainer.add('EditorFields', {
      Status: 'Beantragt',
      Editor: '',
      Time: '',
      Cause: '',
    });
  }

  componentDidMount() {
    // TODO: Not getting any data at the moment.
    // reqwest({
    //   method: 'GET',
    //   url: settings.vacation.getStats,
    //   data: {
    //     UserGuid: this.UserGuid,
    //   },
    // })
    //   .then(data => {
    //     this.setState({
    //       vacationStats: data,
    //       loading: false,
    //     });
    //   })
    //   .catch(() => {
    //     throw new Error('Something went very very wrong...');
    //   });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const data = this.dataContainer.getStringified();

    reqwest({
      method: 'GET',
      url: settings.vacation.save,
      data: {
        fd: '10', // TODO: Set the FreeDays
        Vacation: data,
      },
    })
      .then(() => {
        swal({
          title: 'Erfolgreich!',
          text: 'Ihr Urlaubsantrag wurde erfolgreich eingereicht.',
          icon: 'success',
        });
        this.props.history.replace(this.parentRoute);
      })
      .catch(() => {
        this.props.history.replace(this.parentRoute);
        throw new Error('Something went very very wrong...');
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>Neuer Urlaubsantrag</p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <div className='FormArea-Form-Left'>
                    <Form ref={node => (this.form = node)}>
                      <ContentBox>
                        <Datepicker
                          label='Von: '
                          onChange={day => {
                            this.dataContainer.set('General', 'From')(day);
                          }}
                          defaultValue={this.dataContainer.get('General', 'From')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='From'
                        />
                        <Datepicker
                          label='Bis: '
                          onChange={day => {
                            this.dataContainer.set('General', 'To')(day);
                          }}
                          defaultValue={this.dataContainer.get('General', 'To')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='To'
                        />
                        <Dropdown
                          label='Zeitraum: '
                          isSearchable
                          getSelected={selected => {
                            this.dataContainer.set('General', 'Type')(selected);
                          }}
                          defaultValue={this.dataContainer.get('General', 'Type')}
                          options={[
                            { value: 'Fullday', label: 'Ganzer Tag' },
                            { value: 'Halfday', label: 'Halber Tag' },
                          ]}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='Type'
                        />
                        <Dropdown
                          label='Typ: '
                          isSearchable
                          getSelected={selected => {
                            this.dataContainer.set('General', 'VacationType')(selected);
                          }}
                          defaultValue={this.dataContainer.get('General', 'VacationType')}
                          options={[
                            { value: 'Urlaub', label: 'Urlaub' },
                            { value: 'Kur', label: 'Kur' },
                            { value: 'Elternzeit', label: 'Elternzeit' },
                          ]}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='VacationType'
                        />
                        <Input
                          type='text'
                          label='Kommentar: '
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('General', 'Comment')(value);
                          }}
                          defaultValue={this.dataContainer.get('General', 'Comment')}
                          name='Kommentar'
                        />
                      </ContentBox>
                    </Form>
                  </div>
                  <RightSide>
                    <Button big icon={faTimes} to={this.parentRoute}>
                      Abbrechen
                    </Button>
                    <Button big type='primary' icon={faSave} onClick={this.save}>
                      Speichern
                    </Button>
                  </RightSide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(VacationRequest);
