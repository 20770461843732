import React, { Component } from 'react';
import { Switch, NavLink, Route } from 'react-router-dom';

import Constants from './Constants/index';
import Sections from './Sections/index';
import Roles from './Roles/index';
import Rights from './Rights/index';
import EMailClient from './EMailClients/index';
import EMailMacro from './EMailMacro/index';
import EMailTemplates from './EMailTemplates/index';
import IntervalPasswordReset from './IntervalPasswordReset/index';
import Scheduler from './Scheduler/index';
import Inventory from './Inventory/index';

class Systemmangement extends Component {
  render() {
    return (
      <div className='FormArea'>
        <div className='FormArea-Top'>
          <p className='FormArea-Title'>Systemverwaltung</p>
        </div>
        <div className='FormArea-Body'>
          <div className='FormArea-Body-Tabs'>
            <NavLink to={`${this.props.match.url}/Constants`} className='FormArea-Body-Tab' activeClassName='is-Active'>
              Konstanten
            </NavLink>
            <NavLink to={`${this.props.match.url}/Sections`} className='FormArea-Body-Tab' activeClassName='is-Active'>
              Anwendungssektionen
            </NavLink>
            <NavLink to={`${this.props.match.url}/Roles`} className='FormArea-Body-Tab' activeClassName='is-Active'>
              Rollen
            </NavLink>
            <NavLink to={`${this.props.match.url}/Rights`} className='FormArea-Body-Tab' activeClassName='is-Active'>
              Rechte
            </NavLink>
            <NavLink to={`${this.props.match.url}/EMailClient`} className='FormArea-Body-Tab' activeClassName='is-Active'>
              E-Mail Nutzer
            </NavLink>
            <NavLink to={`${this.props.match.url}/EMailMacro`} className='FormArea-Body-Tab' activeClassName='is-Active'>
              E-Mail Makro
            </NavLink>
            <NavLink to={`${this.props.match.url}/EMailTemplates`} className='FormArea-Body-Tab' activeClassName='is-Active'>
              E-Mail Templates
            </NavLink>
            <NavLink to={`${this.props.match.url}/IntervalPasswordReset`} className='FormArea-Body-Tab' activeClassName='is-Active'>
              Passwort Interval
            </NavLink>
            <NavLink to={`${this.props.match.url}/Scheduler`} className='FormArea-Body-Tab' activeClassName='is-Active'>
              Zeitplaner
            </NavLink>
            <NavLink to={`${this.props.match.url}/Inventory`} className='FormArea-Body-Tab' activeClassName='is-Active'>
              Inventar
            </NavLink>
          </div>
          <div className='FormArea-Body-Content'>
            <div className='FormArea-Body-Content-Center'>
              <div className='FormArea-Form'>
                <Switch>
                  <Route path={`${this.props.match.path}/Constants`} render={() => <Constants />} />
                  <Route path={`${this.props.match.path}/Sections`} render={() => <Sections />} />
                  <Route path={`${this.props.match.path}/Roles`} render={() => <Roles />} />
                  <Route path={`${this.props.match.path}/Rights`} render={() => <Rights />} />
                  <Route path={`${this.props.match.path}/EMailClient`} render={() => <EMailClient />} />
                  <Route path={`${this.props.match.path}/EMailMacro`} render={() => <EMailMacro />} />
                  <Route path={`${this.props.match.path}/EMailTemplates`} render={() => <EMailTemplates />} />
                  <Route path={`${this.props.match.path}/IntervalPasswordReset`} render={() => <IntervalPasswordReset />} />
                  <Route path={`${this.props.match.path}/Scheduler`} render={() => <Scheduler />} />
                  <Route path={`${this.props.match.path}/Inventory`} render={() => <Inventory />} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Systemmangement;
