import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';

import RenderHTML from '../RenderHTML';
import Button from '../Button';

class Comment extends React.Component {
  constructor(props) {
    super(props);

    this.userRoles = JSON.parse(localStorage.getItem('userdata')).roles;
    this.userHasAdministrativeActions = this.userRoles.some(role => ['Superuser', 'Regionalleiter'].includes(role));

    this.state = {
      actionsOpen: false,
    };
  }

  _toggleActions = () => {
    this.setState({
      actionsOpen: !this.state.actionsOpen,
    });
  };

  render() {
    return (
      <div className='Comment'>
        <div className='Comment-Head'>
          {this.userHasAdministrativeActions ? (
            <Link className='Comment-Name' to={`/usermanagement/${this.props.userGuid ? this.props.userGuid : ''}`}>
              {this.props.name}
            </Link>
          ) : (
            <div className='Comment-Name'>{this.props.name}</div>
          )}
          <div className='Comment-Date'>{this.props.date}</div>
        </div>
        {this.props.editMode ? (
          <div className='Comment-EditMode'>
            <textarea
              ref={this.props.textRef}
              className='Comment-Content Input-field'
              defaultValue={this.props.content?.replace(/<br \/>/g, '\n')}
            ></textarea>
            <div className='Comment-EditModeButtons'>
              {this.props.editModeButtons.map(eButton => (
                <Button
                  key={eButton.text}
                  small
                  icon={eButton.icon}
                  type={eButton.type}
                  className='Comment-EditModeButton'
                  onClick={() => {
                    eButton.onClick(this.props.guid);
                  }}
                >
                  {eButton.text}
                </Button>
              ))}
            </div>
          </div>
        ) : (
          <div className='Comment-Content'>
            <RenderHTML>{this.props.content}</RenderHTML>
          </div>
        )}
        <button className='Comment-Actions-Opener' onClick={this._toggleActions}>
          <FontAwesomeIcon icon={faEllipsisV} />
        </button>
        {this.state.actionsOpen && (
          <div className='Comment-Actions'>
            {this.props.avaliableActions.map(action => (
              <button key={action.name} onClick={() => action.onClick(this.props.guid)} className={'Comment-Action'}>
                {action.icon && <FontAwesomeIcon icon={action.icon} fixedWidth className='Comment-Action-Icon' />}
                {action.name}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  }
}

Comment.propTypes = {
  guid: PropTypes.string,
  userGuid: PropTypes.string,
  name: PropTypes.string,
  content: PropTypes.string,
  date: PropTypes.string,
  textRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.elementType })]),
  editMode: PropTypes.bool,
  editModeButtons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        prefix: PropTypes.string,
        iconName: PropTypes.string,
        icon: PropTypes.arrayOf(PropTypes.any),
      }),
      onClick: PropTypes.func.isRequired,
      type: PropTypes.oneOf(['default', 'primary', 'danger', 'warning']),
    }),
  ),
  avaliableActions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        prefix: PropTypes.string,
        iconName: PropTypes.string,
        icon: PropTypes.arrayOf(PropTypes.any),
      }),
      onClick: PropTypes.func.isRequired,
    }),
  ),
};

export default Comment;
