import React from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../../settings';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Button from '../../../../../components/Button';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

class InventoryModuleOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groupData: [],
      fields: [],
      loading: true,
    };

    const groupDataPromise = reqwest({
      method: 'get',
      url: settings.inventory.GetGroupData,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
        OfficeGuid: this.props.OfficeGuid,
        GroupGuid: this.props.GroupGuid,
      },
    }).then(groupDataResponse => {
      const groupData = JSON.parse(groupDataResponse);
      return groupData.map(groupData => {
        return { ...groupData[1].Params, Guid: groupData[0].Params.Guid };
      });
    });

    const fieldsPromise = reqwest({
      method: 'get',
      url: settings.inventory.GetFields,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
        OfficeGuid: this.props.OfficeGuid,
        GroupGuid: this.props.GroupGuid,
      },
    }).then(fieldsResponse => {
      const fields = JSON.parse(fieldsResponse);
      return fields;
    });

    Promise.all([groupDataPromise, fieldsPromise]).then(([groupData, fields]) => {
      this.setState({
        groupData,
        fields,
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.state.groupData;

    const columns = this.state.fields
      .map(field => {
        if (field[1].Params.InOverview === 'true') {
          return {
            Header: field[1].Params.Name,
            accessor: `guid-${field[0].Params.Guid}`,
          };
        }
        return null;
      })
      .filter(field => field);

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Daten'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => this.props.history.replace(`${this.props.match.url}/${original.Guid}`),
              })}
              data={data}
              pageSize={data.length}
              noDataText='Es liegen keine Daten für das ausgewählte Büro vor.'
              columns={columns}
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faPlus} to={`${this.props.match.url}/new`} replace>
            Neuer Datensatz
          </Button>
        </RightSide>
      </>
    );
  }
}

export default InventoryModuleOverview;
