import React, { Component } from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';

import Loader from '../../../../../components/Loader';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Input from '../../../../../components/Inputs/Input';
import Datepicker from '../../../../../components/Inputs/Datepicker';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import RightSide from '../../../../../components/FormArea/RightSide';
import Button from '../../../../../components/Button';
import Form from '../../../../../components/Form';
import { faSave } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../../logic/dataContainer';
import getConstants from '../../../../../logic/constants';

class BasedataDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      states: [],
      types: [],
      weekdays: [],
      Cooperationoffices: [],
    };

    const dataPromise = reqwest({
      method: 'GET',
      url: settings.office.general.get,
      data: {
        Token: localStorage.getItem('token'),
        CcrGuid: props.OfficeGuid,
        ConTyp: 'Generals',
      },
    }).then(res => {
      this.DataContainer = new DataContainer(res);
      document.querySelector('.FormArea-Title').innerHTML = `#${this.DataContainer.get('Officedata', 'CCR')} ${this.DataContainer.get(
        'Officedata',
        'Shortname',
      )}`;
    });

    const promiseBcountry = getConstants('Bcountry').then(states => {
      this.setState({
        states,
      });
    });

    const promiseOfficetype = getConstants('Officetype').then(types => {
      this.setState({
        types,
      });
    });

    const promiseWeekday = getConstants('Weekday').then(weekdays => {
      this.setState({
        weekdays,
      });
    });

    const cooperationOfficePromise = reqwest({
      method: 'get',
      url: settings.office.general.getShortList,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(data => {
      const offices = JSON.parse(data);
      this.setState({
        Cooperationoffices: offices.map(office => ({
          value: office.Params.Guid,
          label: `${office.Params.Shortname} (${office.Params.CCR})`,
        })),
      });
    });

    const supervisorsPromise = reqwest({
      method: 'get',
      url: settings.usermanagement.general.getUserForPosition,
      data: {
        Token: localStorage.getItem('token'),
        Position: 'RL',
      },
    }).then(users => {
      users = JSON.parse(users);

      this.setState({
        supervisors: users.map(element => {
          return {
            value: element.Params.Guid,
            label: `${element.Params.Firstname} ${element.Params.Lastname} (${element.Params.Expnr})`,
          };
        }),
      });
    });

    Promise.all([cooperationOfficePromise, dataPromise, promiseBcountry, promiseOfficetype, promiseWeekday, supervisorsPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const data = this.DataContainer.getStringified();

    reqwest({
      method: 'post',
      url: settings.office.general.save,
      data: {
        Office: data,
      },
    }).then(() => {
      document.querySelector('.FormArea-Title').innerHTML = `#${this.DataContainer.get('Officedata', 'CCR')} ${this.DataContainer.get(
        'Officedata',
        'Shortname',
      )}`;
      swal({
        title: 'Erfolgreich!',
        text: 'Das Büro wurde erfolgreich gespeichert.',
        icon: 'success',
      });
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Stammdaten'>
              <Input
                type='text'
                label='KST:'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                  {
                    type: 'number',
                    error: 'Bitte geben Sie nur Zahlen an',
                  },
                ]}
                onChange={value => {
                  this.DataContainer.set('Officedata', 'CCR')(value);
                }}
                defaultValue={this.DataContainer.get('Officedata', 'CCR')}
                name='KST'
                isDisabled={true}
              />
              <Input
                type='text'
                label='Kurzbezeichnung:'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                onChange={value => {
                  this.DataContainer.set('Officedata', 'Shortname')(value);
                }}
                defaultValue={this.DataContainer.get('Officedata', 'Shortname')}
                name='Kurzbezeichnung'
              />
              <Input
                type='text'
                label='Strasse:'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                onChange={value => {
                  this.DataContainer.set('Officedata', 'Street')(value);
                }}
                defaultValue={this.DataContainer.get('Officedata', 'Street')}
                name='Strasse'
              />
              <Input
                type='text'
                label='PLZ:'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                onChange={value => {
                  this.DataContainer.set('Officedata', 'Zip')(value);
                }}
                defaultValue={this.DataContainer.get('Officedata', 'Zip')}
                name='PLZ'
              />
              <Input
                type='text'
                label='Ort:'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                onChange={value => {
                  this.DataContainer.set('Officedata', 'City')(value);
                }}
                defaultValue={this.DataContainer.get('Officedata', 'City')}
                name='Ort'
              />
              <Dropdown
                label='Bundesland:'
                isSearchable
                getSelected={value => {
                  this.DataContainer.set('Officedata', 'State')(value);
                }}
                defaultValue={this.DataContainer.get('Officedata', 'State')}
                options={this.state.states}
                name='Bundesland'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
              />

              <Dropdown
                label='Bürotyp:'
                isSearchable
                getSelected={value => {
                  this.DataContainer.set('Officedata', 'Officetype')(value);
                }}
                defaultValue={this.DataContainer.get('Officedata', 'Officetype')}
                options={this.state.types}
                name='Bürotyp'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
              />

              <Input
                type='text'
                label='Telefon:'
                validator={[]}
                onChange={value => {
                  this.DataContainer.set('Officedata', 'Tel')(value);
                }}
                defaultValue={this.DataContainer.get('Officedata', 'Tel')}
                name='Telefon'
              />
              <Input
                type='text'
                label='Fax:'
                validator={[]}
                onChange={value => {
                  this.DataContainer.set('Officedata', 'Fax')(value);
                }}
                defaultValue={this.DataContainer.get('Officedata', 'Fax')}
                name='Fax'
              />
              <Input
                type='text'
                label='E-Mail:'
                validator={[
                  {
                    type: 'email',
                    error: 'Die E-Mail Adresse scheint nicht gültig zu sein',
                  },
                ]}
                onChange={value => {
                  this.DataContainer.set('Officedata', 'EMail')(value);
                }}
                defaultValue={this.DataContainer.get('Officedata', 'EMail')}
                name='Email'
              />
              <Datepicker
                label='Eröffnung:'
                onChange={day => {
                  this.DataContainer.set('Officedata', 'Opening')(day);
                }}
                defaultValue={this.DataContainer.get('Officedata', 'Opening')}
                name='Eröffnung'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
              />
              <Datepicker
                label='Schliessung:'
                onChange={day => {
                  this.DataContainer.set('Officedata', 'Closure')(day);
                }}
                defaultValue={this.DataContainer.get('Officedata', 'Closure')}
                name='Schliessung'
                validator={[]}
              />
              <Input
                type='text'
                label='Min. Mitarbeiterzahl:'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                  {
                    type: 'number',
                    error: 'Bitte geben Sie nur Zahlen an',
                  },
                ]}
                onChange={value => {
                  this.DataContainer.set('Officedata', 'Minemployees')(value);
                }}
                defaultValue={this.DataContainer.get('Officedata', 'Minemployees')}
                name='Mitarbeiterzahl'
              />
              <Dropdown
                label='Betreuer:'
                isSearchable
                getSelected={value => {
                  this.DataContainer.set('Officedata', 'Supervisor')(value);
                }}
                defaultValue={this.DataContainer.get('Officedata', 'Supervisor')}
                options={this.state.supervisors}
                name='Betreuer'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
              />
              <Input
                type='text'
                label='Planumsatz:'
                validator={[
                  {
                    type: 'number',
                    error: 'Bitte geben Sie nur Zahlen an',
                  },
                ]}
                onChange={value => {
                  this.DataContainer.set('Officeplan', 'Planscales')(value);
                }}
                defaultValue={this.DataContainer.get('Officeplan', 'Planscales')}
                name='Planumsatz'
              />
              <Dropdown
                label='Kooperationsbüros:'
                isSearchable
                getSelected={value => {
                  this.DataContainer.set('Officedata', 'Cooperationoffices')(value);
                }}
                defaultValue={this.DataContainer.get('Officedata', 'Cooperationoffices')}
                options={this.state.Cooperationoffices}
                isMulti
                name='Kooperationsbüros'
                validator={[]}
              />
              <Input
                type='textarea'
                label='Bemerkung 1:'
                validator={[]}
                onChange={value => {
                  this.DataContainer.set('Officecomments', 'Comment1')(value);
                }}
                defaultValue={this.DataContainer.get('Officecomments', 'Comment1')}
                name='Bemerkung'
              />
              <Input
                type='textarea'
                label='Bemerkung 2:'
                validator={[]}
                onChange={value => {
                  this.DataContainer.set('Officecomments', 'Comment2')(value);
                }}
                defaultValue={this.DataContainer.get('Officecomments', 'Comment2')}
                name='Bemerkung'
              />
              <Input
                type='textarea'
                label='Schaufenster:'
                validator={[]}
                onChange={value => {
                  this.DataContainer.set('Officedata', 'Showcase')(value);
                }}
                defaultValue={this.DataContainer.get('Officedata', 'Showcase')}
                name='Schaufenster'
              />
            </ContentBox>
            {this.DataContainer.get('Accountant', 'CanSee') && (
              <ContentBox title='Buchhalter'>
                <Input
                  type='text'
                  label='Vorname:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('Accountant', 'AccountantFirstname')(value);
                  }}
                  defaultValue={this.DataContainer.get('Accountant', 'AccountantFirstname')}
                  name='AccountantFirstname'
                  isDisabled={!this.DataContainer.get('Accountant', 'CanEdit')}
                />
                <Input
                  type='text'
                  label='Nachname:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('Accountant', 'AccountantLastname')(value);
                  }}
                  defaultValue={this.DataContainer.get('Accountant', 'AccountantLastname')}
                  name='AccountantLastname'
                  isDisabled={!this.DataContainer.get('Accountant', 'CanEdit')}
                />
                <Input
                  type='text'
                  label='E-Mail:'
                  validator={[
                    {
                      type: 'email',
                      error: 'Bitte geben Sie eine gültige E-Mail ein.',
                    },
                  ]}
                  onChange={value => {
                    this.DataContainer.set('Accountant', 'AccountantEmail')(value);
                  }}
                  defaultValue={this.DataContainer.get('Accountant', 'AccountantEmail')}
                  name='AccountantEmail'
                  isDisabled={!this.DataContainer.get('Accountant', 'CanEdit')}
                />
              </ContentBox>
            )}
          </Form>
        </div>
        <RightSide
          createDate={this.DataContainer.get('RightSide', 'CreateDate')}
          createUserName={this.DataContainer.get('RightSide', 'CreateUserName')}
          updateDate={this.DataContainer.get('RightSide', 'UpdateDate')}
          updateUserName={this.DataContainer.get('RightSide', 'UpdateUserName')}
        >
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default BasedataDetail;
