import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Input from '../../../../../components/Inputs/Input';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../../logic/dataContainer';
import Dropdown from '../../../../../components/Inputs/Dropdown';

class AgencynumberNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');

    reqwest({
      method: 'GET',
      url: settings.promoter.general.getList,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(promoter => {
      promoter = JSON.parse(promoter);

      this.setState({
        loading: false,
        promoter: promoter.map(pro => {
          return {
            value: pro.Params.Guid,
            label: `${pro.Params.Firstname} ${pro.Params.Lastname}`,
          };
        }),
      });
    });

    this.state = {
      loading: true,
    };

    this.container = new DataContainer();
    this.container.add('Basic', {
      Guid: '',
      Typ: 'Agencynumber',
      OfficeGuid: this.props.OfficeGuid,
    });
    this.container.add('Generals', {
      Agencynumber: '',
      Organiser: '',
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const newAgencynumber = this.container.getStringified();

    reqwest({
      method: 'GET',
      url: settings.office.agencynumbers.save,
      data: {
        Token: localStorage.getItem('token'),
        Agencynumber: newAgencynumber,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Ihre Agenturnummer wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Agenturnummer'>
              <Input
                type='text'
                label='Agenturnummer:'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                onChange={value => {
                  this.container.set('Generals', 'Agencynumber')(value);
                }}
                defaultValue={this.container.get('Generals', 'Agencynumber')}
                name='Agenturnummer'
              />
              <Dropdown
                label='Veranstalter:'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                defaultValue={this.container.get('Generals', 'Organiser')}
                options={this.state.promoter}
                name='Veranstalter'
                getSelected={value => {
                  this.container.set('Generals', 'Organiser')(value);
                }}
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default AgencynumberNew;
