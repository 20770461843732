import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../settings';
import DataContainer from '../../logic/dataContainer';

import Loader from '../../components/Loader';
import RightSide from '../../components/FormArea/RightSide';
import Button from '../../components/Button';
import Form from '../../components/Form';
import ContentBox from '../../components/FormArea/ContentBox';
import Dropdown from '../../components/Inputs/Dropdown';
import Datepicker from '../../components/Inputs/Datepicker';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';

class VisitsNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');

    this.dataContainer = new DataContainer();
    this.dataContainer.add('Basic', {
      Guid: '',
      Typ: 'Visit',
    });
    this.dataContainer.add('Generals', {
      Date: '',
      CCR: '',
      Reason: '',
    });

    this.state = {
      offices: [],
      reasons: [
        {
          label: 'anlassbezogen',
          value: 'anlassbezogen',
        },
        {
          label: 'allgemein',
          value: 'allgemein',
        },
      ],
      loading: true,
    };
  }

  componentDidMount() {
    reqwest({
      method: 'get',
      url: settings.office.general.getList,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(res => {
      this.setState({
        offices: JSON.parse(res).officeList.map(office => ({
          value: office.Guid,
          label: office.CCR,
        })),
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const newVisit = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.visits.save,
      data: {
        Token: localStorage.getItem('token'),
        Protocol: newVisit,
      },
    }).then(res => {
      if (JSON.parse(res).Params.SaveResult === 'Saved') {
        swal({
          title: 'Erfolgreich!',
          text: 'Ihr Termin wurde erfolgreich gespeichert.',
          icon: 'success',
        });
        this.props.history.replace(this.parentRoute);
      } else {
        swal({
          title: 'Fehler!',
          text: 'Beim speichern Ihres Termins ist ein Fehler aufgetreten',
          icon: 'error',
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>Neuen Termin anlegen</p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <div className='FormArea-Form-Left'>
                    <Form ref={node => (this.form = node)}>
                      <ContentBox title='Termin'>
                        <Datepicker
                          label='Datum:'
                          onChange={date => {
                            this.dataContainer.set('Generals', 'Date')(date);
                          }}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          defaultValue={this.dataContainer.get('Generals', 'Date')}
                          name='Date'
                        />
                        <Dropdown
                          label='KST:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          getSelected={value => {
                            this.dataContainer.set('Generals', 'CCR')(value);
                          }}
                          options={this.state.offices}
                          name='CCR'
                        />
                        <Dropdown
                          label='Grund:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          getSelected={value => {
                            this.dataContainer.set('Generals', 'Reason')(value);
                          }}
                          options={this.state.reasons}
                          name='Reason'
                        />
                      </ContentBox>
                    </Form>
                  </div>
                  <RightSide>
                    <Button big icon={faTimes} to={this.parentRoute}>
                      Abbrechen
                    </Button>
                    <Button big type='primary' icon={faSave} onClick={this.save}>
                      Speichern
                    </Button>
                  </RightSide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default VisitsNew;
