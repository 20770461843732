import React from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../settings';

import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import RightSide from '../../../../components/FormArea/RightSide';
import ContentBox from '../../../../components/FormArea/ContentBox';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

class SectionOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      applicationSections: [],
      loading: true,
    };

    reqwest({
      method: 'get',
      url: settings.permissions.GetApplicationSections,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(applicationSectionsResult => {
      const applicationSections = JSON.parse(applicationSectionsResult);

      this.setState({
        applicationSections,
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Anwendungssektionen'>
            <ReactTable
              data={this.state.applicationSections}
              pageSize={this.state.applicationSections.length}
              noDataText='Es liegen keine Sektionen vor.'
              columns={[
                {
                  Header: 'Name',
                  accessor: 'Params.Name',
                },
                {
                  Header: 'Bemerkung',
                  accessor: 'Params.Bemerkung',
                },
              ]}
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faPlus} to={`${this.props.match.url}/new`} replace>
            Neue Sektion
          </Button>
        </RightSide>
      </>
    );
  }
}

export default SectionOverview;
