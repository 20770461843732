import React from 'react';
import reqwest from 'reqwest';
import { Redirect } from 'react-router-dom';

import Loader from '../../components/Loader';
import PasswordValidator from '../../components/PasswordValidator';
import Logo from '../../../Images/logo.png';

import DataContainer from '../../logic/dataContainer';
import settings from '../../../settings';
import swal from 'sweetalert';
import sha512 from 'sha512';

class ForgotDetail extends React.Component {
  constructor(props) {
    super(props);

    const accessToken = props.match.params.accessToken;

    reqwest({
      method: 'get',
      url: settings.forgot.check,
      data: {
        token: accessToken,
      },
    }).then(checkResult => {
      if (checkResult === 'No Token found') {
        this.setState({
          loading: false,
          isValidGuid: checkResult,
        });
      }
      this.container = new DataContainer();
      checkResult = JSON.parse(checkResult);

      this.setState({
        loading: false,
        isValidGuid: checkResult.Params.Checkresult,
      });
    });

    this.state = {
      loading: true,
      isValidGuid: 'valid',
      passwordFocus: false,
      passwordsMatch: false,
      isAvaliable: false,
    };
  }

  _submit = () => {
    reqwest({
      method: 'post',
      url: settings.forgot.save,
      data: {
        Password: sha512(this.state.password).toString('hex'),
      },
    })
      .then(passwordResult => {
        passwordResult = JSON.parse(passwordResult);
        swal({
          title: 'Erfolgreich!',
          text: 'Ihr Passwort wurde erfolgreich geändert.',
          icon: 'success',
        }).then(() => {
          this.props.history.push('/Login');
        });
      })
      .fail(() => {
        swal({
          title: 'Fehler!',
          text: 'Ihr Passwort wurde konnte nicht geändert werden.',
          icon: 'error',
        }).then(() => {
          this.props.history.push('/Login');
        });
      });
  };

  _change = () => {
    // Needed for Password Validator, needs State to Change
    this.setState({
      password: this.password?.value,
    });
  };

  _isPasswordSafe = isValid => {
    this.setState({
      isAvaliable: isValid,
    });
  };

  _passwordFocus = () => {
    this.setState({
      passwordFocus: !this.state.passwordFocus,
    });
  };

  _changePasswordRepeat = () => {
    const originalPasswordValue = this.password.value;
    const value = this.password_repeat.value;

    if (originalPasswordValue === value) {
      this.setState({
        passwordsMatch: true,
      });
    } else {
      this.setState({
        passwordsMatch: false,
      });
    }
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.isValidGuid !== 'valid') {
      swal({
        title: 'Fehler!',
        text: 'Dieser Token ist leider nicht gültig. Bitte versuchen Sie es erneut.',
        icon: 'error',
      });
      return <Redirect to='/Login' />;
    }

    return (
      <div className='Login-Form-box'>
        <img src={Logo} alt='alltours Reisecenter' className='Login-Form-logo' />
        <div className='Login-Form-title'>Passwort ändern</div>
        <div className='Login-Form radius'>
          <input
            name='password'
            type='password'
            autoComplete='password'
            placeholder='Neues Passwort'
            className='field field-border-bottom radius-top'
            onKeyUp={this._change}
            onFocus={this._passwordFocus}
            onBlur={this._passwordFocus}
            ref={node => {
              this.password = node;
            }}
          />
          {this.state.passwordFocus && <PasswordValidator value={this.state.password} onChangeValid={this._isPasswordSafe} />}
          <input
            name='password'
            type='password'
            autoComplete='password'
            placeholder='Neues Passwort wiederholen'
            className='field field-border-bottom'
            onKeyUp={this._changePasswordRepeat}
            ref={node => {
              this.password_repeat = node;
            }}
          />
          <input
            name='forgot'
            disabled={!this.state.isAvaliable && this.state.passwordsMatch}
            type='submit'
            value='Abschicken'
            onClick={this._submit}
            className='Login-submit'
          />
        </div>
        <div className='Login-Form-actions'>
          <button
            type='button'
            className='Link Link--underlined'
            onClick={() => {
              this.props.history.push('/Login');
            }}
          >
            Zurück zum Login
          </button>
        </div>
      </div>
    );
  }
}

export default ForgotDetail;
