import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../settings';

import RightSide from '../../../../components/FormArea/RightSide';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import ContentBox from '../../../../components/FormArea/ContentBox';
import Input from '../../../../components/Inputs/Input';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../logic/dataContainer';

class SchedulerNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');

    this.state = {
      loading: false,
    };

    this.dataContainer = new DataContainer();
    this.dataContainer.add('SchedulerEntry', {
      Name: '',
      Bemerkung: '',
      Timing: '',
      Command: '',
    });
  }

  save = () => {
    const Entry = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.systemmanagement.scheduler.save,
      data: {
        Token: localStorage.getItem('token'),
        Entry,
      },
    }).then(saveResult => {
      saveResult = JSON.parse(saveResult);

      if (saveResult[0].Params.SaveResult === 'Saved') {
        swal({
          title: 'Erfolg!',
          text: 'Der Eintrag wurde erfolgreich angelegt.',
          icon: 'success',
        });
        this.props.history.replace(this.parentRoute);
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Job'>
            <Input
              type='text'
              label='Name:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('SchedulerEntry', 'Name')(value);
              }}
              defaultValue={this.dataContainer.get('SchedulerEntry', 'Name')}
              name='Name'
            />
            <Input
              type='text'
              label='Bemerkung:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('SchedulerEntry', 'Bemerkung')(value);
              }}
              defaultValue={this.dataContainer.get('SchedulerEntry', 'Bemerkung')}
              name='Bemerkung'
            />
            <Input
              type='text'
              label='Cronjob Timing (* * * * *):'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('SchedulerEntry', 'Timing')(value);
              }}
              defaultValue={this.dataContainer.get('SchedulerEntry', 'Timing')}
              name='Timing'
            />
            <Input
              type='text'
              label='Befehl:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('SchedulerEntry', 'Command')(value);
              }}
              defaultValue={this.dataContainer.get('SchedulerEntry', 'Command')}
              name='Command'
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default SchedulerNew;
