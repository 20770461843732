import React from 'react';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../settings';

import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import RightSide from '../../../../components/FormArea/RightSide';
import ContentBox from '../../../../components/FormArea/ContentBox';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

class InventoryOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: [],
      loading: true,
    };

    reqwest({
      method: 'get',
      url: settings.inventory.GetGroups,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(groupsResponse => {
      if (groupsResponse === 'No Guid') {
        this.setState({
          groups: [],
          loading: false,
        });
        return;
      }

      const groups = JSON.parse(groupsResponse);
      this.setState({
        groups: groups.filter(group => !!group[0].Params.Guid).map(group => ({ ...group[0].Params, ...group[1].Params })),
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.state.groups.sort((a, b) => {
      if (a.Weight > b.Weight) return 1;
      if (a.Weight < b.Weight) return -1;
      return 0;
    });

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Inventar'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => {
                  return this.props.history.replace(`${this.props.match.url}/${original.Guid}`);
                },
              })}
              data={data}
              pageSize={data.length}
              noDataText='Es liegen keine Gruppen vor.'
              columns={[
                {
                  Header: 'Name',
                  accessor: 'Name',
                },
                {
                  Header: 'Gültig für',
                  accessor: 'ValidFor',
                },
              ]}
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faPlus} to={`${this.props.match.url}/new`} replace>
            Neue Gruppe
          </Button>
        </RightSide>
      </>
    );
  }
}

// ConstantsOverview.propTypes = {
//   UserGuid: PropTypes.string.isRequired,
// };

export default InventoryOverview;
