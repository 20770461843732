import React, { Component } from 'react';
import ReactTable from 'react-table';
import reqwest from 'reqwest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText, faMapMarkerAlt, faPlus } from '@fortawesome/pro-solid-svg-icons';

import history from '../../history';
import settings from '../../../settings';
import Loader from '../../components/Loader';
import Searchbar from '../../components/Searchbar';
import Button from '../../components/Button';

class Usermanagement extends Component {
  constructor(props) {
    super(props);

    let searchString = '';
    if (props.match.params.search) {
      searchString = props.match.params.search;
    }

    this.state = {
      loading: true,
      data: [],
      searchString,
    };
  }

  componentDidMount() {
    const promise = reqwest({
      method: 'get',
      url: settings.usermanagement.general.getList,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(res => {
      this.setState({
        data: JSON.parse(res).userList,
      });
    });

    Promise.all([promise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  _search = value => {
    const val = value.toLowerCase();

    history.replace(val ? `/usermanagement/search/${val}` : '/usermanagement');

    this.setState({
      searchString: val,
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.state.data.filter(rowData =>
      [rowData.Lastname, rowData.Firstname, rowData.Expnr, rowData.Email, rowData.OStreet, rowData.OZip, rowData.OCity]
        .map(e => e.toLowerCase())
        .some(v => {
          return v.indexOf(this.state.searchString) >= 0;
        }),
    );

    return (
      <>
        <div className='Toolbar'>
          <Searchbar placeholder='Mitarbeiter suchen...' defaultValue={this.state.searchString} onChange={this._search} />
          <div className='Toolbar-buttons'>
            <Button icon={faPlus} to='/usermanagement/new'>
              Mitarbeiter erstellen
            </Button>
          </div>
        </div>
        <ReactTable
          getTrProps={(_, { original }) => ({
            onClick: () => this.props.history.push(`/usermanagement/${original.Guid}`),
          })}
          data={data}
          pageSize={data.length}
          noDataText='Es wurden keine Mitarbeiter gefunden.'
          columns={[
            {
              id: 'name',
              Header: 'Name',
              maxWidth: 300,
              Cell: ({ original }) => (
                <>
                  {original.Lastname}, {original.Firstname}
                </>
              ),
            },
            {
              Header: 'ExpNr',
              accessor: 'Expnr',
              maxWidth: 200,
            },
            {
              id: 'address',
              Header: 'Mail und Büroadresse',
              Cell: ({ original }) => (
                <>
                  <FontAwesomeIcon fixedWidth icon={faEnvelopeOpenText} /> {original.Email}
                  <br />
                  <FontAwesomeIcon fixedWidth icon={faMapMarkerAlt} /> {original.OStreet}, {original.OZip} {original.OCity}
                </>
              ),
            },
          ]}
        />
      </>
    );
  }
}

export default Usermanagement;
