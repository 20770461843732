import React from 'react';
import PropTypes from 'prop-types';
import reqwest from 'reqwest';
import ReactTable from 'react-table';

import settings from '../../../../../../settings';

import Loader from '../../../../../components/Loader';
import Button from '../../../../../components/Button';
import RightSide from '../../../../../components/FormArea/RightSide';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

class ContractsOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      officesList: [],
      loading: true,
    };

    reqwest({
      method: 'get',
      url: settings.usermanagement.originatingOffice.getList,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
        UserGuid: this.props.UserGuid,
      },
    }).then(contractListResponse => {
      const officesList = JSON.parse(contractListResponse);
      this.setState({
        officesList,
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.state.officesList.filter(row => row.Params.Guid);

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='Stammbüros'>
            <ReactTable
              getTrProps={(_, { original }) => ({
                onClick: () => this.props.history.replace(`${this.props.match.url}/${original.Params.Guid}`),
              })}
              data={data}
              pageSize={data.length}
              noDataText='Es liegen kein Stammbüro für den ausgewählten Benutzer vor.'
              columns={[
                {
                  Header: 'Name',
                  accessor: 'Params.OfficeName',
                },
                {
                  Header: 'Von',
                  accessor: 'Params.From',
                },
                {
                  Header: 'Bis',
                  accessor: 'Params.To',
                },
              ]}
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faPlus} to={`${this.props.match.url}/new`} replace>
            Neues Stammbüro
          </Button>
        </RightSide>
      </>
    );
  }
}

ContractsOverview.propTypes = {
  UserGuid: PropTypes.string.isRequired,
};

export default ContractsOverview;
