import React, { Component } from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import reqwest from 'reqwest';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import settings from '../../../../settings';
import Loader from '../../../components/Loader';
import Dropdown from '../../../components/Inputs/Dropdown';
import Button from '../../../components/Button';
import getConstants from '../../../logic/constants';

class SickDays extends Component {
  constructor(props) {
    super(props);

    const userData = JSON.parse(localStorage.getItem('userdata'));

    this.state = {
      loading: true,
      data: [],
      sickTypes: [],
      users: [],
      SelectedMonth: moment().format('MM'),
      SelectedYear: moment().format('YYYY'),
      SelectedUser: userData.userGuid,
    };

    getConstants('SickType').then(sickTypes => {
      this.setState({
        sickTypes,
      });
    });

    const usersPromise = reqwest({
      method: 'get',
      url: settings.usermanagement.general.getList,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(res => {
      this.setState({
        users: JSON.parse(res).userList,
      });
    });

    Promise.all([usersPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  componentDidMount() {
    this._getSickDays();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.SelectedUser !== this.state.SelectedUser ||
      prevState.SelectedMonth !== this.state.SelectedMonth ||
      prevState.SelectedYear !== this.state.SelectedYear
    ) {
      this._getSickDays();
    }
  }

  _getSickDays = () => {
    this.setState({
      loading: true,
    });
    return reqwest({
      method: 'GET',
      url: settings.vacation.getAbsenceOverview,
      data: {
        Token: localStorage.getItem('token'),
        UserGuid: this.state.SelectedUser,
        Month: this.state.SelectedMonth,
        Year: this.state.SelectedYear,
        Typ: 'Sick',
      },
    })
      .then(res => {
        let data = JSON.parse(res);
        if (data) {
          this.setState({
            data: [...data],
            loading: false,
          });
        }
      })
      .catch(() => {
        throw new Error('Something went very very wrong...');
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const data = this.state.data.filter(row => row.Params.Guid);

    return (
      <>
        <div className='Toolbar'>
          <Dropdown
            defaultValue={this.state.SelectedMonth}
            placeholder='Monat'
            isClearable
            options={[
              { label: 'Alle', value: '' },
              { label: 'Januar', value: '01' },
              { label: 'Februar', value: '02' },
              { label: 'März', value: '03' },
              { label: 'April', value: '04' },
              { label: 'Mai', value: '05' },
              { label: 'Juni', value: '06' },
              { label: 'Juli', value: '07' },
              { label: 'August', value: '08' },
              { label: 'September', value: '09' },
              { label: 'Oktober', value: '10' },
              { label: 'November', value: '11' },
              { label: 'Dezember', value: '12' },
            ]}
            getSelected={value => {
              this.setState({
                SelectedMonth: value,
              });
            }}
            name='Month'
          />
          <Dropdown
            defaultValue={this.state.SelectedYear}
            placeholder='Jahr'
            isClearable
            options={Array.from({ length: 10 }, (v, i) => new Date().getFullYear() - i).map(year => ({ label: year, value: `${year}` }))}
            getSelected={value => {
              this.setState({
                SelectedYear: value,
              });
            }}
            name='Year'
          />
          <Dropdown
            defaultValue={this.state.SelectedUser}
            placeholder='Mitarbeiter'
            isSearchable
            options={this.state.users.map(user => ({
              label: `${user.Firstname} ${user.Lastname} (${user.Expnr})`,
              value: user.Guid,
            }))}
            getSelected={value => {
              this.setState({
                SelectedUser: value,
              });
            }}
            name='User'
          />
          <div className='Toolbar-buttons'>
            <Button big icon={faPlus} to={`${this.props.match.url}/new`} replace>
              Neue Krankmeldung
            </Button>
          </div>
        </div>
        <ReactTable
          getTrProps={(_, { original }) => ({
            onClick: () => this.props.history.replace(`${this.props.match.url}/${original.Params.Guid}`),
          })}
          data={data}
          pageSize={data.length}
          noDataText='Es liegen keine Krankheitstage für den ausgewählten Zeitraum vor.'
          columns={[
            {
              Header: 'Von',
              accessor: 'Params.From',
              maxWidth: 200,
            },
            {
              Header: 'Bis',
              accessor: 'Params.To',
              maxWidth: 200,
            },
            {
              Header: 'Krankheitsart',
              accessor: 'Params.SickType',
              Cell: ({ value }) => this.state.sickTypes.map(type => (type.value === value ? type.label : null)),
            },
          ]}
        />
      </>
    );
  }
}

export default SickDays;
