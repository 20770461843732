import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Modal from '../../../components/Modal';

import CorrectionRequest from './CorrectionRequest';
import Overview from './Overview';

const TimeTracking = props => {
  const paths = {
    correctionRequest: `${props.match.path}/CorrectionRequest`,
  };

  return (
    <Modal title='Zeiterfassung'>
      <Switch>
        <Route path={paths.correctionRequest} component={CorrectionRequest} />
        <Route component={Overview} />
      </Switch>
    </Modal>
  );
};

export default withRouter(TimeTracking);
