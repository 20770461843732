import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Row extends Component {
  render() {
    let rowStyle = {};
    if (this.props.column > 1) {
      rowStyle = {
        width: `${100 / this.props.column}%`,
        display: 'inline-flex',
      };
    }

    return (
      <div className='FormArea-Form-Row' style={rowStyle}>
        <div className='FormArea-Form-Label'>{this.props.label}</div>
        <div className='FormArea-Form-Field'>{this.props.children}</div>
      </div>
    );
  }
}

Row.propTypes = {
  label: PropTypes.string.isRequired,
  column: PropTypes.string,
};

export default Row;
