import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Default from './Default/index';
import FranchiseNews from './FranchiseNews/index';
import Region from './Region/index';
import SectorMagazine from './SectorMagazine/index';

const News = props => {
  const paths = {
    franchiseNews: `${props.match.path}/FranchiseNews`,
    regionalNews: `${props.match.path}/RegionalNews`,
    sectorMagazine: `${props.match.path}/SectorMagazine`,
  };

  return (
    <Switch>
      <Route path={paths.franchiseNews} component={FranchiseNews} />
      <Route path={paths.regionalNews} component={Region} />
      <Route path={paths.sectorMagazine} component={SectorMagazine} />
      <Route component={Default} />
    </Switch>
  );
};

export default withRouter(News);
