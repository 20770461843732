import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../settings';

import RightSide from '../../components/FormArea/RightSide';
import Loader from '../../components/Loader';
import Form from '../../components/Form';
import Button from '../../components/Button';
import Input from '../../components/Inputs/Input';
import File from '../../components/Inputs/File';
import ContentBox from '../../components/FormArea/ContentBox';
import Datepicker from '../../components/Inputs/Datepicker';
import Dropdown from '../../components/Inputs/Dropdown';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../logic/dataContainer';
import getConstants from '../../logic/constants';

class WorkshopsEdit extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/edit/${props.match.params.id}$`), '');

    this.state = {
      loading: true,
      statuses: [
        {
          label: 'Anmeldungen offen',
          value: 'open',
        },
        {
          label: 'Anmeldungen geschlossen',
          value: 'closed',
        },
      ],
      types: [],
      files: [],
    };

    const dataPromise = reqwest({
      method: 'GET',
      url: settings.trainings.general.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: props.match.params.id,
      },
    }).then(res => {
      this.container = new DataContainer(res);
    });

    const workshopTypes = getConstants('WorkshopType').then(types => {
      this.setState({
        types,
      });
    });

    Promise.all([workshopTypes, dataPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    this.fileInput.saveFiles().then(guids => {
      this.container.set('General', 'Files')(guids);

      const newTraining = this.container.getStringified();

      reqwest({
        method: 'POST',
        url: settings.trainings.general.save,
        data: {
          Token: localStorage.getItem('token'),
          Training: newTraining,
        },
      }).then(() => {
        swal({
          title: 'Erfolgreich!',
          text: 'Ihre Schulung / Inforeise wurde erfolgreich gespeichert.',
          icon: 'success',
        });
        this.props.history.replace(this.parentRoute);
      });
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>Schulung / Inforeise bearbeiten</p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <div className='FormArea-Form-Left'>
                    <Form ref={node => (this.form = node)}>
                      <ContentBox title='Allgemein'>
                        <Input
                          type='text'
                          label='Titel:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.container.set('General', 'Title')(value);
                          }}
                          name='Titel'
                          defaultValue={this.container.get('General', 'Title')}
                        />
                        <Input
                          type='textarea'
                          label='Beschreibung:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.container.set('General', 'Description')(value);
                          }}
                          name='Beschreibung'
                          defaultValue={this.container.get('General', 'Description')}
                        />
                        <File
                          editable
                          ref={node => (this.fileInput = node)}
                          label='Anhänge:'
                          maxFiles={10}
                          defaultFiles={this.container.get('General', 'Files')}
                        />
                        <Input
                          type='text'
                          label='Ort:'
                          validator={[]}
                          onChange={value => {
                            this.container.set('General', 'Location')(value);
                          }}
                          name='Ort'
                          defaultValue={this.container.get('General', 'Location')}
                        />
                        <Input
                          type='text'
                          label='Kosten:'
                          validator={[]}
                          onChange={value => {
                            this.container.set('General', 'Costs')(value);
                          }}
                          name='Kosten'
                          defaultValue={this.container.get('General', 'Costs')}
                        />
                        <Input
                          type='text'
                          label='Trainer / Veranstalter:'
                          validator={[]}
                          onChange={value => {
                            this.container.set('General', 'Trainer')(value);
                          }}
                          name='Trainer / Veranstalter'
                          defaultValue={this.container.get('General', 'Trainer')}
                        />
                        <Input
                          type='text'
                          label='Dreilettercode:'
                          validator={[]}
                          onChange={value => {
                            this.container.set('General', 'ThreeLetterCode')(value);
                          }}
                          name='Dreilettercode'
                          defaultValue={this.container.get('General', 'ThreeLetterCode')}
                        />
                      </ContentBox>
                      <ContentBox title='Einstellungen'>
                        <Datepicker
                          label='Von:'
                          onChange={day => {
                            this.container.set('General', 'From')(day);
                          }}
                          defaultValue={this.container.get('General', 'From')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='From'
                        />
                        <Datepicker
                          label='Bis:'
                          onChange={day => {
                            this.container.set('General', 'To')(day);
                          }}
                          defaultValue={this.container.get('General', 'To')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='To'
                        />
                        <Datepicker
                          label='Anmeldeschluss:'
                          onChange={day => {
                            this.container.set('General', 'Deadline')(day);
                          }}
                          defaultValue={this.container.get('General', 'Deadline')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='Deadline'
                        />
                        <Input
                          type='text'
                          label='Stundenzahl:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                            {
                              type: 'number',
                              error: 'Bitte geben Sie nur Zahlen an',
                            },
                          ]}
                          onChange={value => {
                            this.container.set('General', 'Hours')(value);
                          }}
                          name='Stundenzahl'
                          defaultValue={this.container.get('General', 'Hours')}
                        />
                        <Input
                          type='text'
                          label='Plätze:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                            {
                              type: 'number',
                              error: 'Bitte geben Sie nur Zahlen an',
                            },
                          ]}
                          onChange={value => {
                            this.container.set('General', 'Places')(value);
                          }}
                          name='Plätze'
                          defaultValue={this.container.get('General', 'Places')}
                        />
                        <Dropdown
                          label='Art:'
                          isSearchable
                          getSelected={value => {
                            this.container.set('General', 'Type')(value);
                          }}
                          defaultValue={this.container.get('General', 'Type')}
                          options={this.state.types}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='Type'
                        />
                        <Dropdown
                          label='Status:'
                          isSearchable
                          getSelected={value => {
                            this.container.set('General', 'Status')(value);
                          }}
                          defaultValue={this.container.get('General', 'Status')}
                          options={this.state.statuses}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='Status'
                        />
                      </ContentBox>
                    </Form>
                  </div>
                  <RightSide
                    createDate={this.container.get('RightSide', 'CreateDate')}
                    createUserName={this.container.get('RightSide', 'CreateUserName')}
                    updateDate={this.container.get('RightSide', 'UpdateDate')}
                    updateUserName={this.container.get('RightSide', 'UpdateUserName')}
                  >
                    <Button big icon={faTimes} to={this.parentRoute}>
                      Abbrechen
                    </Button>
                    <Button big type='primary' icon={faSave} onClick={this.save}>
                      Speichern
                    </Button>
                  </RightSide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WorkshopsEdit;
