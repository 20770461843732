import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import New from './New';
import Details from './Details';
import Overview from './Overview';

const InventoryModule = props => {
  const paths = {
    details: `${props.match.path}/:id`,
    new: `${props.match.path}/new`,
  };

  return (
    <Switch>
      <Route
        exact
        key={props.match.params.GroupGuid}
        path={paths.new}
        render={routerProps => <New {...routerProps} GroupGuid={props.match.params.GroupGuid} OfficeGuid={props.OfficeGuid} />}
      />
      <Route
        exact
        key={props.match.params.GroupGuid}
        path={paths.details}
        render={routerProps => <Details {...routerProps} GroupGuid={props.match.params.GroupGuid} OfficeGuid={props.OfficeGuid} />}
      />
      <Route
        render={routerProps => (
          <Overview key={props.match.params.GroupGuid} {...routerProps} GroupGuid={props.match.params.GroupGuid} OfficeGuid={props.OfficeGuid} />
        )}
      />
    </Switch>
  );
};

export default withRouter(InventoryModule);
