import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../settings';

import RightSide from '../../components/FormArea/RightSide';
import Loader from '../../components/Loader';
import Button from '../../components/Button';
import Form from '../../components/Form';
import Input from '../../components/Inputs/Input';
import ContentBox from '../../components/FormArea/ContentBox';
import Datepicker from '../../components/Inputs/Datepicker';
import Dropdown from '../../components/Inputs/Dropdown';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../logic/dataContainer';
import getConstants from '../../logic/constants';

class OfficeNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');

    this.state = {
      loading: true,
    };

    this.container = new DataContainer();
    this.container.add('Basic', {
      Guid: '',
      Typ: 'Office',
    });
    this.container.add('Officedata', {
      CCR: '',
      Shortname: '',
      Street: '',
      Zip: '',
      City: '',
      State: '',
      Officetype: '',
      Tel: '',
      Fax: '',
      EMail: '',
      Opening: '',
      Closure: '',
      Minemployees: '',
      Supervisor: '',
      Showcase: '',
      Cooperationoffices: '',
    });
    this.container.add('Officeplan', {
      Planscales: '',
    });
    this.container.add('Officecomments', {
      Comment1: '',
      Comment2: '',
    });

    const promiseBcountry = getConstants('Bcountry').then(states => {
      this.setState({
        states,
      });
    });

    const promiseOfficetype = getConstants('Officetype').then(types => {
      this.setState({
        types,
      });
    });

    const promiseWeekday = getConstants('Weekday').then(weekdays => {
      this.setState({
        weekdays,
      });
    });

    const cooperationOfficePromise = reqwest({
      method: 'get',
      url: settings.office.general.getShortList,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(data => {
      const offices = JSON.parse(data);
      this.setState({
        Cooperationoffices: offices.map(office => ({
          value: office.Params.Guid,
          label: `${office.Params.Shortname} (${office.Params.CCR})`,
        })),
      });
    });

    const supervisorsPromise = reqwest({
      method: 'get',
      url: settings.usermanagement.general.getUserForPosition,
      data: {
        Token: localStorage.getItem('token'),
        Position: 'RL',
      },
    }).then(users => {
      users = JSON.parse(users);

      this.setState({
        supervisors: users.map(element => {
          return {
            value: element.Params.Guid,
            label: `${element.Params.Firstname} ${element.Params.Lastname} (${element.Params.Expnr})`,
          };
        }),
      });
    });

    Promise.all([cooperationOfficePromise, promiseBcountry, promiseOfficetype, promiseWeekday, supervisorsPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const newOffice = this.container.getStringified();

    reqwest({
      method: 'POST',
      url: settings.office.general.save,
      data: {
        Token: localStorage.getItem('token'),
        Office: newOffice,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Das Büro wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>Neues Büro erstellen</p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <div className='FormArea-Form-Left'>
                    <Form ref={node => (this.form = node)}>
                      <ContentBox title='Stammdaten'>
                        <Input
                          type='text'
                          label='KST:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                            {
                              type: 'number',
                              error: 'Bitte geben Sie nur Zahlen an',
                            },
                          ]}
                          onChange={value => {
                            this.container.set('Officedata', 'CCR')(value);
                          }}
                          defaultValue={this.container.get('Officedata', 'CCR')}
                          name='KST'
                        />
                        <Input
                          type='text'
                          label='Kurzbezeichnung:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.container.set('Officedata', 'Shortname')(value);
                          }}
                          defaultValue={this.container.get('Officedata', 'Shortname')}
                          name='Kurzbezeichnung'
                        />
                        <Input
                          type='text'
                          label='Strasse:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.container.set('Officedata', 'Street')(value);
                          }}
                          defaultValue={this.container.get('Officedata', 'Street')}
                          name='Strasse'
                        />
                        <Input
                          type='text'
                          label='PLZ:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.container.set('Officedata', 'Zip')(value);
                          }}
                          defaultValue={this.container.get('Officedata', 'Zip')}
                          name='PLZ'
                        />
                        <Input
                          type='text'
                          label='Ort:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.container.set('Officedata', 'City')(value);
                          }}
                          defaultValue={this.container.get('Officedata', 'City')}
                          name='Ort'
                        />
                        <Dropdown
                          label='Bundesland:'
                          isSearchable
                          getSelected={value => {
                            this.container.set('Officedata', 'State')(value);
                          }}
                          defaultValue={this.container.get('Officedata', 'State')}
                          options={this.state.states}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='State'
                        />
                        <Dropdown
                          label='Bürotyp:'
                          isSearchable
                          getSelected={value => {
                            this.container.set('Officedata', 'Officetype')(value);
                          }}
                          defaultValue={this.container.get('Officedata', 'Officetype')}
                          options={this.state.types}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='Officetype'
                        />
                        <Input
                          type='text'
                          label='Telefon:'
                          validator={[]}
                          onChange={value => {
                            this.container.set('Officedata', 'Tel')(value);
                          }}
                          defaultValue={this.container.get('Officedata', 'Tel')}
                          name='Telefon'
                        />
                        <Input
                          type='text'
                          label='Fax:'
                          validator={[]}
                          onChange={value => {
                            this.container.set('Officedata', 'Fax')(value);
                          }}
                          defaultValue={this.container.get('Officedata', 'Fax')}
                          name='Fax'
                        />
                        <Input
                          type='text'
                          label='E-Mail:'
                          validator={[
                            {
                              type: 'email',
                              error: 'Die E-Mail Adresse scheint nicht gültig zu sein',
                            },
                          ]}
                          onChange={value => {
                            this.container.set('Officedata', 'EMail')(value);
                          }}
                          defaultValue={this.container.get('Officedata', 'EMail')}
                          name='Email'
                        />
                        <Datepicker
                          label='Eröffnung:'
                          onChange={day => {
                            this.container.set('Officedata', 'Opening')(day);
                          }}
                          defaultValue={this.container.get('Officedata', 'Opening')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='Opening'
                        />
                        <Datepicker
                          label='Schliessung:'
                          onChange={day => {
                            this.container.set('Officedata', 'Closure')(day);
                          }}
                          defaultValue={this.container.get('Officedata', 'Closure')}
                          validator={[]}
                          name='Closure'
                        />
                        <Input
                          type='text'
                          label='Min. Mitarbeiterzahl:'
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                            {
                              type: 'number',
                              error: 'Bitte geben Sie nur Zahlen an',
                            },
                          ]}
                          onChange={value => {
                            this.container.set('Officedata', 'Minemployees')(value);
                          }}
                          defaultValue={this.container.get('Officedata', 'Minemployees')}
                          name='Mitarbeiterzahl'
                        />
                        <Dropdown
                          label='Betreuer:'
                          isSearchable
                          getSelected={value => {
                            this.container.set('Officedata', 'Supervisor')(value);
                          }}
                          defaultValue={this.container.get('Officedata', 'Supervisor')}
                          options={this.state.supervisors}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='Supervisor'
                        />
                        <Input
                          type='text'
                          label='Planumsatz:'
                          validator={[
                            {
                              type: 'number',
                              error: 'Bitte geben Sie nur Zahlen an',
                            },
                          ]}
                          onChange={value => {
                            this.container.set('Officeplan', 'Planscales')(value);
                          }}
                          defaultValue={this.container.get('Officeplan', 'Planscales')}
                          name='Planumsatz'
                        />
                        <Dropdown
                          label='Kooperationsbüros:'
                          isSearchable
                          getSelected={value => {
                            this.container.set('Officedata', 'Cooperationoffices')(value);
                          }}
                          defaultValue={this.container.get('Officedata', 'Cooperationoffices')}
                          options={this.state.Cooperationoffices}
                          isMulti
                          validator={[]}
                          name='Cooperationoffices'
                        />
                        <Input
                          type='textarea'
                          label='Bemerkung 1:'
                          validator={[]}
                          onChange={value => {
                            this.container.set('Officecomments', 'Comment1')(value);
                          }}
                          defaultValue={this.container.get('Officecomments', 'Comment1')}
                          name='Bemerkung'
                        />
                        <Input
                          type='textarea'
                          label='Bemerkung 2:'
                          validator={[]}
                          onChange={value => {
                            this.container.set('Officecomments', 'Comment2')(value);
                          }}
                          defaultValue={this.container.get('Officecomments', 'Comment2')}
                          name='Bemerkung'
                        />
                        <Input
                          type='textarea'
                          label='Schaufenster:'
                          validator={[]}
                          onChange={value => {
                            this.container.set('Officedata', 'Showcase')(value);
                          }}
                          defaultValue={this.container.get('Officedata', 'Showcase')}
                          name='Schaufenster'
                        />
                      </ContentBox>
                    </Form>
                  </div>
                  <RightSide>
                    <Button big icon={faTimes} to={this.parentRoute}>
                      Abbrechen
                    </Button>
                    <Button big type='primary' icon={faSave} onClick={this.save}>
                      Speichern
                    </Button>
                  </RightSide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OfficeNew;
