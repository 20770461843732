import React, { Component } from 'react';
import moment from 'moment';

import { withRouter } from 'react-router-dom';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import Loader from '../../../../components/Loader';
import Form from '../../../../components/Form';
import ContentBox from '../../../../components/FormArea/ContentBox';
import Button from '../../../../components/Button';
import settings from '../../../../../settings';
import RightSide from '../../../../components/FormArea/RightSide';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import Input from '../../../../components/Inputs/Input';
import DataContainer from '../../../../logic/dataContainer';

class CorrectionRequestNew extends Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/CorrectionRequest/new/${props.match.params.date}$`), '');

    this.state = {
      loading: false,
    };

    const userData = JSON.parse(localStorage.getItem('userdata'));
    this.UserGuid = userData.userGuid;

    this.dataContainer = new DataContainer();
    this.dataContainer.add('Basic', {
      Guid: '',
      UserGuid: userData.userGuid,
      Typ: 'CorrectionRequest',
    });
    this.dataContainer.add('Generals', {
      Date: moment(props.match.params.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      Hours: '',
      Reason: '',
    });
    this.dataContainer.add('EditorFields', {
      Status: 'Beantragt',
      Editor: '',
      Time: '',
      Cause: '',
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const data = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.azn.timetracking.save,
      data: {
        CorrectionRequest: data,
      },
    })
      .then(() => {
        swal({
          title: 'Erfolgreich!',
          text: 'Ihr Korrekturantrag wurde erfolgreich eingereicht.',
          icon: 'success',
        });
        this.props.history.replace(this.parentRoute);
      })
      .catch(() => {
        this.props.history.replace(this.parentRoute);
        throw new Error('Something went very very wrong...');
      });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>Neuer Korrekturantrag</p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <div className='FormArea-Form-Left'>
                    <Form ref={node => (this.form = node)}>
                      <ContentBox title={`Korrekturantrag für den ${moment(this.props.match.params.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}`}>
                        <Input
                          fullWidth
                          type='text'
                          label='Stundenänderung: '
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                            {
                              custom: value => !isNaN(parseFloat(value.replace(/,/g, '.'))),
                              error: 'Bitte geben Sie eine Zahl ein',
                            },
                            {
                              custom: value => parseFloat(value.replace(/,/g, '.')) <= 10,
                              error: 'Der Wert darf nicht größer als 10 sein',
                            },
                            {
                              custom: value => parseFloat(value.replace(/,/g, '.')) > 0,
                              error: 'Der Wert muss größer als 0 sein',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('Generals', 'Hours')(value);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'Hours')}
                          name='Stundenänderung'
                        />
                        <Input
                          type='textarea'
                          label='Grund: '
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          onChange={value => {
                            this.dataContainer.set('Generals', 'Reason')(value);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'Reason')}
                          name='Grund'
                        />
                      </ContentBox>
                    </Form>
                  </div>
                  <RightSide>
                    <Button big icon={faTimes} to={this.parentRoute}>
                      Abbrechen
                    </Button>
                    <Button big type='primary' icon={faSave} onClick={this.save}>
                      Speichern
                    </Button>
                  </RightSide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(CorrectionRequestNew);
