import React from 'react';
import PropTypes from 'prop-types';

import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import RightSide from '../../../../../components/FormArea/RightSide';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Inputs/Input';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';

class PersonalAccessNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp('/new$'), '');

    this.dataContainer = new DataContainer();
    this.dataContainer.add('Basic', {
      Guid: '',
      Typ: 'PersonalAccess',
      UserGuid: this.props.UserGuid,
    });
    this.dataContainer.add('General', {
      CCR: '',
      Portal: '',
      Username: '',
      Password: '',
      URL: '',
      Comment: '',
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    // TODO: Switch to List of Datacontainers
    const newAccessData = {
      ParamName: 'Entry',
      Params: {
        ...this.dataContainer.get('Basic'),
        ...this.dataContainer.get('General'),
      },
    };

    reqwest({
      method: 'POST',
      url: settings.usermanagement.personalAccess.save,
      data: {
        Token: localStorage.getItem('token'),
        AccessData: JSON.stringify(newAccessData),
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Ihr Zugang wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Persönliche Zugänge'>
              <Input
                type='text'
                validator={[]}
                label='Kostenstelle:'
                onChange={value => {
                  this.dataContainer.set('General', 'CCR')(value);
                }}
                defaultValue={this.dataContainer.get('General', 'CCR')}
                name='Kostenstelle'
              />
              <Input
                type='text'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                label='Portal:'
                onChange={value => {
                  this.dataContainer.set('General', 'Portal')(value);
                }}
                defaultValue={this.dataContainer.get('General', 'Portal')}
                name='Portal'
              />
              <Input
                type='text'
                validator={[]}
                label='Benutzername:'
                onChange={value => {
                  this.dataContainer.set('General', 'Username')(value);
                }}
                defaultValue={this.dataContainer.get('General', 'Username')}
                name='Benutzername'
              />
              <Input
                type='text'
                validator={[]}
                label='Passwort:'
                onChange={value => {
                  this.dataContainer.set('General', 'Password')(value);
                }}
                defaultValue={this.dataContainer.get('General', 'Password')}
                name='Passwort'
              />
              <Input
                type='text'
                validator={[
                  {
                    type: 'url',
                    error: 'Bitte geben Sie eine valide URL ein.',
                  },
                ]}
                label='URL:'
                onChange={value => {
                  this.dataContainer.set('General', 'URL')(value);
                }}
                defaultValue={this.dataContainer.get('General', 'URL')}
                name='URL'
              />
              <Input
                type='textarea'
                validator={[]}
                label='Bemerkung:'
                onChange={value => {
                  this.dataContainer.set('General', 'Comment')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Comment')}
                name='Comment'
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

PersonalAccessNew.propTypes = {
  UserGuid: PropTypes.string.isRequired,
};

export default PersonalAccessNew;
