import reqwest from 'reqwest';
import settings from '../../settings';

const constants = {};
const getConstants = type => {
  return new Promise(async (resolve, reject) => {
    if (!type) {
      reject('Constants: "getConstants" needs to have one argument, while you provided none.');
    }

    if (!constants.hasOwnProperty(type)) {
      await reqwest({
        method: 'get',
        url: settings.constants.getList,
        data: {
          Token: localStorage.getItem('token'),
          typ: type,
        },
      })
        .then(typeResult => {
          let result = JSON.parse(typeResult);
          result = result
            .sort((a, b) => {
              if (a.Ord > b.Ord) return 1;
              if (a.Ord < b.Ord) return -1;
              return 0;
            })
            .map(element => {
              return {
                value: element.Guid,
                label: element.Name,
              };
            });

          constants[type] = result;
          resolve(constants[type]);
        })
        .fail(reject);
    }

    resolve(constants[type]);
  });
};

export default getConstants;
