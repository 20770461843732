import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../settings';

import RightSide from '../../../../components/FormArea/RightSide';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import ContentBox from '../../../../components/FormArea/ContentBox';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../logic/dataContainer';
import Dropdown from '../../../../components/Inputs/Dropdown';
import Input from '../../../../components/Inputs/Input';

class EMailMacroNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');

    this.state = {
      loading: true,
      EMailClients: [],
      EMailTemplates: [],
    };

    this.dataContainer = new DataContainer();
    this.dataContainer.add('Basic', {
      Guid: '',
      Typ: 'EMailMacro',
    });
    this.dataContainer.add('Generals', {
      Title: '',
      EMailTemplate: '',
      EMailClient: '',
      SenderAdress: '',
    });

    const EMailTemplatesPromise = reqwest({
      method: 'get',
      url: settings.systemmanagement.emailtemplate.getList,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
      },
    });

    const EMailClientsPromise = reqwest({
      method: 'get',
      url: settings.systemmanagement.emailclient.getList,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
      },
    });

    Promise.all([EMailTemplatesPromise, EMailClientsPromise]).then(([EMailTemplatesData, EMailClientsData]) => {
      this.setState({
        EMailTemplates: JSON.parse(EMailTemplatesData)
          .filter(emailclient => {
            return emailclient?.Params?.Guid !== '';
          })
          .map(EMailTemplate => ({
            value: EMailTemplate.Params.Guid,
            label: EMailTemplate.Params.Title,
          })),
        EMailClients: JSON.parse(EMailClientsData)
          .filter(emailclient => {
            return emailclient?.Params?.Guid !== '';
          })
          .map(EMailClient => ({
            value: EMailClient.Params.Guid,
            label: EMailClient.Params.ClientName,
          })),
        loading: false,
      });
    });
  }

  save = () => {
    const EmailMacro = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.systemmanagement.emailmacro.save,
      data: {
        Token: localStorage.getItem('token'),
        EmailMacro,
      },
    }).then(() => {
      swal({
        title: 'Erfolg!',
        text: 'Das E-Mail Makro wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='E-Mail Makro'>
            <Input
              type='text'
              label='Makro Title:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('Generals', 'Title')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'Title')}
              name='Title'
            />
            <Dropdown
              label='E-Mail Template:'
              isSearchable
              getSelected={value => {
                this.dataContainer.set('Generals', 'EMailTemplate')(value);
              }}
              validator={[]}
              defaultValue={this.dataContainer.get('Generals', 'EMailTemplate')}
              options={this.state.EMailTemplates}
              name='Template'
            />
            <Dropdown
              label='E-Mail Nutzer:'
              isSearchable
              getSelected={value => {
                this.dataContainer.set('Generals', 'EMailClient')(value);
              }}
              validator={[]}
              defaultValue={this.dataContainer.get('Generals', 'EMailClient')}
              options={this.state.EMailClients}
              name='Clients'
            />
            <Input
              type='text'
              label='Absender Adresse:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('Generals', 'SenderAdress')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'SenderAdress')}
              name='SenderAdress'
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default EMailMacroNew;
