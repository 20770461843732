import React, { Component } from 'react';
import moment from 'moment';
import { faTrash, faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import swal from 'sweetalert';
import { withRouter } from 'react-router-dom';
import reqwest from 'reqwest';

import Loader from '../../../../components/Loader';
import Form from '../../../../components/Form';
import Dropdown from '../../../../components/Inputs/Dropdown';
import Input from '../../../../components/Inputs/Input';
import ContentBox from '../../../../components/FormArea/ContentBox';
import Button from '../../../../components/Button';
import settings from '../../../../../settings';
import RightSide from '../../../../components/FormArea/RightSide';
import DataContainer from '../../../../logic/dataContainer';

class CorrectionRequestDetail extends Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/CorrectionRequest/${props.match.params.id}$`), '');
    this.state = {
      loading: true,
    };

    reqwest({
      method: 'GET',
      url: settings.azn.timetracking.get,
      data: {
        Guid: props.match.params.id,
      },
    }).then(data => {
      this.dataContainer = new DataContainer(data);
      this.setState({
        loading: false,
      });
    });

    const userData = JSON.parse(localStorage.getItem('userdata'));
    this.UserGuid = userData.userGuid;
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const data = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.azn.timetracking.save,
      data: {
        CorrectionRequest: data,
      },
    })
      .then(() => {
        swal({
          title: 'Erfolgreich!',
          text: 'Ihr Korrekturantrag wurde erfolgreich bearbeitet.',
          icon: 'success',
        });
        this.props.history.replace(this.parentRoute);
      })
      .catch(() => {
        this.props.history.replace(this.parentRoute);
        throw new Error('Something went very very wrong...');
      });
  };

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diesen Korrekturantrag wirklich löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'POST',
          url: settings.azn.timetracking.delete,
          data: {
            Guid: this.dataContainer('Basic', 'Guid'),
          },
        })
          .then(() => {
            swal({
              title: 'Erfolgreich!',
              text: 'Der Korrekturantrag wurde erfolgreich gelöscht.',
              icon: 'success',
            });
            this.props.history.replace(this.parentRoute);
          })
          .catch(() => {
            throw new Error('Something went very very wrong...');
          });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div className='FormArea'>
        <div className='FormArea-Top'>
          <p className='FormArea-Title'>Korrekturantrag</p>
        </div>
        <div className='FormArea-Body'>
          <div className='FormArea-Body-Content'>
            <div className='FormArea-Body-Content-Center'>
              <div className='FormArea-Form'>
                <div className='FormArea-Form-Left'>
                  <Form ref={node => (this.form = node)}>
                    <ContentBox title={`Korrekturantrag für den ${this.dataContainer.get('Generals', 'Date')}`}>
                      <Input
                        fullWidth
                        type='text'
                        label='Stundenänderung: '
                        validator={[
                          {
                            required: true,
                            error: 'Bitte füllen Sie dieses Feld aus',
                          },
                          {
                            custom: value => !isNaN(parseFloat(value.replace(/,/g, '.'))),
                            error: 'Bitte geben Sie eine Zahl ein',
                          },
                          {
                            custom: value => parseFloat(value.replace(/,/g, '.')) <= 10,
                            error: 'Der Wert darf nicht größer als 10 sein',
                          },
                          {
                            custom: value => parseFloat(value.replace(/,/g, '.')) > 0,
                            error: 'Der Wert muss größer als 0 sein',
                          },
                        ]}
                        onChange={value => {
                          this.dataContainer.set('Generals', 'Hours')(value);
                        }}
                        defaultValue={this.dataContainer.get('Generals', 'Hours')}
                        name='Stundenänderung'
                      />
                      <Input
                        type='textarea'
                        label='Grund: '
                        validator={[
                          {
                            required: true,
                            error: 'Bitte füllen Sie dieses Feld aus',
                          },
                        ]}
                        onChange={value => {
                          this.dataContainer.set('Generals', 'Reason')(value);
                        }}
                        defaultValue={this.dataContainer.get('Generals', 'Reason')}
                        name='Grund'
                      />
                    </ContentBox>
                    <ContentBox title='Genehmigen / Ablehnen'>
                      <Dropdown
                        label='Status: '
                        isDisabled={!this.dataContainer.get('EditorFields', 'CanEdit')}
                        isSearchable
                        getSelected={selected => {
                          this.dataContainer.set('EditorFields', 'Status')(selected);
                          this.dataContainer.set('EditorFields', 'Time')(moment().format('DD.MM.YYYY'));
                          this.dataContainer.set('EditorFields', 'Editor')(this.UserGuid);
                        }}
                        defaultValue={this.dataContainer.get('EditorFields', 'Status')}
                        options={[
                          { value: 'Beantragt', label: 'Beantragt' },
                          { value: 'Genehmigt', label: 'Genehmigt' },
                          { value: 'Abgelehnt', label: 'Abgelehnt' },
                        ]}
                        validator={[
                          {
                            required: true,
                            error: 'Bitte füllen Sie dieses Feld aus',
                          },
                        ]}
                        name='Status'
                      />
                      <Input
                        type='textarea'
                        label='Grund: '
                        validator={[
                          {
                            required: true,
                            error: 'Bitte füllen Sie dieses Feld aus',
                          },
                        ]}
                        isDisabled={!this.dataContainer.get('EditorFields', 'CanEdit')}
                        onChange={value => {
                          this.dataContainer.set('EditorFields', 'Cause')(value);
                          this.dataContainer.set('EditorFields', 'Time')(moment().format('DD.MM.YYYY'));
                          this.dataContainer.set('EditorFields', 'Editor')(this.UserGuid);
                        }}
                        defaultValue={this.dataContainer.get('General', 'Cause')}
                        name='Grund'
                      />
                    </ContentBox>
                  </Form>
                </div>
                <RightSide>
                  <Button big type='danger' icon={faTrash} onClick={this.delete}>
                    Löschen
                  </Button>
                  <Button big icon={faTimes} to={this.parentRoute}>
                    Abbrechen
                  </Button>
                  <Button big type='primary' icon={faSave} onClick={this.save}>
                    Speichern
                  </Button>
                </RightSide>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CorrectionRequestDetail);
