import swal from '@sweetalert/with-react';

const base64Downloader = (file, filename) => {
  if (!file) {
    swal({
      title: 'Fehler!',
      text:
        'Die Datei zum herunterladen fehlt, bitte versuchen Sie es erneut. Sollte es danach nicht funktionieren, wenden Sie sich bitte an den Systemadministrator.',
      icon: 'error',
    });
  }

  if (Array.isArray(file)) file = file[0];

  const link = document.createElement('a');

  const sliceSize = 512;
  const splittedB64 = file.split(',');
  const fileType = splittedB64[0].split(';')[0].split(':')[1];

  const byteCharacters = atob(splittedB64[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: fileType });
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};

export { base64Downloader };
export default base64Downloader;
