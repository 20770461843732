import React, { Component } from 'react';
import ReactTable from 'react-table';
import reqwest from 'reqwest';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import history from '../../history';
import settings from '../../../settings';
import Loader from '../../components/Loader';
import Searchbar from '../../components/Searchbar';
import Button from '../../components/Button';

class PromoterOverview extends Component {
  constructor(props) {
    super(props);

    let searchString = '';
    if (props.match.params.search) {
      searchString = props.match.params.search;
    }

    this.state = {
      loading: true,
      data: [],
      searchString,
    };
  }

  componentDidMount() {
    const promise = reqwest({
      method: 'get',
      url: settings.promoter.general.getList,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(res => {
      this.setState({
        data: JSON.parse(res),
      });
    });

    Promise.all([promise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  _search = value => {
    const val = value.toLowerCase();

    history.replace(val ? `/promotermanagement/search/${val}` : '/promotermanagement');

    this.setState({
      searchString: val,
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    let data = [];
    if (this.state.data[0].Params.Guid !== '') {
      data = this.state.data.filter(rowData =>
        [rowData.Params.Firstname, rowData.Params.Lastname]
          .map(e => e.toLowerCase())
          .some(v => {
            return v.indexOf(this.state.searchString) >= 0;
          }),
      );
      data.forEach((entry, index) => {
        data[index].Name = entry.Params.Lastname + ', ' + entry.Params.Firstname;
      });
    }

    return (
      <>
        <div className='Toolbar'>
          <Searchbar placeholder='Veranstalter suchen...' defaultValue={this.state.searchString} onChange={this._search} />
          <div className='Toolbar-buttons'>
            <Button icon={faPlus} to='/promotermanagement/new'>
              Veranstalter erstellen
            </Button>
          </div>
        </div>
        <ReactTable
          getTrProps={(_, { original }) => ({
            onClick: () => this.props.history.push(`/promotermanagement/${original.Params.Guid}`),
          })}
          data={data}
          pageSize={data.length}
          noDataText='Es wurden keine Veranstalter gefunden.'
          defaultSorted={[
            {
              id: 'Name',
              desc: false,
            },
          ]}
          columns={[
            {
              Header: 'Name',
              accessor: 'Name',
            },
          ]}
        />
      </>
    );
  }
}

export default PromoterOverview;
