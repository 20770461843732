import React from 'react';
import PropTypes from 'prop-types';

import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Inputs/Input';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import { faTrash, faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';

class PersonalAccessDetail extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');

    this.state = {
      loading: true,
    };

    reqwest({
      method: 'GET',
      url: settings.usermanagement.personalAccess.get,
      contentType: 'JSON',
      data: {
        Token: localStorage.getItem('token'),
        Guid: props.match.params.id,
      },
    }).then(personalAccessResponse => {
      this.dataContainer = new DataContainer(personalAccessResponse);

      this.setState({
        loading: false,
      });
    });
  }

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie den Zugang löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'GET',
          url: settings.usermanagement.personalAccess.delete,
          data: {
            Token: localStorage.getItem('token'),
            Guid: this.props.match.params.id,
          },
        }).then(() => {
          swal({
            title: 'Erfolgreich!',
            text: 'Ihr Zugang wurde erfolgreich gelöscht.',
            icon: 'success',
          });
          this.props.history.replace(this.parentRoute);
        });
      }
    });
  };

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    // TODO: Switch to List of Datacontainers
    const newAccess = {
      ParamName: 'Entry',
      Params: {
        ...this.dataContainer.get('Basic'),
        ...this.dataContainer.get('Generals'),
      },
    };

    reqwest({
      method: 'post',
      url: settings.usermanagement.personalAccess.save,
      data: {
        Token: localStorage.getItem('token'),
        AccessData: JSON.stringify(newAccess),
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Ihr Zugang wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Persönliche Zugänge'>
              <Input
                type='text'
                validator={[]}
                label='Kostenstelle:'
                onChange={value => {
                  this.dataContainer.set('General', 'CCR')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'CCR')}
                name='Kostenstelle'
              />
              <Input
                type='text'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                label='Portal:'
                onChange={value => {
                  this.dataContainer.set('General', 'Portal')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Portal')}
                name='Portal'
              />
              <Input
                type='text'
                validator={[]}
                label='Benutzername:'
                onChange={value => {
                  this.dataContainer.set('General', 'Username')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Username')}
                name='Benutzername'
              />
              <Input
                type='text'
                validator={[]}
                label='Passwort:'
                onChange={value => {
                  this.dataContainer.set('General', 'Password')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Password')}
                name='Passwort'
              />
              <Input
                type='text'
                validator={[
                  {
                    type: 'url',
                    error: 'Bitte geben Sie eine valide URL ein.',
                  },
                ]}
                label='URL:'
                onChange={value => {
                  this.dataContainer.set('General', 'URL')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'URL')}
                name='URL'
              />
              <Input
                type='textarea'
                validator={[]}
                label='Bemerkung:'
                onChange={value => {
                  this.dataContainer.set('General', 'Comment')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Comment')}
                name='Comment'
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide
          createDate={this.dataContainer.get('RightSide', 'CreateDate')}
          createUserName={this.dataContainer.get('RightSide', 'CreateUserName')}
          updateDate={this.dataContainer.get('RightSide', 'UpdateDate')}
          updateUserName={this.dataContainer.get('RightSide', 'UpdateUserName')}
        >
          <Button big type='danger' icon={faTrash} onClick={this.delete}>
            Löschen
          </Button>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

PersonalAccessDetail.propTypes = {
  UserGuid: PropTypes.string.isRequired,
};

export default PersonalAccessDetail;
