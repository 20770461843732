import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Default from './New';
import Detail from './Detail';

const Forgot = props => {
  const paths = {
    detail: `${props.match.path}/:accessToken`,
  };

  return (
    <Switch>
      <Route exact path={paths.detail} component={Detail} />
      <Route component={Default} />
    </Switch>
  );
};

export default withRouter(Forgot);
