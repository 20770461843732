import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import New from './New';
import Details from './Details';
import Overview from './Overview';

const Employees = props => {
  const paths = {
    details: `${props.match.path}/:id`,
    new: `${props.match.path}/new`,
  };

  return (
    <Switch>
      <Route exact path={paths.new} render={routerProps => <New {...routerProps} franchiseGuid={props.franchiseGuid} />} />
      <Route exact path={paths.details} render={routerProps => <Details {...routerProps} franchiseGuid={props.franchiseGuid} />} />
      <Route render={routerProps => <Overview {...routerProps} franchiseGuid={props.franchiseGuid} />} />
    </Switch>
  );
};

export default withRouter(Employees);
