import React, { Component } from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';

import Loader from '../../../../../components/Loader';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Input from '../../../../../components/Inputs/Input';
import Form from '../../../../../components/Form';
import Datepicker from '../../../../../components/Inputs/Datepicker';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import Switch from '../../../../../components/Inputs/Switch';
import RightSide from '../../../../../components/FormArea/RightSide';
import Button from '../../../../../components/Button';
import { faSave } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../../logic/dataContainer';
import getConstants from '../../../../../logic/constants';

class EDVDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      connectorType: [],
      mSNUsageType: [],
    };

    const dataPromise = reqwest({
      method: 'get',
      url: settings.office.edv.get,
      data: {
        OfficeGuid: this.props.OfficeGuid,
      },
    }).then(res => {
      this.DataContainer = new DataContainer(res);
    });

    const connectorPromise = getConstants('ConnectorType').then(connectorType => {
      this.setState({
        connectorType,
      });
    });

    const MSNUsagePromise = getConstants('MSNUsageType').then(mSNUsageType => {
      this.setState({
        mSNUsageType,
      });
    });

    Promise.all([dataPromise, connectorPromise, MSNUsagePromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const data = this.DataContainer.getStringified();

    reqwest({
      method: 'post',
      url: settings.office.edv.save,
      data: {
        Edv: data,
        OfficeGuid: this.props.OfficeGuid,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Die EDV wurde erfolgreich gespeichert.',
        icon: 'success',
      });
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            {this.DataContainer.get('Generals', 'CanSee') && (
              <ContentBox title='Kunden'>
                <Input
                  type='text'
                  label='Kundennummer:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('Generals', 'CustomerNumber')(value);
                  }}
                  defaultValue={this.DataContainer.get('Generals', 'CustomerNumber')}
                  name='Kundennummer'
                  isDisabled={!this.DataContainer.get('Generals', 'CanEdit')}
                />
                <Dropdown
                  label='Anschlusstyp:'
                  isSearchable
                  getSelected={value => {
                    this.DataContainer.set('Generals', 'ConnectorType')(value);
                  }}
                  defaultValue={this.DataContainer.get('Generals', 'ConnectorType')}
                  options={this.state.connectorType}
                  isDisabled={!this.DataContainer.get('Generals', 'CanEdit')}
                  validator={[]}
                  name='ConnectorType'
                />
                <Switch
                  label='Anlagenanschluss:'
                  onChange={value => {
                    this.DataContainer.set('Generals', 'EquipmentConnector')(value);
                  }}
                  name='Anlagenanschluss'
                  defaultChecked={!!this.DataContainer.get('Generals', 'EquipmentConnector')}
                  isDisabled={!this.DataContainer.get('Generals', 'CanEdit')}
                  validator={[]}
                />
                <Datepicker
                  label='Vertragskündigung am:'
                  onChange={day => {
                    this.DataContainer.set('Generals', 'CancelationDate')(day);
                  }}
                  defaultValue={this.DataContainer.get('Generals', 'CancelationDate')}
                  isDisabled={!this.DataContainer.get('Generals', 'CanEdit')}
                  validator={[]}
                  name='CancelDate'
                />
                <Datepicker
                  label='VK bestätigt zum:'
                  onChange={day => {
                    this.DataContainer.set('Generals', 'VK')(day);
                  }}
                  defaultValue={this.DataContainer.get('Generals', 'VK')}
                  isDisabled={!this.DataContainer.get('Generals', 'CanEdit')}
                  validator={[]}
                  name='VK'
                />
                <Input
                  type='text'
                  label='Monatliche Gebühren:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('Generals', 'MonthlyFee')(value);
                  }}
                  defaultValue={this.DataContainer.get('Generals', 'MonthlyFee')}
                  name='Monatliche Gebühren'
                  isDisabled={!this.DataContainer.get('Generals', 'CanEdit')}
                />
              </ContentBox>
            )}
            {this.DataContainer.get('MSN', 'CanSee') && (
              <ContentBox title='MSN'>
                <Input
                  type='text'
                  label='MSN Anschluss LFD:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('MSN', 'MSNPort')(value);
                  }}
                  defaultValue={this.DataContainer.get('MSN', 'MSNPort')}
                  name='MSN Anschluss LFD'
                  isDisabled={!this.DataContainer.get('MSN', 'CanEdit')}
                />
                <Input
                  type='text'
                  label='MSN Anschluss Nr:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('MSN', 'MSNPortNumber')(value);
                  }}
                  defaultValue={this.DataContainer.get('MSN', 'MSNPortNumber')}
                  name='MSN Anschluss Nr'
                  isDisabled={!this.DataContainer.get('MSN', 'CanEdit')}
                />
                <Input
                  type='text'
                  label='MSN Vertragsinhaber:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('MSN', 'MSNContractor')(value);
                  }}
                  defaultValue={this.DataContainer.get('MSN', 'MSNContractor')}
                  name='MSN Vertragsinhaber'
                  isDisabled={!this.DataContainer.get('MSN', 'CanEdit')}
                />
                <Input
                  type='text'
                  label='MSN Verrechnungsnr:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('MSN', 'MSNOffsetNumber')(value);
                  }}
                  defaultValue={this.DataContainer.get('MSN', 'MSNOffsetNumber')}
                  name='MSN Verrechnungsnr'
                  isDisabled={!this.DataContainer.get('MSN', 'CanEdit')}
                />
                <Input
                  type='text'
                  label='MSN Buchungskonto:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('MSN', 'MSNBookingNumber')(value);
                  }}
                  defaultValue={this.DataContainer.get('MSN', 'MSNBookingNumber')}
                  name='MSN Buchungskonto'
                  isDisabled={!this.DataContainer.get('MSN', 'CanEdit')}
                />
                <Switch
                  label='MSN DSL:'
                  onChange={value => {
                    this.DataContainer.set('MSN', 'MSNDsl')(value);
                  }}
                  name='MSN DSL'
                  defaultChecked={!!this.DataContainer.get('MSN', 'MSNDsl')}
                  isDisabled={!this.DataContainer.get('MSN', 'CanEdit')}
                  validator={[]}
                />
                <Dropdown
                  label='MSN Nutzung:'
                  isSearchable
                  getSelected={value => {
                    this.DataContainer.set('MSN', 'MSNUsage')(value);
                  }}
                  defaultValue={this.DataContainer.get('MSN', 'MSNUsage')}
                  placeholderName='MSN Nutzung'
                  options={this.state.mSNUsageType}
                  isDisabled={!this.DataContainer.get('MSN', 'CanEdit')}
                  validator={[]}
                  name='MSNUsage'
                />
              </ContentBox>
            )}
            {this.DataContainer.get('VPN', 'CanSee') && (
              <ContentBox title='VPN'>
                <Input
                  type='text'
                  label='VPN Bestätigt:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('VPN', 'VPNApproved')(value);
                  }}
                  defaultValue={this.DataContainer.get('VPN', 'VPNApproved')}
                  name='VPN Bestätigt'
                  isDisabled={!this.DataContainer.get('VPN', 'CanEdit')}
                />
                <Input
                  type='text'
                  label='VPN Anbieter:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('VPN', 'VPNProvider')(value);
                  }}
                  defaultValue={this.DataContainer.get('VPN', 'VPNProvider')}
                  name='VPN Anbieter'
                  isDisabled={!this.DataContainer.get('VPN', 'CanEdit')}
                />
                <Input
                  type='text'
                  label='VPN Kd Nr:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('VPN', 'VPNUser')(value);
                  }}
                  defaultValue={this.DataContainer.get('VPN', 'VPNUser')}
                  name='VPN Kd Nr'
                  isDisabled={!this.DataContainer.get('VPN', 'CanEdit')}
                />
              </ContentBox>
            )}
            {this.DataContainer.get('DSL', 'CanSee') && (
              <ContentBox title='DSL'>
                <Input
                  type='text'
                  label='DSL:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('DSL', 'DSL')(value);
                  }}
                  defaultValue={this.DataContainer.get('DSL', 'DSL')}
                  name='DSL'
                  isDisabled={!this.DataContainer.get('DSL', 'CanEdit')}
                />
                <Datepicker
                  label='DSL Seit:'
                  onChange={day => {
                    this.DataContainer.set('DSL', 'DSLFrom')(day);
                  }}
                  defaultValue={this.DataContainer.get('DSL', 'DSLFrom')}
                  isDisabled={!this.DataContainer.get('DSL', 'CanEdit')}
                  validator={[]}
                  name='DSLFrom'
                />
                <Input
                  type='text'
                  label='DSL User Kennung:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('DSL', 'DSLUserID')(value);
                  }}
                  defaultValue={this.DataContainer.get('DSL', 'DSLUserID')}
                  name='DSL User Kennung'
                  isDisabled={!this.DataContainer.get('DSL', 'CanEdit')}
                />
                <Input
                  type='text'
                  label='DSL Passwort:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('DSL', 'DSLPassword')(value);
                  }}
                  defaultValue={this.DataContainer.get('DSL', 'DSLPassword')}
                  name='DSL Passwort'
                  isDisabled={!this.DataContainer.get('DSL', 'CanEdit')}
                />
                <Input
                  type='text'
                  label='DSL Vertrag:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('DSL', 'DSLContract')(value);
                  }}
                  defaultValue={this.DataContainer.get('DSL', 'DSLContract')}
                  name='DSL Vertrag'
                  isDisabled={!this.DataContainer.get('DSL', 'CanEdit')}
                />
                <Input
                  type='text'
                  label='DSL Anmelder:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('DSL', 'DSLDeclarant')(value);
                  }}
                  defaultValue={this.DataContainer.get('DSL', 'DSLDeclarant')}
                  name='DSL Anmelder'
                  isDisabled={!this.DataContainer.get('DSL', 'CanEdit')}
                />
              </ContentBox>
            )}
            {this.DataContainer.get('QSCCEP', 'CanSee') && (
              <ContentBox title='QSC / CEP'>
                <Input
                  type='text'
                  label='QSC / CEP:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('QSCCEP', 'QSCCEP')(value);
                  }}
                  defaultValue={this.DataContainer.get('QSCCEP', 'QSCCEP')}
                  name='QSC / CEP'
                  isDisabled={!this.DataContainer.get('QSCCEP', 'CanEdit')}
                />
              </ContentBox>
            )}
            {this.DataContainer.get('EinsUndEins', 'CanSee') && (
              <ContentBox title='1&1'>
                <Input
                  type='text'
                  label='1&1 POP Kontoname:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('EinsUndEins', 'EinsEinsPopAccountname')(value);
                  }}
                  defaultValue={this.DataContainer.get('EinsUndEins', 'EinsEinsPopAccountname')}
                  name='1&1 POP Kontoname'
                  isDisabled={!this.DataContainer.get('EinsUndEins', 'CanEdit')}
                />
                <Input
                  type='text'
                  label='1&1 POP Kontopasswort:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('EinsUndEins', 'EinsEinsPopPassword')(value);
                  }}
                  defaultValue={this.DataContainer.get('EinsUndEins', 'EinsEinsPopPassword')}
                  name='1&1 POP Kontopasswort'
                  isDisabled={!this.DataContainer.get('EinsUndEins', 'CanEdit')}
                />
              </ContentBox>
            )}
            {this.DataContainer.get('Mails', 'CanSee') && (
              <ContentBox title='Mails'>
                <Input
                  type='text'
                  label='Alternative Büro Email:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('Mails', 'AllOfficeEmail')(value);
                  }}
                  defaultValue={this.DataContainer.get('Mails', 'AllOfficeEmail')}
                  name='Alternative Büro Email'
                  isDisabled={!this.DataContainer.get('Mails', 'CanEdit')}
                />
                <Input
                  type='text'
                  label='Bemerkungen:'
                  validator={[]}
                  onChange={value => {
                    this.DataContainer.set('Mails', 'Commit')(value);
                  }}
                  defaultValue={this.DataContainer.get('Mails', 'Commit')}
                  name='Bemerkungen'
                  isDisabled={!this.DataContainer.get('Mails', 'CanEdit')}
                />
              </ContentBox>
            )}
          </Form>
        </div>
        <RightSide
          createDate={this.DataContainer.get('RightSide', 'CreateDate')}
          createUserName={this.DataContainer.get('RightSide', 'CreateUserName')}
          updateDate={this.DataContainer.get('RightSide', 'UpdateDate')}
          updateUserName={this.DataContainer.get('RightSide', 'UpdateUserName')}
        >
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default EDVDetail;
