import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Inputs/Input';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import { faTrash, faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';

class IventoryModuleDetails extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');

    this.state = {
      loading: true,
      fields: [],
      Basic: {},
      Generals: {},
      RightSide: {},
    };

    const groupDataPromise = reqwest({
      method: 'get',
      url: settings.inventory.GetGroupData,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
        OfficeGuid: this.props.OfficeGuid,
        GroupGuid: this.props.GroupGuid,
      },
    }).then(groupDataResponse => {
      const groupData = JSON.parse(groupDataResponse);
      return {
        Basic: groupData[0][0].Params,
        Generals: groupData[0][1].Params,
        RightSide: groupData[0][2].Params,
      };
    });

    const fieldsPromise = reqwest({
      method: 'get',
      url: settings.inventory.GetFields,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
        OfficeGuid: this.props.OfficeGuid,
        GroupGuid: this.props.GroupGuid,
      },
    }).then(fieldsResponse => {
      let fields = JSON.parse(fieldsResponse);

      fields.sort((a, b) => {
        if (a[1].Params.Weight > b[1].Params.Weight) return 1;
        if (a[1].Params.Weight < b[1].Params.Weight) return -1;
        return 0;
      });
      return fields;
    });

    Promise.all([groupDataPromise, fieldsPromise]).then(([groupData, fields]) => {
      this.setState({
        ...groupData,
        fields,
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const data = JSON.stringify([
      { ParamName: 'Basic', Params: this.state.Basic },
      { ParamName: 'Generals', Params: this.state.Generals },
      { ParamName: 'RightSide', Params: this.state.RightSide },
    ]);

    reqwest({
      method: 'post',
      url: settings.inventory.SaveGroupData,
      data: {
        Token: localStorage.getItem('token'),
        GroupData: data,
        OfficeGuid: this.props.OfficeGuid,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Ihr Datensatz wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diesen Datensatz wirklich löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'POST',
          url: settings.inventory.Delete,
          data: {
            Token: localStorage.getItem('token'),
            Guid: this.state.Basic.Guid,
          },
        }).then(() => {
          swal({
            title: 'Erfolgreich!',
            text: 'Ihr Datensatz wurde erfolgreich gelöscht.',
            icon: 'success',
          });
          this.props.history.replace(this.parentRoute);
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='General'>
              {!!this.state.fields.length &&
                this.state.fields.map((field, index) => (
                  <Input
                    key={index}
                    type='text'
                    label={field[1].Params.Name}
                    fullWidth
                    validator={[
                      {
                        required: true,
                        error: 'Bitte füllen Sie dieses Feld aus',
                      },
                    ]}
                    onChange={value => {
                      this.setState(prevState => ({
                        ...prevState,
                        Generals: {
                          ...prevState.Generals,
                          ['guid-' + field[0].Params.Guid]: value,
                        },
                      }));
                    }}
                    defaultValue={this.state.Generals['guid-' + field[0].Params.Guid]}
                    name={field[1].Params.Name}
                  />
                ))}
            </ContentBox>
          </Form>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='danger' icon={faTrash} onClick={this.delete}>
            Löschen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default IventoryModuleDetails;
