import React, { Component } from 'react';
import swal from '@sweetalert/with-react';
import { faTrash, faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import reqwest from 'reqwest';

import Loader from '../../../components/Loader';
import Form from '../../../components/Form';
import Datepicker from '../../../components/Inputs/Datepicker';
import Dropdown from '../../../components/Inputs/Dropdown';
import ContentBox from '../../../components/FormArea/ContentBox';
import Button from '../../../components/Button';
import settings from '../../../../settings';
import RightSide from '../../../components/FormArea/RightSide';
import getConstants from '../../../logic/constants';
import DataContainer from '../../../logic/dataContainer';

class SickDaysDetail extends Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');

    this.state = {
      loading: true,

      sickTypes: [],
      notificationTypes: [],
    };

    const sickTypesPromise = getConstants('SickType').then(sickTypes => {
      this.setState({
        sickTypes,
      });
    });

    const notificationTypesPromise = getConstants('NotificationType').then(notificationTypes => {
      this.setState({
        notificationTypes,
      });
    });

    const sickDataPromise = reqwest({
      method: 'GET',
      url: settings.sick.get,
      data: {
        Guid: props.match.params.id,
      },
    }).then(data => {
      this.dataContainer = new DataContainer(data);
    });

    Promise.all([sickTypesPromise, notificationTypesPromise, sickDataPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });

    const userData = JSON.parse(localStorage.getItem('userdata'));

    this.UserGuid = userData.userGuid;
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    reqwest({
      method: 'POST',
      url: settings.sick.save,
      data: {
        Sick: this.dataContainer.getStringified(),
      },
    })
      .then(() => {
        swal({
          title: 'Erfolgreich!',
          text: 'Krankheitstag wurde erfolgreich bearbeitet.',
          icon: 'success',
        });
        this.props.history.replace(this.parentRoute);
      })
      .catch(() => {
        throw new Error('Something went very very wrong...');
      });
  };

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diesen Krankheitstag wirklich löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'GET',
          url: settings.sick.delete,
          data: {
            Guid: this.dataContainer.get('Basic', 'Guid'),
          },
        })
          .then(() => {
            swal({
              title: 'Erfolgreich!',
              text: 'Krankheitstag wurde erfolgreich gelöscht.',
              icon: 'success',
            });
            this.props.history.replace(this.parentRoute);
          })
          .catch(() => {
            throw new Error('Something went very very wrong...');
          });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>Krankheitstag</p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <div className='FormArea-Form-Left'>
                    <Form ref={node => (this.form = node)}>
                      <ContentBox title='Übersicht'>
                        <Datepicker
                          label='Von: '
                          onChange={date => {
                            this.dataContainer.set('Generals', 'From')(date);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'From')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='From'
                        />
                        <Datepicker
                          label='Bis: '
                          onChange={date => {
                            this.dataContainer.set('Generals', 'To')(date);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'To')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='To'
                        />
                        <Dropdown
                          label='Krankheitsart: '
                          isSearchable
                          getSelected={value => {
                            this.dataContainer.set('Generals', 'SickType')(value);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'SickType')}
                          options={this.state.sickTypes}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='SickType'
                        />
                        <Dropdown
                          label='Meldungsart: '
                          isSearchable
                          getSelected={value => {
                            this.dataContainer.set('Generals', 'NotificationType')(value);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'NotificationType')}
                          options={this.state.notificationTypes}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='NotificationType'
                        />
                        <Datepicker
                          label='Gemeldet am: '
                          onChange={date => {
                            this.dataContainer.set('Generals', 'NotificationTime')(date);
                          }}
                          defaultValue={this.dataContainer.get('Generals', 'NotificationTime')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          name='NotificationTime'
                        />
                      </ContentBox>
                    </Form>
                  </div>
                  <RightSide
                    createDate={this.dataContainer.get('RightSide', 'CreateDate')}
                    createUserName={this.dataContainer.get('RightSide', 'CreateUserName')}
                    updateDate={this.dataContainer.get('RightSide', 'UpdateDate')}
                    updateUserName={this.dataContainer.get('RightSide', 'UpdateUserName')}
                  >
                    <Button big type='danger' icon={faTrash} onClick={this.delete}>
                      Löschen
                    </Button>
                    <Button big icon={faTimes} to={this.parentRoute}>
                      Abbrechen
                    </Button>
                    <Button big type='primary' icon={faSave} onClick={this.save}>
                      Speichern
                    </Button>
                  </RightSide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SickDaysDetail);
