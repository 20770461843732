import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Input from '../../../../../components/Inputs/Input';
import Datepicker from '../../../../../components/Inputs/Datepicker';
import { faTrash, faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../../logic/dataContainer';

class FixedCostsDetails extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');

    this.state = {
      loading: true,
    };

    const fixedCostsPromise = reqwest({
      method: 'GET',
      url: settings.office.fixedCosts.get,
      contentType: 'JSON',
      data: {
        Token: localStorage.getItem('token'),
        Guid: props.match.params.id,
      },
    }).then(fixedCostsResponse => {
      this.container = new DataContainer(fixedCostsResponse);
    });

    Promise.all([fixedCostsPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const newFixedCosts = this.container.getStringified();

    reqwest({
      method: 'POST',
      url: settings.office.fixedCosts.save,
      data: {
        Token: localStorage.getItem('token'),
        FixedCostsData: newFixedCosts,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Ihre Ausbuchung wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diese Ausbuchung wirklich löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'GET',
          url: settings.office.fixedCosts.delete,
          data: {
            Guid: this.container.get('Basic', 'Guid'),
          },
        }).then(() => {
          swal({
            title: 'Erfolgreich!',
            text: 'Ihre Ausbuchung wurde erfolgreich gelöscht.',
            icon: 'success',
          });
          this.props.history.replace(this.parentRoute);
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Fixkosten'>
              <Input
                type='text'
                label='Vermieter:'
                validator={[]}
                onChange={value => {
                  this.container.set('Generals', 'Landlord')(value);
                }}
                defaultValue={this.container.get('Generals', 'Landlord')}
                name='Vermieter'
              />
              <Datepicker
                label='Frist:'
                defaultValue={this.container.get('Generals', 'Deadline')}
                onChange={day => {
                  this.container.set('Generals', 'Deadline')(day);
                }}
                validator={[]}
                name='Deadline'
              />
              <Input
                type='text'
                label='Miete:'
                validator={[]}
                onChange={value => {
                  this.container.set('Generals', 'Rent')(value);
                }}
                defaultValue={this.container.get('Generals', 'Rent')}
                name='Miete'
              />
              <Datepicker
                label='Kü.-/Op.-Frist:'
                defaultValue={this.container.get('Generals', 'OpDeadline')}
                onChange={day => {
                  this.container.set('Generals', 'OpDeadline')(day);
                }}
                validator={[]}
                name='OpDeadline'
              />
              <Input
                type='text'
                label='Untervermietung:'
                validator={[]}
                onChange={value => {
                  this.container.set('Generals', 'Sublet')(value);
                }}
                defaultValue={this.container.get('Generals', 'Sublet')}
                name='Untervermietung'
              />
              <Input
                type='text'
                label='NK:'
                validator={[]}
                onChange={value => {
                  this.container.set('Generals', 'Nk')(value);
                }}
                defaultValue={this.container.get('Generals', 'Nk')}
                name='NK'
              />
              <Input
                type='text'
                label='WK:'
                validator={[]}
                onChange={value => {
                  this.container.set('Generals', 'Wk')(value);
                }}
                defaultValue={this.container.get('Generals', 'Wk')}
                name='WK'
              />
              <Input
                type='text'
                label='Ansprechpartner:'
                validator={[]}
                onChange={value => {
                  this.container.set('Generals', 'ContactPerson')(value);
                }}
                defaultValue={this.container.get('Generals', 'ContactPerson')}
                name='Ansprechpartner'
              />
              <Input
                type='text'
                label='Telefon:'
                validator={[]}
                onChange={value => {
                  this.container.set('Generals', 'Phone')(value);
                }}
                defaultValue={this.container.get('Generals', 'Phone')}
                name='Telefon'
              />
              <Input
                type='textarea'
                label='Bemerkung:'
                validator={[]}
                onChange={value => {
                  this.container.set('Generals', 'Comment')(value);
                }}
                defaultValue={this.container.get('Generals', 'Comment')}
                name='Bemerkung'
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide>
          <Button big type='danger' icon={faTrash} onClick={this.delete}>
            Löschen
          </Button>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default FixedCostsDetails;
