import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../settings';

import RightSide from '../../../../components/FormArea/RightSide';
import Form from '../../../../components/Form';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import ContentBox from '../../../../components/FormArea/ContentBox';
import Input from '../../../../components/Inputs/Input';
import Dropdown from '../../../../components/Inputs/Dropdown';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../logic/dataContainer';

class RightsNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');

    this.state = {
      loading: true,
      sections: [],
    };

    reqwest({
      method: 'GET',
      url: settings.permissions.GetApplicationSections,
      data: {
        Token: localStorage.getItem('token'),
      },
    }).then(applicationSectionsResult => {
      this.sectionContainer = new DataContainer(applicationSectionsResult);
      applicationSectionsResult = JSON.parse(applicationSectionsResult);

      this.setState({
        loading: false,
        sections: applicationSectionsResult.map(section => ({
          value: section.Params.Guid,
          label: section.Params.Name,
        })),
      });
    });

    this.dataContainer = new DataContainer();
    this.dataContainer.add('PermissionEntry', {
      Name: '',
      Bemerkung: '',
      Section: '',
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const Right = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.permissions.AddRight,
      data: {
        Token: localStorage.getItem('token'),
        Right,
      },
    }).then(saveResult => {
      saveResult = JSON.parse(saveResult);

      if (saveResult[0].Params.SaveResult === 'Saved') {
        swal({
          title: 'Erfolg!',
          text: 'Das Recht wurde erfolgreich angelegt.',
          icon: 'success',
        });
        this.props.history.replace(this.parentRoute);
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Neues Recht'>
              <Input
                type='text'
                label='Name:'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                onChange={value => {
                  this.dataContainer.set('PermissionEntry', 'Name')(value);
                }}
                defaultValue={this.dataContainer.get('PermissionEntry', 'Name')}
                name='Name'
              />
              <Input
                type='text'
                label='Bemerkung:'
                validator={[]}
                onChange={value => {
                  this.dataContainer.set('PermissionEntry', 'Bemerkung')(value);
                }}
                defaultValue={this.dataContainer.get('PermissionEntry', 'Bemerkung')}
                name='Bemerkung'
              />
              <Dropdown
                label='Anwendungssektion:'
                fullWidth
                isSearchable
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                getSelected={value => {
                  const name = this.state.sections.find(sec => sec.value === value).label;
                  this.dataContainer.set('PermissionEntry', 'Section')(name);
                }}
                defaultValue={this.dataContainer.get('PermissionEntry', 'Section')}
                options={this.state.sections}
                name='Section'
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default RightsNew;
