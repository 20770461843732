import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../settings';

import RightSide from '../../../../components/FormArea/RightSide';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import ContentBox from '../../../../components/FormArea/ContentBox';
import Input from '../../../../components/Inputs/Input';
import Dropdown from '../../../../components/Inputs/Dropdown';
import { faTimes, faSave, faTrash } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../logic/dataContainer';

class EMailClientDetails extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');

    this.state = {
      loading: true,
    };

    const EMailClientPromise = reqwest({
      method: 'GET',
      url: settings.systemmanagement.emailclient.get,
      contentType: 'JSON',
      data: {
        Token: localStorage.getItem('token'),
        Guid: props.match.params.id,
      },
    }).then(eMailClientResponse => {
      this.dataContainer = new DataContainer(eMailClientResponse);
    });

    const ProtocolPromise = reqwest({
      method: 'GET',
      url: settings.constants.getList,
      data: {
        Token: localStorage.getItem('token'),
        typ: 'EMailProtocols',
      },
    }).then(protocols => {
      const protocolsJson = JSON.parse(protocols);
      const result = protocolsJson.map(protocol => ({
        value: protocol.Guid,
        label: protocol.Name,
      }));

      this.setState({
        protocols: result,
      });
    });

    Promise.all([EMailClientPromise, ProtocolPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  save = () => {
    const EMailClient = this.dataContainer.getStringified();

    reqwest({
      method: 'POST',
      url: settings.systemmanagement.emailclient.save,
      data: {
        Token: localStorage.getItem('token'),
        EMailClient,
      },
    }).then(() => {
      swal({
        title: 'Erfolg!',
        text: 'Der E-Mail Nutzer wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diesen E-Mail Nutzer wirklich löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'POST',
          url: settings.systemmanagement.emailclient.delete,
          data: {
            Guid: this.dataContainer.get('Basic', 'Guid'),
          },
        }).then(() => {
          swal({
            title: 'Erfolg!',
            text: 'Der E-Mail Nutzer wurde erfolgreich gelöscht.',
            icon: 'success',
          });
          this.props.history.replace(this.parentRoute);
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <ContentBox title='E-Mail Nutzer'>
            <Dropdown
              label='Protokoll:'
              isSearchable
              getSelected={value => {
                this.dataContainer.set('Generals', 'Protocol')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'Protocol')}
              options={this.state.protocols}
            />
            <Input
              type='text'
              label='UserName:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('Generals', 'ClientName')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'ClientName')}
              name='ClientName'
            />
            <Input
              type='text'
              label='Passwort:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('Generals', 'ClientPassword')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'ClientPassword')}
              name='ClientPassword'
            />
            <Input
              type='text'
              label='Host:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('Generals', 'Host')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'Host')}
              name='Host'
            />
            <Input
              type='text'
              label='Port:'
              validator={[]}
              onChange={value => {
                this.dataContainer.set('Generals', 'Port')(value);
              }}
              defaultValue={this.dataContainer.get('Generals', 'Port')}
              name='Port'
            />
          </ContentBox>
        </div>
        <RightSide>
          <Button big type='danger' icon={faTrash} onClick={this.delete}>
            Löschen
          </Button>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default EMailClientDetails;
