import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import New from './New';
import Details from './Details';
import Overview from './Overview';

const Retirement = props => {
  const paths = {
    details: `${props.match.path}/:id`,
    new: `${props.match.path}/new`,
  };

  return (
    <Switch>
      <Route exact path={paths.new} render={routerProps => <New {...routerProps} OfficeGuid={props.OfficeGuid} />} />
      <Route exact path={paths.details} render={routerProps => <Details {...routerProps} OfficeGuid={props.OfficeGuid} />} />
      <Route render={routerProps => <Overview {...routerProps} OfficeGuid={props.OfficeGuid} />} />
    </Switch>
  );
};

export default withRouter(Retirement);
