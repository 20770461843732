import React, { Component } from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';

import Loader from '../../../../../components/Loader';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Row from '../../../../../components/FormArea/Row';
import RightSide from '../../../../../components/FormArea/RightSide';
import Button from '../../../../../components/Button';
import { faTimes, faCheck } from '@fortawesome/pro-solid-svg-icons';
import DataContainer from '../../../../../logic/dataContainer';

class RegistrationDetail extends Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');

    this.state = {
      loading: true,
    };

    const dataPromise = reqwest({
      method: 'GET',
      url: settings.trainings.registrations.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: this.props.match.params.id,
      },
    }).then(res => {
      this.container = new DataContainer(res);
    });

    Promise.all([dataPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  accept = () => {
    this.container.set('General', 'Status')('genehmigt');
    const Registration = this.container.getStringified();

    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diese Schulung / Inforeise wirklich genehmigen?',
      icon: 'info',
      buttons: ['Abbrechen', 'Ja, jetzt genehmigen'],
    }).then(response => {
      if (response) {
        this.setState({
          loading: true,
        });
        reqwest({
          method: 'POST',
          url: settings.trainings.registrations.save,
          data: {
            Token: localStorage.getItem('token'),
            Registration,
          },
        })
          .then(() => {
            swal({
              title: 'Erfolgreich!',
              text: 'Die Schulung / Inforeise wurde genehmigt.',
              icon: 'success',
            });
            this.props.history.replace(this.parentRoute);
            this.setState({
              loading: false,
            });
          })
          .fail(() => {
            swal({
              title: 'Fehler...',
              text: 'Da ist etwas schief gelaufen. Die Schulung / Inforeise wurde nicht genehmigt. Versuchen Sie es später noch einmal...',
              icon: 'error',
            });
            this.setState({
              loading: false,
            });
          });
      }
    });
  };

  decline = () => {
    this.container.set('General', 'Status')('abgelehnt');

    const Registration = this.container.getStringified();
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diese Schulung / Inforeise wirklich ablehnen?',
      icon: 'info',
      buttons: ['Abbrechen', 'Ja, jetzt ablehnen'],
    }).then(response => {
      if (response) {
        this.setState({
          loading: true,
        });
        reqwest({
          method: 'POST',
          url: settings.trainings.registrations.save,
          data: {
            Token: localStorage.getItem('token'),
            Registration,
          },
        })
          .then(() => {
            swal({
              title: 'Erfolgreich!',
              text: 'Die Schulung / Inforeise wurde abgelehnt.',
              icon: 'success',
            });
            this.props.history.replace(this.parentRoute);
            this.setState({
              loading: false,
            });
          })
          .fail(() => {
            swal({
              title: 'Fehler...',
              text: 'Da ist etwas schief gelaufen. Die Schulung / Inforeise wurde nicht abgelehnt. Versuchen Sie es später noch einmal...',
              icon: 'error',
            });
            this.setState({
              loading: false,
            });
          });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div style={{ width: '100%' }}>
          <ContentBox>
            <Row label='Workshop / Inforeise:'>{this.container.get('General', 'Title')}</Row>
            <Row label='Von:'>{this.container.get('General', 'From')}</Row>
            <Row label='Bis:'>{this.container.get('General', 'To')}</Row>
            <Row label='Stunden:'>{this.container.get('General', 'Hours')}</Row>
            <br />
            <Row label='ExpNr:'>{this.container.get('General', 'Expnr')}</Row>
            <Row label='Vorname:'>{this.container.get('General', 'Firstname')}</Row>
            <Row label='Nachname:'>{this.container.get('General', 'Lastname')}</Row>
            <Row label='Status:'>{this.container.get('General', 'Status')}</Row>
          </ContentBox>
        </div>
        <RightSide
          createDate={this.container.get('RightSide', 'CreateDate')}
          createUserName={this.container.get('RightSide', 'CreateUserName')}
          updateDate={this.container.get('RightSide', 'UpdateDate')}
          updateUserName={this.container.get('RightSide', 'UpdateUserName')}
        >
          <Button big to={this.parentRoute}>
            Abbrechen
          </Button>
          {this.container.get('General', 'Status') === 'beantragt' && (
            <>
              <Button big type='danger' icon={faTimes} onClick={this.decline}>
                Ablehnen
              </Button>
              <Button big type='primary' icon={faCheck} onClick={this.accept}>
                Genehmigen
              </Button>
            </>
          )}
        </RightSide>
      </>
    );
  }
}

export default RegistrationDetail;
