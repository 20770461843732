import React from 'react';
import PropTypes from 'prop-types';

import 'froala-editor/js/froala_editor.pkgd.min';
import 'froala-editor/js/plugins/word_paste.min';
import 'froala-editor/js/plugins/font_size.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/languages/de';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/plugins/image.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

// import FroalaEditor from 'react-froala-wysiwyg';
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

import settings from '../../../settings';

import RenderHTML from '../RenderHTML';

// Stolen from KatS-Plan
class Editor extends React.Component {
  constructor(props) {
    super(props);
    const defaultConfig = {
      htmlAllowedStyleProps: [
        'font-size',
        'font-weight',
        'font-style',
        'text-decoration',
        'line-height',
        'background',
        'color',
        'width',
        'text-align',
        'vertical-align',
        'background-color',
      ],
      htmlAllowedAttrs: [
        'accept',
        'accept-charset',
        'accesskey',
        'action',
        // 'align',
        'allowfullscreen',
        'allowtransparency',
        'alt',
        'aria-.*',
        'async',
        'autocomplete',
        'autofocus',
        'autoplay',
        'autosave',
        'background',
        'bgcolor',
        'border',
        'charset',
        'cellpadding',
        'cellspacing',
        'checked',
        'cite',
        'class',
        'color',
        'cols',
        'colspan',
        'content',
        'contenteditable',
        'contextmenu',
        'controls',
        'coords',
        'data',
        'data-.*',
        'datetime',
        'default',
        'defer',
        'dir',
        'dirname',
        'disabled',
        'download',
        'draggable',
        'dropzone',
        'enctype',
        'for',
        'form',
        'formaction',
        'frameborder',
        'headers',
        'height',
        'hidden',
        'high',
        'href',
        'hreflang',
        'http-equiv',
        'icon',
        // 'id',
        'ismap',
        'itemprop',
        'keytype',
        'kind',
        'label',
        'lang',
        'language',
        'list',
        'loop',
        'low',
        'max',
        'maxlength',
        'media',
        'method',
        'min',
        'mozallowfullscreen',
        'multiple',
        'muted',
        'name',
        'novalidate',
        'open',
        'optimum',
        'pattern',
        'ping',
        'placeholder',
        'playsinline',
        'poster',
        'preload',
        'pubdate',
        'radiogroup',
        'readonly',
        'rel',
        'required',
        'reversed',
        'rows',
        'rowspan',
        'sandbox',
        'scope',
        'scoped',
        'scrolling',
        'seamless',
        'selected',
        'shape',
        'size',
        'sizes',
        'span',
        'src',
        'srcdoc',
        'srclang',
        'srcset',
        'start',
        'step',
        'summary',
        'spellcheck',
        'style',
        'tabindex',
        'target',
        'title',
        'type',
        'translate',
        'usemap',
        'value',
        'valign',
        'webkitallowfullscreen',
        'width',
        'wrap',
      ],
      pasteAllowedStyleProps: [
        'font-size',
        'font-weight',
        'font-style',
        'text-decoration',
        'line-height',
        'background',
        'color',
        'width',
        'text-align',
        'vertical-align',
        'background-color',
      ],
      pasteDeniedAttrs: ['align', 'id'],
      key: settings.external.froala_key,
      zIndex: 1000,
      // toolbarInline: true,
      charCounterCount: false,
      language: 'de',
      autofocus: false,
      quickInsertButtons: ['table', 'ul', 'ol', 'hr'],
      tableEditButtons: [
        'tableHeader',
        '|',
        'tableRows',
        'tableColumns',
        'tableCells',
        '|',
        'tableCellBackground',
        'tableCellVerticalAlign',
        'tableCellHorizontalAlign',
        '|',
        'tableRemove',
      ],
      toolbarButtons: [
        'paragraphFormat',
        '|',
        // 'fullscreen',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        '|',
        // 'subscript',
        // 'superscript',
        // 'fontFamily',
        'fontSize',
        // 'inlineClass',
        // 'inlineStyle',
        // 'paragraphStyle',
        // 'lineHeight',
        'align',
        'formatOL',
        'formatUL',
        'outdent',
        'indent',
        'quote',
        '|',
        'color',
        '-',
        'insertLink',
        'insertImage',
        // 'insertVideo',
        // 'embedly',
        // 'insertFile',
        'insertTable',
        // 'emoticons',
        // 'specialCharacters',
        'insertHR',
        // 'selectAll',
        // '|',
        // 'print',
        // 'getPDF',
        'spellChecker',
        // 'help',
        // 'html',
        '|',
        'clearFormatting',
        'undo',
        'redo',
      ],
      attribution: false,
      events: {
        initialized: () => {
          this.props.getFroalaInstance(this.editor);
        },
        contentChanged: () => {
          this.handleModelChange(this.editor.html.get());
        },
        'image.beforeUpload': function(files) {
          let editor = this;
          if (files.length) {
            let reader = new FileReader();

            reader.onload = function(e) {
              let result = e.target.result;
              editor.image.insert(result, null, null, editor.image.get());
            };

            reader.readAsDataURL(files[0]);
          }

          editor.popups.hideAll();

          return false;
        },
      },
    };

    this.config = this.props.updateConfig(defaultConfig);

    this.state = {
      model: this.props.children,
    };
    this.value = this.props.children || '';
  }

  cleanHTML = html => {
    const cleanHtml = html.replace(/align="[^"]*"/g, '').replace(/id="[^"]*"/g, '');
    return cleanHtml;
  };

  handleModelChange = model => {
    this.value = model;
    this.setState({
      model,
    });
    this.props.onChange(model);
  };

  componentDidMount() {
    this.props.templatePlaceholder.forEach(item => {
      /* eslint-disable */

      FroalaEditor.DefineIconTemplate('fa5template', '<i class="fa fa-[FA5NAME]" aria-hidden="true"></i>');
      FroalaEditor.DefineIcon(item.title, { FA5NAME: item.icon, template: 'fa5template' });
      FroalaEditor.RegisterCommand(item.title, {
        title: item.title + ' Platzhalter',
        type: 'dropdown',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        options: item.value.reduce(
          (accumulator, object) => ({
            ...accumulator,
            [`##${object.key}##`]: object.value,
          }),
          {},
        ),
        callback: function(cmd, val) {
          this.html.insert(val);
        },
      });
      /* eslint-enable */
    });

    this.editor = new FroalaEditor('#froala-editor', this.config); // eslint-disable-line
  }

  render() {
    return (
      <div className='Editor'>
        {!this.props.editable ? (
          <div id='preview' className='fr-view'>
            <RenderHTML>{this.cleanHTML(this.props.children)}</RenderHTML>
          </div>
        ) : (
          <textarea id='froala-editor' defaultValue={this.state.model}></textarea>
        )}
      </div>
    );
  }
}

Editor.propTypes = {
  children: PropTypes.string,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  getFroalaInstance: PropTypes.func,
  updateConfig: PropTypes.func,
};

Editor.defaultProps = {
  children: '',
  onChange: () => {},
  updateConfig: config => config,
  editable: false,
  getFroalaInstance: () => {},
  templatePlaceholder: [],
};

export default Editor;
