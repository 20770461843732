import React from 'react';
import PropTypes from 'prop-types';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.inputTypeNames = ['Input', 'Datepicker', 'Dropdown', 'Switch'];
    this.inputs = [];
  }

  validate = async () => {
    const inputs = this.inputs.filter(Boolean);
    if (!inputs || inputs.length === 0) {
      return true;
    }

    return await new Promise(resolve => {
      const promises = inputs.map(
        input =>
          new Promise(async res => {
            if (input) {
              const valid = await input.validate();
              res(valid);
            }
          }),
      );
      Promise.all(promises).then(values => {
        resolve(values.every(value => value));
      });
    }).then(valid => valid);
  };

  componentDidMount() {
    this.validate();
  }

  handleSubmit = evt => {
    evt.preventDefault();
    this.validate();
    return false;
  };

  hijackAllInputs = children => {
    this.inputs = [];
    return React.Children.map(children, (child, index) => {
      if (child && child.type && this.inputTypeNames.includes(child.type.name)) {
        // HIJACK THEM
        if (child.props && child.props.children) {
          return React.cloneElement(child, {
            ...child.props,
            ref: node => {
              if (node) this.inputs.push(node);
            },
            children: this.hijackAllInputs(child.props.children),
          });
        }
        return React.cloneElement(child, {
          ...child.props,
          ref: node => {
            if (node) this.inputs.push(node);
          },
        });
      }
      if (child && child.props && child.props.children) {
        return React.cloneElement(child, {
          ...child.props,
          key: index,
          children: this.hijackAllInputs(child.props.children),
        });
      }
      return child;
    });
  };

  render() {
    return (
      <form style={{ width: '100%' }} ref={node => (this.form = node)} action='/' onSubmit={this.handleSubmit} method='POST'>
        {this.hijackAllInputs(this.props.children)}
      </form>
    );
  }
}

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Form;
