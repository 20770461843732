import React, { Component } from 'react';
import moment from 'moment';
import { faTrash, faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import swal from 'sweetalert';
import { withRouter } from 'react-router-dom';
import reqwest from 'reqwest';

import Loader from '../../../components/Loader';
import Form from '../../../components/Form';
import Datepicker from '../../../components/Inputs/Datepicker';
import Dropdown from '../../../components/Inputs/Dropdown';
import Input from '../../../components/Inputs/Input';
import ContentBox from '../../../components/FormArea/ContentBox';
import Button from '../../../components/Button';
import settings from '../../../../settings';
import RightSide from '../../../components/FormArea/RightSide';
import DataContainer from '../../../logic/dataContainer';

class VacationRequestDetail extends Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');
    this.state = {
      loading: true,
    };

    reqwest({
      method: 'GET',
      url: settings.vacation.get,
      data: {
        Guid: props.match.params.id,
      },
    }).then(data => {
      this.dataContainer = new DataContainer(data);
      this.setState({
        loading: false,
      });
    });

    const userData = JSON.parse(localStorage.getItem('userdata'));
    this.UserGuid = userData.userGuid;
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    const data = this.dataContainer.getStringified();

    reqwest({
      method: 'GET',
      url: settings.vacation.save,
      data: {
        fd: '10',
        Vacation: data,
      },
    })
      .then(() => {
        swal({
          title: 'Erfolgreich!',
          text: 'Ihr Urlaubsantrag wurde erfolgreich bearbeitet.',
          icon: 'success',
        });
        this.props.history.replace(this.parentRoute);
      })
      .catch(() => {
        this.props.history.replace(this.parentRoute);
        throw new Error('Something went very very wrong...');
      });
  };

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diesen Urlaubsantrag wirklich löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'GET',
          url: settings.vacation.delete,
          data: {
            Guid: this.dataContainer('Basic', 'Guid'),
          },
        })
          .then(() => {
            swal({
              title: 'Erfolgreich!',
              text: 'Der Urlaubsantrag wurde erfolgreich gelöscht.',
              icon: 'success',
            });
            this.props.history.replace(this.parentRoute);
          })
          .catch(() => {
            throw new Error('Something went very very wrong...');
          });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div className='FormArea'>
        <div className='FormArea-Top'>
          <p className='FormArea-Title'>Urlaubsantrag</p>
        </div>
        <div className='FormArea-Body'>
          <div className='FormArea-Body-Content'>
            <div className='FormArea-Body-Content-Center'>
              <div className='FormArea-Form'>
                <div className='FormArea-Form-Left'>
                  <Form ref={node => (this.form = node)}>
                    {this.dataContainer.get('General', 'CanEdit') && (
                      <ContentBox title='Basisdaten'>
                        <Datepicker
                          label='Von: '
                          isDisabled={!this.dataContainer.get('General', 'CanEdit')}
                          onChange={day => {
                            this.dataContainer.set('General', 'From')(day);
                          }}
                          defaultValue={this.dataContainer.get('General', 'From')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                        />
                        <Datepicker
                          label='Bis: '
                          isDisabled={!this.dataContainer.get('General', 'CanEdit')}
                          onChange={day => {
                            this.dataContainer.set('General', 'To')(day);
                          }}
                          defaultValue={this.dataContainer.get('General', 'To')}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                        />
                        <Dropdown
                          label='Zeitraum: '
                          isDisabled={!this.dataContainer.get('General', 'CanEdit')}
                          isSearchable
                          getSelected={selected => {
                            this.dataContainer.set('General', 'Type')(selected);
                          }}
                          defaultValue={this.dataContainer.get('General', 'Type')}
                          options={[
                            { value: 'Fullday', label: 'Ganzer Tag' },
                            { value: 'Halfday', label: 'Halber Tag' },
                          ]}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                        />
                        <Dropdown
                          label='Typ: '
                          isDisabled={!this.dataContainer.get('General', 'CanEdit')}
                          isSearchable
                          getSelected={selected => {
                            this.dataContainer.set('General', 'VacationType')(selected);
                          }}
                          defaultValue={this.dataContainer.get('General', 'VacationType')}
                          options={[
                            { value: 'Urlaub', label: 'Urlaub' },
                            { value: 'Kur', label: 'Kur' },
                            { value: 'Elternzeit', label: 'Elternzeit' },
                          ]}
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                        />
                        <Input
                          type='text'
                          label='Kommentar: '
                          validator={[
                            {
                              required: true,
                              error: 'Bitte füllen Sie dieses Feld aus',
                            },
                          ]}
                          isDisabled={!this.dataContainer.get('General', 'CanEdit')}
                          onChange={value => {
                            this.dataContainer.set('General', 'Comment')(value);
                          }}
                          defaultValue={this.dataContainer.get('General', 'Comment')}
                          name='Kommentar'
                        />
                      </ContentBox>
                    )}
                    {this.dataContainer.get('EditorFields', 'CanEdit') && (
                      <ContentBox title='Genehmigen / Ablehnen'>
                        <Dropdown
                          label='Status: '
                          isDisabled={!this.dataContainer.get('EditorFields', 'CanEdit')}
                          isSearchable
                          getSelected={selected => {
                            this.dataContainer.set('EditorFields', 'Status')(selected);
                            this.dataContainer.set('EditorFields', 'Time')(moment().format('DD.MM.YYYY'));
                            this.dataContainer.set('EditorFields', 'Editor')(this.UserGuid);
                          }}
                          defaultValue={this.dataContainer.get('EditorFields', 'Status')}
                          options={[
                            { value: 'Beantragt', label: 'Beantragt' },
                            { value: 'Genehmigt', label: 'Genehmigt' },
                            { value: 'Abgelehnt', label: 'Abgelehnt' },
                          ]}
                          validator={[]}
                        />
                        <Input
                          type='text'
                          label='Grund: '
                          validator={[]}
                          isDisabled={!this.dataContainer.get('EditorFields', 'CanEdit')}
                          onChange={value => {
                            this.dataContainer.set('EditorFields', 'Cause')(value);
                            this.dataContainer.set('EditorFields', 'Time')(moment().format('DD.MM.YYYY'));
                            this.dataContainer.set('EditorFields', 'Editor')(this.UserGuid);
                          }}
                          defaultValue={this.dataContainer.get('General', 'Cause')}
                          name='Grund'
                        />
                      </ContentBox>
                    )}
                  </Form>
                </div>
                <RightSide>
                  <Button big type='danger' icon={faTrash} onClick={this.delete}>
                    Löschen
                  </Button>
                  <Button big icon={faTimes} to={this.parentRoute}>
                    Abbrechen
                  </Button>
                  <Button big type='primary' icon={faSave} onClick={this.save}>
                    Speichern
                  </Button>
                </RightSide>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(VacationRequestDetail);
