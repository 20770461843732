import React, { Component } from 'react';
import swal from '@sweetalert/with-react';

import reqwest from 'reqwest';
import settings from '../../../../settings';

import Loader from '../../../components/Loader';
import ContentBox from '../../../components/FormArea/ContentBox';
import RightSide from '../../../components/FormArea/RightSide';
import Button from '../../../components/Button';

import downloadB64 from '../../../logic/base64Downloader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faTrash, faPen, faFileAlt, faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';
import Editor from '../../../components/Editor';

class SectorMagazineDetail extends Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');
    this.userRoles = JSON.parse(localStorage.getItem('userdata')).roles;
    this.userHasAdministrativeActions = this.userRoles.some(role =>
      ['Superuser', 'Geschäftsführer', 'GF', 'Regionalleitung', 'Marketing', 'Schulung / Personal'].includes(role),
    );
    this.commentsPerPage = 4;

    this.state = {
      loading: true,
      FileGuids: '',
      Files: [],
      Basic: {},
      General: {},
      RightSide: {},
    };

    reqwest({
      method: 'GET',
      url: settings.news.default.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: props.match.params.id,
      },
    }).then(res => {
      res = JSON.parse(res);

      const files = [];
      const filesQueue = [];
      const fileGuids = res[1].Params?.Files.split(';');
      fileGuids.forEach(fileGuid => {
        if (!fileGuid) return;
        filesQueue.push(
          reqwest({
            method: 'get',
            url: settings.files.getHead,
            data: {
              Token: localStorage.getItem('token'),
              Guid: fileGuid,
            },
          }).then(fileResult => {
            fileResult = JSON.parse(fileResult).Params;

            files.push(fileResult);
          }),
        );
      });

      Promise.all(filesQueue).then(() => {
        this.setState({
          loading: false,
          Files: files,
          FileGuids: fileGuids,
          Basic: {
            ...res[0].Params,
          },
          General: {
            Categories: JSON.parse(res[1].Params.Categories),
            ...res[1].Params,
          },
          RightSide: {
            ...res[2].Params,
          },
        });
      });
    });
  }

  confirm = () => {
    reqwest({
      method: 'GET',
      url: settings.news.default.confirmRead,
      data: {
        Token: localStorage.getItem('token'),
        NewsGuid: this.props.match.params.id,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Sie haben die Branchenmagazin erfolgreich als gelesen makiert.',
        icon: 'success',
      });
    });
  };

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diese Branchenmagazin wirklich löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'POST',
          url: settings.news.default.delete,
          data: {
            Token: localStorage.getItem('token'),
            Guid: this.props.match.params.id,
          },
        })
          .then(() => {
            swal({
              title: 'Erfolgreich!',
              text: 'Branchenmagazin wurde erfolgreich gelöscht.',
              icon: 'success',
            });
            this.props.history.replace(this.parentRoute);
          })
          .catch(() => {
            throw new Error('Something went very very wrong...');
          });
      }
    });
  };

  publish = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      content: (
        <p>
          Möchten Sie dieses Branchenmagazin wirklich veröffentlichen? Danach wird Sie an ausgewählte Mitarbeiter verschickt und Sie können den Titel
          nicht mehr ändern!
        </p>
      ),
      icon: 'warning',
      buttons: ['Abbrechen', 'Veröffentlichen'],
      dangerMode: true,
    }).then(willPublish => {
      if (willPublish) {
        let toPublish = [{}, {}, {}];

        toPublish[0].ParamName = 'Basic';
        toPublish[0].Params = this.state.Basic;

        toPublish[1].ParamName = 'General';
        toPublish[1].Params = this.state.General;
        toPublish[1].Params.Released = 'true';

        toPublish[2].ParamName = 'RightSide';
        toPublish[2].Params = this.state.RightSide;

        reqwest({
          method: 'post',
          url: settings.news.default.publishNews,
          data: {
            Token: localStorage.getItem('token'),
            News: JSON.stringify(toPublish),
          },
        })
          .then(() => {
            swal({
              title: 'Erfolgreich!',
              text: 'Branchenmagazin wurden erfolreich veröffentlicht!',
              icon: 'success',
            });
            this.props.history.replace(this.parentRoute);
          })
          .catch(() => {
            throw new Error('Something went very very wrong...');
          });
      }
    });
  };

  download = guid => {
    reqwest({
      method: 'GET',
      url: settings.files.get,
      data: {
        Token: localStorage.getItem('token'),
        Guid: guid,
      },
    }).then(fileResult => {
      fileResult = JSON.parse(fileResult).Params;

      downloadB64(fileResult.Data, fileResult.Filename);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea'>
          <div className='FormArea-Top'>
            <p className='FormArea-Title'>{this.state.General.Title}</p>
          </div>
          <div className='FormArea-Body'>
            <div className='FormArea-Body-Content'>
              <div className='FormArea-Body-Content-Center'>
                <div className='FormArea-Form'>
                  <div className='FormArea-Form-Left'>
                    <ContentBox>
                      <div className='NewsContent'>
                        <Editor>{this.state.General.Text}</Editor>
                      </div>
                    </ContentBox>
                    {this.state.Files.length !== 0 && (
                      <div className='Attachements'>
                        <h2 className='Attachements-Title'>Anhänge</h2>
                        <div className='Attachements-Files'>
                          {this.state.Files.map(file => (
                            <button
                              key={file.Guid}
                              className='Attachements-Button'
                              onClick={() => {
                                this.download(file.Guid);
                              }}
                            >
                              <FontAwesomeIcon className='Attachements-Button-Icon' icon={faFileAlt} />
                              <span className='Attachements-Button-Name'>{file.Filename}</span>{' '}
                              <small>({(file.Filesize / 1000000).toFixed(2)}mb)</small>
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <RightSide
                    createDate={this.state.RightSide.CreateDate}
                    createUserName={this.state.RightSide.CreateUserName}
                    updateDate={this.state.RightSide.UpdateDate}
                    updateUserName={this.state.RightSide.UpdateUserName}
                  >
                    <Button big icon={faTimes} to={this.parentRoute}>
                      Abbrechen
                    </Button>
                    {(this.state.General.Confirm || this.state.General.Status === 'confirmed') && (
                      <Button big icon={faCheck} type='primary' onClick={this.confirm}>
                        Bestätigen
                      </Button>
                    )}
                    {this.userHasAdministrativeActions && (
                      <>
                        <label className='LabelInRightside'>Administrative Aktionen: </label>
                        {(this.state.General.Released === 'true' || this.state.General.Confirm === 'true') &&
                          this.state.General.Status !== 'confirmed' && (
                            <Button big icon={faBadgeCheck} type='warning' onClick={this.publish}>
                              Veröffentlichen
                            </Button>
                          )}
                        <Button big icon={faTrash} type='danger' onClick={this.delete}>
                          Löschen
                        </Button>
                        <Button big icon={faPen} type='default' to={`${this.parentRoute}/edit/${this.props.match.params.id}`}>
                          Bearbeiten
                        </Button>
                      </>
                    )}
                  </RightSide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SectorMagazineDetail;
