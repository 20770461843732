import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';
import sha512 from 'sha512';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Inputs/Input';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import { faTimes, faSave, faTrash } from '@fortawesome/pro-solid-svg-icons';
import getConstants from '../../../../../logic/constants';
import checkAvailable from '../../../../../logic/checkAvailable';

class EmployeesDetail extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');

    this.state = {
      loading: true,
      positions: [],
    };

    const employeePromise = reqwest({
      method: 'GET',
      url: settings.franchise.user.get,
      contentType: 'JSON',
      data: {
        Token: localStorage.getItem('token'),
        Guid: props.match.params.id,
      },
    }).then(employeeResponse => {
      this.dataContainer = new DataContainer(employeeResponse);
    });

    const positionPromise = getConstants('FranPositiontypes').then(positions => {
      this.setState({
        positions,
      });
    });

    Promise.all([employeePromise, positionPromise]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    // TODO: Switch to List of Datacontainers
    const newUser = {
      ParamName: 'Entry',
      Params: {
        ...this.dataContainer.get('Basic'),
        ...this.dataContainer.get('Generals'),
        ...this.dataContainer.get('Login'),
        Password: sha512(this.state.Password).toString('hex'),
      },
    };

    reqwest({
      method: 'POST',
      url: settings.franchise.user.save,
      data: {
        User: JSON.stringify(newUser),
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Ihr Mitarbeiter wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diesen Mitarbeiter wirklich löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'GET',
          url: settings.franchise.user.delete,
          data: {
            Guid: this.dataContainer.get('Basic', 'Guid'),
          },
        }).then(deleteResult => {
          deleteResult = JSON.parse(deleteResult).DeleteResult;
          if (deleteResult === 'Deleted') {
            swal({
              title: 'Erfolgreich!',
              text: 'Mitarbeiter wurde erfolgreich gelöscht.',
              icon: 'success',
            });
            this.props.history.replace(this.parentRoute);
            return;
          }
          swal({
            title: 'Fehler!',
            text: 'Der Mitarbeiter konnte nicht gelöscht werden.',
            icon: 'error',
          });
          this.props.history.replace(this.parentRoute);
          return;
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Mitarbeiter'>
              <Input
                type='text'
                label='Name:'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                name='Nachname'
                onChange={value => {
                  this.dataContainer.set('Generals', 'Lastname')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Lastname')}
              />
              <Input
                type='text'
                label='Vorname:'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                name='Vorname'
                onChange={value => {
                  this.dataContainer.set('Generals', 'Firstname')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Firstname')}
              />
              <Input
                type='text'
                label='E-Mail:'
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                  {
                    type: 'email',
                    error: 'Die E-Mail Adresse scheint nicht gültig zu sein',
                  },
                ]}
                name='EMail'
                onChange={value => {
                  this.dataContainer.set('Generals', 'EMail')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'EMail')}
              />
              <Dropdown
                label='Position'
                isSearchable
                getSelected={value => {
                  this.dataContainer.set('Generals', 'Position')(value);
                }}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                defaultValue={this.dataContainer.get('Generals', 'Position')}
                options={this.state.positions}
                name='Position'
              />
            </ContentBox>
            <ContentBox title='Anmeldedaten'>
              <Input
                type='text'
                label='Nutzername:'
                validator={[
                  {
                    type: 'username',
                    error: 'Mindestens 2 Zeichen maximal 15. Keine Leerzeichen.',
                  },
                  {
                    custom: async value => {
                      if (this.dataContainer.getInitial('Login', 'Username') !== value) {
                        return await checkAvailable('username', value);
                      }
                      return true;
                    },
                    error: 'Dieser Nutzername wird bereits verwendet',
                  },
                ]}
                fullWidth
                name='Nutzername'
                onChange={value => {
                  this.dataContainer.set('Login', 'Username')(value);
                }}
                defaultValue={this.dataContainer.get('Login', 'Username')}
              />
              <Input
                type='password'
                label='Passwort:'
                passwordShowButton
                passwordValidator
                validator={[]}
                name='Passwort'
                onChange={value => {
                  this.dataContainer.set('Login', 'Password')(value);
                  this.setState({
                    Password: value,
                  });
                }}
              />
              <Input
                type='password'
                label='Passwort wiederholen:'
                validator={[
                  {
                    match: this.state.Password,
                    error: 'Die Passwörter müssen übereinstimmen.',
                  },
                ]}
                name='Passwortrepeat'
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide
          createDate={this.dataContainer.get('RightSide', 'CreateDate')}
          createUserName={this.dataContainer.get('RightSide', 'CreateUserName')}
          updateDate={this.dataContainer.get('RightSide', 'UpdateDate')}
          updateUserName={this.dataContainer.get('RightSide', 'UpdateUserName')}
        >
          <Button big type='danger' icon={faTrash} onClick={this.delete}>
            Löschen
          </Button>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default EmployeesDetail;
