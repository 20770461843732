import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Datepicker from '../../../../../components/Inputs/Datepicker';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import { faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import getConstants from '../../../../../logic/constants';

class CentralMarketingNew extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(/\/new$/, '');
    this.dataContainer = new DataContainer();
    this.dataContainer.add('Basic', {
      Guid: '',
      Typ: 'CentralMarketing',
      FranGuid: this.props.franchiseGuid,
    });
    this.dataContainer.add('Generals', {
      Date: '',
      Action: '',
    });

    this.state = {
      loading: true,
      actionTypes: [],
    };

    getConstants('CentralMarketingActionTypes').then(actionTypes => {
      this.setState({
        loading: false,
        actionTypes,
      });
    });
  }

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    // TODO: Switch to List of Datacontainers
    const newMarketing = {
      ParamName: 'Entry',
      Params: {
        ...this.dataContainer.get('Basic'),
        ...this.dataContainer.get('Generals'),
      },
    };

    reqwest({
      method: 'POST',
      url: settings.franchise.marketing.save,
      data: {
        Token: localStorage.getItem('token'),
        Marketing: JSON.stringify(newMarketing),
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Ihr Marketing wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='Zentrales Marketing'>
              <Datepicker
                label='Datum:'
                onChange={day => {
                  this.dataContainer.set('Generals', 'Date')(day);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Date')}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                name='Date'
              />
              <Dropdown
                label='Aktion:'
                isSearchable
                getSelected={value => {
                  this.dataContainer.set('Generals', 'Action')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Action')}
                options={this.state.actionTypes}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                name='Action'
              />
            </ContentBox>
          </Form>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default CentralMarketingNew;
