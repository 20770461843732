import React from 'react';
import reqwest from 'reqwest';
import swal from 'sweetalert';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import settings from '../../../../../../settings';
import DataContainer from '../../../../../logic/dataContainer';

import RightSide from '../../../../../components/FormArea/RightSide';
import Loader from '../../../../../components/Loader';
import Form from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Inputs/Input';
import Datepicker from '../../../../../components/Inputs/Datepicker';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import { faTrash, faTimes, faSave } from '@fortawesome/pro-solid-svg-icons';
import getConstants from '../../../../../logic/constants';

const moment = extendMoment(Moment);

class OpeningHoursDetails extends React.Component {
  constructor(props) {
    super(props);

    this.parentRoute = this.props.match.url.replace(new RegExp(`/${props.match.params.id}$`), '');

    this.state = {
      loading: true,
      weekdays: [],
      openingHours: [],
      openingHourList: [],
    };

    const promiseOpeningHour = reqwest({
      method: 'get',
      url: settings.franchise.openingHours.get,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
        Guid: props.match.params.id,
      },
    }).then(openingHourResponse => {
      this.dataContainer = new DataContainer(openingHourResponse);
      this.OpeningHourItems = this.dataContainer.get('Generals', 'OpeningHourItems');
    });

    const promiseOpeningHourList = reqwest({
      method: 'get',
      url: settings.franchise.openingHours.getList,
      contentType: 'json',
      data: {
        Token: localStorage.getItem('token'),
        FranGuid: this.props.franchiseGuid,
      },
    }).then(openingHoursResponse => {
      const openingHourList = JSON.parse(openingHoursResponse);
      this.setState({
        openingHourList,
      });
    });

    const promiseWeekday = getConstants('Weekday').then(weekdays => {
      this.setState({
        weekdays,
      });
    });

    Promise.all([promiseOpeningHour, promiseOpeningHourList, promiseWeekday]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  save = async () => {
    const openingHourList = this.state.openingHourList
      .filter(entry => {
        return entry.Params.Guid !== this.props.match.params.id;
      })
      .map(entry => {
        return {
          from: entry.Params.Validfrom,
          to: entry.Params.Validto,
        };
      });
    const enteredRange = moment.range(
      moment(this.dataContainer.get('Generals', 'Validfrom'), 'DD.MM.YYYY'),
      moment(this.dataContainer.get('Generals', 'Validto'), 'DD.MM.YYYY'),
    );
    const rangeValid = openingHourList.every(openingHour => {
      const range = moment.range(moment(openingHour.from, 'DD.MM.YYYY'), moment(openingHour.to, 'DD.MM.YYYY'));
      return !enteredRange.overlaps(range);
    });

    if (!rangeValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Die eingegebenen Daten überschneiden sich mit anderen Öffnungszeiten',
      });
      return;
    }

    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Überprüfung fehlgeschlagen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
      });
      return;
    }

    this.dataContainer.set('Generals', 'OpeningHourItems')(this.OpeningHourItems);
    const data = this.dataContainer.getStringified();

    reqwest({
      method: 'post',
      url: settings.franchise.openingHours.save,
      data: {
        OpeningHours: data,
        FranGuid: this.props.franchiseGuid,
      },
    }).then(() => {
      swal({
        title: 'Erfolgreich!',
        text: 'Ihre Öffnungszeit wurde erfolgreich gespeichert.',
        icon: 'success',
      });
      this.props.history.replace(this.parentRoute);
    });
  };

  delete = () => {
    swal({
      title: 'Sind Sie sich sicher?',
      text: 'Möchten Sie diese Öffnungszeit wirklich löschen?',
      icon: 'warning',
      buttons: ['Abbrechen', 'Löschen'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        reqwest({
          method: 'GET',
          url: settings.franchise.openingHours.delete,
          data: {
            Guid: this.dataContainer.get('Basic', 'Guid'),
          },
        }).then(() => {
          swal({
            title: 'Erfolgreich!',
            text: 'Ihre Öffnungszeit wurde erfolgreich gelöscht.',
            icon: 'success',
          });
          this.props.history.replace(this.parentRoute);
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <>
        <div className='FormArea-Form-Left'>
          <Form ref={node => (this.form = node)}>
            <ContentBox title='General'>
              <Input
                type='text'
                label='Titel:'
                fullWidth
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                ]}
                onChange={value => {
                  this.dataContainer.set('Generals', 'Title')(value);
                }}
                defaultValue={this.dataContainer.get('Generals', 'Title')}
                name='Titel'
              />
              <Datepicker
                label='Von:'
                onChange={day => {
                  this.dataContainer.set('Generals', 'Validfrom')(day);
                }}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                  {
                    custom: value => {
                      if (!this.dataContainer.get('Generals', 'Validto')) {
                        return true;
                      }
                      const validFrom = moment(value, 'DD.MM.YYYY');
                      const validTo = moment(this.dataContainer.get('Generals', 'Validto'), 'DD.MM.YYYY');
                      return validFrom.isBefore(validTo);
                    },
                    error: 'Von darf nicht nach Bis liegen.',
                  },
                ]}
                defaultValue={this.dataContainer.get('Generals', 'Validfrom')}
              />
              <Datepicker
                label='Bis:'
                onChange={day => {
                  this.dataContainer.set('Generals', 'Validto')(day);
                }}
                validator={[
                  {
                    required: true,
                    error: 'Bitte füllen Sie dieses Feld aus',
                  },
                  {
                    custom: value => {
                      if (!this.dataContainer.get('Generals', 'Validfrom')) {
                        return true;
                      }
                      const validFrom = moment(this.dataContainer.get('Generals', 'Validfrom'), 'DD.MM.YYYY');
                      const validTo = moment(value, 'DD.MM.YYYY');
                      return validTo.isAfter(validFrom);
                    },
                    error: 'Bis darf nicht vor Von liegen.',
                  },
                ]}
                defaultValue={this.dataContainer.get('Generals', 'Validto')}
              />
            </ContentBox>
            <ContentBox title='Öffnungszeiten'>
              <div className='OpeningHourItem-Wrapper'>
                {this.state.weekdays.map((item, mapIndex) => {
                  let ohItemIndex = this.OpeningHourItems.findIndex(oh => oh.Params.Day === item.value);
                  if (ohItemIndex === -1) {
                    this.OpeningHourItems.push({
                      Params: {
                        Day: item.value,
                        From: '',
                        To: '',
                      },
                    });
                    ohItemIndex = this.OpeningHourItems.length - 1;
                  }
                  return (
                    <div className='OpeningHourItem' key={item.value}>
                      <div className='OpeningHourItem-Label'>{item.label}:</div>
                      <Input
                        label='Von:'
                        placeholder='08:00'
                        onChange={value => {
                          this.OpeningHourItems[ohItemIndex] = {
                            Params: {
                              ...this.OpeningHourItems[ohItemIndex].Params,
                              From: value,
                            },
                          };
                        }}
                        validator={[
                          {
                            type: 'time',
                            error: 'Bitte geben Sie eine valide Uhrzeit an',
                          },
                        ]}
                        defaultValue={this.OpeningHourItems[ohItemIndex].Params.From}
                        name={`From-${mapIndex}`}
                      />
                      <Input
                        label='Bis:'
                        placeholder='17:00'
                        onChange={value => {
                          this.OpeningHourItems[ohItemIndex] = {
                            Params: {
                              ...this.OpeningHourItems[ohItemIndex].Params,
                              To: value,
                            },
                          };
                        }}
                        validator={[
                          {
                            type: 'time',
                            error: 'Bitte geben Sie eine valide Uhrzeit an',
                          },
                        ]}
                        defaultValue={this.OpeningHourItems[ohItemIndex].Params.To}
                        name={`To-${mapIndex}`}
                      />
                    </div>
                  );
                })}
              </div>
            </ContentBox>
          </Form>
        </div>
        <RightSide>
          <Button big icon={faTimes} to={this.parentRoute}>
            Abbrechen
          </Button>
          <Button big type='danger' icon={faTrash} onClick={this.delete}>
            Löschen
          </Button>
          <Button big type='primary' icon={faSave} onClick={this.save}>
            Speichern
          </Button>
        </RightSide>
      </>
    );
  }
}

export default OpeningHoursDetails;
