const settings = {};

const URL = `${window.location.origin}/api`;

settings.general = {
  login: `${URL}/Home/Login`,
  checkToken: `${URL}/Home/CheckToken`,
  logout: `${URL}/Home/Logout`,
  getMenu: `${URL}/Dashboard/Menu`,
};

settings.widgets = {
  general: {
    get: `${URL}/Dashboard/GetUserWidgets`,
    save: `${URL}/Dashboard/UpdateWidgets`,
  },
  didYouKnow: {
    get: `${URL}/Widget/GetDidYouKnow`,
  },
  news: {
    get: `${URL}/Widget/GetNewsWidget`, // UserGuid,
  },
};

settings.validators = {
  email: `${URL}/Validate/ValidateEmail`,
  username: `${URL}/Validate/ValidateUsername`,
  expnr: `${URL}/Validate/ValidateExpnr`,
  ccr: `${URL}/Validate/ValidateCcr`,
  agencynumber: `${URL}/Validate/ValidateAgencynumber`,
};

settings.constants = {
  getList: `${URL}/Constants/GetConstantsList`,
  getTypes: `${URL}/Constants/GetConstantsTypes`,
  save: `${URL}/Constants/SaveConstantsItem`,
  delete: `${URL}/Constants/RemoveConstant`,
};

settings.azn = {
  getLastTimestate: `${URL}/TimeManager/GetLastTimeState`,
  getAZNForDay: `${URL}/TimeManager/GetAznForDay`,
  saveAZN: `${URL}/TimeManager/SaveNewTime`,
  timetracking: {
    getList: `${URL}/Azn/GetAznMonth`, // Month, Year, UserGuid
    get: `${URL}/Azn/GetCorrectionRequest`, // Guid
    save: `${URL}/Azn/SaveCorrectionRequest`, // CorrectionRequest  --> JSON {List<DataContainer>}
    delete: `${URL}/Azn/RemoveCorrectionRequest`, // Guid
  },
};

settings.vacation = {
  get: `${URL}/TimeManager/GetVacationItem`,
  save: `${URL}/TimeManager/SaveVacationItem`,
  delete: `${URL}/TimeManager/RemoveVacationItem`,
  getStats: `${URL}/TimeManager/CreateNewVacation`,
  getAbsenceOverview: `${URL}/TimeManager/GetUserAbsenceOverview`,
};

settings.sick = {
  get: `${URL}/TimeManager/GetSickItem`,
  save: `${URL}/TimeManager/SaveSickItem`,
  delete: `${URL}/TimeManager/RemoveSickItem`,
};

settings.usermanagement = {
  general: {
    get: `${URL}/User/GetUser`,
    save: `${URL}/User/SaveUser`,
    delete: `${URL}/User/DeleteUser`,
    getList: `${URL}/User/GetAllUserList`,
    getUserForPosition: `${URL}/User/GetUserForPosition`,
  },
  contracts: {
    get: `${URL}/User/GetContractData`,
    save: `${URL}/User/SaveContract`,
    delete: `${URL}/User/RemoveContract`,
    getList: `${URL}/User/GetContracts`,
  },
  personalAccess: {
    get: `${URL}/User/GetPersonalAccess`,
    save: `${URL}/User/SavePersonalAccess`,
    delete: `${URL}/User/RemovePersonalAccess`,
    getList: `${URL}/User/GetPersonalAccessList`,
  },
  trainings: {
    getList: `${URL}/User/GetTrainingList`,
  },
  originatingOffice: {
    get: `${URL}/User/GetUserOffice`,
    save: `${URL}/User/SaveUserOffice`,
    delete: `${URL}/User/RemoveUserOffice`,
    getList: `${URL}/User/GetUserOfficeList`,
    getOfficeList: `${URL}/Office/GetOfficeList`,
  },
  files: {
    get: `${URL}/User/GetUserFile`,
    save: `${URL}/User/SaveUserFiles`,
    delete: `${URL}/User/DeleteUserFile`,
    getList: `${URL}/User/GetUserFiles`,
  },
  travelExpensesRequest: {
    get: `${URL}/User/GetTravelExpensesRequest`,
    getList: `${URL}/User/GetTravelExpensesRequestList`,
    save: `${URL}/User/SaveTravelExpensesRequest`,
  },
};

settings.office = {
  general: {
    get: `${URL}/Office/GetOfficeBasis`,
    save: `${URL}/Office/SaveOfficeBasis`,
    getList: `${URL}/Office/GetAllOfficesList`,
    getShortList: `${URL}/Office/GetOfficeList`,
  },
  edv: {
    get: `${URL}/Inventory/GetEdv`,
    save: `${URL}/Inventory/SaveEdv`,
  },
  marketing: {
    get: `${URL}/Office/GetMarketing`,
    getList: `${URL}/Office/GetMarketingList`,
    save: `${URL}/Office/SaveMarketing`,
    delete: `${URL}/Office/DeleteMarketing`,
  },
  inventory: {
    get: `${URL}/Inventory/GetInventory`,
    save: `${URL}/Inventory/SaveInventory`,
  },
  devices: {
    getList: `${URL}/Inventory/GetDeviceList`,
  },
  phonesystem: {
    get: `${URL}/Inventory/GetPhonesystem`,
    save: `${URL}/Inventory/SavePhonesystem`,
  },
  retirement: {
    get: `${URL}/Office/GetRetirementItem`,
    getList: `${URL}/Office/GetRetirementsList`,
    save: `${URL}/Office/SaveRetirement`,
    delete: `${URL}/Office/RemoveRetirement`,
  },
  fixedCosts: {
    get: `${URL}/Office/GetFixedCostsItem`,
    getList: `${URL}/Office/GetFixedCostsList`,
    save: `${URL}/Office/SaveFixedCosts`,
    delete: `${URL}/Office/RemoveFixedCosts`,
  },
  openingHours: {
    get: `${URL}/Office/GetOpening`,
    save: `${URL}/Office/SaveOpeningHours`,
    delete: `${URL}/Office/RemoveOpeningHour`,
    getList: `${URL}/Office/GetOpeningHours`,
  },
  agencynumbers: {
    get: `${URL}/Office/GetAgency`,
    save: `${URL}/Office/SaveAgencynumber`,
    delete: `${URL}/Office/DeleteAgencynumber`,
    getList: `${URL}/Office/GetAgencyList`,
  },
  invoice: {
    get: `${URL}/Office/GetInvoice`,
    save: `${URL}/Office/SaveInvoice`,
    delete: `${URL}/Office/DeleteInvoice`,
    getList: `${URL}/Office/GetInvoiceList`,
  },
  images: {
    get: `${URL}/Office/GetImage`,
    save: `${URL}/Office/SaveImage`,
    delete: `${URL}/Office/DeleteImage`,
    getList: `${URL}/Office/GetImageList`,
  },
};

settings.franchise = {
  general: {
    getList: `${URL}/Franchise/GetFranchiseList`,
    get: `${URL}/Franchise/GetFranchiseItem`,
    save: `${URL}/Franchise/SaveFranchiseItem`,
    delete: `${URL}/Franchise/DeleteFranchiseItem`,
  },
  marketing: {
    get: `${URL}/Franchise/GetFranMarketing`,
    save: `${URL}/Franchise/SaveFranMarketing`,
    delete: `${URL}/Franchise/DeleteFranMarketing`,
    getList: `${URL}/Franchise/GetFranMarketingList`,
  },
  technic: {
    get: `${URL}/Franchise/GetFrantechnic`,
    save: `${URL}/Franchise/SaveFranTechnic`,
    delete: `${URL}/Franchise/DeleteFranTechnic`,
  },
  training: {
    get: `${URL}/Franchise/GetFranTraining`,
    save: `${URL}/Franchise/SaveFranTraining`,
    delete: `${URL}/Franchise/DeleteFranTraining`,
    getList: `${URL}/Franchise/GetFranTrainingList`,
  },
  contract: {
    get: `${URL}/Franchise/GetFranContract`,
    save: `${URL}/Franchise/SaveFranContract`,
    delete: `${URL}/Franchise/DeleteFranContract`,
    getList: `${URL}/Franchise/GetFranContractList`,
  },
  appearance: {
    get: `${URL}/Franchise/GetFranAppearance`,
    save: `${URL}/Franchise/SaveFranAppearance`,
    delete: `${URL}/Franchise/DeleteFranAppearance`,
    getList: `${URL}/Franchise/GetFranAppearanceList`,
  },
  user: {
    get: `${URL}/Franchise/GetFranUser`,
    save: `${URL}/Franchise/SaveFranUser`,
    delete: `${URL}/Franchise/DeleteFranUser`,
    getList: `${URL}/Franchise/GetFranUserList`,
  },
  openingHours: {
    get: `${URL}/Franchise/GetOpening`,
    save: `${URL}/Franchise/SaveOpeningHours`,
    delete: `${URL}/Franchise/RemoveOpeningHour`,
    getList: `${URL}/Franchise/GetOpeningHours`,
  },
  files: {
    get: `${URL}/Franchise/GetFranchiseFile`,
    save: `${URL}/Franchise/SaveFranchiseFiles`,
    delete: `${URL}/Franchise/DeleteFranchiseFile`,
    getList: `${URL}/Franchise/GetFranchiseFiles`,
  },
  pwmanager: {
    get: `${URL}/Franchise/GetFranchiseAccon`,
    save: `${URL}/Franchise/SaveFranchiseAccon`,
  },
};

settings.systemmanagement = {
  role: {
    get: `${URL}/Systemmanagement/GetRole`,
    save: `${URL}/Systemmanagement/SaveRole`,
    delete: `${URL}/Systemmanagement/RemoveRole`,
    getList: `${URL}/Systemmanagement/GetRoles`,
  },
  right: {
    get: `${URL}/Systemmanagement/GetRight`,
    save: `${URL}/Systemmanagement/SaveRight`,
    delete: `${URL}/Systemmanagement/RemoveRight`,
    getList: `${URL}/Systemmanagement/GetRights`,
  },
  emailclient: {
    get: `${URL}/Systemmanagement/GetEmailClient`, // GET
    save: `${URL}/Systemmanagement/SaveEmailClient`, // POST
    delete: `${URL}/Systemmanagement/RemoveEmailClient`, // POST
    getList: `${URL}/Systemmanagement/GetEmailClients`, // GET
  },
  emailmacro: {
    get: `${URL}/Systemmanagement/GetEmailmacro`,
    save: `${URL}/Systemmanagement/SaveEmailmacro`,
    delete: `${URL}/Systemmanagement/RemoveEmailmacro`,
    getList: `${URL}/Systemmanagement/GetEmailmacros`,
  },
  emailtemplate: {
    get: `${URL}/Systemmanagement/GetEmailtemplate`,
    save: `${URL}/Systemmanagement/SaveEmailtemplate`,
    delete: `${URL}/Systemmanagement/RemoveEmailtemplate`,
    getList: `${URL}/Systemmanagement/GetEmailtemplates`,
  },
  intervalpasswordreset: {
    get: `${URL}/Systemmanagement/GetIntervalPasswordReset`,
    save: `${URL}/Systemmanagement/SaveIntervalPasswordReset`,
    delete: `${URL}/Systemmanagement/RemoveIntervalPasswordReset`,
    getList: `${URL}/Systemmanagement/GetIntervalPasswordResets`,
  },
  scheduler: {
    get: `${URL}/Systemmanagement/GetScheduler`,
    save: `${URL}/Systemmanagement/SaveScheduler`,
    delete: `${URL}/Systemmanagement/RemoveScheduler`,
    getList: `${URL}/Systemmanagement/GetSchedulers`,
  },
};

settings.promoter = {
  general: {
    get: `${URL}/Promoter/GetPromoter`,
    save: `${URL}/Promoter/SavePromoter`,
    delete: `${URL}/Promoter/RemovePromoter`,
    getList: `${URL}/Promoter/GetPromoterList`,
  },
};

settings.trainings = {
  general: {
    get: `${URL}/Training/GetTraining`,
    save: `${URL}/Training/SaveTraining`,
    delete: `${URL}/Training/DeleteTraining`,
    getList: `${URL}/Training/GetTrainingList`,
  },
  registrations: {
    get: `${URL}/Training/GetRegistration`,
    getForTraining: `${URL}/Training/GetRegistrationsForTraining`,
    getForUser: `${URL}/Training/GetRegistrationsForUser`,
    save: `${URL}/Training/SaveRegistration`,
    delete: `${URL}/Training/DeleteRegistration`,
    getList: `${URL}/Training/GetRegistrationList`,
  },
};

settings.news = {
  default: {
    get: `${URL}/News/GetNews`,
    save: `${URL}/News/SaveNews`,
    delete: `${URL}/News/DeleteNews`,
    getList: `${URL}/News/GetNewsList`,
    confirmRead: `${URL}/News/CheckNews`,
    publishNews: `${URL}/News/PublishNews`,
  },
  comments: {
    getComment: `${URL}/News/GetNewsComment`,
    save: `${URL}/News/SaveNewsComment`,
    delete: `${URL}/News/DeleteNewsComment`,
    getList: `${URL}/News/GetNewsCommentList`,
  },
};

settings.files = {
  upload: `${URL}/File/UploadFile`,
  get: `${URL}/File/GetFile`,
  save: `${URL}/File/SaveFile`,
  delete: `${URL}/File/DeleteFile`,
  getHead: `${URL}/File/GetFileHead`,
  getList: `${URL}/File/GetFileList`,
  getListForGuids: `${URL}/File/GetFileListForGuids`,
  getFileHeadList: `${URL}/File/getFileHeadList`,
};

settings.dutySchedule = {
  bookings: {
    save: `${URL}/Roster/SaveBooking`,
    remove: `${URL}/Roster/RemoveBooking`,
    getRange: `${URL}/Roster/GetRosterBookingsForWeek`,
  },
  getRosterOverview: `${URL}/Roster/GetRosterOverview`,
  review: `${URL}/Roster/SendToReview`,
  accept: `${URL}/Roster/Accept`,
  decline: `${URL}/Roster/Decline`,
};

settings.forgot = {
  new: `${URL}/Password/ForgotPassword`,
  check: `${URL}/Password/CheckPasswordToken`,
  save: `${URL}/Password/SaveNewPassword`,
};

settings.visits = {
  get: `${URL}/Protocol/GetProtocol`,
  getList: `${URL}/Protocol/GetProtocolList`,
  save: `${URL}/Protocol/SaveProtocol`,
  delete: `${URL}/Protocol/RemoveProtocol`,
  getListForUser: `${URL}/Protocol/GetProtocolListForUser`,
};

settings.permissions = {
  GetApplicationSections: `${URL}/Permission/GetApplicationSections`,
  GetRightsForSection: `${URL}/Permission/GetRightsForSection`,
  GetAllRights: `${URL}/Permission/GetAllRights`,
  GetAllRoles: `${URL}/Permission/GetAllRoles`,
  GetRightsForRole: `${URL}/Permission/GetRightsForRole`,
  GetPermission: `${URL}/Permission/GetPermission`,
  AddRightsToRole: `${URL}/Permission/AddRightsToRole`,
  AddRight: `${URL}/Permission/AddRight`,
  AddRole: `${URL}/Permission/AddRole`,
  AddSection: `${URL}/Permission/AddSection`,
  RemoveRight: `${URL}/Permission/RemoveRight`,
  RemoveRole: `${URL}/Permission/RemoveRole`,
  RemoveSection: `${URL}/Permission/RemoveSection`,
  RemoveRightFromRole: `${URL}/Permission/RemoveRightFromRole`,
  GetPositionsList: `${URL}/Permission/GetPositionsList`,
  AddRoleToPosition: `${URL}/Permission/AddRoleToPosition`,
  RemoveRoleFromPosition: `${URL}/Permission/RemoveRoleFromPosition`,
};

settings.inventory = {
  GetGroups: `${URL}/Inventory/GetGroups`,
  SaveGroup: `${URL}/Inventory/SaveGroup`,
  GetFields: `${URL}/Inventory/GetFields`,
  SaveField: `${URL}/Inventory/SaveField`,
  GetGroupData: `${URL}/Inventory/GetGroupData`,
  SaveGroupData: `${URL}/Inventory/SaveGroupData`,
  Delete: `${URL}/Inventory/DeleteInventory`,
};

settings.external = {
  weather: 'https://api.openweathermap.org/data/2.5/weather?APPID=0688238fdcb01cd3eb3fb2ed63081027&units=metric&q=',
  zip: 'https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-germany-postleitzahl&rows=1&q=',
  iban: 'https://fintechtoolbox.com/validate/iban.json',
  blz: 'https://fintechtoolbox.com/bankcodes/',
  iban_apikey: 'cO5zWWoaz4K0AgH0Y0bZ75XuCdItc1Mb8I0dgxscTFNrE_9nRJo1ebuLExwnXIvi',
  froala_key:
    process.env.NODE_ENV === 'production'
      ? 'ZOD3gB8C10B5E6C2E3C-8bhirhC-13B-11yyidA-21jstC-11gqgB1twtfA4A3G3I3F2C6A4C4C3A2=='
      : '4NC5fE4G5E3A3F3C6B-16nlgdjogzC-16wD1D1D1G1F1H4C11B1D6F6==',
};

settings.FILEPOND_LABELS = {
  labelIdle: 'Klicken Sie <u><strong>hier</strong></u> um eine Datei hochzuladen oder ziehen Sie diese auf dieses Feld.',
  labelFileProcessing: 'Hochladen ...',
  labelFileProcessingComplete: 'Datei wurde hochgeladen!',
  labelFileProcessingAborted: 'Upload abgebrochen.',
  labelFileProcessingError: 'Upload fehlgeschlagen.',
  labelTapToCancel: 'Abbrechen',
  labelTapToRetry: 'Erneut versuchen?',
  labelTapToUndo: 'Rückgänig machen?',
  labelButtonRemoveItem: 'Löschen',
  labelButtonRetryItemLoad: 'Erneut versuchen?',
  labelButtonAbortItemLoad: 'Abbrechen',
  labelButtonAbortItemProcessing: 'Abbrechen',
  labelButtonUndoItemProcessing: 'Rückgänig machen?',
  labelButtonRetryItemProcessing: 'Erneut versuchen?',
  labelButtonProcessItem: 'Hochladen',
};

export default settings;
